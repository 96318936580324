import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';

class ContactModal extends Component {
  sendMail = e => {
    e.preventDefault();
    const { name, email, company, size, about } = e.target;

    const data = new FormData();
    data.append('name', name.value);
    data.append('email', email.value);
    data.append('company', company.value);
    data.append('size', size.value);
    data.append('about', about.value);
    data.append('source', this.props.source);

    const headers = HEADERS();
    fetch(`${APP_URL}/contact_form`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.props.handleClose();
        });
      });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        dialogClassName="win-modal"
        backdrop
        keyboard
      >
        <div className="modal-inner-wrap">
          <div className="close-cross" onClick={this.props.handleClose} />
          <h1>We will get back to you soon!</h1>
          <form onSubmit={this.sendMail}>
            <div className="form-group">
              <label htmlFor="name">
                <h2>
                  Name
                  <span>*</span>
                </h2>
              </label>
              <input
                name="name"
                required
                type="text"
                className="form-control"
                id="name"
                aria-describedby="name"
                placeholder="Enter name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                <h2>
                  Email address
                  <span>*</span>
                </h2>
              </label>
              <input
                name="email"
                required
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">
                <h2>
                  Company
                  <span>*</span>
                </h2>
              </label>
              <input
                name="company"
                required
                type="text"
                className="form-control"
                id="company"
                aria-describedby="company"
                placeholder="Company name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="companySize">
                <h2>
                  Company size
                  <span>*</span>
                </h2>
              </label>
              <select
                name="size"
                className="form-control custom-select"
                id="companySize"
                aria-describedby="company"
              >
                <option value="0 - 50">0 - 50</option>
                <option value="50 - 100">50 - 100</option>
                <option value="100 - 500">100 - 500</option>
                <option value="500 - 1000">500 - 1000</option>
                <option value="1000+">1000+</option>
              </select>
            </div>
            <input id="hidden-form-section" type="hidden" name="section" value="Chat" />
            <div className="form-group">
              <label htmlFor="about-us">
                <h2>How did you hear about us?</h2>
              </label>
              <textarea
                name="about"
                rows="3"
                className="form-control"
                id="about-us"
                aria-describedby="name"
                placeholder=""
              />
            </div>
            <div>
              <button id="contact-submit" type="submit" className="custom-button btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

ContactModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
};

export default ContactModal;
