import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import loadImage from 'blueimp-load-image';
import PropTypes from 'prop-types';
import qs from 'qs';
import EditMediaWrap from 'components/post/shared/EditMediaWrap';
import mapPlainTextIndex from './utils/react-mentions-utils/mapPlainTextIndex.js';
import findStartOfMentionInPlainText from './utils/react-mentions-utils/findStartOfMentionInPlainText.js';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import { GROW_ACTIONS } from '../../../redux/actions/growActions';
import FileUpload from './partials/FileUpload';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import { isIE, ROTATE_IE_IMAGE } from '../../../helpers';
import GooglePicker from '../../shared/GooglePicker';
import verifyFileSize from '../../shared/fileUpload/verifyFileSize';
import recalculateTotalFileSize from '../../shared/fileUpload/recalculateTotalFileSize';
import verifyVideo from '../../shared/fileUpload/verifyVideo';
import processGoogleFiles from '../../shared/GooglePicker/processGoogleFiles';
import generateFormData from '../../shared/fileUpload/generateFormData';
import BottomBar from './partials/BottomBar';
import { SPACE_ACTIONS } from '../../../redux/actions/spaceActions';
import { initialPostState } from './createPostHelper';
import FeedbackButtons from './CreateNote/FeedbackButtons';
import { FileDropzoneWrap } from './utils/utils';

let initialState = initialPostState;

class CreatePost extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.allFilesSize = 0;
  }

  componentWillUnmount() {
    initialState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.spaceId !== this.props.spaceId) {
      this.setState({ postErrors: [] });
    }
  }

  receiveErrors = errors => {
    if (!errors) {
      this.allFilesSize = 0;
      this.setState(initialState, () => {
      this.props.resetPosts();
      this.props.resetGrowPosts();
      this.setState(initialPostState, () => {
      const spaceQuery =
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).space || '';
      const spaceId = +this.props.spaceId;
      if (+spaceQuery === spaceId) {
        this.props.closePopup();
        this.props.fetchSpaceDetails(spaceId);
        this.props.fetchPosts();
        this.props.fetchGrowPosts();
      } else {
        this.props.closePopup();
        this.props.history.push(`/feed?space=${spaceId}`);
      }
     });
  });
    } else if (errors.error) {
      this.setState({
        postErrors: errors.error,
        disabledButton: false,
      });
    } else {
      this.setState({
        postErrors: errors,
        disabledButton: false,
      });
    }
  };

  generalError = error => {
    if (error) {
      this.setState({
        generalError: error,
        disabledButton: false,
      });
    }
  };

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      charsLeftContent: 1000 - unformattedContent.length,
      errorContent:
        unformattedContent.length > 1000
          ? 'Your post exceeds the maximum length of 1000 characters'
          : '',
      postErrors: [],
      isHashtag:
        this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]hashtags:') ||
        this.checkIfContainHashtagOrMention(input, '#'),
      isMention: this.checkIfContainHashtagOrMention(input, '[-/-markupStart-/-]people:'),
    });
  };

  checkIfContainHashtagOrMention(string, type) {
    return string.includes(type);
  }

  mediaUploadHandler = files => {
    let mediaUrl = [];
    let mediaSize = [];

    if (!this.verifyAndRecalculateFiles(files)) return;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const index = i;

      const type = file.type.split('/')[0];

      if (
        (type === 'image' && !(this.state.mediaUrl[0] && this.state.mediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.mediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            mediaUrl: [],
            disabledButton: false,
            mediaSize: [],
            postErrors: [],
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                mediaUrl[index] = {
                  type: type === 'image' ? 'i' : 'v',
                  url: img,
                };
                mediaSize[index] = { size: file.size };
                if (mediaUrl.filter(Boolean).length === files.length) {
                  this.setState({
                    mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                    disabledButton: false,
                    mediaSize: [...this.state.mediaSize, ...mediaSize],
                    postErrors: [],
                  });
                }
              },
              loadImageOptions
            );
          } else {
            mediaUrl[index] = {
              type: type === 'image' ? 'i' : 'v',
              url: reader.result,
            };
            mediaSize[index] = { size: file.size };
            if (mediaUrl.filter(Boolean).length === files.length) {
              this.setState({
                mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                disabledButton: false,
                mediaSize: [...this.state.mediaSize, ...mediaSize],
                postErrors: [],
              });
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        mediaSize = [];
        mediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  removeMediaHandler = index => {
    const updatedMediaUrl = [...this.state.mediaUrl];
    const updatedMediaSize = [...this.state.mediaSize];
    const { mediaSize } = this.state;

    this.allFilesSize -= mediaSize[index].size;
    updatedMediaUrl.splice(index, 1);
    updatedMediaSize.splice(index, 1);
    this.setState({
      mediaUrl: updatedMediaUrl,
      mediaSize: updatedMediaSize,
      postErrors: [],
    });
  };

  fileUploadHandler = files => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= 10 * 1024 * 1024) {
        alert('One of files is too big! Maximum size allowed is 10MB');
        return;
      }
    }
    this.setState({
      files: [...this.state.files, ...files],
      postErrors: [],
    });
  };

  removeFileHandler = index => {
    const newFilesArray = [...this.state.files];
    newFilesArray.splice(index);
    this.setState({
      files: newFilesArray,
      postErrors: [],
    });
  };

  handleInsertMentionHashtag = event => {
    const { content, selection, inputElement, markup } = this.state;

    const selectionStart = isIE() ? inputElement.selectionStart : selection.start;
    const selectionEnd = isIE() ? inputElement.selectionEnd : selection.end;

    const start = mapPlainTextIndex(content, markup, selectionStart);
    const end = mapPlainTextIndex(content, markup, selectionEnd);

    const preChar = content.charAt(start - 1);
    const pre = /\s/.test(preChar) || !preChar ? '' : ' ';

    const postChar = content.charAt(end);
    const post = /\s/.test(postChar) || !postChar ? '' : ' ';

    const { id } = event.target.dataset;

    const beginning =
      findStartOfMentionInPlainText(content, markup, selectionStart) || selectionStart;
    const pos = beginning + pre.length + id.length;

    this.setState(
      {
        content: content.substr(0, start) + pre + id + post + content.substr(end),
      },
      () => {
        inputElement.selectionStart = pos;
        inputElement.selectionEnd = pos;
        inputElement.focus();
      }
    );
  };

  handleBlur = event => {
    this.setState({ inputElement: event.target });
  };

  handleSelection = event => {
    this.setState({
      inputElement: event.target,
      selection: {
        start: event.target.selectionStart,
        end: event.target.selectionEnd,
      },
    });
  };

  async submitForm() {
    const { mediaUrl, content } = this.state;

    if (content || (!content && mediaUrl.length > 0)) {
      if (this.props.spaceId == null) {
        this.setState({
          errorContent: 'Space cannot be empty',
        });
      } else {
        const data = await generateFormData(
          'Buzz',
          this.state,
          this.props,
          this.allFilesSize,
          this.setState.bind(this)
        );
        this.props.addPost(data, this.receiveErrors, this.generalError);
      }
    } else {
      this.setState({
        errorContent: 'Please provide some content for this post or add an image',
      });
    }
  }

  showMediaDropzone(e) {
    if (this.props.reachedDataLimit) {
      e.preventDefault();
    } else {
      this.setState({ showMediaDropzone: !this.state.showMediaDropzone, showFileDropzone: false });
    }
  }

  showFileDropzone(e) {
    if (this.props.reachedDataLimit) {
      e.preventDefault();
    } else {
      this.setState({ showFileDropzone: !this.state.showFileDropzone, showMediaDropzone: false });
    }
  }

  handleGoogleFiles = files => {
    if (!this.verifyAndRecalculateFiles(files, 'sizeBytes', 'mimeType')) return;

    this.setState(processGoogleFiles(files, this.state));
  };

  verifyAndRecalculateFiles = (files, sizeProperty = 'size', typeProperty = 'type') => {
    if (!verifyVideo(files, this.state.mediaUrl, typeProperty)) return false;
    const verifiedFiles = verifyFileSize(files, this.allFilesSize, sizeProperty);
    this.allFilesSize = verifiedFiles.size;
    if (verifiedFiles.alert) {
      this.allFilesSize = recalculateTotalFileSize(this.state.mediaSize);
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="create-post-tab">
        <div className="create-post-feed">
          <FeedbackButtons
            onClick={this.props.changeTab}
            showMediaDropzone={e => this.showMediaDropzone(e)}
            shownMediaDropzone={this.state.showMediaDropzone}
            showFileDropzone={e => this.showFileDropzone(e)}
            shownFileDropzone={this.state.showFileDropzone}
          />
          <MentionsInputWrapper
            handleBlur={this.handleBlur}
            handleSelection={this.handleSelection}
            value={this.state.content}
            contentChangedHandler={this.contentChangedHandler}
            placeholder="Start typing here or use one of the features above to add photos/videos, give a shoutout,
run a poll and more."
            setInputRef={ref => this.setState({ inputElement: ref })}
          />
          {this.state.errorContent ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.errorContent}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors['post.content'] ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors['post.content'][0]}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors.mention_error && this.state.isMention ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors.mention_error}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.files.map((file, index) => (
            <FileUpload
              file={file}
              typeProperty={file.mimeType ? 'mimeType' : 'type'}
              key={index}
              fileIndex={index}
              removeFileHandler={this.removeFileHandler}
            />
          ))}
          {this.state.showMediaDropzone ? (
            <div className="dropzone-wrap">
              <Dropzone
                onDrop={e => this.mediaUploadHandler(e)}
                accept={{
                  'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
                  'video/*': ['.mp4', '.mov', '.avi'],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        style: {
                          width: '100%',
                          height: '80px',
                          border: 'dashed 1px #11A9FF',
                          borderRadius: '3px',
                          position: 'relative',
                          zIndex: '2',
                        },
                      })}
                    >
                      <input {...getInputProps({ capture: false })} />
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className="dropzone-placeholder">
                <h3>
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.6759 3H17.0344C18.1155 3 19 3.90002 19 5V14C19 15.1 18.1155 16 17.0344 16H1.96558C0.884521 16 0 15.1 0 14V5C0 3.90002 0.884521 3 1.96558 3H3.66895C3.99316 3 4.34229 2.74402 4.44507 2.43103L5.05481 0.569214C5.15723 0.255981 5.50671 0 5.83105 0H12.5138C12.8381 0 13.1871 0.255981 13.2899 0.56897L13.8997 2.43079C14.0021 2.74402 14.3516 3 14.6759 3ZM5.24146 8.99988C5.24146 6.97546 6.85461 5.33325 8.84485 5.33325C10.8347 5.33325 12.4484 6.97546 12.4484 8.99988C12.4484 11.0251 10.8347 12.6666 8.84485 12.6666C6.85461 12.6666 5.24146 11.0251 5.24146 8.99988ZM8.84497 14C6.1311 14 3.93115 11.7614 3.93115 9C3.93115 6.23877 6.1311 4 8.84497 4C11.5583 4 13.7588 6.23877 13.7588 9C13.7588 11.7614 11.5583 14 8.84497 14ZM15.7241 5.33337C15.7241 5.60632 16.0176 6.00012 16.3793 6.00012C16.7411 6.00012 17.0344 5.60632 17.0344 5.33337C17.0344 5.06079 16.7413 4.66675 16.3793 4.66675C16.0176 4.66675 15.7241 5.06079 15.7241 5.33337Z"
                      fill="#11A9FF"
                    />
                  </svg>
                  Drop your image or video here to upload or <span>browse</span>
                </h3>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.state.showFileDropzone ? (
            <FileDropzoneWrap>
              <div className="dropzone-wrap file-dropzone-wrap">
                <Dropzone
                  accept={{
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc', '.docx'],
                    'application/vnd.ms-excel': ['.xls', '.xlsx'],
                    'text/plain': ['.txt'],
                  }}
                  onDropAccepted={e => this.fileUploadHandler(e)}
                  onDropRejected={() => alert('File extension mismatch')}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps({
                          style: {
                            width: '100%',
                            height: '80px',
                            border: 'dashed 1px #11A9FF',
                            borderRadius: '3px',
                            position: 'relative',
                            zIndex: '2',
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                      </div>
                      <div className="dropzone-placeholder">
                        <h3>
                          <svg
                            width="24"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.5363 8.99771L19.3488 8.99922C19.4463 8.50578 19.4988 8.00182 19.4988 7.49937C19.4988 3.36422 16.1345 0 11.9993 0C7.86703 0 4.49976 3.33123 4.49523 7.42292L4.50721 7.5744C1.93789 7.9433 0 10.1646 0 12.7504C0 15.3692 1.88687 17.622 4.49971 18H8.24948C8.66346 18 8.99942 17.6625 8.99942 17.2501C8.99942 16.8376 8.66346 16.5001 8.24948 16.5001L4.6092 16.5076C2.86631 16.2512 1.49989 14.5998 1.49989 12.7505C1.49989 10.9071 2.88278 9.32324 4.71869 9.06076L5.36363 8.96774C5.73258 8.91527 6.0071 8.5988 6.0071 8.22532L5.99512 7.4274C5.9996 4.15766 8.69196 1.4999 11.9992 1.4999C15.308 1.4999 17.9989 4.19069 17.9989 7.49941C17.9989 7.90588 17.9584 8.31536 17.8759 8.71281L17.6779 9.66675C17.6284 9.90521 17.6989 10.1512 17.8639 10.3297C18.0274 10.5067 18.2659 10.5982 18.5119 10.5607C18.5299 10.5592 18.9979 10.4977 19.5378 10.4977C21.1727 10.4977 22.5001 11.8431 22.5001 13.499C22.5001 15.1548 21.1562 16.4987 19.5003 16.4987H14.2506C12.1508 16.4987 12.0083 15.2404 12.0008 14.9988V10.0221L13.7766 11.7965C14.0676 12.086 14.5446 12.086 14.8371 11.7965C15.128 11.504 15.128 11.0255 14.8371 10.7361L11.8177 7.71834C11.6707 7.57133 11.4772 7.49937 11.2838 7.49937L11.2508 7.49337C11.0348 7.49337 10.8443 7.58639 10.7078 7.73183L7.68699 10.7496C7.39448 11.0406 7.39448 11.519 7.68699 11.81C7.97949 12.101 8.45496 12.101 8.74741 11.81L10.5008 10.0581V14.9987C10.5008 16.0352 11.2838 17.9985 14.2506 17.9985H19.5003C21.9811 17.9985 24 15.9797 24 13.4989C24 11.0181 21.9977 8.99771 19.5363 8.99771Z"
                              fill="#11A9FF"
                            />
                          </svg>
                          Drop your files here to upload or <span>browse</span>
                        </h3>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <GooglePicker handleFiles={this.handleGoogleFiles} />
            </FileDropzoneWrap>
          ) : (
            ''
          )}
          {this.state.mediaUrl.length > 0 && (
            <EditMediaWrap
              mediaUrl={this.state.mediaUrl}
              removeNewMediaHandler={this.removeMediaHandler}
            />
          )}
          {this.state.postErrors && this.state.postErrors['post_media.0'] ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors['post_media.0'][0]}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors.file_error ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors.file_error}</span>
            </div>
          ) : (
            ''
          )}
          {this.state.postErrors && this.state.postErrors['post_files.0'] ? (
            <div className="fix-tile-error">
              <span className="text-danger">{this.state.postErrors['post_files.0'][0]}</span>
            </div>
          ) : (
            ''
          )}
          <BottomBar>
            <div className="info-panel pull-right">
              <input
                id="post-btn"
                type="button"
                data-disable-with="Posting..."
                disabled={
                  this.state.disabledButton ||
                  this.state.charsLeftContent < 0 ||
                  this.state.charsLeftTitle < 0
                }
                name="commit"
                className="btn btn-primary"
                value={
                  this.state.postErrors &&
                  this.state.postErrors.mention_error &&
                  this.state.isMention
                    ? 'Post it anyway'
                    : 'Post'
                }
                onClick={() => this.submitForm()}
              />
            </div>
          </BottomBar>
        </div>
      </div>
    );
  }
}

CreatePost.propTypes = {
  spaceId: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  addPost: (data, receiveErrors, generalError) =>
    dispatch(POST_ACTIONS.addPost(data, receiveErrors, generalError)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetGrowPosts: () => dispatch(GROW_ACTIONS.resetPosts()),
  fetchGrowPosts: () => dispatch(GROW_ACTIONS.fetchPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
});

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  tags: state.tagsDomain.hashtags,
  filters: state.postsDomain.filters,
  hashtags: state.tagsDomain.hashtags,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePost));
