// NOTE! this file is only for constant values
export const APP_URL = 'https://apiv2.heelix.com'; //Add Jobvibe https://FQDN for non-local envrionments
export const CLIENT_ID = '2';
export const CLIENT_SECRET = 'c30f2c13eed84d64fc5a256731db9733f84b83ccfda865e2a218f2bed50be2ad';
export const ADMIN_ROLE = 'admin';
export const COMPANY_OWNER_ROLE = 'company owner';
export const TEAM_MANAGER_ROLE = 'team manager';
export const TEAM_MEMBER_ROLE = 'team member';
export const STRIPE_KEY = 'pk_live_Zsp4Pchg37t2SLRi7XLLd6Jn';
export const AWS_S3_URL = 'https://assets.heelix.com/';
export const CDN_URL = 'https://d8zx0qhrxdyf9.cloudfront.net/';
export const GOOGLE_ANALYTICS_ID = 'UA-122746298-2';
export const GOOGLE_SITE_KEY = '6Lc8NJMUAAAAAOaoB7PWo3hLglgAnSna0OtAlCif';
export const GOOGLE_KEY =
  '644193226972-aiebr8dq0rvvrhjljjvai721d7ud0c58.apps.googleusercontent.com';
export const GOOGLE_DEVELOPER_KEY = 'AIzaSyC_MjOtmruHcOjy2htBCL7B_idWsUDO5ZA';
export const INTERCOM_ID = 'h1jwlqic';
export const AVATAR_GENERIC = 'public/images/defaults/avatar_generic.png';
export const SHOUTOUT_LIMIT = 4;
export const HIDE_GROW = false;
export const ENV = 'production';
export const CAPTCHA_KEY = '6LfXeZ4UAAAAAJYavvqq4P9RJQ84N4RbgdQ3oouS';

export const SLACK_CLIENT_ID = '400114100663.729598864321';
export const SELENIUM_TESTING = false;
export const LOGIN_TIMEOUT_MILISECONDS = 30 * 24 * 3600 * 1000;
export const INITIAL_POST_FILTERS = {
  space: null,
  types: [],
  excludedSpaces: [],
  hashtags: [],
  string: '',
  period: '',
  start_date: '',
  end_date: '',
};
export const INITIAL_QUESTIONNAIRES_FILTERS = {
  space: null,
  types: ['questionnaire'],
  period: '',
  start_date: '',
  end_date: '',
  excludedSpaces: [],
  hashtags: [],
};
export const INITIAL_GROW_FILTERS = {
  types: [],
  hashtags: [],
  users: [],
  member: '',
  period: '',
  start_date: '',
  end_date: '',
  post: '',
  tab: 'grow',
  team: '',
};

export const FILTER_TYPES_FEED = [
  { caption: 'Open discussions', filter: 'open' },
  { caption: 'Resolved discussions', filter: 'resolved' },
  { caption: 'Start/stop/continue', filter: 'feedback' },
  { caption: 'Owned posts', filter: 'owned' },
  { caption: 'Polls', filter: 'poll' },
  { caption: 'Mentioned in', filter: 'mentioned' },
  { caption: 'Shoutouts', filter: 'shoutout' },
  { caption: 'Favourite posts', filter: 'favourite' },
];

export const FEED_FEATURE_ROUTES = ['/', '/feed', '/post/:id', '/spaces', '/questionnaires'];

export const GROW_FEATURE_ROUTES = [
  '/grow_board',
  '/meetings',
  '/member_notes',
  '/achievements',
  '/grow_profile',
  '/wins',
];
export const MICROSOFT_KEY = '32cba513-475c-422f-be30-fa2115d36ec2';
