/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const embedType = {
  youtube: 'youtube',
  wistia: 'wistia',
  spotify: 'spotify',
  googleDrive: 'googleDrive',
};

const ResponsiveContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const YoutubeEmbed = ({ id }) =>
  id ? (
    <ResponsiveContainer>
      <iframe
        src={`https://www.youtube.com/embed/${id}?rel=0`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </ResponsiveContainer>
  ) : null;

const WistiaEmbed = ({ id }) => (
  <>
    <div
      className="wistia_responsive_padding"
      css={css`
        padding: 56.25% 0 0 0;
        position: relative;
      `}
    >
      <div
        className="wistia_responsive_wrapper"
        css={css`
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        `}
      >
        <iframe
          src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=false`}
          title=" [Example Video] Wistia Video Essentials"
          allowTransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          mozallowfullscreen
          webkitallowfullscreen
          oallowfullscreen
          msallowfullscreen
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
  </>
);

const GoogleDriveEmbed = ({ id }) => (
  <ResponsiveContainer>
    <iframe src={`https://drive.google.com/file/d/${id}/preview`} frameBorder="0" allowFullScreen />
  </ResponsiveContainer>
);

const SpotifyEmbed = ({ id }) => {
  const sizes = {
    artist: '232',
    track: '80',
    album: '232',
    episode: '232',
    playlist: '232',
  };
  const getSize = () => {
    const type = id.split('/')[0];
    return sizes[type] ?? '80';
  };

  return (
    <iframe
      css={css`
        width: 100%;
      `}
      src={`https://open.spotify.com/embed/${id}`}
      height={getSize()}
      frameBorder="0"
      allowTransparency="true"
      allow="encrypted-media"
    />
  );
};

const VideoEmbed = ({ id, type }) => {
  const embed = () => {
    switch (type) {
      case embedType.youtube:
        return <YoutubeEmbed id={id} />;
      case embedType.wistia:
        return <WistiaEmbed id={id} />;
      case embedType.spotify:
        return <SpotifyEmbed id={id} />;
      case embedType.googleDrive:
        return <GoogleDriveEmbed id={id} />;
      default:
        return null;
    }
  };

  return id ? (
    <div
      css={css`
        margin-top: 21px;
        margin-bottom: 22px;
      `}
    >
      {embed()}
    </div>
  ) : null;
};

VideoEmbed.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

export default VideoEmbed;
