/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

const Dot = styled.div`
  background: ${props => (props.active ? '#11A9FF' : '#D3EFFF')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  display: inline-block;
`;
const PageSelectorSection = ({ step, steps = 3 }) => {
  return (
    <div className="panel-body u-padding-vertical-none message-input">
      <div
        className="centered "
        css={css`
          padding: 32px;
        `}
      >
        <Dot active={step === 2} />
        <Dot active={step === 3} />
        <Dot active={step === 4} />
      </div>
    </div>
  );
};

export default PageSelectorSection;
