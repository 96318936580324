/** @jsxImportSource @emotion/react */
import Prompt, { PromptButton, promptColors } from 'components/payments/prompts/Prompt';
import { jsx } from '@emotion/react';
import React from 'react';

const PlanUpgradedPrompt = ({ prompt }) => {
  return (
    <Prompt
      color={promptColors.green}
      title={`Yeeha! You've just unlocked some awesome new features with your Heelix ${prompt.data.planType}`}
      content={
        <div>
          Dive in now to see what you've unlocked and if you have any questions at any time, just
          drop us a line at{' '}
          <a href="mailto:hello@heelix.com?subject=Question about Heelix">hello@heelix.com</a> or
          message us using our in-app support and we will be right back in touch!
        </div>
      }
      button={hide => (
        <PromptButton color={promptColors.green} onClick={hide}>
          GOT IT
        </PromptButton>
      )}
    />
  );
};

export default PlanUpgradedPrompt;
