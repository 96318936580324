/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const CustomTooltip = ({ children, id, x = null, y = null, toggled = false }) => {
  let ref = null;

  return (
    <>
      <ReactTooltip
        id={id}
        css={css`
          && {
            background: #e1f2fa;
            color: #000000;
            margin-top: -10px !important;
            max-width: 224px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            :after {
              color: #000000;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
              border-top: solid 8px #e1f2fa !important;
              bottom: -8px;
              border-left-width: 8px !important;
              border-right-width: 8px !important;
              width: 0;
            }
          }
        `}
        place="top"
        type="light"
        effect="solid"
        isOpen={toggled}
        position={{ x: x, y: y }}
      >
        <span>{children}</span>
      </ReactTooltip>
      <span
        data-tooltip-id={id}
        data-tooltip-content={children}
        ref={inst => (ref = inst)}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
        `}
      />
    </>
  );
};

CustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  toggled: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default CustomTooltip;
