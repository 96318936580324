/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import checkMark from './checkMark.svg';

const Name = styled.span`
  font-size: 15px;
  color: #323232;
  font-weight: bold;
`;

const rows = isMember => ({
  active: user => ({
    picture: user.image_url,
    fields: [
      <Name>
        {user.first_name} {user.last_name}
      </Name>,
      user.email,
      user.role,
      user.account_admin ? 'Account admin' : user.lead_teams.length ? 'Team lead' : 'Team member',
      user.team ? user.team.name : '',
      ...(isMember ? [] : [user.seen_last_four_weeks && <img src={checkMark} alt="" />]),
    ],
  }),
  invited: user => ({
    picture: user.image_url,
    fields: [
      <Name>
        {user.first_name} {user.last_name}
      </Name>,
      user.email,
      user.role,
      user.team ? user.team.name : '',
      user.invitation_sent_at.split(' ')[0],
    ],
  }),
  notInvited: user => ({
    picture: user.image_url,
    fields: [
      <Name>
        {user.first_name} {user.last_name}
      </Name>,
      user.email,
      user.role,
      user.team ? user.team.name : '',
    ],
  }),
  disabled: user => ({
    picture: user.image_url,
    fields: [
      <Name>
        {user.first_name} {user.last_name}
      </Name>,
      user.email,
      user.role,
      user.team ? user.team.name : '',
      user.disabled_at.split(' ')[0],
    ],
  }),
});
const column = {
  name: {
    label: 'Name',
    key: 'name',
    width: '150px',
    css: css`
      margin-left: 10px;
    `,
  },
  email: {
    label: 'Email',
    key: 'email',
    width: '200px',
    css: css`
      text-align: center;
    `,
  },
  role: {
    label: 'Role / Position',
    key: 'role',
    width: '140px',
    css: css`
      text-align: center;
    `,
  },
  permissions: {
    label: 'Permissions',
    key: 'permissions',
    width: '140px',
    css: css`
      text-align: center;
    `,
  },
  team: {
    label: 'Team',
    key: 'team',
    width: '140px',
    css: css`
      text-align: center;
    `,
  },
  last_seen: {
    label: 'Seen in last\n4 weeks?',
    key: 'last_seen',
    width: '73px',
    css: css`
      text-align: center;
    `,
  },
  last_invited: {
    label: 'Last invited',
    key: 'last_invited',
    width: '140px',
    css: css`
      text-align: center;
    `,
  },
  disabled_at: {
    label: 'Disabled at',
    key: 'disabled_at',
    width: '140px',
    css: css`
      text-align: center;
    `,
  },
};
const { name, email, role, permissions, team, last_seen, last_invited, disabled_at } = column;
const columns = isMember => {
  return {
    active: [name, email, role, permissions, team, ...(isMember ? [] : [last_seen])],
    invited: [name, email, role, team, last_invited],
    notInvited: [name, email, role, team],
    disabled: [name, email, role, team, disabled_at],
  };
};

const fields = { rows, columns };

export default fields;
