/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { jsx } from '@emotion/react';
import { HEADERS, getUrl } from 'helpers';
import { connect } from 'react-redux';
import Feed from '../Feed';

const ScheduleIndex = props => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const headers = HEADERS();
    const response = await fetch(getUrl('meeting_settings/users'), { method: 'get', headers });
    const json = await response.json();
    if (json.users) {
      setUsers(json.users);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [props.posts]);

  return (
    <Feed
      pageTitle="Your schedule"
      pageUrl="your_schedule"
      postsType="schedule"
      history={props.history}
      setErrorPage={props.setErrorPage}
      fetchMeetingFilters={() => fetchUsers()}
      users={users}
      showCreateMeetingButton
    />
  );
};

ScheduleIndex.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setErrorPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  posts: state.growDomain.posts,
});

export default connect(mapStateToProps, null)(ScheduleIndex);
