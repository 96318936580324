import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { POST_ACTIONS } from '../../../redux/actions/postActions';

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  resolvePost: (postId, content) => dispatch(POST_ACTIONS.resolvePost(postId, content)),
});

class MarkAsDone extends Component {
  state = {
    reason: '',
  };

  render() {
    return (
      <div>
        <Modal
          className="resolve-modal"
          show={this.props.show}
          onHide={this.props.closeModal}
          id={`resolve-modal-${this.props.postId}`}
        >
          <div className="modal-content">
            <div className="modal-header no-border">
              <button onClick={this.props.closeModal}>
                <i className="fa fa-times" />
              </button>
              <h1 className="modal-title">Nice Job!</h1>
            </div>
            <div className="modal-body" style={{ minHeight: '182px' }}>
              <h2>
                What did you do?
                <span>*</span>
              </h2>
              <textarea
                className="form-control"
                value={this.state.reason}
                style={{ overflow: 'hidden', wordWrap: 'break-word', height: '126px' }}
                placeholder="Add a comment..."
                onChange={e => this.setState({ reason: e.target.value })}
              />
              <input
                type="button"
                className="btn btn-highlight"
                id="resolve-btn"
                value="Resolve"
                disabled={this.state.reason === ''}
                onClick={() => {
                  this.props.resolvePost(this.props.postId, this.state.reason);
                  this.props.closeModal();
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkAsDone);
