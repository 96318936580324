/** @jsxImportSource @emotion/react */
import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import TeamFilter from 'components/feed/QuestionnaireTile/TeamFilter/TeamFilter';
import { ReactComponent as GradientImage } from 'components/feed/QuestionnaireTile/gradient.svg';
import MultipleResults from './MultipleResults';
import CheckboxResults from './CheckboxResults';
import ShortAnswerResults from './ShortAnswerResults';

const AnswersWrapper = styled.div`
  overflow: hidden;
  padding-top: 4px;
  position: relative;
`;

const QuestionWrapper = styled.div`
  &:not(:last-of-type) {
    padding-bottom: 15px;
  }
  ${({ full }) =>
    !full &&
    `
    &:nth-child(2) {
      height: 90px;
    }
    &:nth-child(n+3) {
      height: 0px;
    }
  `}
`;

const Content = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #323232;
  word-break: break-word;
`;
const Gradient = styled(GradientImage)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const getResults = (question, user, post, details = false) => {
  switch (question.type) {
    case 'multiple':
      return (
        <MultipleResults
          user={user}
          question={question}
          selectedOptions={post.questionnaire_selected_options ?? question.option_users}
        />
      );
    case 'checkbox':
      return (
        <CheckboxResults
          question={question}
          selectedOptions={post.questionnaire_selected_options ?? question.option_users}
        />
      );
    case 'short':
      return (
        <ShortAnswerResults
          question={question}
          answers={post.answers ?? question.answers}
          details={details}
        />
      );
  }
};

const AnsweredQuestionnaire = ({ post, user, full, details, setTeam }) => {
  let { questions, author_id } = post;
  if (!full) {
    questions = questions.slice(0, 2);
  }
  return (
    <AnswersWrapper full={full}>
      {user.id === author_id && <TeamFilter setTeam={setTeam} />}
      {questions.map((question, index) => (
        <QuestionWrapper key={index} full={full}>
          <Content>
            {index + 1}. {question.content}
          </Content>
          {getResults(question, user, post, details)}
        </QuestionWrapper>
      ))}
      {!full && <Gradient />}
    </AnswersWrapper>
  );
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

export default connect(mapStateToProps, null)(AnsweredQuestionnaire);
