/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Select from 'react-select';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { findLabel } from 'helpers';
import multiple from './multiple.svg';
import checkbox from './checkbox.svg';
import short from './short.svg';
import QuestionOptions from './QuestionOptions';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 18px;
  width: 100%;
  position: relative;
`;

const QuestionWrapper = styled.div`
  width: 65%;
  padding-right: 5px;
`;

const SelectWrapper = styled.div`
  width: 35%;
  padding-left: 5px;
  padding-top: 4px;
`;

const TopBar = styled.div`
  height: 7px;
`;

const RequiredButton = styled.div`
  float: right;
  position: absolute;
  bottom: 32px;
  right: 19px;
  span.switch {
    vertical-align: middle;
  }
  span.switch:not(.turned-on) {
    background: #e7e7e7;
  }
`;

const RequiredLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #6a6a6a;
  vertical-align: middle;
`;

const questionTypes = [
  {
    value: 'checkbox',
    label: (
      <div>
        <img src={multiple} alt="" /> Multiple choice
      </div>
    ),
  },
  {
    value: 'short',
    label: (
      <div>
        <img src={short} alt="" /> Short answer
      </div>
    ),
  },
  {
    value: 'multiple',
    label: (
      <div>
        <img src={checkbox} alt="" /> Checkboxes
      </div>
    ),
  },
  {
    value: 'delete',
    label: <div>Delete question</div>,
  },
];

const Question = ({ question, questionKey, setQuestionState, errors, removeQuestion }) => {
  const selectType = type => {
    if (type === 'delete') {
      removeQuestion(questionKey);
    } else {
      setQuestionState(questionKey, 'type', type);
    }
  };

  return (
    <Wrapper>
      <TopBar />
      <QuestionWrapper className="pull-left">
        <QuestionOptions
          question={question}
          setQuestionState={setQuestionState}
          questionKey={questionKey}
          errors={errors}
        />
      </QuestionWrapper>
      <SelectWrapper className="pull-left">
        <Select
          className="question-type"
          options={questionTypes}
          value={question.type ? { value: question.type, label: findLabel(question.type, questionTypes) }: ''}
          onChange={e => selectType(e.value)}
          isSearchable={false}
          isMulti={false}
          isClearable={false}
          classNamePrefix="r-select"
        />
        <RequiredButton>
          <SwitchButton
            turnedOn={question.required}
            onClick={() => setQuestionState(questionKey, 'required', !question.required)}
          />
          <RequiredLabel>Required</RequiredLabel>
        </RequiredButton>
      </SelectWrapper>
    </Wrapper>
  );
};
export default Question;
