import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class FinalSetupModal extends Component {
  interval = null;

  state = {
    seconds: 5,
  };

  componentWillReceiveProps(props) {
    if (props.showFinalSetupModal && !this.interval) {
      this.interval = setInterval(() => {
        this.setState({ seconds: this.state.seconds - 1 });
        if (this.state.seconds === 1) this.props.close();
        if (this.state.seconds === 0) {
          clearInterval(this.interval);
          this.props.finishSetup();
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showFinalSetupModal}
        onHide={this.props.close}
        dialogClassName="final-setup-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.close} />
        <div className="circle-tick" />
        <h1>Done!</h1>
        <h2>
          The invites are on their way!
          <br />
          <br />
          Your selected users will now receive an email with instructions on how to sign up.
        </h2>
        <p>We will auto-return you to the previous screen in {this.state.seconds} sec ...</p>
      </Modal>
    );
  }
}

export default FinalSetupModal;
