import React, { Component } from 'react';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputsCount: 0,
      inputs: 1,
      hideRemove: true,
      emailValue: '',
      firstNameValue: '',
    };
  }

  componentWillMount() {
    this.setState({ inputsCount: this.props.inputsCount });
    this.setState({ inputs: this.props.inputs });
    if (this.props.value) {
      this.setState({ emailValue: this.props.value });
      this.setFirstName(this.props.value);
    }
    const number = this.props.inputs;
    this.setState({ inputs: number });
    this.hideRemove(number);
  }

  setFirstName(email) {
    const name = email.split('@')[0].replace(/\b\w/g, l => l.toUpperCase());
    this.setState({ firstNameValue: name });
  }

  remove(e) {
    e.preventDefault();
    e.target.closest('.user-box').remove();
    const number = this.state.inputs - 1;
    this.setState({ inputs: number });
    this.hideRemove(number);
  }

  hideRemove(number) {
    if (number > 1) {
      this.setState({ hideRemove: false });
    } else {
      this.setState({ hideRemove: true });
    }
  }

  render() {
    const email = `invites[user][${this.state.inputsCount}][email]`;
    const first_name = `invites[user][${this.state.inputsCount}][first_name]`;
    const last_name = `invites[user][${this.state.inputsCount}][last_name]`;
    const role = `invites[user][${this.state.inputsCount}][role]`;
    return (
      <div className="row u-margin-vertical-xs user-box" ref="jsTemplate">
        <div className="col-sm-4">
          <div className="form-group string optional">
            <input
              type="email"
              name={email}
              defaultValue={this.state.emailValue}
              placeholder="Email"
              className="string optional stretch form-control"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group string optional">
            <input
              type="text"
              name={first_name}
              defaultValue={this.state.firstNameValue}
              placeholder="First Name"
              className="string optional stretch form-control"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group string optional">
            <input
              type="text"
              name={last_name}
              placeholder="Last Name"
              className="string optional stretch form-control"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group string optional">
            <input
              type="text"
              name={role}
              placeholder="Role/Position"
              className="string optional stretch form-control"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <a
            id={`remove-row-${this.state.inputsCount}`}
            href="/#"
            className="u-sizevr-1 u-line-lg js-remove-user-box"
            onClick={e => this.remove(e)}
            hidden={this.state.hideRemove}
          >
            Remove
          </a>
        </div>
      </div>
    );
  }
}
export default Form;
