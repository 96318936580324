/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { USER_ACTIONS } from 'redux/actions/userActions';
import Modal from 'react-bootstrap/Modal';
import OnboardingButton from 'components/onboarding/partials/OnboardingButton';
import PostponeButton from 'components/onboarding/partials/PostponeButton';
import { CloseButton } from 'components/shared/Modal/UserModal';
import {
  ButtonWrapper,
  ModalContent,
  ModalWrapper,
} from 'components/onboarding/ExistedMeetingModal';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import SwitchButton from '../shared/buttons/SwitchButton';

const Switch = ({ enabled, type, title, content, toggleFeature, ...props }) => (
  <div
    css={css`
      background-color: ${enabled ? '#D3EFFF' : '#F3F3F3'};
      width: 100%;
      display: block;
      padding: 25px;
      border-radius: 8px;
    `}
    {...props}
  >
    <SwitchButton turnedOn={enabled} onClick={() => toggleFeature(type)} showLabel />
    <SwitchHeader>{title}</SwitchHeader>
    <SwitchContent>{content}</SwitchContent>
  </div>
);

const SwitchWrap = styled.div`
  display: inline-flex;
  width: 50%;
  padding-right: 20px;
`;

const SwitchHeader = styled.h3`
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
`;

const SwitchContent = styled.div`
  font-size: 13px;
  line-height: 21px;
`;

class FeatureSwitch extends Component {
  state = {
    showModal: false,
    loading: false,
  };

  closeModal = () => {
    this.setState({ showModal: false, loading: false });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.showModal && prevProps.enabled !== this.props.enabled) {
      this.closeModal();
    }
  }

  render() {
    const { showModal, loading } = this.state;
    return (
      <SwitchWrap>
        <Switch
          enabled={this.props.enabled}
          type={this.props.type}
          title={this.props.title}
          content={this.props.content}
          toggleFeature={() => this.setState(({ showModal }) => ({ showModal: !showModal }))}
        />
        <Modal
          show={showModal}
          dialogClassName="existed-meeting-modal"
          backdrop
          keyboard
          onHide={this.closeModal}
        >
          <ModalWrapper>
            <LoadingOverlay toggled={loading} message="Processing..." height="100%" />
            <ModalContent
              css={css`
                overflow: hidden;
              `}
            >
              Are you sure? Turning {this.props.enabled ? 'off' : 'on'} the {this.props.title} will
              require logging out all of your account members for changes to be applied.
              <ButtonWrapper>
                <OnboardingButton
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ loading: true });
                    this.props.toggleFeature(this.props.type);
                  }}
                  label="Yes"
                  submit
                  top={10}
                  width={65}
                  height={36}
                />
                <PostponeButton
                  text="Cancel"
                  onClick={e => {
                    e.preventDefault();
                    this.closeModal();
                  }}
                />
              </ButtonWrapper>
            </ModalContent>
            <CloseButton className="close-button" onClick={this.closeModal} />
          </ModalWrapper>
        </Modal>
      </SwitchWrap>
    );
  }
}

const mapDispatchToProps = {
  toggleFeature: type => USER_ACTIONS.toggleFeature(type),
};

const mapStateToProps = state => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeatureSwitch));
