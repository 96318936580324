/** @jsxImportSource @emotion/react */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { getUrl, HEADERS } from 'helpers';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useGoogleCalendarSync } from 'pages/Grow/GoogleCalendarPopup';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import ExistedMeetingModal from 'components/onboarding/ExistedMeetingModal';
import PostponeButton from 'components/onboarding/partials/PostponeButton';
import OnboardingButton from 'components/onboarding/partials/OnboardingButton';
import { Container, FormContent } from 'components/vibes/createForms/CreateMeeting/shared/layout';
import RepeatingMeeting from 'components/vibes/createForms/CreateMeeting/RepeatingMeeting';
import OneOffMeeting from 'components/vibes/createForms/CreateMeeting/OneOffMeeting';
import { recordNote } from 'components/vibes/createForms/CreateNote/createNoteHelper';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import ShareMenu, { useShareMenu } from 'components/vibes/createForms/CreateNote/ShareMenu';
import ShareBottomBar from 'components/vibes/createForms/CreateNote/ShareBottomBar';
import BottomBar from 'components/vibes/createForms/partials/BottomBar';
import { getResponseTypeFromNoteType } from 'components/vibes/createForms/CreateNote/typeParsers';

const defaultOneOffMeetingState = {
  steps: [
    {
      label: 'Next step 1',
      value: '',
      id: 1,
      dbId: null,
    },
    {
      label: 'Next step 2',
      value: '',
      id: 2,
      dbId: null,
    },
  ],
  stepsCount: null,
  meetingContent: '',
};

const MeetingSchedule = ({
  resetSettings,
  closeMeetingModal,
  resetPosts,
  fetchPosts,
  showPrompt,
  nextStep,
  setMeetingMember,
  setReminder,
  settings,
  stopShowingButton,
  setRedirectToYourSchedule,
  users,
  leads,
  handleResponse,
  toggledMode,
}) => {
  const [frequency, setFrequency] = useState(
    toggledMode === noteTypes.meetingOneOff ? 'oneoff' : 'weekly'
  );
  const [ownerPrompt, setOwnerPrompt] = useState('');
  const [memberPrompt, setMemberPrompt] = useState('');
  const [shareAgendas, setShareAgendas] = useState(false);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [nextMeetingTime, setNextMeetingTime] = useState('12:00 PM');
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [members, setMembers] = useState([]);
  const [member, setMember] = useState(null);
  const [showExistedModal, setShowExistedModal] = useState(false);
  const [setCalendarEnabled, syncWithGoogleCalendar, setSyncWithGoogleCalendar, loadingOverlay] =
    useGoogleCalendarSync();
  const [oneOffMeeting, setOneOffMeeting] = useState(defaultOneOffMeetingState);
  const { shareState, setShareWith, setSharing } = useShareMenu(noteTypes.quickMeeting);

  const isOneOffMeeting =
    frequency === 'oneoff' || (!frequency && toggledMode === noteTypes.meetingOneOff);

  const fetchSettings = async (memberId, memberType, isOneOff) => {
    const headers = HEADERS();
    const response = await fetch(
      getUrl(`meetings/schedule_modal?member=${memberId}&type=${memberType}`),
      {
        method: 'get',
        headers,
      }
    );
    const json = await response.json();
    if (json.settings && !isOneOff) {
      setUpdated(Boolean(json.settings));
      setFrequency(json.settings.frequency);
      setOwnerPrompt(json.settings.owner_prompt);
      setReminder(json.settings.owner_prompt);
      setMemberPrompt(json.settings.member_prompt);
      if (json.settings.next_meeting) {
        setNextMeeting(moment(json.settings.next_meeting));
        setNextMeetingTime(json.settings.time);
      }
      return;
    }
    setUpdated(Boolean(json.settings));
    setFrequency(isOneOff ? 'oneoff' : 'weekly');
  };
  const fetchMembers = async () => {
    const headers = HEADERS();
    const response = await fetch(getUrl('meetings/members_teams'), { method: 'get', headers });
    const json = await response.json();
    setLoading(false);
    setCalendarEnabled(json.isGoogleCalendarEnabled);
    if (json.members) {
      json.members.forEach(m => {
        m.className = m.has_settings ? 'has_settings' : '';
      });
      setMembers(json.members);
    }
  };

  const setFrequencyField = value => {
    if (value !== 'oneoff' && isOneOffMeeting && member) {
      updateMember(member.value, false);
    }
    if (value === 'daily') {
      setOwnerPrompt('day');
      setReminder('day');
      setMemberPrompt('day');
    }
    setFrequency(value);
  };

  useLayoutEffect(() => {
    if (loading) {
      fetchMembers();
    }
    if (settings) {
      let editedMember = null;
      if (settings.member_id) {
        editedMember = members.find(m => m.value === `user_${settings.member_id}`);
      } else {
        editedMember = members.find(m => m.value === `team_${settings.team_id}`);
      }
      setMember(editedMember);
      setFrequency(settings.frequency);
      setShareAgendas(Boolean(settings.share_agendas));
      setOwnerPrompt(settings.owner_prompt);
      setReminder(settings.owner_prompt);
      setMemberPrompt(settings.member_prompt);
      setNextMeeting(moment(settings.next_meeting));
      setNextMeetingTime(settings.time);
      setMeetingMember(editedMember);
      setSyncWithGoogleCalendar(settings.google_calendar);
    }
    setUpdated(Boolean(settings));
  }, [members]);

  useEffect(() => {
    if (frequency === 'oneoff') {
      if (nextMeeting && nextMeetingTime && oneOffMeeting.meetingContent && member && !submitted) {
        setDisabled(false);
        return;
      }
    } else if (
      frequency &&
      ownerPrompt &&
      memberPrompt &&
      frequency &&
      nextMeeting &&
      nextMeetingTime &&
      member &&
      !submitted
    ) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  });

  useEffect(() => {
    if (toggledMode === noteTypes.meetingOneOff) setFrequency('oneoff');
    if (toggledMode === noteTypes.meetingSchedule) setFrequency('weekly');
  }, [toggledMode]);

  const submitOneOffMeeting = async () => {
    setSubmitted(true);
    setDisabled(true);

    const data = new FormData();
    const noteState = {
      toggledMode: noteTypes.quickMeeting,
      meetingShareWith: shareState.shareWith,
      meetingMember: member,
      meetingDate: moment(nextMeeting).format('YYYY-MM-DD'),
      meetingTime: nextMeetingTime,
      ...oneOffMeeting,
    };

    const response = await recordNote(noteState, {}, data, noteTypes.meeting, false);

    handleResponse(response, getResponseTypeFromNoteType(noteTypes.meeting));
    setSubmitted(false);
    setDisabled(false);
  };

  const submitRepeatingMeeting = () => {
    setSubmitted(true);
    setDisabled(true);
    const headers = HEADERS();
    const data = new FormData();
    data.append('frequency', frequency);
    data.append('owner_prompt', ownerPrompt);
    data.append('member_prompt', memberPrompt);
    data.append('next_meeting', moment(nextMeeting).format('YYYY-MM-DD'));
    data.append('time', nextMeetingTime);
    data.append('share_agendas', shareAgendas);
    if (member.type === 'user') {
      data.append('member_id', member.id);
    } else {
      data.append('team_id', member.id);
    }
    data.append('google_calendar', syncWithGoogleCalendar);
    fetch(getUrl('meeting_settings'), { method: 'post', body: data, headers })
      .then(response => {
        if (response.status === 200) {
          setRedirectToYourSchedule(true);
          nextStep();
          resetPosts();
          fetchPosts();
          resetSettings();
          response.json().then(json => {
            if (json.redirectUrl) {
              window.open(json.redirectUrl);
            }
          });
        }
        if (response.status === 403) {
          closeMeetingModal();
        }
      })
      .then(() => {})
      .catch(console.error);
  };

  const updateMember = (memberValue, isOneOff = isOneOffMeeting) => {
    const member = members.find(m => m.value === memberValue);
    if (member.has_settings) {
      setShowExistedModal(true);
    }
    fetchSettings(member.id, member.type, isOneOff);
    setMember(member);
    setMeetingMember(member);
  };

  const updateOwnerReminder = prompt => {
    setOwnerPrompt(prompt);
    setReminder(prompt);
  };

  const resetForm = () => {
    setRedirectToYourSchedule(false);
    setMember(null);
    setShowExistedModal(false);
    setFrequency('weekly');
    setOwnerPrompt('');
    setReminder('');
    setMemberPrompt('');
    setNextMeeting(null);
    setNextMeetingTime('12:00 PM');
    setMeetingMember(null);
    setUpdated(false);
  };

  const handleOneOffMeetingState = (key, value) => {
    setOneOffMeeting(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <Container className="share-menu">
      <FormContent>
        {isOneOffMeeting ? (
          <OneOffMeeting
            members={members}
            frequency={frequency ?? 'oneoff'}
            setNextMeetingTime={setNextMeetingTime}
            setFrequencyField={setFrequencyField}
            nextMeeting={nextMeeting}
            updateMember={updateMember}
            member={member}
            nextMeetingTime={nextMeetingTime}
            setNextMeeting={setNextMeeting}
            meetingState={oneOffMeeting}
            setMeetingState={handleOneOffMeetingState}
          />
        ) : (
          <RepeatingMeeting
            updateOwnerReminder={updateOwnerReminder}
            ownerPrompt={ownerPrompt}
            nextMeetingTime={nextMeetingTime}
            syncWithGoogleCalendar={syncWithGoogleCalendar}
            setNextMeetingTime={setNextMeetingTime}
            setNextMeeting={setNextMeeting}
            memberPrompt={memberPrompt}
            setMemberPrompt={setMemberPrompt}
            showPrompt={showPrompt}
            loadingOverlay={loadingOverlay}
            nextMeeting={nextMeeting}
            setFrequencyField={setFrequencyField}
            frequency={frequency ?? 'weekly'}
            updateMember={updateMember}
            member={member}
            setSyncWithGoogleCalendar={setSyncWithGoogleCalendar}
            members={members}
            shareAgendas={shareAgendas}
            setShareAgendas={setShareAgendas}
          />
        )}
      </FormContent>
      <BottomBar
        css={css`
          margin-left: 0;
          margin-right: 0;
        `}
        secondBar={
          isOneOffMeeting &&
          shareState.isSharing && (
            <ShareBottomBar
              users={users}
              leads={leads}
              shareWith={shareState.shareWith}
              setShareWith={setShareWith}
              isSharing={shareState.isSharing}
              mode={shareState.mode}
            />
          )
        }
      >
        {isOneOffMeeting && (
          <ShareMenu
            mode={shareState.mode}
            setShareWith={setShareWith}
            setSharing={setSharing}
            isSharing={shareState.isSharing}
            isSharedWithMultiple={shareState.isSharedWithMultiple}
            setAssignTo={() => null}
          />
        )}
        <OnboardingButton
          disabled={disabled}
          onClick={
            !disabled ? (isOneOffMeeting ? submitOneOffMeeting : submitRepeatingMeeting) : null
          }
          label={isOneOffMeeting || !updated ? 'Create meeting' : 'Update meeting'}
          submit
          top={15}
          width={142}
          height={36}
          style={css`
            margin-left: auto;
            font-size: 14px;
            line-height: 35px;
            margin-top: auto;
            margin-bottom: auto;
          `}
        />
      </BottomBar>

      {stopShowingButton && (
        <div
          css={css`
            display: block;
            text-align: center;
          `}
        >
          <PostponeButton
            text="Stop showing me this"
            onClick={e => {
              e.preventDefault();
              closeMeetingModal(true);
            }}
          />
        </div>
      )}
      <ExistedMeetingModal
        showModal={showExistedModal && !isOneOffMeeting}
        closeModal={() => setShowExistedModal(false)}
        resetForm={() => resetForm()}
        member={member?.value}
        members={members}
      />
    </Container>
  );
};

const mapDispatchToProps = dispatch => ({
  resetSettings: () => dispatch(ONBOARDING_ACTIONS.resetSettings()),
  closeMeetingModal: permanently => dispatch(ONBOARDING_ACTIONS.closeMeetingModal(permanently)),
  resetPosts: () => dispatch(GROW_ACTIONS.resetPosts()),
  fetchPosts: () => dispatch(GROW_ACTIONS.fetchPosts()),
  showPrompt: prompt => dispatch(PLAN_ACTIONS.showPrompt(prompt)),
});

export default withRouter(connect(null, mapDispatchToProps)(MeetingSchedule));
