/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AdminNavigation from './AdminNavigation';
import AccountsIndex from '../admin/accounts/AccountsIndex';
import InvoicesIndex from '../admin/offline_invoices/InvoicesIndex';
import AnalyticsIndex from '../admin/analytics/AnalyticsIndex';
import StatsIndex from '../admin/stats/StatsIndex';
import InvoicesNew from '../admin/offline_invoices/InvoicesNew';
import InvoicesEdit from '../admin/offline_invoices/InvoicesEdit';
import ReportsSetup from '../admin/reports/ReportsSetup';
import AccountsShow from '../admin/accounts/AccountShow';
import AnalyticsShow from '../admin/analytics/AnalyticsShow';
import AccountEdit from '../admin/accounts/AccountEdit';
import { UsersIndex } from '../admin/users/UsersIndex';
import Flash from '../shared/Flash';

const FrameWrapper = styled.div`
  padding-left: 0;
`;

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flashMessage: '',
      showFlash: false,
      flashType: '',
    };
  }

  closeFlash() {
    this.setState({ showFlash: false });
  }

  updateFlash = (flashType, flashMessage) => {
    this.setState({
      flashName: flashType,
      flashMessage,
      showFlash: true,
    });
  };

  render() {
    return (
      <FrameWrapper className="frame-wrapper">
        <AdminNavigation />
        {this.state.flashMessage ? (
          <Flash
            closeFlash={() => this.setState({ flashMessage: '' })}
            flashMessage={this.state.flashMessage}
            flashType={this.state.flashType}
            dismissable
          />
        ) : (
          ''
        )}
        <div className="page-wrapper">
          <Switch>
            <Route exact path="/admin" component={UsersIndex} />
            <Route exact path="/admin/accounts" component={AccountsIndex} />
            <Route exact path="/admin/accounts/:id" component={AccountsShow} />
            <Route exact path="/admin/users" component={StatsIndex} />
            <Route
              exact
              path="/admin/accounts/:id/edit"
              render={routeProps => (
                <AccountEdit {...routeProps} updateFlash={this.updateFlash.bind(this)} />
              )}
            />
            <Route exact path="/admin/offline_invoices" component={InvoicesIndex} />
            <Route exact path="/admin/offline_invoices/:id/edit" component={InvoicesEdit} />
            <Route exact path="/admin/offline_invoices/new" component={InvoicesNew} />
            <Route exact path="/admin/analytics/:date/show" component={AnalyticsShow} />
            <Route exact path="/admin/analytics/:year?/:week?" component={AnalyticsIndex} />
            <Route exact path="/admin/reports/setup" component={ReportsSetup} />
          </Switch>
        </div>
      </FrameWrapper>
    );
  }
}

export default Admin;
