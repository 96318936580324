import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

class FavPostTooltip extends Component {
  state = {
    label: this.props.isFavourite ? 'Unfavourite this post' : 'Favourite this post',
  };

  shouldComponentUpdate(nextProps) {
    return this.props.isFavourite !== nextProps.isFavourite;
  }

  componentDidMount() {
    this.setState({
      label: this.props.isFavourite ? 'Unfavourite this post' : 'Favourite this post',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFavourite !== prevProps.isFavourite) {
      this.setState({
        label: this.props.isFavourite ? 'Unfavourite this post' : 'Favourite this post',
      });
    }
  }

  render() {
    const className = this.props.isFavourite ? 'react-tooltip favourite' : 'react-tooltip';
    return (
      <ReactTooltip
        id={`post_fav_status${this.props.postId}`}
        className={className}
        place="top"
        type="dark"
        delayUpdate={1000}
        offset={{ right: -3 }}
        content={this.props.isFavourite ? 'Unfavourite this post' : 'Favourite this post'}
        effect="solid"
      />
    );
  }
}

export default FavPostTooltip;
