/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/react';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import { noScrollBar } from 'styles';
import OptionButton from '../partials/OptionButton';

const OuterWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  @media (min-width: 580px) {
    flex-wrap: unset;
  }
  div {
    margin-left: 15px;
    margin-bottom: 5px;
    :last-of-type {
      margin-right: 15px;
    }
    @media (min-width: 580px) {
      margin-bottom: 0;
    }
  }
  ${noScrollBar}
`;

const Wrap = styled.div`
  position: relative;
`;

const InnerWrap = styled.div`
  position: absolute;
  top: -57px;
  overflow-x: hidden;
  width: 100%;
  @media (min-width: 580px) {
    top: -59px;
  }
`;

const PrivateCategoryButtons = ({ categories, category, setCategory, mode }) => {
  return mode === noteTypes.private ? (
    <Wrap>
      <InnerWrap>
        <OuterWrap>
          {categories.map((label, index) => (
            <OptionButton
              key={index}
              label={label}
              onClick={() => setCategory(label)}
              css={
                category === label
                  ? css`
                      color: #ffffff;
                      background: #11a9ff;
                    `
                  : ''
              }
              type="gray2"
              withPlus={false}
            />
          ))}
        </OuterWrap>
      </InnerWrap>
    </Wrap>
  ) : null;
};

PrivateCategoryButtons.propTypes = {
  categories: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  category: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
};

export default PrivateCategoryButtons;
