import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import Masonry from 'react-masonry-component';
import { HIDE_MENTION_TOOLTIP, SHOW_MENTION_TOOLTIP } from '../shared/tooltips/MentionTooltip';
import DiscussionTile from './DiscussionTile';
import PostTile from './PostTile';
import ShoutoutTile from './ShoutoutTile';
import PollAlertTile from './AppAlerts/PollAlertTile';
import PollTile from './PollTile';
import PostAlertTile from './AppAlerts/PostAlertTile';
import FeedbackAlertTile from './AppAlerts/FeedbackAlertTile';
import BlankPost from './BlankPost';
import QuestionnaireTile from './QuestionnaireTile';
import ConsolidatedShoutoutsTile from './ConsolidatedShoutoutsTile/ConsolidatedShoutoutsTile';

const PostsMasonry = (props) => {
  return (
    <div>
      <Row>
        <Col>
          <Masonry
            onLayoutComplete={() => {
              const last = document.querySelector('.post-feed-wrap .post-tile-wrap:last-child');
              if (
                last &&
                (last.style.top === '' || last.style.top === '0px') &&
                props.posts.length > 2
              ) {
                props.tileRealignHandler();
              }
              const renderedHashtags = document.getElementsByClassName('inline-hashtag');
              Array.from(renderedHashtags).forEach((hashtag) => {
                hashtag.removeEventListener('click', props.hashtagClickedHandler);
                hashtag.addEventListener('click', props.hashtagClickedHandler, false);
              });

              Array.from(document.getElementsByClassName('inline-mention')).forEach((mention) => {
                mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
                mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
                mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
                mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
                mention.addEventListener('click', props.mentionClickedHandler, false);
              });
            }}
          >
            {props.user.should_see_sample_data || (!props.posts.length && props.hasMorePostsToLoad) ? (
              [...Array(4)].map((item, index) => <BlankPost animated={props.hasMorePostsToLoad} key={index} />)
            ) : (
              <>
                {props.vibeTile}
                {props.posts.map((post, index) => {
                  const showSpaceInfo = props.shouldShowPost(post.space_id);
                  switch (post.type) {
                    case 'Fix':
                      return (
                        <DiscussionTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'Buzz':
                      return (
                        <PostTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'ConsolidatedShoutouts':
                    case 'MonthlyValueWinners':
                      return (
                        <ConsolidatedShoutoutsTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'Shoutout':
                      if (post.shoutout && (post.shoutout.recipient || post.shoutout.recipient_team)) {
                        return (
                          <ShoutoutTile
                            showSpaceInfo={showSpaceInfo}
                            post={post}
                            key={post.id}
                            tileRealignHandler={props.tileRealignHandler}
                          />
                        );
                      }
                      return null;
                    case
                      'Poll':
                      if (post.subtype === 'PollAlert') {
                        return (
                          <PollAlertTile
                            showSpaceInfo={showSpaceInfo}
                            post={post}
                            key={post.id}
                            tileRealignHandler={props.tileRealignHandler}
                          />
                        );
                      }
                      return (
                        <PollTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'LeadPostAlert':
                    case 'PostAlert':
                      return (
                        <PostAlertTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'AdminFeedbackAlert':
                    case 'LeadFeedbackAlert':
                      return (
                        <FeedbackAlertTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    case 'Questionnaire':
                      return (
                        <QuestionnaireTile
                          showSpaceInfo={showSpaceInfo}
                          post={post}
                          key={post.id}
                          tileRealignHandler={props.tileRealignHandler}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </>
            )}
          </Masonry>
        </Col>
      </Row>
    </div>
  );
};

export default PostsMasonry;