/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { AVATAR_ERROR, FORMATTER } from 'helpers';
import SpaceLogo from 'components/spaces/SpaceLogo';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import useCan from 'rbac/useCan';
import { spaces } from 'rbac/plans';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import MetaInfo from '../../feed/MetaInfo';

const LogoWrapper = styled.span`
  @media (max-width: 1140px) {
    max-width: 10%;
  }
`;

const AuthorInfo = styled.div`
  color: #58575f;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  display: inline-block;
  span {
    font-weight: normal;
  }
`;
const AuthorWrapper = styled.div`
  display: inline-block;
  padding-bottom: 5px;
`;

const UserImage = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
`;

const SpaceWrapper = styled.div`
  display: inline-block;
  padding-bottom: 5px;
  max-width: 100%;
  ${props => props.showAuthorInfo && `padding-left: 18px;`}
`;

const checkIfQuestionnaire = (e, post) => {
  if (post.type === 'Questionnaire') {
    e.preventDefault();
  }
};

const checkIfPostDetailsDisabled = (e, post) => {
  if (
    post.type === 'Questionnaire' ||
    post.type === 'ConsolidatedShoutouts' ||
    post.type === 'MonthlyValueWinners'
  ) {
    e.preventDefault();
  }
};

const PostDetails = ({
  post,
  showSpaceInfo = true,
  large = false,
  showAuthorInfo = false,
  mainSpaceId,
  showUpgradeModal,
}) => {
  const canAccessSpace = useCan(spaces.accessView, { mainSpaceId, spaceId: post.space.id });

  return (
    <>
      <MetaInfo data={post.meta_info} large={large} />
      <div className="post-details-wrap">
        <Link
          to={`/post/${post.id}`}
          className="post-details"
          onClick={e => checkIfPostDetailsDisabled(e, post)}
        >
          <span className="time-ago">
            <TimeAgo
              date={`${post.created_at} ${post.user.time_zone}`}
              formatter={FORMATTER}
              title=""
            />
          </span>
          <span className="views-counter">
            {post.views_count === 1 ? '1 view' : `${post.views_count} views`}
          </span>
        </Link>
        {showSpaceInfo && (
          <div className="post-tile-space-info">
            {showAuthorInfo && (
              <AuthorWrapper>
                <UserImage
                  alt={`${post.user.first_name} ${post.user.last_name}`}
                  src={post.user.image_url}
                  onError={e => AVATAR_ERROR(e.target)}
                />
                <AuthorInfo post={post} user={post.user}>
                  {post.user.first_name} {post.user.last_name}
                  <span> in </span>
                </AuthorInfo>
              </AuthorWrapper>
            )}
            <SpaceWrapper showAuthorInfo={showAuthorInfo}>
              <LogoWrapper>
                <SpaceLogo space={post.space} />
              </LogoWrapper>
              <Link
                css={css`
                  max-width: ${large ? '96%' : '92%'};
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  float: right;
                  overflow: hidden;
                  @media (max-width: 1285px) {
                    max-width: 90%;
                  }
                `}
                to={`/feed?space=${post.space.id}`}
                onClick={e => {
                  checkIfQuestionnaire(e, post);
                  if (!canAccessSpace) {
                    e.preventDefault();
                    showUpgradeModal();
                  }
                }}
              >
                {post.space.name}
              </Link>
            </SpaceWrapper>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatch = {
  showUpgradeModal: PLAN_ACTIONS.showUpgradeModal,
};

const mapState = state => ({
  mainSpaceId: state.usersDomain.defaultSpaceId,
});

export default connect(mapState, mapDispatch)(PostDetails);
