/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import styled from '@emotion/styled';
import { jsx } from '@emotion/react';
import ProfileToggle from './partials/ProfileToggle';
import ProfileMenu from './partials/ProfileMenu';
import { AWS_S3_URL } from '../../constants';
import { LOCALSTORAGE_GET } from '../../helpers';

const NavBar = styled.div`
  margin: 15px;
`;

class AdminNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userImg:
        localStorage !== null && LOCALSTORAGE_GET('user_image_uid') !== 'null'
          ? AWS_S3_URL + LOCALSTORAGE_GET('user_image_uid')
          : '',
      firstName: localStorage !== null ? LOCALSTORAGE_GET('user_first_name') : '',
      lastName: localStorage !== null ? LOCALSTORAGE_GET('user_last_name') : '',
    };
  }

  render() {
    const imgSrc = this.state.userImg
      ? this.state.userImg
      : `${AWS_S3_URL}public/images/defaults/user.jpg`;
    return (
      <nav
        className="navbar navbar-fixed-top navbar-default"
        role="navigation"
        style={{ position: 'absolute' }}
      >
        <div className="container-fluid wrapper-admin-nav">
          <div className="navbar-header wrapper-admin-navbar-header">
            <div className="navbar-text visible-xs">
              <Link to="/">
                <img
                  src={`${AWS_S3_URL}public/images/jv-logo.png`}
                  style={{ height: '35px', margin: '0 auto' }}
                  alt=""
                />
              </Link>
            </div>
            <NavBar className="mini-logo hidden-xs">
              <Link to="/">
                <img src={`${AWS_S3_URL}public/images/Heelix-Logo-black.svg`} alt="" />
              </Link>
            </NavBar>
            <aside id="primary-navbar" className="collapse navbar-collapse">
            <ul className="nav navbar-right top-nav navbar-nav">
              <Dropdown className="bold" componentClass="li" id="profileDropdown">
                <ProfileToggle
                  bsRole="toggle"
                  userImg={imgSrc}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                />
                <ProfileMenu bsRole="menu" />
              </Dropdown>
            </ul>
          </aside>
          </div>
          <aside id="side-navbar" className="collapse navbar-collapse navbar-ex1-collapse">
            <ul className="nav navbar-nav side-nav m-sidebar" />
          </aside>
        </div>
      </nav>
    );
  }
}

export default AdminNavigation;
