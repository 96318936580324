/** @jsxImportSource @emotion/react */
import Prompt, { promptColors } from 'components/payments/prompts/Prompt';
import { jsx } from '@emotion/react';
import React from 'react';
import UpgradeButton from 'components/payments/prompts/UpgradeButton';
import { connect } from 'react-redux';

const getContent = (isAccountAdmin, firstName) => {
  if (isAccountAdmin)
    return (
      <div>
        They'll help you create awesome conversations with your team - if you've forgotten what they
        are and would like to get a demo, drop us a line at{' '}
        <a href="mailto:hello@heelix.com?subject=Question about Heelix">hello@heelix.com</a> or
        through our in-app chat support.
      </div>
    );
  return `Chat with your Account Admin, ${firstName}, to ask about getting access today.`;
};

const InsightsPrompt = ({ prompt, isAccountAdmin, accountAdmin: { first_name } }) => {
  return (
    <Prompt
      color={promptColors.yellow}
      title={`You'll need to upgrade to one of our premium plans to unlock more insights`}
      content={getContent(isAccountAdmin, first_name)}
      button={hide => <UpgradeButton hide={hide} />}
    />
  );
};

const mapState = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  accountAdmin: state.plansDomain.accountAdmin,
});

export default connect(mapState)(InsightsPrompt);
