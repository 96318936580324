import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

class CommentsNotificationsTooltip extends Component {
  render() {
    return (
      <ReactTooltip id={this.props.id} class="react-tooltip" place="top" type="dark" effect="solid">
        <span>
          If someone comments on your post,
          <br />
          you will get a notification
          <br />
          on email and mobile.
        </span>
      </ReactTooltip>
    );
  }
}
export default CommentsNotificationsTooltip;
