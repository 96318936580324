/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Page from 'components/layouts/Page';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import { css, jsx } from '@emotion/react';
import fetchApi from 'fetchApi';
import Heatmap from 'pages/Insights/OrganisationInsights/Heatmap';
import {
  ButtonContainer,
  ComparisonButton,
  SidebarHeader,
  SidebarInput,
  SidebarLabel,
  StyledSelect,
  SwitchContainer,
} from 'pages/Insights/OrganisationInsights/layout';
import qs from 'qs';
import { Route, withRouter } from 'react-router-dom';
import factors from 'pages/Insights/OrganisationInsights/factors';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import Score from 'pages/Insights/OrganisationInsights/Score/Score';
import SearchInput from 'pages/Insights/OrganisationInsights/SearchInput';
import TeamModalContainer from 'pages/Insights/OrganisationInsights/TeamModalContainer';
import { BODY_CLASSES, findLabel } from 'helpers';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { CheckBox, TeamTagSidebarContainer } from './Score/layout';

const sortOptions = {
  hierarchicalDesc: 'Hierarchical view (high to low)',
  hierarchicalAsc: 'Hierarchical view (low to high)',
  scoreDesc: 'Score (high to low)',
  scoreAsc: 'Score (low to high)',
};

const tagSortOptions = {
  scoreDesc: 'Score (high to low)',
  scoreAsc: 'Score (low to high)',
};

const viewTypes = {
  score: 'Heelix score',
  participation: 'Participation',
};

const defaultParams = {
  period: 30,
  view: 'score',
  sort: 'hierarchicalDesc',
  tags: [],
};

const handleScroll = (searchTerm, teams) => {
  const term = searchTerm.toLowerCase().trim();
  if (searchTerm.length === 0) return null;
  const team = teams.find(team => team.name.toLowerCase().includes(term));
  return !team ? null : team.id;
};

const OrganisationInsights = ({ location, history }) => {
  const [teams, setTeams] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrollTo, setScrollTo] = useState(null);
  const [isHierarchical, setIsHierarchical] = useState(false);
  const [isParticipationView, setIsParticipationView] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [checkedTeamTags, setCheckedTeamTags] = useState([]);
  const [isByTag, setIsByTag] = useState(false);
  const [teamTags, setTeamTags] = useState([]);
  const [taggedTeams, setTaggedTeams] = useState([]);
  const [teamsByTag, setTeamsByTag] = useState([]);
  const [showPrevious, setShowPrevious] = useState(false);

  const scoreRef = React.createRef();

  const fetchTeams = async (query = {}) => {
    const data = await fetchApi(`/organisation_insights?${qs.stringify(query)}`);
    setTeams(data.teams);
    setTeamTags(data.team_tags);
    setTaggedTeams(data.tagged_teams);
    setTeamsByTag(() => {
      return data?.tagged_teams?.filter(tt => checkedTeamTags.includes(tt.id));
    });
    setIsHierarchical(!query.sort ? true : query.sort.includes('hierarchical'));
    setIsParticipationView(query.view === 'participation');
  };

  const params = useCallback(qs.parse(location.search, { ignoreQueryPrefix: true }), [
    location.search,
  ]);

  const addToQuery = (object, params) =>
    history.push(`${location.pathname}?${qs.stringify({ ...params, ...object })}`);

  const handleSort = ({ value }) => {
    addToQuery({ sort: value }, params);
  };

  const handleView = view => {
    const withoutFactor = { ...params };
    delete withoutFactor.factor;
    return addToQuery({ view }, withoutFactor);
  };
  const handleFactor = factor => {
    const withoutView = { ...params };
    delete withoutView.view;
    return addToQuery({ factor }, withoutView);
  };

  const handleCheckedTeamTag = id => {
    const queryParams = { ...params };
    const teamTags = checkedTeamTags.includes(id)
      ? checkedTeamTags.filter(v => v !== id)
      : [...checkedTeamTags, id];
    setCheckedTeamTags(teamTags);
    setTeamsByTag(taggedTeams.filter(tt => teamTags.includes(tt.id) && Boolean(tt.teamIds.length)));
    setIsByTag(Boolean(teamTags.length));
    if (teamTags.length) {
      setIsHierarchical(false);
      if (queryParams.sort && queryParams.sort.includes('hierarchical')) {
        addToQuery({ sort: 'scoreDesc' }, queryParams);
      }
    }
    if (teamTags.includes(id)) {
      setScrollTo(`tag-${id}`);
    }
  };

  useEffect(() => {
    if (scrollTo === null) {
      return;
    }
    const targetNode = scoreRef.current;
    if (
      !targetNode ||
      targetNode.parentElement.getBoundingClientRect().height + 100 < window.innerHeight
    )
      return;

    const offsetTop = targetNode.getBoundingClientRect().top;

    const scrollOffset = document.documentElement.scrollTop ?? window.scrollY;
    window.scrollTo({ top: offsetTop + scrollOffset - 15, behavior: 'smooth' });
  }, [scrollTo]);

  useEffect(() => {
    const filledQueryString = qs.stringify({ ...defaultParams, ...params });
    if (location.pathname.includes('heatmap')) {
      setIsByTag(false);
      setCheckedTeamTags([]);
    }
    if (filledQueryString !== qs.stringify(params)) {
      setSearchTerm('');
      history.replace(`${location.pathname}?${filledQueryString}`);
      return;
    }

    fetchTeams({
      search: searchTerm,
      ...params,
    });
  }, [params, location.pathname, searchTerm]);

  useLayoutEffect(() => {
    BODY_CLASSES();
  }, []);

  const getSortOptions = sortValues => {
    return Object?.keys(sortValues)?.length > 0
      ? Object.keys(sortValues).map(value => ({
          value,
          label: sortValues[value],
        }))
      : [];
  };

  return (
    <div>
      <Page.Wrapper>
        <Page.Content>
          <Page.Header
            css={css`
              display: flex;
              flex-direction: row;
              max-height: 36px;
            `}
            icon={Insights}
          >
            Heelix Score
            {checkedValues.length === 2 && (
              <ComparisonButton
                to={`/organisation_insights/score/comparison?${qs.stringify({
                  team1: checkedValues[0],
                  team2: checkedValues[1],
                  period: params.period,
                })}`}
              >
                Compare selected
              </ComparisonButton>
            )}
            <DateRange
              queryString={params}
              tabLink={location.pathname}
              hideThisWeek
              css={css`
                margin-left: auto;
                font-weight: normal;
                display: none;
                @media (min-width: 1000px) {
                  display: block;
                }
              `}
            />
            <Route
              path="/organisation_insights/score"
              render={() => (
                <SwitchContainer>
                  <SwitchButton
                    turnedOn={showPrevious}
                    onClick={() => setShowPrevious(show => !show)}
                  />
                  Show previous period
                </SwitchContainer>
              )}
            />
          </Page.Header>
          <Page.Paper
            cssProps={css`
              position: relative;
            `}
          >
            <Route
              path="/organisation_insights/score"
              render={() => (
                <Score
                  teams={teams}
                  isHierarchical={isHierarchical}
                  isParticipationView={isParticipationView}
                  checkedValues={checkedValues}
                  setCheckedValues={setCheckedValues}
                  ref={scoreRef}
                  highlightIndex={scrollTo}
                  setSelectedTeamId={setSelectedTeamId}
                  teamsByTag={teamsByTag}
                  isByTag={isByTag}
                  showPrevious={showPrevious}
                />
              )}
            />
            <Route
              path="/organisation_insights/heatmap"
              render={() => <Heatmap teams={teams} isParticipationView={isParticipationView} />}
            />
          </Page.Paper>
        </Page.Content>
        <Page.Sidebar>
          <Route
            path="/organisation_insights/heatmap"
            render={() => <SearchInput placeholder="Search a team" setSearch={setSearchTerm} />}
          />
          <Route
            path="/organisation_insights/score"
            render={() => (
              <SearchInput
                placeholder="Search a team"
                setSearch={search => setScrollTo(handleScroll(search, teams))}
              />
            )}
          />
          <div
            css={css`
              @media (min-width: 1000px) {
                display: none;
              }
            `}
          >
            <SidebarHeader>Date range</SidebarHeader>
            <DateRange
              queryString={params}
              tabLink={location.pathname}
              hideThisWeek
              hideLabel
              portal
              css={css`
                font-weight: normal;
                display: block;
                margin-top: 15px;

                .date-range {
                  width: 100%;
                }
              `}
            />
          </div>
          <div
            css={css`
              @media (min-width: 1200px) {
                display: none;
              }
            `}
          >
            <SidebarHeader
              css={css`
                margin-bottom: 15px;
              `}
            >
              Show previous period
            </SidebarHeader>
            <SwitchButton turnedOn={showPrevious} onClick={() => setShowPrevious(show => !show)} />
          </div>
          <Route
            path="/organisation_insights/score"
            render={() => {
              const options = checkedTeamTags?.length ? tagSortOptions : sortOptions;
              return (
                <>
                  <SidebarHeader>Sort by</SidebarHeader>
                  <StyledSelect
                    options={getSortOptions(options)}
                    inputProps={{ id: 'shoutout-for-input' }}
                    value={{
                      value: params.sort,
                      label: findLabel(params.sort, getSortOptions(options)) || 'hierarchical',
                    }}
                    onChange={handleSort}
                    className="select-component recipients orange"
                    isClearable={false}
                    isSearchable={false}
                  />
                  {Boolean(teamTags?.length) && (
                    <>
                      <SidebarHeader>Tags</SidebarHeader>
                      <ButtonContainer>
                        {teamTags.map(({ id, name }) => (
                          <TeamTagSidebarContainer
                            onClick={() => handleCheckedTeamTag(id)}
                            checked={checkedTeamTags.includes(id)}
                          >
                            <CheckBox isChecked={checkedTeamTags.includes(id)} />
                            {name}
                          </TeamTagSidebarContainer>
                        ))}
                      </ButtonContainer>
                    </>
                  )}
                </>
              );
            }}
          />
          <SidebarHeader>View</SidebarHeader>
          <ButtonContainer>
            {Object.keys(viewTypes).map(key => (
              <>
                <SidebarInput
                  type="radio"
                  value={key}
                  checked={(params.factor ?? params.view ?? 'score') === key}
                  onClick={e => handleView(e.target.value)}
                  id={`viewButton${key}`}
                />
                <SidebarLabel htmlFor={`viewButton${key}`}>{viewTypes[key]}</SidebarLabel>
              </>
            ))}
          </ButtonContainer>
          <SidebarHeader>Factors</SidebarHeader>
          <ButtonContainer>
            {Object.keys(factors).map(key => (
              <>
                <SidebarInput
                  type="radio"
                  value={key}
                  checked={params.factor === key}
                  onClick={e => handleFactor(e.target.value)}
                  id={`factorButton${key}`}
                />
                <SidebarLabel htmlFor={`factorButton${key}`}>{factors[key]}</SidebarLabel>
              </>
            ))}
          </ButtonContainer>
        </Page.Sidebar>
      </Page.Wrapper>
      <TeamModalContainer selectedId={selectedTeamId} setSelectedId={setSelectedTeamId} />
    </div>
  );
};

export default withRouter(OrganisationInsights);
