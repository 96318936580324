/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { getUrl, HEADERS } from 'helpers.js';
import { Link } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { connect } from 'react-redux';
import ScheduleMeetingSection from './ScheduleMeetingSection';

const ScheduleSectionWrapper = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-weight: normal;
  margin-top: 0;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 18px;
`;

const MeetingsWrapper = styled.div`
  margin-left: 0;
  margin-right: 0;
  ${props => !props.showButton && 'margin-bottom: 12px;'}
`;

const ViewAllWrapper = styled.div`
  height: 54px;
  padding-top: 12px;
  margin-right: 10px;
`;
const ViewAllButton = styled.div`
  color: #11a9ff;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
`;

const ViewAll = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #11a9ff;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  display: inline-block;
  margin-right: 10px;
`;

const ScheduleSection = ({ displayMeetingModal }) => {
  const [meetings, setMeetings] = useState([]);
  const [total, setTotal] = useState(false);
  const [isGoogleCalendarEnabled, setIsGoogleCalendarEnabled] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchMeetings = async () => {
    const headers = HEADERS();
    setLoading(true);
    const response = await fetch(getUrl('meeting_settings' + '?limit=3'), {
      method: 'get',
      headers,
    });
    const json = await response.json();
    setMeetings(json.settings);
    setTotal(json.total);
    setIsGoogleCalendarEnabled(json.isGoogleCalendarEnabled);
  };

  useLayoutEffect(() => {
    !loading && fetchMeetings();
    displayMeetingModal && loading && setLoading(false);
  }, [meetings, displayMeetingModal]);

  const disableMeeting = (id, disabled) => {
    const meetingsArr = meetings;
    meetingsArr.forEach(function (m) {
      if (m.id === id) {
        m.disabled = disabled;
      }
    });
    setMeetings(meetingsArr);
  };
  const showButton = total > 3;
  return (
    <ScheduleSectionWrapper>
      {total > 0 && (
        <>
          <Title>Your schedule</Title>
          <MeetingsWrapper className="row" showButton={showButton}>
            {meetings.map((meeting, key) => (
              <div
                key={key}
                className="col-lg-4"
                css={css`
                  padding: 0 !important;
                `}
              >
                <ScheduleMeetingSection key={key} meeting={meeting} fetchMeetings={fetchMeetings} />
              </div>
            ))}
          </MeetingsWrapper>
        </>
      )}
      {showButton && (
        <ViewAllWrapper>
          <Link to="/your_schedule">
            <ViewAllButton className="pull-right">
              <ViewAll>+</ViewAll>
              View all scheduled meetings
            </ViewAllButton>
          </Link>
        </ViewAllWrapper>
      )}
      <Title>Your meeting notes</Title>
    </ScheduleSectionWrapper>
  );
};

const mapStateToProps = state => ({
  displayMeetingModal: state.onboardingDomain.displayMeetingModal,
});

export default connect(mapStateToProps, null)(ScheduleSection);
