/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import Select from 'react-select';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 12px;
`;

const CustomSelect = styled(Select)`
  font-size: 14px;
`;

const CompanyValueSelect = ({ leaderBoardData, setCompanyValueFilter }) => {
  const companyValueSelectOptions = leaderBoardData.companyValues?.length
    ? [
        {
          value: 0,
          label: 'All company values',
        },
        ...leaderBoardData.companyValues.map(companyValue => ({
          value: companyValue.id,
          label: companyValue.name,
        })),
      ]
    : [];
  const [companyValue, setCompanyValue] = useState(companyValueSelectOptions[0]);

  return (
    <div>
      <Title>Company value</Title>
      <CustomSelect
        name="select-member"
        id="select-member"
        onChange={companyValue => {
          setCompanyValueFilter(companyValue.value);
          setCompanyValue(companyValue);
        }}
        value={companyValue}
        options={companyValueSelectOptions}
        isClearable={false}
        placeholder="Select company value"
      />
    </div>
  );
};

CompanyValueSelect.propTypes = {
  leaderBoardData: PropTypes.shape({
    companyValues: PropTypes.array,
    date: PropTypes.string,
  }).isRequired,
  setCompanyValueFilter: PropTypes.func.isRequired,
};

export default CompanyValueSelect;
