/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/react';

import awesome from 'images/vibe/awesome.svg';
import good from 'images/vibe/good.svg';
import okay from 'images/vibe/okay.svg';
import bad from 'images/vibe/bad.svg';
import terrible from 'images/vibe/terrible.svg';
import unknown from 'images/vibe/unknown.svg';

const getImage = name => {
  switch (name) {
    case 'awesome':
      return awesome;
    case 'good':
      return good;
    case 'okay':
      return okay;
    case 'bad':
      return bad;
    case 'terrible':
      return terrible;
    default:
      return unknown;
  }
};

const VibeInfo = ({ vibe, children }) => (
  <div
    css={css`
      display: inline-block;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      max-width: 180px;
      margin: 28px 20px;
      @media (max-width: 1392px) {
        margin: 28px 10px;
      }
      @media (max-width: 415px) {
        margin: 28px 10px 10px;
      }
    `}
  >
    <img
      css={css`
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto 16px;
      `}
      src={getImage(vibe)}
      alt={vibe}
    />
    <span>
      {children}
      <strong>{vibe ? vibe.charAt(0).toUpperCase() + vibe.slice(1) : '...'}</strong>
    </span>
  </div>
);

VibeInfo.propTypes = {
  vibe: PropTypes.string.isRequired,
  children: PropTypes.node,
};

VibeInfo.defaultProps = {
  children: 'Your overall team Vibe is ',
};

export default VibeInfo;
