// TODO: Need to support mobile view at least.
// TODO: Rewrite styles to emotion. Was added before it was added to project.
// TODO: Split to seperate components with prop types and export as new object with them.
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import RightSidebar from 'components/layouts/RightSidebar';

const Page = {
  Section: props => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
      }}
      {...props}
    />
  ),
  Wrapper: props => (
    <div
      style={{
        display: 'flex',
        margin: '0 -15px',
        maxWidth: '1200px',
        flex: 1,
      }}
      {...props}
    />
  ),
  Content: props => (
    <div
      style={{
        flex: '1',
        padding: '0 18px',
        overflowX: 'auto',
      }}
      {...props}
    />
  ),
  Header: ({ children, icon: Icon, ...props }) => (
    <h1
      style={{
        fontWeight: 900,
        fontSize: 24,
        lineHeight: '28px',
        marginBottom: 20,
      }}
      {...props}
    >
      {Icon && (
        <Icon
          style={{
            fill: '#11A9FF',
            marginRight: 12,
            verticalAlign: 'top',
          }}
        />
      )}
      {children}
    </h1>
  ),
  Right: props => (
    <div
      css={css`
        float: right;
        margin-top: -6px;
        @media (max-width: 1199px) {
          display: none;
        }
      `}
      {...props}
    />
  ),
  Clearfix: () => (
    <div
      css={css`
        clear: both;
      `}
    />
  ),
  Divider: () => (
    <div
      style={{
        borderBottom: 'solid 1px #E9E9E9',
        margin: '30px 0 40px',
      }}
    />
  ),
  H2: ({ children, ...props }) => (
    <h2
      style={{
        fontSize: 20,
        textAlign: 'center',
      }}
      {...props}
    >
      {children}
    </h2>
  ),
  Paper: ({ cssProps, ...props }) => (
    <section
      css={css`
        background: #fff;
        padding: 25px;
        border-radius: 4px;
        @media (max-width: 1199px) {
          padding: 10px;
        }
        ${cssProps}
      `}
      {...props}
    />
  ),
  SidebarPlaceholder: props => (
    <div
      css={css`
        flex: 0 200px;
        padding: 0 15px;
        @media (max-width: 1000px) {
          padding: 0;
          flex: 0;
        }
      `}
      {...props}
    />
  ),
  Sidebar: RightSidebar,
};

export default Page;
