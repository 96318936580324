import React, { Component } from 'react';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';
import { toggleUserModal } from 'redux/app/actions';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import MarkedDone from 'components/post/shared/MarkedDone';
import PinnedPost from 'components/post/shared/PinnedPost';
import CommentInput from '../post/shared/CommentInput';
import PostOwners from '../post/shared/PostOwners';
import { AWS_S3_URL } from '../../constants';
import MarkAsDone from '../post/shared/MarkAsDone';
import PostDropdown from './PostDropdown';
import PostTileComments from './PostTileComments';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { AVATAR_ERROR } from '../../helpers';
import PostFile from './PostFile';
import favActiveBtn from '../../images/feed/star_active.png';
import favBtn from '../../images/feed/star.png';
import FavPostTooltip from '../grow/tooltips/FavPostTooltip';
import MetaInfo from './MetaInfo';

export const getTypeString = post => {
  switch (post.subtype) {
    case 'improvement':
      return `Improvement${post.name ? ':' : ''}`;
    case 'idea':
      return `Idea${post.name ? ':' : ''}`;
    case 'start':
    case 'stop':
    case 'continue':
      return `I think we should ${post.subtype}`;
    default:
      return 'Discussion:';
  }
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  discussionTags: state.tagsDomain.discussionTags,
  hashtags: state.tagsDomain.hashtags,
});

const mapDispatchToProps = dispatch => ({
  favPost: (postId, type) => dispatch(POST_ACTIONS.favPost(postId, type)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class DiscussionTile extends Component {
  state = {
    errorAction: null,
    markingAsDone: false,
    unfollowVisible: false,
    lightboxIsOpen: false,
    currentImage: 0,
  };

  componentDidMount() {
    this.setState({
      tags: this.props.tags,
      unfollowVisible: true, // this.props.auth.id !== this.props.fix.user.id,
    });
  }

  toggleOwnersModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showOwnersModal: !this.state.showOwnersModal });
  };

  buildQueryString(tagName) {
    return `?page=1&${qs.stringify(
      { subjects: [tagName] },
      { encode: false, arrayFormat: 'brackets' }
    )}`;
  }

  render() {
    const hasSubtype = !!this.props.post.subtype;
    let content = (
      <>
        {(this.props.post.name || hasSubtype) && (
          <div className="post-tile-title">
            {this.props.post.status === 'resolved' && (
              <img
                src={`${AWS_S3_URL}public/images/icons/marked-as-done.png`}
                className="post-tile-icon-done"
                alt=""
              />
            )}
            <span className={`type ${hasSubtype && 'black'}`}>
              {getTypeString(this.props.post)}&nbsp;
            </span>
            {this.props.post.name}
          </div>
        )}
        <div
          className="post-tile-content"
          dangerouslySetInnerHTML={{
            __html: striptags(this.props.post.content, ['a', 'br']),
          }}
        />
        {this.props.post.possible_fix && (
          <div className="post-tile-possible-solution">
            Possible solution:
            <p>{this.props.post.possible_fix}</p>
          </div>
        )}
        {this.props.post.vibe_reasons.length > 0 && (
          <div className={hasSubtype ? 'categories-wrapper' : 'tags-wrapper'}>
            {hasSubtype && <span>Category:&nbsp;</span>}
            {this.props.post.vibe_reasons.map(tag => {
              if (this.props.post.id > 0) {
                return (
                  <Link key={tag.id} to={`/posts/list${this.buildQueryString(tag.name)}`}>
                    {tag.name}&nbsp;
                  </Link>
                );
              }
              return (
                <span key={tag.id} style={{ color: '#11A9FF' }}>
                  {tag.name}&nbsp;
                </span>
              );
            })}
          </div>
        )}
      </>
    );

    if (this.props.post.id > 0) {
      content = <Link to={`/post/${this.props.post.id}`}>{content}</Link>;
    }

    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }
    rolePosition.push(this.props.post.user.team.name);

    return (
      <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
        <MarkAsDone
          postId={this.props.post.id}
          closeModal={() => {
            this.setState({ markingAsDone: false });
          }}
          show={this.state.markingAsDone}
        />
        <div className={`post-tile ${this.props.post.status}`}>
          <div className="post-tile-header">
            <div className="author-avatar">
              <img
                alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
                src={this.props.post.user.image_url}
                onError={e => AVATAR_ERROR(e.target)}
              />
            </div>
            <AuthorInfo post={this.props.post} user={this.props.user} />
            <PinnedPost post={this.props.post} />
            <img
              src={this.props.post.favourite ? favActiveBtn : favBtn}
              data-tooltip-id={`post_fav_status${this.props.post.id}`}
              className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
              onClick={() => this.props.favPost(this.props.post.id, this.props.type)}
              alt=""
            />
            <PostDropdown
              type={this.props.type}
              post={this.props.post}
              markAsDone={() => this.setState({ markingAsDone: true })}
            />
          </div>
          <div className="post-tile-body">
            <PostMedia
              media={this.props.post.media}
              embed={{
                id: this.props.post.integration_video_id,
                type: this.props.post.integration_video_type,
              }}
            />
            {content}
            {this.props.post.files.map((file, index) => (
              <PostFile file={file} key={index} />
            ))}
            <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} />
            {this.props.post.owners.length > 0 && this.props.post.status !== 'resolved' && (
              <PostOwners
                owners={this.props.post.owners}
                showAllOwners={this.toggleOwnersModal}
                postId={this.props.post.id}
                show={this.state.showOwnersModal}
                user={this.props.user}
              />
            )}
            <MarkedDone post={this.props.post} user={this.props.user} />
            <CommentInput
              post={this.props.post}
              tileRealignHandler={this.props.tileRealignHandler}
              type={this.props.type}
            />
          </div>
          <div className="post-tile-comments-section">
            <div className="post-tile-comments">
              <PostTileComments
                post={this.props.post}
                user={this.props.user}
                type={this.props.type}
              />
            </div>
          </div>
          <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiscussionTile));
