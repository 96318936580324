import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import delayUnmounting from '../../../../HOC/delayUnmounting';

class DateDropdownList extends Component {
  state = {
    mounted: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        mounted: true,
      });
    }, 0);
  }

  componentWillReceiveProps(props) {
    if (this.state.mounted !== props.isMounted) this.setState({ mounted: !this.state.mounted });
  }

  render = () => (
    <RootCloseWrapper disabled={!this.state.mounted} onRootCloseWrapper={this.props.toggle}>
      <div className={`date-dropdown ${this.state.mounted ? 'mounted' : ''}`}>
        {this.props.options.map((option, index) => {
          const qsCopy = JSON.parse(JSON.stringify(this.props.queryString));
          qsCopy.period = option.period;
          delete qsCopy.start_date;
          delete qsCopy.end_date;
          return option.period === 'custom' ? (
            <div
              onClick={this.props.openCustomRangeHandler}
              key={index}
              className="dropdown-option"
            >
              {option.description}
            </div>
          ) : (
            <Link key={index} to={`${this.props.tabLink}?${qs.stringify(qsCopy)}`}>
              {option.description}
            </Link>
          );
        })}
      </div>
    </RootCloseWrapper>
  );
}

export default delayUnmounting(DateDropdownList);
