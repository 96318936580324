import loginWithMicrosoft from 'components/signup/integration/loginWithMicrosoft';
import { APP_URL } from '../../constants';

export default async function registerWithMicrosoft(
  body,
  teamName,
  agreedToTerms,
  onSuccess,
  onFailure,
  setNameError,
  setResponse
) {
  const data = new FormData();
  data.append('username', body.account.userName);
  data.append('token', body.accessToken);
  data.append('name', teamName);
  data.append('terms', agreedToTerms);

  const registerResponse = await fetch(`${APP_URL}/register_microsoft`, {
    method: 'post',
    body: data,
  });

  const json = await registerResponse.json();

  if (json.errorType === 'name') {
    setNameError(json.error);
  } else if (json.error) {
    setResponse({ error: json.error });
  } else {
    loginWithMicrosoft(body, onFailure).then(() => {
      onSuccess();
    });
  }
}
