/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import TeamLead from './TeamLead';
import VibeDoughnutWrap from '../../spaces/SpacesIndex/VibeDoughnutWrap';
import SpaceLogo from '../../spaces/SpaceLogo';
import AssignLead from './AssignLead';
import TeamTags from './TeamTags';

const Name = styled.span`
  font-size: 15px;
  color: #323232;
  font-weight: bold;
  margin-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NameColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const rows = {
  flat: (team, fetchTeams, isMember) => ({
    picture: <SpaceLogo space={team} className="space-logo-teams-new" large />,
    fields: [
      <NameColumnWrapper>
        <Name title={team.name}>{team.name}</Name>
        <TeamTags tags={team.team_tags} />
      </NameColumnWrapper>,
      team.members_count,
      team.invited_count,
      team.team_leads[0] ? (
        <TeamLead leads={team.team_leads} />
      ) : !isMember ? (
        <AssignLead team={team} fetchTeams={fetchTeams} />
      ) : (
        'None.'
      ),
      <div className="space-score-teams-new">
        <VibeDoughnutWrap
          value={+team.vibe_stats_value}
          trend={+team.vibe_stats_trend}
          id={team.id}
          enoughMembers={team.has_enough_members}
        />
      </div>,
    ],
  }),
  hierarchical: team => ({
    picture: <SpaceLogo space={team} className="space-logo-teams-new" large />,
    fields: [
      <Name title={team.name}>{team.name}</Name>,
      team.members_count,
      <div className="space-score-teams-new">
        <VibeDoughnutWrap
          value={+team.vibe_stats_value}
          trend={+team.vibe_stats_trend}
          id={team.id}
          enoughMembers={team.has_enough_members}
        />
      </div>,
    ],
    depth: team.depth,
  }),
};

const column = {
  name: {
    label: 'Name',
    key: 'name',
    width: '290px',
    css: css`
      margin-left: 10px;
      @media (min-width: 768px) and (max-width: 1400px) {
        width: 100px !important;
        max-width: 100px !important;
        min-width: 100px !important;
      }
    `,
  },
  nameHierarchical: {
    label: 'Name',
    key: 'name',
    width: '500px',
    css: css`
      margin-left: 10px;
    `,
  },
  members: {
    label: 'Members',
    key: 'members',
    width: '90px',
    css: css`
      text-align: center;
    `,
  },
  invited: {
    label: 'Invited',
    key: 'invited',
    width: '90px',
    css: css`
      text-align: center;
    `,
  },
  membersHierarchical: {
    label: 'Members',
    key: 'members',
    width: '250px',
    css: css`
      text-align: center;
    `,
  },
  lead: {
    label: 'Lead',
    key: 'lead',
    width: '280px',
    isSortable: false,
    css: css`
      cursor: default;
      text-align: center;
      @media (min-width: 768px) and (max-width: 1100px) {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
      }
      @media (min-width: 1101px) and (max-width: 1200px) {
        width: 250px !important;
        max-width: 250px !important;
        min-width: 250px !important;
      }
    `,
  },
  score: {
    label: 'Score',
    key: 'score',
    width: '100px',
    css: css`
      text-align: center;
    `,
  },
};
const { name, nameHierarchical, members, invited, membersHierarchical, lead, score } = column;
const columns = {
  flat: [name, members, invited, lead, score],
  hierarchical: [nameHierarchical, membersHierarchical, score],
};

const fields = { rows, columns };

export default fields;
