import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Croppie from 'components/shared/croppie';
import Dropzone from 'react-dropzone';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import EditPasswordForm from './EditPasswordForm';
import AccountDeletionModal from './AccountDeletionModal';
import { AVATAR_ERROR, BODY_CLASSES, HEADERS, LOCALSTORAGE_BOOL, findLabel } from '../../helpers';
import { APP_URL, AVATAR_GENERIC, AWS_S3_URL, HIDE_GROW } from '../../constants';
import LoadingScale from '../shared/LoadingScale';
import { USER_ACTIONS } from '../../redux/actions/userActions';
import CroppieRotate from '../shared/CroppieRotate';
import verifyAvatarImage from '../shared/fileUpload/verifyAvatarImage';

const initialState = {
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  originalEmail: '',
  croppieModal: false,
  timezones: [],
  timezone: { value: 'Australia/Sydney', label: 'Sydney' },
  teams: [],
  user_tags: '',
  description: '',
  teamId: null,
  teamsLead: [],
  disabled: false,
  state: '',
  authUser: false,
  image: '',
  imagePreviewUrl: null,
  loaded: false,
  redirect: false,
  passChange: false,
  emailChanged: false,
  currentPassword: '',
  newPassword: '',
  canChangeAccountAdmin: false,
  isAccountAdmin: false,
  imagePreviewCroppyUrl: false,
  sendImage: false,
  loading: false,
  charsLeft: 250,
  maxChar: 250,
  isModalOpen: false,
};

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;

    this.state = initialState;
  }

  componentDidMount() {
    BODY_CLASSES(['users', 'edit', 'jobvibe-future']);
    this.edit();
    this.props.closeFlash();
    if (window.location.hash === '#delete') {
      this.setState({ isModalOpen: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const locationObject = this.props.location;
    const nextLocationObject = nextProps.location;

    if (locationObject !== nextLocationObject) {
      if (locationObject.hash !== nextLocationObject.hash) {
        nextLocationObject.hash === '#delete' && this.setState({ isModalOpen: true });
      } else {
        this.id = nextProps.match.params.id;
        this.edit();
      }
    }
  }

  componentDidUpdate() {
    if (this.state.imagePreviewCroppyUrl) {
      const el = this.refs.croppie;
      if (el) {
        this.croppie = new Croppie(el, {
          url: this.state.imagePreviewCroppyUrl,
          viewport: {
            width: 200,
            height: 200,
            type: 'circle',
          },
          boundary: {
            width: 300,
            height: 300,
          },
          enableExif: true,
          enableOrientation: true,
        });
      }
    }
  }

  receiveErrors = (errors, response) => {
    if (!errors) {
      if (this.croppie) {
        this.croppie.destroy();
        this.croppie = null;
      }
      this.allFilesSize = 0;
      this.setState({
        imagePreviewUrl: response.data.user.image_url,
        loading: false,
        currentPassword: '',
        newPassword: '',
        sendImage: false,
      });
    } else {
      this.setState({
        postErrors: errors,
        disabledButton: false,
        loading: false,
      });
    }
    this.props.updateFlash(response.data.flashName, response.data.message);
  };

  generalError = error => {
    if (error) {
      this.setState({
        generalError: error,
        disabledButton: false,
        loading: false,
      });
    }
  };

  handleWordCount = event => {
    const charCount = event.target.value.length;
    const { maxChar } = this.state;
    const charLength = maxChar - charCount;
    if (charLength > 0) {
      this.setState({
        charsLeft: charLength,
        description: event.target.value,
      });
    } else {
      this.setState({
        charsLeft: 0,
      });
    }
  };

  edit() {
    const headers = HEADERS();
    const url = this.id ? `/user/${this.id}/edit` : '/edit_profile';

    fetch(APP_URL + url, { headers })
      .then(response => {
        if (response.status == 413) {
          alert('Image to large. Please send photos not larger then 2MB');
          return;
        }
        if (response.status !== 200) {
          this.props.setErrorPage(response.status);
        } else {
          response.json().then(jsonResponse => {
            this.setState({
              firstName: jsonResponse.user.first_name,
              lastName: jsonResponse.user.last_name,
              email: jsonResponse.user.email,
              originalEmail: jsonResponse.user.email,
              role: jsonResponse.user.role ? jsonResponse.user.role : '',
              timezones: jsonResponse.timezones ? jsonResponse.timezones : [],
              timezone: jsonResponse.user.time_zone
                ? {
                    value: jsonResponse.user.time_zone,
                    label: findLabel(
                      jsonResponse.user.time_zone,
                      this.timeZoneOptions(jsonResponse.timezones ? jsonResponse.timezones : null)
                    ),
                  }
                : { value: 'Australia/Sydney', label: findLabel('Australia/Sydney', 'Sydney') },
              teams: jsonResponse.teams ? jsonResponse.teams : [],
              user_tags: jsonResponse.user.tags ? jsonResponse.user.tags : '',
              description: jsonResponse.user.description ? jsonResponse.user.description : '',
              teamId: jsonResponse.user.team_id ? jsonResponse.user.team_id : null,
              teamsLead: jsonResponse.lead_teams ? jsonResponse.lead_teams : [],
              teamsToLead: jsonResponse.teamsToLead ? jsonResponse.teamsToLead : [],
              disabled: jsonResponse.disabled ? jsonResponse.disabled : false,
              state: jsonResponse.user.state ? jsonResponse.user.state : '',
              authUser: jsonResponse.auth_user ? jsonResponse.auth_user : false,
              imagePreviewUrl: jsonResponse.user.image_url,
              canChangeAccountAdmin: jsonResponse.canChangeAccountAdmin,
              isAccountAdmin: jsonResponse.isAccountAdmin,
              loaded: true,
              charsLeft: jsonResponse.user.description
                ? 250 - jsonResponse.user.description.length
                : 250,
            });

            this.props.setHeader(
              `Edit profile - ${jsonResponse.user.first_name} ${jsonResponse.user.last_name}`
            );
          });
        }
      })
      .catch(() => {});
  }

  manageRole() {
    if (LOCALSTORAGE_BOOL('is_admin')) {
      return (
        <div className="form-group boolean optional disabled user_account_admin checkbox-primary">
          <div className="checkbox">
            <input
              type="checkbox"
              name="user[account_admin]"
              className="boolean optional"
              id="user_account_admin"
              onChange={() => this.setState({ isAccountAdmin: !this.state.isAccountAdmin })}
              defaultChecked={this.state.isAccountAdmin}
            />
            <label htmlFor="user_account_admin" className="boolean optional">
              This user is an Account Administrator
            </label>
          </div>
        </div>
      );
    }
    if (LOCALSTORAGE_BOOL('is_company_owner')) {
      const classes = this.state.canChangeAccountAdmin
        ? 'boolean optional'
        : 'boolean optional disabled';
      const opts = {};
      if (!this.state.canChangeAccountAdmin) {
        opts.disabled = 'disabled';
      }
      return (
        <div className="form-group boolean optional disabled user_account_admin checkbox-primary">
          <div className="checkbox">
            <input
              type="checkbox"
              name="user[account_admin]"
              className={classes}
              id="user_account_admin"
              defaultChecked={this.state.isAccountAdmin}
              {...opts}
              onChange={() => this.setState({ isAccountAdmin: !this.state.isAccountAdmin })}
            />
            <label htmlFor="user_account_admin" className="boolean optional">
              This user is an Account Administrator
            </label>
          </div>
        </div>
      );
    }
  }

  manageTeamLead() {
    if (LOCALSTORAGE_BOOL('is_company_owner')) {
      return (
        <div className="form-group select optional user_lead_teams">
          <label htmlFor="team-lead-select" className="select optional control-label">
            Lead
          </label>
          <Select
            ref="select"
            name="lead_team_ids"
            id="team-lead-select"
            className="select-component"
            onChange={lead => this.setTeamsLead(lead)}
            value={
              this.state.teamsLead?.length > 0
                ? this.state.teamsLead.map(leadId => ({
                    value: leadId.value,
                    label: findLabel(leadId.value, this.teamsToLeadOptions()),
                  }))
                : ''
            }
            options={this.teamsToLeadOptions()}
            isMulti
            classNamePrefix="r-select"
          />
        </div>
      );
    }
  }

  addDeleteHash() {
    window.location.hash = 'delete';
  }

  removeDeleteHash() {
    this.props.history.push(this.props.location.pathname);
  }

  closeModal() {
    this.removeDeleteHash();
    this.setState({ isModalOpen: false });
  }

  submitAccountDeletion() {
    this.setState({ loading: true });
    const headers = HEADERS();
    fetch(`${APP_URL}/request_deletion`, { method: 'post', headers })
      .then(response => {
        if (response.status === 200) {
          this.props.updateAccountDeletionStatus('pending');
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.updateAccountDeletionStatus('rejected');
      })
      .finally(() => {
        this.closeModal();
      });
  }

  manageAccountDeletion() {
    return (
      <div className="account-deletion">
        <button
          onClick={e => {
            e.preventDefault();
            this.addDeleteHash();
          }}
          className="btn btn-danger"
          disabled={this.props.requestDeletionStatus === 'pending'}
        >
          {this.props.requestDeletionStatus === 'pending'
            ? 'Marked for deletion'
            : 'Request account deletion'}
        </button>
        <i className="fa fa-info-circle info-icon" aria-hidden="true" data-tooltip-id="info" />
        <ReactTooltip id="info" class="react-tooltip" place="top" type="dark" effect="solid">
          <p className="info-tooltip">
            If you request account deletion it will
            <br />
            take up to 1 week for the request to be processed
          </p>
        </ReactTooltip>
      </div>
    );
  }

  setTeamsLead(leads) {
    this.setState({ teamsLead: leads });
  }

  formatTags = tags => {
    const tagsArray = tags
      .split('#')
      .filter(tag => tag.length > 0)
      .map(tag => {
        return `#${tag.trim()}`;
      });
    return tagsArray.join(' ');
  };

  sendData(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const data = new FormData();
    data.append('user[first_name]', this.state.firstName);
    data.append('user[last_name]', this.state.lastName);
    data.append('user[email]', this.state.email);
    data.append('user[role]', this.state.role);
    data.append('user[time_zone]', this.state.timezone?.value);
    data.append('user[time_zone]', this.state.timezone?.value);
    data.append('user[tags]', this.state.user_tags);
    data.append('user[description]', this.state.description);
    if (this.state.teamId) {
      data.append('user[team_id]', this.state.teamId);
    }
    data.append('user[account_admin]', this.state.isAccountAdmin);
    data.append('userImage', this.state.image);
    if (this.state.sendImage) {
      data.append('userImageBase64', this.state.imagePreviewUrl);
    }
    for (let i = 0; i < this.state.teamsLead.length; i++) {
      data.append('lead_team_ids[]', this.state.teamsLead[i].value);
    }

    if (this.state.passChange) {
      data.append('pass_change', this.state.passChange);
      data.append('user[current_password]', this.state.currentPassword);
      data.append('user[password]', this.state.newPassword);
    }
    this.props.updateProfile(this.id, data, this.receiveErrors, this.generalError);
    this.setState({ user_tags: this.formatTags(this.state.user_tags) });
    window.scrollTo(0, 0);
  }

  deleteImage(e) {
    e.preventDefault();
    this.setState({
      disabledButton: true,
      postErrors: null,
      generalError: null,
      loading: true,
    });
    const data = new FormData();
    data.append('remove_image', true);
    this.props.removePhoto(this.id, data, this.receiveErrors, this.generalError);
  }

  updateImage = () => {
    const data = new FormData();
    data.append('update_image', true);
    if (this.state.sendImage) {
      data.append('userImageBase64', this.state.imagePreviewUrl);
    }
    this.props.updatePhoto(this.id, data, this.receiveErrors, this.generalError);
  };

  showCroppieModal(e, state) {
    if (!state) {
      this.setState({ imagePreviewCroppyUrl: false });
      document.getElementById('trigger-croppie').value = '';
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({ croppieModal: state });
  }

  insertToCroppie = files => {
    const reader = new FileReader();
    const image = files[0];
    if (verifyAvatarImage(image)) {
      this.showCroppieModal(null, true);
      reader.onloadend = () => {
        this.setState({
          image,
          imagePreviewCroppyUrl: reader.result,
        });
      };
      reader.readAsDataURL(image);
    } else {
      files = null;
    }
  };

  enableUser(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const headers = HEADERS();
    const data = new FormData();
    data.append('user[disabled]', 0);
    fetch(`${APP_URL}/user/${this.id}/update`, { headers, method: 'post', body: data })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          response.json().then(jsonResponse => {
            this.setState({
              redirect: jsonResponse.redirect,
              state: jsonResponse.state,
              disabled: false,
            });
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
            this.setState({ loading: false });
          });
        }
      })
      .catch(() => {});
  }

  disableUser(e) {
    if (window.confirm('Are you sure?')) {
      this.setState({ loading: true });
      e.preventDefault();
      const data = new FormData();
      data.append('user[disabled]', 1);
      const headers = HEADERS();
      fetch(`${APP_URL}/user/${this.id}/update`, { headers, method: 'post', body: data })
        .then(response => {
          if (response.status === 200 && response.ok === true) {
            response.json().then(jsonResponse => {
              this.setState({
                redirect: jsonResponse.redirect,
                state: jsonResponse.state,
                disabled: true,
              });
              this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
              this.setState({ loading: false });
            });
          }
        })
        .catch(() => {});
    }
  }

  setCurrentPassword(e) {
    this.setState({ currentPassword: e.target.value });
  }

  setNewPassword(e) {
    this.setState({ newPassword: e.target.value });
  }

  passChange(value) {
    this.setState({ passChange: value });
  }

  teamOptions(teamValues = null) {
    const opts = [];
    const teamsList = teamValues ? teamValues : this.state.teams;
    teamsList.forEach(team => {
      opts.push({ value: team.id, label: team.name });
    });
    return opts;
  }

  timeZoneOptions(timezoneValues = null) {
    const timezones = [];
    const timezonesList = timezoneValues ? timezoneValues : this.state.timezones;
    timezonesList.forEach(timezone => {
      timezones.push({ value: timezone.identifier, label: timezone.name_display });
    });
    return timezones;
  }

  teamsToLeadOptions() {
    const opts = [];
    this.state.teamsToLead.forEach(team => {
      opts.push({ value: team.id, label: team.name });
    });
    return opts;
  }

  getCroppieResult() {
    this.croppie.result('blob').then(blob => {
      if (blob.size > 2000000) {
        alert('File is too big!');
      } else {
        this.croppie.result('base64').then(base => {
          this.setState(
            {
              imagePreviewCroppyUrl: false,
              imagePreviewUrl: base,
              croppieModal: false,
              sendImage: true,
            },
            () => this.updateImage()
          );
        });
      }
    });
  }

  tagsChangedHandler = event => {
    this.setState({ user_tags: event.target.value });
  };

  emailChangedHandler = event => {
    this.setState({
      email: event.target.value,
      emailChanged: event.target.value.trim() !== this.state.originalEmail,
    });
  };

  rotateCroppie = deg => this.croppie.rotate(deg);

  render() {
    if (this.state.loaded) {
      if (this.state.redirect === true) {
        return (
          <Redirect
            push
            to={{
              pathname: '/users/',
              state: {
                message: this.state.message,
              },
            }}
          />
        );
      }

      let emailChangedWarning = null;
      let emailInputClasses = 'form-group email optional user_email';
      if (this.state.emailChanged) {
        emailChangedWarning = (
          <div>
            <span className="text-warning small">
              If this email is linked to a Google account you will no longer be able to login via
              Google Single Sign on
            </span>
          </div>
        );
        emailInputClasses = 'form-group email optional user_email has-warning';
      }

      return (
        <form className="simple_form edit_user">
          {this.state.loading && <LoadingScale />}
          <div className="row" id="edit-profile-header">
            <div className="col-xs-12">
              <h1>Edit profile</h1>
            </div>
          </div>
          <div className="row" id="edit-form-wrapper">
            <div className="col-md-6" id="edit-form-left">
              <div id="croppie-form">
                <div className="image-input margin-below clear">
                  <div className="dropzone-wrap trigger-croppie" id="trigger-croppie">
                    <Dropzone
                      onDrop={e => this.insertToCroppie(e)}
                      accept={{
                        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps({
                              style: {
                                width: '100%',
                                height: '121px',
                                border: 'dashed 1px #11A9FF',
                                borderRadius: '5px',
                                position: 'relative',
                                zIndex: '2',
                              },
                            })}
                          >
                            <input {...getInputProps({ capture: false })} />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div className="dropzone-placeholder">
                      <a className="square-box" onClick={() => this.refs.uploadInput.click()}>
                        <div className="circle-image md">
                          <img
                            id="img-preview"
                            src={this.state.imagePreviewUrl}
                            alt={this.state.firstName}
                            onError={e => AVATAR_ERROR(e.target)}
                          />
                        </div>
                      </a>
                      <div className="dropzone-placeholder-info">
                        <svg
                          width="35"
                          height="30"
                          viewBox="0 0 35 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="pull-left"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.0345 5.52632H31.3793C33.3707 5.52632 35 7.18421 35 9.21053V25.7895C35 27.8158 33.3707 29.4737 31.3793 29.4737H3.62069C1.62931 29.4737 0 27.8158 0 25.7895V9.21053C0 7.18421 1.62931 5.52632 3.62069 5.52632H6.75862C7.35603 5.52632 7.99907 5.05474 8.18843 4.47816L9.31157 1.04853C9.50021 0.471579 10.144 0 10.7414 0H23.0517C23.6491 0 24.2922 0.471579 24.4815 1.04816L25.6047 4.47779C25.7933 5.05474 26.4371 5.52632 27.0345 5.52632ZM9.65531 16.5792C9.65531 12.8499 12.6269 9.82486 16.2932 9.82486C19.9587 9.82486 22.9312 12.8499 22.9312 16.5792C22.9312 20.3099 19.9587 23.3336 16.2932 23.3336C12.6269 23.3336 9.65531 20.3099 9.65531 16.5792ZM16.2933 25.7895C11.2942 25.7895 7.2416 21.6657 7.2416 16.5789C7.2416 11.4925 11.2942 7.36842 16.2933 7.36842C21.2917 7.36842 25.345 11.4925 25.345 16.5789C25.345 21.6657 21.2917 25.7895 16.2933 25.7895ZM28.9655 9.82426C28.9655 10.3271 29.5062 11.0523 30.1724 11.0523C30.8389 11.0523 31.3793 10.3271 31.3793 9.82426C31.3793 9.32197 30.8393 8.59619 30.1724 8.59619C29.5062 8.59619 28.9655 9.32197 28.9655 9.82426Z"
                            fill="#11A9FF"
                          />
                        </svg>
                        <h3>
                          Drop your new photo here or <span>choose a file</span>
                          <br />
                          <span className="dropzone-placeholer-img-info">
                            100 x 100 px minimum!
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.imagePreviewUrl !== AWS_S3_URL + AVATAR_GENERIC &&
                !this.state.sendImage ? (
                  <a
                    href="/#"
                    className="primary-link"
                    id="remove-image-btn"
                    onClick={e => this.deleteImage(e)}
                  >
                    Remove current photo
                  </a>
                ) : (
                  ''
                )}
                {this.state.croppieModal && (
                  <Modal
                    id="avatar_edit_modal"
                    show={this.state.croppieModal}
                    onHide={() => this.showCroppieModal(null, false)}
                  >
                    <Modal.Header className="no-border centered">
                      <Modal.Title>Crop your image</Modal.Title>
                      <p className="u-margin-bottom-none">Zoom the image to fit.</p>
                    </Modal.Header>
                    <Modal.Body className="clear">
                      <div id="croppie" ref="croppie" />
                      <CroppieRotate rotate={this.rotateCroppie.bind(this)} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        id="save-image"
                        className="btn btn-sm btn-wide btn-primary pull-right upload-result"
                        onClick={() => this.getCroppieResult()}
                      >
                        Save
                      </button>
                      <a
                        className="cancel btn btn-sm btn-fixed pull-right"
                        id="cancel-image"
                        onClick={() => this.showCroppieModal(null, false)}
                      >
                        Cancel
                      </a>
                    </Modal.Footer>
                  </Modal>
                )}
                <AccountDeletionModal
                  isModalOpen={this.state.isModalOpen}
                  closeModal={() => this.closeModal()}
                  submitAccountDeletion={e => {
                    e.preventDefault();
                    this.submitAccountDeletion();
                  }}
                />
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group string optional user_first_name">
                    <label htmlFor="user_first_name" className="string required control-label">
                      First name
                      <i className="fa fa-asterisk required-icon" aria-hidden="true" />
                    </label>
                    <input
                      type="text"
                      value={this.state.firstName}
                      id="user_first_name"
                      name="user[first_name]"
                      className="string optional form-control"
                      onChange={e => this.setState({ firstName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="form-group string optional user_last_name">
                    <label htmlFor="user_last_name" className="string required control-label">
                      Last name
                      <i className="fa fa-asterisk required-icon" aria-hidden="true" />
                    </label>
                    <input
                      type="text"
                      value={this.state.lastName}
                      id="user_last_name"
                      name="user[last_name]"
                      className="string optional form-control"
                      onChange={e => this.setState({ lastName: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group string optional user_role">
                <label htmlFor="user_role" className="string optional control-label">
                  Role/Position
                </label>
                <input
                  type="text"
                  value={this.state.role}
                  id="user_role"
                  className="string optional form-control"
                  name="user[role]"
                  onChange={e => this.setState({ role: e.target.value })}
                />
              </div>
              <div className={emailInputClasses}>
                <label htmlFor="user_email" className="email optional control-label">
                  Email
                </label>
                <input
                  type="text"
                  value={this.state.email}
                  id="user_email"
                  className="string email optional form-control"
                  name="user[email]"
                  onChange={e => this.emailChangedHandler(e)}
                />
                {emailChangedWarning}
              </div>
              {!HIDE_GROW && (
                <div className="form-group optional user_description">
                  <label htmlFor="user_description" className="description optional control-label">
                    Description
                  </label>
                  <textarea
                    name="user[description]"
                    id="user_description"
                    cols="30"
                    maxLength={this.state.maxChar}
                    value={this.state.description}
                    onChange={this.handleWordCount}
                    className="textarea optional form-control"
                    rows="4"
                  />
                  <p>
                    Characters left:
                    {this.state.charsLeft}
                  </p>
                </div>
              )}
              {!HIDE_GROW && (
                <div className="form-group optional user_description">
                  <label htmlFor="user_tags" className="tags optional control-label">
                    Interests
                    <span> (begin with #)</span>
                  </label>
                  <input
                    type="text"
                    name="user_tags"
                    id="user_tags"
                    className="text form-control"
                    placeholder="Add #hashtags"
                    value={this.state.user_tags}
                    onChange={this.tagsChangedHandler}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 password-change" id="edit-form-right">
              {this.state.authUser ? (
                <EditPasswordForm
                  setCurrentPassword={this.setCurrentPassword.bind(this)}
                  setNewPassword={this.setNewPassword.bind(this)}
                  currentPassword={this.state.currentPassword}
                  newPassword={this.state.newPassword}
                  passChange={this.passChange.bind(this)}
                />
              ) : (
                ''
              )}
              <div className="form-group select optional user_time_zone">
                <label htmlFor="user_time_zone" className="select optional control-label">
                  Time zone
                  <i className="fa fa-asterisk required-icon" aria-hidden="true" />
                </label>
                <Select
                  name="user[time_zone]"
                  id="user_time_zone-select"
                  className="select-component"
                  isSearchable
                  classNamePrefix="r-select"
                  isClearable={false}
                  onChange={timezone => {
                    this.setState({
                      timezone: timezone || {
                        value: 'Australia/Sydney',
                        label: findLabel('Australia/Sydney', this.timeZoneOptions()),
                      },
                    });
                  }}
                  value={
                    this.state.timezone || {
                      value: 'Australia/Sydney',
                      label: findLabel('Australia/Sydney', this.timeZoneOptions()),
                    }
                  }
                  options={this.timeZoneOptions()}
                />
              </div>
              {this.props.user.isAccountAdmin && (
                <div className="form-group user_team">
                  <label htmlFor="team-select" className="control-label">
                    Member of...
                  </label>
                  <Select
                    name="user[team_id]"
                    id="team-select"
                    className="select-component"
                    isSearchable
                    classNamePrefix="r-select"
                    onChange={id => this.setState({ teamId: id.value })}
                    value={
                      this.state.teamId
                        ? {
                            value: this.state.teamId,
                            label: findLabel(this.state.teamId, this.teamOptions()),
                          }
                        : ''
                    }
                    options={this.teamOptions()}
                  />
                </div>
              )}
              {this.manageRole()}
              {this.manageTeamLead()}
              {this.manageAccountDeletion()}
            </div>
          </div>
          <div className="form-footer clear">
            <button className="btn btn-lg btn-wide btn-primary" onClick={e => this.sendData(e)}>
              Save
            </button>
            {localStorage != null &&
            LOCALSTORAGE_BOOL('is_company_owner') &&
            !this.state.authUser ? (
              this.state.disabled ? (
                <a
                  className="primary-link form-footer-text-link pull-right"
                  onClick={e => this.enableUser(e)}
                >
                  Enable User
                </a>
              ) : (
                <a
                  className="primary-link form-footer-text-link pull-right"
                  onClick={e => this.disableUser(e)}
                >
                  Disable User
                </a>
              )
            ) : (
              <div />
            )}
          </div>
        </form>
      );
    }
    return <div />;
  }
}

const mapDispatchToProps = dispatch => ({
  removePhoto: (id, receiveErrors, generalError) =>
    dispatch(USER_ACTIONS.removePhoto(id, receiveErrors, generalError)),
  updatePhoto: (id, data, receiveErrors, generalError) =>
    dispatch(USER_ACTIONS.updatePhoto(id, data, receiveErrors, generalError)),
  updateProfile: (id, data, receiveErrors, generalError) =>
    dispatch(USER_ACTIONS.updateProfile(id, data, receiveErrors, generalError)),
  updateAccountDeletionStatus: deletionStatus =>
    dispatch(USER_ACTIONS.updateAccountDeletionStatus(deletionStatus)),
});

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  requestDeletionStatus: state.usersDomain.requestDeletionStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
