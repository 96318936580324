import { withRouter } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import React from 'react';
import PropTypes from 'prop-types';
import AppleLogin from 'components/signup/AppleLogin';
import qs from 'qs';
import { AWS_S3_URL, GOOGLE_KEY, MICROSOFT_KEY, APP_URL } from '../../../constants';
import WelcomeContainer from '../WelcomeContainer';
import MicrosoftLogin from "react-microsoft-login";

const InviteWelcome = ({
  firstName,
  lastName,
  teamName,
  handleNames,
  googleResponse,
  googleFailure,
  location,
  microsoftAuthHandler
}) => (
  <WelcomeContainer
    title={`Hi ${firstName},`}
    body={
      <>
        <div className="description-gray mx-auto">
          <div>You're about to join your team in {teamName}.</div>
          <div>Use Google to create your account or start with your first and last name.</div>
        </div>
        <div className="google-button text-center">
          <GoogleLogin
            clientId={GOOGLE_KEY}
            prompt="select_account"
            onSuccess={googleResponse}
            onFailure={googleFailure}
            render={renderProps => (
              <button
                onClick={renderProps.onClick}
                type="button"
                className="btn text-center mx-auto"
              >
                <img src={`${AWS_S3_URL}public/images/icons/google-button-logo.png`} alt="" />
                <span>Use Google account</span>
              </button>
            )}
          />
        </div>
        <AppleLogin
          state={qs.parse(location.search, { ignoreQueryPrefix: true }).invitation_token}
        />
        <div className="google-button text-center">
          <MicrosoftLogin
            clientId={MICROSOFT_KEY}
            authCallback={microsoftAuthHandler}
          >
            <button
                type="button"
                className="btn text-center mx-auto"
              >
                <img src={`${APP_URL}/microsoft.png`} alt="" />
                <span>Sign in with Microsoft</span>
            </button>
          </MicrosoftLogin>
        </div>
        <div className=" horizontal-line mx-auto">
          <span>Or</span>
        </div>
        <form onSubmit={handleNames} action="" className="simple_form form-compact" id="new_user">
          <div className="form-inline">
            <div className="form-group">
              <input
                required
                name="first_name"
                type="text"
                className="string required form-control"
                placeholder="First name"
                defaultValue={firstName}
              />
            </div>
            <div className="form-group">
              <input
                required
                name="last_name"
                type="text"
                className="string required form-control"
                placeholder="Last name"
                defaultValue={lastName}
              />
            </div>
          </div>
          <button
            disabled={false}
            type="submit"
            className="btn btn-primary btn-full invite-submit"
            id="sign-in-btn"
          >
            Go
          </button>
        </form>
      </>
    }
  />
);

InviteWelcome.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  handleNames: PropTypes.func.isRequired,
  googleResponse: PropTypes.func.isRequired,
  googleFailure: PropTypes.func.isRequired,
  microsoftAuthHandler: PropTypes.func.isRequired,
};

export default withRouter(InviteWelcome);
