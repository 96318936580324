/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 57%;
  float: left;
  text-align: left;
  color: #323232;
  padding-left: 23px;
  margin-bottom: 45px;
  @media (max-width: 450px) {
    margin-top: 50px;
    padding-left: 0px;
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
`;

const List = styled.div`
  color: #11a9ff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

const Link = styled.a`
  padding-left: 12px;
  cursor: pointer;
`;

const ListElement = ({ element, ...props }) => (
  <div
    css={css`
      padding-bottom: 11px;
    `}
    {...props}
  >
    <Icon />
    <Link target="_blank" href={`http://help.heelix.com/en/articles/${element.url}`}>
      {element.title}
    </Link>
  </div>
);

const Icon = () => (
  <div className="menu-icon">
    <span className="line" />
    <span className="line" />
    <span className="line" />
  </div>
);

const Support = ({}) => {
  const supportArray = [
    {
      title: 'What is the Vibe?',
      url: '4254222-what-is-the-vibe',
    },
    {
      title: 'Insights',
      url: '4253675-insights',
    },
    {
      title: 'Grow',
      url: '4254223-grow',
    },
    {
      title: 'Spaces',
      url: '4254394-spaces',
    },
    {
      title: 'Anonymity in Heelix',
      url: '4253677-anonymity-in-heelix',
    },
    {
      title: 'Notification settings',
      url: '4253674-notification-settings',
    },
    {
      title: 'Mobile apps',
      url: '4253680-mobile-apps',
    },
    {
      title: 'Changing your password',
      url: '4253678-changing-your-password',
    },
    {
      title: 'Resetting your password',
      url: '4253679-resetting-your-password',
    },
    {
      title: 'Changing your name',
      url: '4254208-changing-your-name',
    },
    {
      title: 'Deactivating your account',
      url: '4254210-deactivating-your-account',
    },
    {
      title: 'Not receiving emails from us?',
      url: '4254213-not-receiving-emails-from-heelix',
    },
    {
      title: 'Your data',
      url: '4253681-data-security-and-privacy-policy',
    },
  ];
  return (
    <Wrapper>
      <Title>Support</Title>
      <List>
        {supportArray.map((el, index) => (
          <ListElement key={index} element={el} />
        ))}
      </List>
    </Wrapper>
  );
};

export default Support;
