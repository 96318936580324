/** @jsxImportSource @emotion/react */
import UpgradeModal, { initialData } from 'components/payments/PaymentChoose/UpgradeModal';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/react';
import {
  ComparisonContainer,
  ComparisonDescription,
  ComparisonItem,
  ComparisonTitle,
} from 'components/payments/layout';
import qs from 'qs';
import fetchApi from 'fetchApi';

const UpgradeModalContainer = ({ show, planData, ...props }) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const queryString = qs.stringify(planData);
    const response = await fetchApi(`/payments/upgrade_modal?${queryString}`);
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (show && planData.plan && planData.planSize) {
      fetchData();
    }

    if (!show) {
      setData(initialData);
      setLoading(true);
    }
  }, [show]);

  return (
    <UpgradeModal
      data={data}
      loading={loading}
      show={show}
      content={() => (
        <>
          <PlanModal.Title
            css={css`
              font-size: 18px;
              line-height: 21px;
              justify-content: center;
              align-items: center;
            `}
          >
            <PlanModal.Pig
              css={css`
                width: 30px;
                height: 30px;
                flex-shrink: 0;
                margin-right: 12px;
              `}
            />
            Confirm your new plan
          </PlanModal.Title>
          <PlanModal.Content
            css={css`
              font-size: 14px;
              margin-bottom: 25px;
            `}
          >
            Here are the details for your new plan. Please review the below and once you’re happy,
            simply hit the blue button to confirm.
          </PlanModal.Content>
          <ComparisonContainer>
            <ComparisonItem>
              <ComparisonTitle>Current billing</ComparisonTitle>
              <ComparisonDescription>{data.currentPlanDetails}</ComparisonDescription>
            </ComparisonItem>
            <ComparisonItem>
              <ComparisonTitle>New billing</ComparisonTitle>
              <ComparisonDescription>
                {data.upgradePlanDetails}
                {data.upgradePlanMoreDetails && (
                  <div>
                    <strong>{data.upgradePlanMoreDetails}</strong>
                  </div>
                )}
              </ComparisonDescription>
            </ComparisonItem>
          </ComparisonContainer>
        </>
      )}
      {...props}
    />
  );
};

UpgradeModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateFlash: PropTypes.func,
};

export default UpgradeModalContainer;
