import React, { useState } from 'react';
import striptags from 'striptags';
import { getUrl, HEADERS } from 'helpers';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  clearActionBody,
  setAction,
  setCurrentNoteModal,
  setShowActionModal,
  toggleNoteModal,
} from 'redux/app/actions';
import { generateTitle } from 'components/vibes/createForms/CreateNote';
import { handleEdit } from 'pages/Grow/GrowPost/NoteDropdown/Convert';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import {
  Checkbox,
  Content,
  ContentWrapper,
  Header,
  Notes,
  PreparationWrapper,
  StepDetails,
  Steps,
  StepWrapper,
  Title,
} from 'pages/Grow/Meeting/layout';
import DateWrapper from 'pages/Grow/Meeting/DateWrapper';
import MeetingPreparation from 'pages/Grow/Meeting/MeetingPreparation';
import MeetingPreparationAlert from './MeetingPreparationAlert';

const MeetingSection = ({
  memberNote,
  toggleNoteModal,
  setAction,
  setShowActionModal,
  user,
  clearActionBody,
  setCurrentNoteModal,
}) => {
  const [note, setNote] = useState(memberNote);
  const [steps, setSteps] = useState(memberNote.steps);
  const isOwner = user.id === memberNote.lead_id;
  const editPostModal = e => {
    e.preventDefault();
    clearActionBody();
    const shareWith = memberNote.member
      ? {
          image: memberNote.member.image_url,
          label: `${memberNote.member.first_name} ${memberNote.member.last_name}`,
          title: generateTitle(memberNote.member.role, memberNote.member.team.name),
          value: `${memberNote.member.id}_user`,
          id: memberNote.member.id,
          type: 'user',
        }
      : memberNote.team
      ? {
          image: memberNote.team.image_url,
          thumbnail_details: memberNote.team.thumbnail_details,
          label: memberNote.team.name,
          title: '',
          value: `${memberNote.team.id}_team`,
          id: memberNote.team.id,
          type: 'team',
        }
      : {};
    const meetingMember = memberNote.user
      ? { id: memberNote.user.id, type: 'user' }
      : memberNote.team
      ? { id: memberNote.team.id, type: 'team' }
      : null;
    setAction('create-note-meeting', {
      preparationNote: memberNote,
      meetingIsSharing: !!shareWith,
      meetingShareWith: [shareWith],
      meetingMember: meetingMember,
      meetingMemberId: memberNote.user?.id ?? null,
      meetingTeamId: memberNote.team?.id ?? null,
    });
    setShowActionModal(true);
  };

  const editAgendaModal = e => {
    e.preventDefault();
    handleEdit(
      memberNote,
      'edit',
      setAction,
      setShowActionModal,
      toggleNoteModal,
      null,
      false,
      null,
      user,
      setCurrentNoteModal
    );
  };

  const completeStep = async (e, stepId, key) => {
    e.preventDefault();
    const headers = HEADERS();
    fetch(getUrl(`meetings/complete/${stepId}`), {
      method: 'post',
      headers,
    })
      .then(response => {
        if (response.status === 200) {
          const stepsArr = steps;
          stepsArr[key].completed = !stepsArr[key].completed;
          setSteps([...stepsArr]);
        }
      })
      .catch(console.error);
  };
  const participantName =
    note && note.member
      ? `${note.member.first_name} ${note.member.last_name}`
      : note.team
      ? note.team.name
      : '';
  const ownerName = `${note.owner.first_name} ${note.owner.last_name}`;
  const userName = isOwner ? participantName : ownerName;
  const isManagerPrompt = note.type === noteTypes.managerPrompt;
  const isUserParticipant = [note.member?.id, note.owner?.id].includes(user.id);

  return (
    <PreparationWrapper>
      {note.type === noteTypes.meetingPreparation &&
        (note.visible_on_feed ? (
          <MeetingPreparation
            editAgendaModal={editAgendaModal}
            user={user}
            isOwner={isOwner}
            editPostModal={editPostModal}
            note={note}
          />
        ) : (
          <MeetingPreparationAlert note={note} user={user} editAgendaModal={editAgendaModal} />
        ))}
      {(note.type === noteTypes.meeting || note.type === noteTypes.managerPrompt) && (
        <>
          {note.type === noteTypes.managerPrompt && <Header>Action plan: {note.title}</Header>}
          {/* headerTitle class is used by onboarding process */}
          {note.type === noteTypes.meeting && (
            <Header className="headerTitle">
              {isUserParticipant
                ? `${note.title} ${userName}`
                : `${ownerName}'s meeting with ${participantName}`}
            </Header>
          )}
          <DateWrapper note={note} user={user} />
          <Title>{isManagerPrompt ? 'Notes' : 'What was discussed'}</Title>
          <Notes
            dangerouslySetInnerHTML={{
              __html: striptags(note.content, ['a', 'br']),
            }}
          />
          <Title>{isManagerPrompt ? 'Actions you want to take' : 'Next steps'}</Title>
          <Steps>
            {steps.map((step, key) => (
              <StepWrapper
                key={step.id}
                completed={step.completed}
                onClick={e => completeStep(e, step.id, key)}
              >
                <Checkbox completed={step.completed} />
                <ContentWrapper>
                  <Content>{step.content}</Content>
                  {step.assignee && (
                    <StepDetails>
                      {step.assignee.user.first_name} {step.assignee.user.last_name}
                      {step.assignee.due_date && (
                        <span>, {moment(step.assignee.due_date).format('MMMM DD')}</span>
                      )}
                    </StepDetails>
                  )}
                </ContentWrapper>
              </StepWrapper>
            ))}
          </Steps>
        </>
      )}
    </PreparationWrapper>
  );
};

const mapStateToProps = state => ({
  user: !!state.usersDomain.user && state.usersDomain.user,
});

const mapDispatch = dispatch => ({
  setAction: (tab, body) => dispatch(setAction(tab, body)),
  setShowActionModal: open => dispatch(setShowActionModal(open)),
  clearActionBody: () => dispatch(clearActionBody()),
  toggleNoteModal: toggled => dispatch(toggleNoteModal(toggled)),
  setCurrentNoteModal: note => dispatch(setCurrentNoteModal(note)),
});

export default connect(mapStateToProps, mapDispatch)(MeetingSection);
