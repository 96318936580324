/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { findLabel } from 'helpers';
import MemberPreview from './MemberPreview';

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  padding-bottom: 13px;
  padding-top: 25px;
`;

const SelectWrapper = styled.div`
  width: ${props => (props.fullwidth ? '100%' : '47%')};
  ${props => props.css}
`;

const customStylesD = {
  option: (provided, state) => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isSelected ? '#f4faff' : 'white',
    '&:hover': {
      backgroundColor: '#ebf5ff',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

const SelectQuestion = ({
  question,
  id,
  value,
  setValue,
  options,
  searchable,
  disabled = false,
  fullwidth = true,
  placeholder = 'Select ...',
  ...props
}) => {
  return (
    <SelectWrapper fullwidth={fullwidth} className="select-question" {...props}>
      <Title> {question} </Title>
      {id === 'member' && value ? (
        <MemberPreview member={value} members={options} />
      ) : (
        <Select
          name={`select-${id}`}
          id={`select-${id}`}
          onChange={el => setValue(el.value)}
          value={value ? { value, label: findLabel(value, options) }: ''}
          options={options}
          isSearchable={searchable}
          disabled={disabled}
          isClearable={false}
          placeholder={placeholder}
          styles={customStylesD}
          classNamePrefix="r-select"
          css={css`
            .Select-control {
              border: 1px solid ${disabled ? '#d9d9d9' : '#EDEDED'};
            }
          `}
        />
      )}
    </SelectWrapper>
  );
};
export default SelectQuestion;
