/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { setShowActionModal } from 'redux/app/actions';
import planTypes from 'components/payments/PaymentChoose/planTypes';
import { PLAN_ACTIONS } from '../../redux/actions/planActions';

const mapStateToProps = state => ({
  showUpgradePlanModal: state.plansDomain.showUpgradePlanModal,
  normalContent: state.plansDomain.upgradeModalNormalContent,
  adminContent: state.plansDomain.upgradeModalAdminContent,
  isAccountAdmin: state.usersDomain.user?.isAccountAdmin,
});
const mapDispatchToProps = dispatch => ({
  showUpgradeModal: () => dispatch(PLAN_ACTIONS.showUpgradeModal()),
  hideUpgradeModal: () => dispatch(PLAN_ACTIONS.hideUpgradeModal()),
  setShowActionModal: show => dispatch(setShowActionModal(show)),
});

class UpgradePlanModal extends Component {
  redirectToSettings(e) {
    this.props.setShowActionModal(false);
    if (this.props.showUpgradePlanModal) {
      e.preventDefault();
      this.props.hideUpgradeModal();
    }
    this.props.history.push('/payments/choose_plan');
  }

  render() {
    const { isAccountAdmin, adminContent, normalContent } = this.props;
    return (
      <div>
        <Modal
          className="upgrade-plan-modal"
          show={this.props.showUpgradePlanModal}
          onHide={this.props.hideUpgradeModal}
          css={css`
            .modal-content {
              overflow: hidden;
            }
          `}
        >
          <PlanModal.TopBar
            css={css`
              @media (max-width: 479px) {
                display: none;
              }
            `}
          />
          <PlanModal.CloseButton onClick={this.props.hideUpgradeModal} />
          <PlanModal.Content
            css={css`
              text-align: center;
              font-size: 14px;
              margin: 0;
              padding: 48px 35px;
            `}
          >
            <PlanModal.Crown
              css={css`
                width: 48px;
                height: 48px;
                text-align: center;
              `}
            />
            <PlanModal.Title
              css={css`
                justify-content: center;
                font-size: 18px;
                margin-top: 13px;
              `}
            >
              This feature is available only for premium accounts
            </PlanModal.Title>
            <div
              css={css`
                margin-top: 16px;
                margin-bottom: 30px;
              `}
            >
              {isAccountAdmin ? adminContent : normalContent}
            </div>
            {isAccountAdmin && (
              <PlanModal.Button onClick={this.redirectToSettings.bind(this)}>
                View Plans & Pricing
              </PlanModal.Button>
            )}
          </PlanModal.Content>
        </Modal>
      </div>
    );
  }
}

UpgradePlanModal.propTypes = {
  adminContent: PropTypes.string,
  normalContent: PropTypes.string,
};

UpgradePlanModal.defaultProps = {
  adminContent: `Upgrade from $${planTypes[25].prices.pro.usd} USD per month to get access to rich dashboards and insights to evaluate trends over time, run dynamic polls, greater access to performance tools, integrations, support and more`,
  normalContent:
    'Chat with your account owner to upgrade and get access to rich dashboards and insights, record more wins and meetings, create personal spaces with colleagues, access to additional integrations, support and more',
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpgradePlanModal));
