/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import styled from '@emotion/styled';
import Page from 'components/layouts/Page';
import { Link } from 'react-router-dom';
import CreateNewMeetingDropdown from 'components/meetings/createNewMeetingDropdown/createNewMeetingDropdown';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import ExportSection from './ExportSection';
import back from './back.svg';
import TeamMemberSelect from './ProgressBoard/TeamMemberSelect';

const PostsCounter = styled.span`
  display: inline-block;
  vertical-align: bottom;
  padding: 3px 7px 3px 6px;
  background: #ffffff;
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  color: #6a6a6a;
  margin-left: 15px;
  position: relative;
  bottom: 4px;
`;

const BackIcon = styled.img`
  margin-right: 13px;
`;

const DropdownWrapper = styled.div`
  margin-right: 5px;
  button {
    width: 220px !important;
  }
  @media (max-width: ${props => (props.export ? '1315' : '992')}px) {
    margin-left: 15px;
    margin-top: 7px;
  }
  @media (min-width: ${props => (props.export ? '1315' : '992')}px) {
    margin-top: -6px;
  }
  button:focus {
    outline: 0;
  }
`;

class FeedHeader extends Component {
  render() {
    const {
      title,
      postsCount,
      fetchingPostsCount,
      exportModeOn,
      exportSelected,
      changeExportMode,
      selectAllPosts,
      resetPostsSelection,
      posts,
      selectedPosts,
      showCreateMeetingButton,
      hideCounter,
      showBackButton,
      allowExport,
    } = this.props;
    return (
      <Page.Section
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 20px 0',
        }}
      >
        <Page.Wrapper className={`grow-feed-header ${exportModeOn && 'export'}`}>
          <Page.Header
            style={{
              padding: '0 15px',
              marginBottom: '0',
              width: `${showCreateMeetingButton ? (!showBackButton ? '186px' : '235px') : 'auto'}`,
            }}
          >
            {showBackButton && (
              <Link to={`/${growProfileUrl}?${qs.stringify({ tab: 'meetings' })}`}>
                <BackIcon src={back} alt="back" />
              </Link>
            )}
            {title}
            {!hideCounter && !fetchingPostsCount && <PostsCounter>{postsCount}</PostsCounter>}
          </Page.Header>
          {showCreateMeetingButton && (
            <DropdownWrapper export={exportModeOn}>
              <CreateNewMeetingDropdown text="Set up new meeting" id="create-meeting-target" />
            </DropdownWrapper>
          )}
          {postsCount > 0 && allowExport && (
            <ExportSection
              changeExportMode={changeExportMode}
              exportModeOn={exportModeOn}
              selectAllPosts={selectAllPosts}
              resetPostsSelection={resetPostsSelection}
              posts={posts}
              selectedPosts={selectedPosts}
              exportSelected={exportSelected}
            />
          )}
        </Page.Wrapper>
      </Page.Section>
    );
  }
}

FeedHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  postsCount: state.growDomain.postsCount,
  fetchingPostsCount: state.growDomain.fetchingPostsCount,
});

export default connect(mapStateToProps)(FeedHeader);
