/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from './arrow.svg';

const ViewDetailsButton = ({ url, search, state = {} }) => (
  <Link
    to={{
      pathname: url,
      search,
      state,
    }}
    css={css`
      color: #11a9ff;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-left: 30px;
      vertical-align: initial;
      cursor: pointer;
      text-decoration: none !important;
      &:hover {
        color: #11a9ff;
      }
      @media (max-width: 500px) {
        display: block;
        margin: 12px 0;
      }
    `}
  >
    <Arrow
      css={css`
        margin-right: 13px;
        vertical-align: sub;
      `}
    />
    View details
  </Link>
);
export default ViewDetailsButton;
