/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Select from 'react-select';
import { APP_URL } from 'constants.js';
import { HEADERS, LOCALSTORAGE_GET, getUrl } from 'helpers';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { connect } from 'react-redux';
import PostponeButton from './partials/PostponeButton';
import Checkbox from './partials/Checkbox';
import SelectQuestion from './SelectQuestion';

const FormContent = styled.form`
  padding: 0 80px 20px 82px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  padding-bottom: 13px;
  padding-top: 25px;
`;

const purposeOptions = [
  {
    key: 'feeling',
    description: 'Understanding how my team is feeling',
  },
  {
    key: 'goals',
    description: 'Make it easier to keep track of 1:1 conversations and goals',
  },
  {
    key: 'connect',
    description: 'Connect and collaborate with my team',
  },
  {
    key: 'surveys',
    description: 'Run surveys to measure my culture',
  },
];

const CheckboxesQuestion = ({ value, setValue }) => {
  return (
    <>
      <Title>What do you want to use Heelix for?</Title>
      {purposeOptions.map(option => (
        <Checkbox key={option.key} option={option} value={value} setValue={setValue} />
      ))}
    </>
  );
};

const SubmitButton = styled.button`
  padding: 0 42px;
  margin-top: 25px;
  margin-bottom: 10px;
`;

const AccountForm = ({ nextStep, postponeTour, ...props }) => {
  const [industry, setIndustry] = useState('');
  const [industryOptions, setIndustryOptions] = useState([]);
  const [size, setSize] = useState('');
  const [purpose, setPurpose] = useState('');
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');

  useEffect(() => {
    const accessToken = LOCALSTORAGE_GET('access_token');
    const headers = HEADERS(accessToken);
    fetch(getUrl('onboarding/modal_data'), { method: 'get', headers })
      .then(response => {
        return response.json();
      })
      .then(({ industryArray, countries }) => {
        setIndustryOptions(industryArray);
        setCountries(countries);
      })
      .catch(console.error);
  }, []);

  const setCheckboxValue = value => {
    if (purpose === value) {
      setPurpose('');
    } else {
      setPurpose(value);
    }
  };

  const submitForm = e => {
    e.preventDefault();
    const headers = HEADERS();
    const data = new FormData();
    data.append('industry', industry);
    data.append('size', size);
    data.append('country', country);
    data.append('purpose', purpose);

    fetch(`${APP_URL}/onboarding/account_form`, { method: 'post', body: data, headers }).then(
      response => {
        if (response.status === 200 && response.ok === true) {
          nextStep();
        }
      }
    );
  };

  const sizes = [
    {
      value: '0 - 50',
      label: '0 - 50',
    },
    {
      value: '51 - 200',
      label: '51 - 200',
    },
    {
      value: '201 - 500',
      label: '201 - 500',
    },
    {
      value: '501 - 1000',
      label: '501 - 1000',
    },
    {
      value: '1001 - 5000',
      label: '1001 - 5000',
    },
    {
      value: '5001 - 10000',
      label: '5001 - 10000',
    },
    {
      value: '10000 +',
      label: '10000 +',
    },
  ];

  return (
    <>
      <FormContent>
        <SelectQuestion
          question="Which industry are you from?"
          id="industry"
          value={industry}
          setValue={setIndustry}
          options={industryOptions}
          searchable
        />
        <SelectQuestion
          question="How big is your company?"
          id="size"
          value={size}
          setValue={setSize}
          options={sizes}
          searchable={false}
        />
        <SelectQuestion
          question="Where are you based?"
          id="country"
          value={country}
          setValue={setCountry}
          options={countries}
          searchable
        />
        <CheckboxesQuestion value={purpose} setValue={setCheckboxValue} />
        <div
          css={css`
            text-align: center;
          `}
        >
          <SubmitButton
            className="btn btn-primary"
            onClick={e => submitForm(e)}
            disabled={!(industry && size && country && purpose)}
          >
            Submit
          </SubmitButton>
        </div>
      </FormContent>
      <PostponeButton onClick={() => postponeTour()} />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  postponeTour: () => dispatch(ONBOARDING_ACTIONS.postponeTour()),
});

export default connect(null, mapDispatchToProps)(AccountForm);
