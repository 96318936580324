import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import striptags from 'striptags';
import { connect } from 'react-redux';
import { APP_URL } from '../../constants';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { BODY_CLASSES, CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

class FixesList extends Component {
  constructor() {
    super();
    this.state = {
      fixesData: [],
      page: 1,
      perPage: 10,
      totalPages: 1,
      showPagination: false,
    };
  }

  componentDidMount() {
    BODY_CLASSES(['jobvibe-future']);
    window.onpopstate = this.updateData.bind(this);
    this.updateData();
  }

  componentWillUnmount() {
    window.onpopstate = () => {};
  }

  pageChangeHandler(number) {
    let query = this.props.location.search;
    query = qs.parse(query.slice(1, query.length));
    let stringified = '';
    if (query.subjects) {
      stringified = qs.stringify(
        { subjects: query.subjects },
        { encode: false, arrayFormat: 'brackets' }
      );
    }
    this.props.history.push(`/posts/list?page=${number}&${stringified}`);
    this.updateData();
  }

  updateData() {
    if (this.props.location.pathname === '/posts/list') {
      // React's location.search returns values prior to popstate
      const query = window.location.href.split('?')[1];
      const headers = HEADERS();
      const parsedQuery = qs.parse(query);

      // --Items count
      let url;
      if (parsedQuery.subjects) {
        url = `${APP_URL}/posts/related_count?${qs.stringify(
          { subjects: parsedQuery.subjects },
          { encode: false, arrayFormat: 'brackets' }
        )}`;
        this.setState({ subtitle: 'List of related discussions' });
      } else {
        url = `${APP_URL}/posts/contributions_count?`;
        this.setState({ subtitle: 'Your contributions' });
      }

      fetch(url, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            const totalPages = Math.ceil(jsonResponse / this.state.perPage);
            const page = parseInt(parsedQuery.page);
            this.setState({
              totalPages,
              page,
              showPagination: totalPages !== 1,
            });
          });
        })
        .catch(() => {});

      // --Items
      let endpoint;
      if (parsedQuery.subjects) endpoint = 'related';
      else endpoint = 'contributions';
      fetch(`${APP_URL}/posts/${endpoint}?${query}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            this.setState({
              fixesData: jsonResponse,
              page: parsedQuery.page ? parseInt(parsedQuery.page) : 1,
            });
          });
        })
        .catch(() => {});
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12" id="back-btn-wrapper" />
        </div>
        <h1 className="fix-list-header">{this.state.subtitle}</h1>
        {this.state.fixesData.map(data => (
          <div
            className="fix-list-tile"
            id={`fix-tile-${data.id}`}
            key={data.id}
            onClick={() => this.props.history.push(`/post/${data.id}`)}
          >
            <h1 id={`discussion-name-${data.id}`}>{data.name}</h1>
            <div>
              <h2>
                <Link
                  id={`show-user-profile-btn-${data.user.id}`}
                  to={
                    this.props.user.id === data.user.id
                      ? `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                      : `/user/${data.user.id}`
                  }
                >
                  <span>{`${data.user.first_name} ${data.user.last_name}`}</span>
                </Link>
                posted in
                <Link
                  id={`show-team-profile-btn-${data.space.id}`}
                  to={`/feed?space=${data.space.id}`}
                >
                  <span>{` ${data.space.name}`}</span>
                </Link>
              </h2>
              {data.vibe_reasons.length > 0 ? (
                <h2>
                  Tags:
                  {data.vibe_reasons.map(reason => (
                    <span
                      className="tag"
                      key={reason.pivot.reason_id}
                      onClick={e => {
                        e.stopPropagation();
                        this.props.history.push(`/posts/list?page=1&subjects[]=${reason.name}`);
                      }}
                    >
                      {reason.name}
                    </span>
                  ))}
                </h2>
              ) : (
                <h2 />
              )}
            </div>
            <p
              id={`discussion-content-${data.id}`}
              dangerouslySetInnerHTML={{
                __html: striptags(data.content, ['a']),
              }}
            />
          </div>
        ))}
        {this.state.showPagination && (
          <Pagination
            bsSize="medium"
            className={`
                            ${this.state.page == 1 ? 'prev-hidden' : ''}
                            ${this.state.page == this.state.totalPages ? 'next-hidden' : ''}
                        `}
            prev
            next
            first
            last
            maxButtons={5}
            items={this.state.totalPages}
            activePage={this.state.page}
            onSelect={this.pageChangeHandler.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(FixesList));
