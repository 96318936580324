import fetchApi from 'fetchApi';
import {
  convertManagerPromptToWin,
  handleComplete,
} from 'pages/Grow/GrowPost/ManagerPrompts/common';
import { setAction, setShowActionModal } from 'redux/app/actions';
import { APP_URL } from '../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import DOMAINS from '../domains';

export const GROW_ACTION_TYPES = {
  // --Used by lower level (not root) reducer
  FETCH_MANY: 'FETCH_MANY',
  SET_LOADING: 'SET_LOADING',
  RECEIVE_MANY: 'RECEIVE_MANY',
  RESET_MANY: 'RESET_MANY',
  SAVE_SCROLL: 'SAVE_SCROLL',
  RESET_FILTERS: 'RESET_FILTERS',
  FILTERS_FROM_OBJECT: 'FILTERS_FROM_OBJECT',
  FETCH_POSTS_COUNT: 'FETCH_POSTS_COUNT',
  RECEIVE_POSTS_COUNT: 'RECEIVE_POSTS_COUNT',
  RECEIVE_POST: 'RECEIVE_POST',

  RESOLVE_NOTE: 'RESOLVE_NOTE',
  CONFIRM_NOTE: 'CONFIRM_NOTE',
  RECEIVE_RESOLVE_STATUS: 'RECEIVE_RESOLVE_STATUS',

  RETURN_ERRORS: 'RETURN_ERRORS',

  DELETE_POST: 'DELETE_POST',
  REMOVE_DELETED_POST: 'REMOVE_DELETED_POST',
  RELOAD_PROGRESS_BOARD: 'RELOAD_PROGRESS_BOARD',

  SELECT_POST: 'SELECT_POST',
  SELECT_ALL_POSTS: 'SELECT_ALL_POSTS',
  RESET_POSTS_SELECTION: 'RESET_POSTS_SELECTION',
  REMOVE_DELETED_POSTS: 'REMOVE_DELETED_POSTS',
};
export const GROW_ACTIONS = {
  // --Used mostly in React components during dispatch (or in fetch results in reducers)
  fetchPosts: type => ({ domain: DOMAINS.GROW, type: GROW_ACTION_TYPES.FETCH_MANY, payload: type }),
  setLoadingPosts: (toggle = true) => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.SET_LOADING,
    payload: toggle,
  }),
  receivePosts: json => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RECEIVE_MANY,
    payload: json,
  }),
  resetPosts: () => ({ domain: DOMAINS.GROW, type: GROW_ACTION_TYPES.RESET_MANY }),
  resetFilters: () => ({ domain: DOMAINS.GROW, type: GROW_ACTION_TYPES.RESET_FILTERS }),
  filtersFromObject: object => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.FILTERS_FROM_OBJECT,
    payload: object,
  }),
  fetchPostsCount: () => ({ domain: DOMAINS.GROW, type: GROW_ACTION_TYPES.FETCH_POSTS_COUNT }),
  receivePostsCount: json => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RECEIVE_POSTS_COUNT,
    payload: json,
  }),
  receivePost: json => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RECEIVE_POST,
    payload: json,
  }),

  resolveNote: id => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RESOLVE_NOTE,
    payload: { id },
  }),
  confirmNote: id => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.CONFIRM_NOTE,
    payload: { id },
  }),
  receiveResolveStatus: (id, json) => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RECEIVE_RESOLVE_STATUS,
    payload: { id, json },
  }),

  saveScroll: position => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.SAVE_SCROLL,
    payload: position,
  }),

  deletePost: (id, type) => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.DELETE_POST,
    payload: { id, type },
  }),
  removeDeletedPost: (id, type) => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.REMOVE_DELETED_POST,
    payload: { id, type },
  }),
  reloadProgressBoard: () => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RELOAD_PROGRESS_BOARD,
  }),
  dismissManagerPrompt: (noteId, actionPlanId) => async dispatch => {
    await fetchApi(`/action_plan/dismiss/${actionPlanId}`, { method: 'put' });
    dispatch(GROW_ACTIONS.removeDeletedPost(noteId, 'note'));
  },
  reopenActionPlan: actionPlanId => async dispatch => {
    await fetchApi(`/action_plan/reopen/${actionPlanId}`, { method: 'put' });
    dispatch(GROW_ACTIONS.resetPosts());
    dispatch(GROW_ACTIONS.fetchPosts());
  },
  completeActionPlan: note => async dispatch => {
    await handleComplete(note.action_plan.id);
    dispatch(GROW_ACTIONS.resetPosts());
    dispatch(GROW_ACTIONS.fetchPosts());
    convertManagerPromptToWin(
      (...args) => dispatch(setAction(...args)),
      note,
      note.action_plan.main_grow_post.note_id
    );
    dispatch(setShowActionModal(true));
  },
  selectPost: id => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.SELECT_POST,
    payload: { id },
  }),
  selectAllPosts: () => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.SELECT_ALL_POSTS,
  }),
  resetPostsSelection: () => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.RESET_POSTS_SELECTION,
  }),
  removeDeletedPosts: () => ({
    domain: DOMAINS.GROW,
    type: GROW_ACTION_TYPES.REMOVE_DELETED_POSTS,
  }),
  deleteSelectedPosts: postIds => async dispatch => {
    const headers = HEADERS();
    const data = new FormData();
    data.append('_method', 'DELETE');
    postIds.forEach(id => data.append('post_ids[]', id));
    const response = await fetch(`${APP_URL}/member_notes/bulk_delete`, {
      method: 'post',
      body: data,
      headers: headers,
    });
    if (response.ok) {
      try {
        const json = await response.json();
        dispatch(GROW_ACTIONS.removeDeletedPosts());
        dispatch(GROW_ACTIONS.resetPostsSelection());
        return Promise.resolve(json);
      } catch (err) {
        return Promise.reject({
          error: true,
          flashName: 'error',
          message: 'Something went wrong. Please try again later.',
        });
      }
    } else {
      try {
        const json = await response.json();
        return Promise.reject(json);
      } catch (err) {
        return Promise.reject({
          error: true,
          flashName: 'error',
          message: 'Something went wrong. Please try again later.',
        });
      }
    }
  },
};
