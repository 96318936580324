/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import OnboardingButton from './partials/OnboardingButton';
import PostponeButton from './partials/PostponeButton';
import { OnboardingUserData } from './partials/OnboardingUserData';
import { OnboardingAdminData } from './partials/OnboardingAdminData';

const Description = styled.div`
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  padding: 25px 0px 0px 15px;
`;

const Introduction = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
`;

const Final = styled.p`
  font-size: 14px;
  line-height: 22px;
`;

const Image = styled.img`
  height: 300px;
  width: auto;
`;

const Title = styled.div`
  color: #323232;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 10px;
`;

const List = ({ data }) => (
  <ul
    css={css`
      font-size: 14px;
      line-height: 19px;
      padding-inline-start: 20px;
      list-style: none;
      margin-bottom: 16px;
      li {
        font-size: 14px;
        line-height: 19px;
        padding-bottom: 8px;
        padding-top: 3px;
      }
      li::before {
        color: #11a9ff;
        content: '\\2022';
        display: inline-block;
        width: 6px;
        margin-left: -21px;
        margin-right: 14px;
        font-size: 170%;
        vertical-align: middle;
      }
    `}
  >
    {data.points.map((point, key) => (
      <li key={key}>{point}</li>
    ))}
  </ul>
);

const OnboardingSummary = ({ nextStep, postponeTour, setTitle, isAccountAdmin, ...props }) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState(
    isAccountAdmin ? OnboardingAdminData[step] : OnboardingUserData[step]
  );

  const nextSlide = () => {
    if (step < 2) {
      const nextStep = step + 1;
      setStep(nextStep);
      const data = isAccountAdmin ? OnboardingAdminData[nextStep] : OnboardingUserData[nextStep];
      setData(data);
      setTitle(data.title);
    } else {
      postponeTour();
    }
  };

  return (
    <div
      css={css`
        padding-top: 10px;
      `}
    >
      <div
        css={css`
          padding: 0 60px;
        `}
      >
        <Introduction>{data.introduction}</Introduction>
        <Image src={data.image} />
        <Description>
          <Title>In {data.section} you can:</Title>
          <List data={data} />
          <Final>{data.final}</Final>
        </Description>
      </div>
      <div
        css={css`
          display: block;
          text-align: center;
        `}
      >
        <OnboardingButton
          onClick={() => nextSlide()}
          label={step < 2 ? 'Next slide' : 'Finish'}
          submit
          top={5}
          left={step < 2 ? 0 : 12}
          right={step < 2 ? 80 : 12}
          width={140}
          height={42}
        />
        {step < 2 && <PostponeButton onClick={() => postponeTour()} />}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  postponeTour: () => dispatch(ONBOARDING_ACTIONS.postponeTour()),
});
const mapStateToProps = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSummary);
