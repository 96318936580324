/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import CustomDatePicker from 'components/shared/buttons/CustomDatePicker';
import TimePicker from 'components/vibes/createForms/partials/TimePicker';
import SelectQuestion from 'components/onboarding/SelectQuestion';

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  padding-bottom: 13px;
  padding-top: 25px;
  @media (max-width: 577px) {
    padding-top: 5px;
  }
`;

const DataPickerWrapper = styled.div`
  padding-top: 25px;
  width: 39%;
  display: table-cell;

  div.react-datepicker-wrapper {
    width: 100%;
  }

  @media (max-width: 577px) {
    display: table-row;
    width: 55%;
  }
`;

const DateTimeWrapper = styled.div`
  width: 100%;
  display: table;
  margin-bottom: -16px;
  @media (max-width: 577px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const TimePickerWrapper = styled.div`
  width: 30%;
  display: table-cell;
  padding-right: 20px;
  padding-left: 30px;

  .Select-control {
    height: 42px;
    border: 1px solid #ededed;
  }

  .Select-placeholder,
  .Select-value {
    padding-top: 4px;
    margin-left: 5px;
  }

  @media (max-width: 577px) {
    display: table-row;
    width: 55%;
    margin-top: -10px;
  }
`;

const RecurrenceWrapper = styled.div`
  width: 31%;
  padding-left: 10px;
  display: table-cell;
  @media (max-width: 577px) {
    display: table-row;
    width: 55%;
  }

  .Select-input {
    height: 42px;
    border: 1px solid #ededed;
  }

  .Select-value-label {
    margin-top: 4px;
    display: inline-block;
  }
`;

const customStylesD = {
  option: (provided, state) => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isSelected ? '#f4faff' : 'white',
    '&:hover': {
      backgroundColor: '#ebf5ff',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: '#000',
  }),
};

const MeetingDateSelect = ({
  date,
  setDate,
  time,
  setTime,
  value,
  setValue,
  options,
  dateTitle,
  future,
}) => {
  return (
    <DateTimeWrapper>
      <DataPickerWrapper>
        <CustomDatePicker date={date} setDate={setDate} title={dateTitle} future={future} />
      </DataPickerWrapper>
      <TimePickerWrapper>
        <Title>What time?</Title>
        <TimePicker setTime={setTime} time={time} placeholder="Select..." />
      </TimePickerWrapper>
      <RecurrenceWrapper>
        <SelectQuestion
          question="Recurrence"
          id="frequency"
          value={value}
          setValue={setValue}
          options={options}
          searchable={false}
          fullwidth
          styles={customStylesD}
          css={css`
            height: 42px;
            border: none;
          `}
        />
      </RecurrenceWrapper>
    </DateTimeWrapper>
  );
};
export default MeetingDateSelect;
