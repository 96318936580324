/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { ReactComponent as ArrowCounterclockwise } from '../../images/arrow-counterclockwise.svg';
import { ReactComponent as ArrowClockwise } from '../../images/arrow-clockwise.svg';

const Container = styled.div`
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

const arrow = css`
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
  fill: #3f3f3f;
  :hover {
    fill: #4c4c4c;
  }
`;

const CroppieRotate = ({ rotate }) => {
  return (
    <Container>
      <ArrowCounterclockwise css={arrow} onClick={() => rotate(90)} alt="" />
      <ArrowClockwise css={arrow} onClick={() => rotate(-90)} alt="" />
    </Container>
  );
};

CroppieRotate.propTypes = {
  rotate: PropTypes.func.isRequired,
};

export default CroppieRotate;
