/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 12px;
`;

const CustomSelect = styled(Select)`
  font-size: 14px;
`;

const getMonthList = () => {
  const startDate = moment('2020-10-01');
  const endDate = moment().subtract(1, 'months').endOf('month');

  const dates = [];
  endDate.subtract(1, 'month');

  const month = moment(startDate);
  while (month < endDate) {
    month.add(1, 'month');
    dates.push({
      value: month.toDate(),
      label: `${month.format('MMMM')}, ${month.format('YYYY')}`,
    });
  }
  return dates;
};

const CompanyValueDatePicker = ({ dateCompanyValue, history, title }) => {
  return (
    <>
      <Title>{title}</Title>
      <CustomSelect
        name="select-month"
        id="select-month"
        options={getMonthList()}
        value={{
          value: dateCompanyValue,
          label: `${dateCompanyValue.format('MMMM')}, ${dateCompanyValue.format('YYYY')}`,
        }}
        onChange={date => {
          history.push(
            `/champions?month=${date.value.getMonth() + 1}&year=${date.value.getFullYear()}`
          );
        }}
        isClearable={false}
        placeholder="Select month"
      />
    </>
  );
};

CompanyValueDatePicker.propTypes = {
  dateCompanyValue: PropTypes.shape({
    value: PropTypes.instanceOf(Date),
    label: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
export default withRouter(CompanyValueDatePicker);
