/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const types = {
  primary: 'primary',
  secondary: 'secondary',
};

const typeStyles = type => {
  let style;
  switch (type) {
    case types.primary:
      style = css`
        background-color: #11a9ff;
        color: #ffffff;
        font-weight: bold;
      `;
      break;
    case types.secondary:
      style = css`
        background-color: #ffffff;
        color: #11a9ff;
        font-weight: normal;
      `;
      break;
  }
  return css`
    ${style};
    border: 1px solid #11a9ff;
    border-radius: 4px;
    padding: 7px 23px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
  `;
};

export const Button = ({ children, type, ...props }) => {
  return (
    <button css={typeStyles(type)} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
};
