import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import qs from 'qs';
import { loadReCaptcha } from 'react-recaptcha-google';
import loginWithGoogle from 'components/signup/integration/loginWithGoogle';
import WelcomeEmailContainer from './WelcomeEmailContainer';
import SignIn from './existingUser/SignIn';
import EmailConfirmation from './newUser/EmailConfirmation';
import steps from './steps';
import { LOCALSTORAGE_BOOL } from '../../helpers';
import ErrorPage from './ErrorPage';
import ResendInvite from './ResendInvite';
import LoadingOverlay from './LoadingOverlay';
import Flash from '../shared/Flash';
import loginWithMicrosoft from 'components/signup/integration/loginWithMicrosoft';

const Welcome = ({ location, history }) => {
  const [data, setData] = useState({
    email: '',
    user: '',
  });

  const [step, setStep] = useState(steps.email);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('/');
  const [error, setError] = useState('');
  const [flash, setFlash] = useState('');

  useEffect(() => {
    setLoading(true);
    loadReCaptcha();
    if (location?.state?.message) {
      setFlash(location.state.message);
    }
    if (location.state && location.state.location && location.state.location.pathname) {
      setRedirect(location.state.location.pathname + location.state.location.search);
    }
    setLoading(false);
  }, []);

  const handleEmail = response => {
    const { email, user } = response;
    setData({ email, user });

    if (email && user === 'exists') {
      history.push(`/login?step=${steps.existingUser}`);
      return setStep(steps.existingUser);
    }
    if (email && user === 'invited') {
      history.push(`/login?step=${steps.resendInvite1}`);
      return setStep(steps.resendInvite1);
    }
    if (email && user === "doesn't exist") {
      history.push(`/login?step=${steps.newUser1}`);
      return setStep(steps.newUser1);
    }
    if (email && user === 'both') {
      setStep(steps.both);
      history.push(`/login?step=${steps.existingUser}`);
      return;
    }
    if (email && user === 'demo') {
      setStep(steps.demo);
      history.push(`/login?step=${steps.existingUser}`);
    }
  };

  const onFailure = response => {
    setLoading(false);
    setError(response.error);
    history.push(`/login?step=${steps.suspended}`);
    return setStep(steps.suspended);
  };

  const handleGoogle = async (json, data) => {
    if (json.user === 'exists') {
      await loginWithGoogle(data, onFailure);
      return setStep(steps.redirect);
    }
    if (json.user === 'invited') {
      setData({ user: json.user, email: json.email });
      history.push(`?step=${steps.resendInvite1}`);
      return setStep(steps.resendInvite1);
    }
    if (json.user === "doesn't exist") {
      history.push({
        pathname: '/confirm_email',
        search: `?step=${steps.teamName}`,
        state: {
          googleData: data,
        },
      });
    }
    if (json.user === 'not company email') {
      setError(json.message);
      history.push(`/login?step=${steps.suspended}`);
      return setStep(steps.suspended);
    }
  };

  const handleMicrosoft = async (json, data) => {
    if (json.user === 'exists') {
      await loginWithMicrosoft(data, onFailure);
      return setStep(steps.redirect);
    }
    if (json.user === 'invited') {
      setData({ user: json.user, email: json.email });
      history.push(`?step=${steps.resendInvite1}`);
      return setStep(steps.resendInvite1);
    }
    if (json.user === "doesn't exist") {
      history.push({
        pathname: '/confirm_email',
        search: `?step=${steps.teamName}`,
        state: {
          microsoftData: data,
        },
      });
    }
    if (json.user === 'not company email') {
      setError(json.message);
      history.push(`/login?step=${steps.suspended}`);
      return setStep(steps.suspended);
    }
  };

  const handleNewUser = s => {
    if (queryStep === steps.newUser1) {
      history.push(`/login?step=${steps.newUser2}`);
      return setStep(steps.newUser2);
    }
    history.push(`/login?step=${steps.newUser1}`);
    return setStep(steps.newUser1);
  };

  const handleResendInvite = s => {
    if (queryStep === steps.resendInvite1) {
      history.push(`/login?step=${steps.resendInvite2}`);
      return setStep(steps.resendInvite2);
    }
    history.push(`/login?step=${steps.resendInvite1}`);
    return setStep(steps.resendInvite1);
  };

  const queryStep = qs.parse(location.search, { ignoreQueryPrefix: true }).step;

  if (localStorage != null && LOCALSTORAGE_BOOL('is_admin')) {
    return <Redirect to="/admin" />;
  }
  if (localStorage !== null && LOCALSTORAGE_BOOL('is_authenticated')) {
    if (redirect.indexOf('vibe/new') !== -1) {
      return <Redirect to={redirect} />;
    }
    return <Redirect to="/" />;
  }

  const goBack = () => {
    history.push(`/login`);
  };

  const render = () => {
    switch (queryStep) {
      case steps.email:
      case undefined:
        return <WelcomeEmailContainer handleEmail={handleEmail} handleGoogle={handleGoogle} handleMicrosoft={handleMicrosoft} />;
      case steps.existingUser:
      case steps.demo:
      case steps.both:
        return <SignIn redirect={redirect} email={data.email} step={step} />;
      case steps.resendInvite1:
      case steps.resendInvite2:
        return <ResendInvite email={data.email} step={queryStep} selectStep={handleResendInvite} />;
      case steps.newUser1:
      case steps.newUser2:
        return <EmailConfirmation email={data.email} step={queryStep} selectStep={handleNewUser} />;
      case steps.welcomeError:
        return <ErrorPage title="Something went wrong" body="Please try again" goBack={goBack} />;
      case steps.suspended:
        return <ErrorPage title="Something went wrong" body={error} goBack={goBack} />;
      default:
        return null;
    }
  };

  if (loading) return <LoadingOverlay toggled={loading} message="" height="100%" />;

  return [
    flash ? (
      <Flash
        closeFlash={() => {
          setFlash('');
          history.replace(location.pathname, null);
        }}
        flashMessage={flash}
        flashType="success"
        dismissable
      />
    ) : null,
    render(),
  ];
};

export default withRouter(Welcome);
