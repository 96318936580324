/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import SpaceLogo from '../../spaces/SpaceLogo';
import SpaceLead from './SpaceLead';

const Name = styled.span`
  font-size: 15px;
  color: #323232;
  font-weight: bold;
`;

const AssignLead = styled.span`
  font-size: 13px;
  color: #323232;
  cursor: hand;
`;

const ActionButton = styled.span`
  font-size: 13px;
  color: #11a9ff;
  cursor: hand;
`;

const NoDescription = styled.span`
  font-size: 13px;
  color: #d8d8d8;
`;

const Picture = styled.img`
  width: 30px;
  margin-left: 10px;
  margin-right: 12px;
  border-radius: 50%;
`;

const LeadWrap = styled.div`
  text-align: left;
`;

const actionButton = (space, requestAccess, joinSpace, isAccountAdmin) => {
  if (space.is_member || space.is_team_lead || space.is_visitor) {
    return `Member`;
  }
  if (space.private && space.space_leads.length > 0) {
    if (space.not_confirmed) {
      return 'Request sent';
    }
    return <ActionButton onClick={e => requestAccess(e, space)}>Request access</ActionButton>;
  }
  if (!space.private) {
    return <ActionButton onClick={e => joinSpace(e, space)}>Join</ActionButton>;
  }
};

const descriptionField = space => {
  return space.description ? (
    space.description
  ) : (
    <NoDescription>No descrtiption provided</NoDescription>
  );
};

const ownerField = (space, setAssignLeadModalToggled, isMember) => {
  return space.space_leads.length > 0 ? (
    <SpaceLead leads={space.space_leads} />
  ) : (
    <AssignLead>
      None.&nbsp;
      {!isMember && (
        <ActionButton onClick={e => setAssignLeadModalToggled(e, space)}>Assign lead</ActionButton>
      )}
    </AssignLead>
  );
};

const rows = (setAssignLeadModalToggled, requestAccess, joinSpace, isMember, isAccountAdmin) => {
  return {
    spaces: space => ({
      picture: <SpaceLogo space={space} className="space-logo-teams-new" large />,
      fields: [
        <Name>{space.name}</Name>,
        descriptionField(space),
        ownerField(space, setAssignLeadModalToggled, isMember),
        actionButton(space, requestAccess, joinSpace, isAccountAdmin),
      ],
    }),
  };
};

const column = {
  name: {
    label: 'Name',
    key: 'name',
    width: '230px',
    css: css`
      margin-left: 10px;
    `,
  },
  description: {
    label: 'Description',
    key: 'description',
    width: '270px',
    css: css`
      text-align: left;
      margin-left: 20px;
    `,
  },
  owner: {
    label: 'Owner',
    key: 'owner',
    width: '170px',
    css: css`
      text-align: center;
    `,
  },
  actions: {
    label: 'Actions',
    key: 'actions',
    width: '110px',
    css: css`
      text-align: center;
    `,
  },
};
const { name, description, owner, actions } = column;
const columns = {
  spaces: [name, description, owner, actions],
};

const fields = { rows, columns };

export default fields;
