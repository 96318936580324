import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AWS_S3_URL } from '../../constants';
import { BODY_CLASSES } from '../../helpers';

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  user: state.usersDomain.user,
});

class Error101 extends Component {
  componentDidMount() {
    BODY_CLASSES(['Error']);
  }

  render() {
    return (
      <section className="ErrorPage ErrorPage-101">
        <header>
          <a href="/">
            <img src={`${AWS_S3_URL}public/images/jv-logo.png`} alt="Heelix Logo" />
          </a>
        </header>
        <span className="ErrorPage-type">
          <img src={`${AWS_S3_URL}public/images/404.png`} alt="404 Error - not found" />
        </span>
        <div className="ErrorPage-message">
          <p>You've reached one of our premium features.</p>
          <p>To learn more about updating to one of our paid plans please click here.</p>
        </div>
        <a
          href="/#"
          onClick={() => {
            window.history.back();
          }}
          className="ErrorPage-back"
        >
          Go back
        </a>
        {this.props.user.isAccountAdmin && (
          <span>
            <br />
            or
            <br />
            <Link to="/settings" style={{ marginTop: '0px' }} className="ErrorPage-back">
              Upgrade account
            </Link>
          </span>
        )}
      </section>
    );
  }
}
export default connect(mapStateToProps)(Error101);
