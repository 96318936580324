import React from 'react';
import qs from 'qs';
import SlidingPane from 'react-sliding-pane';
import { Link } from 'react-router-dom';
import { CLEAR_COOKIE_TOKEN, LOCALSTORAGE_BOOL, LOCALSTORAGE_GET } from 'helpers';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import SlackButton from './SlackButton';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';

const Settings = props => (
  <SlidingPane
    isOpen={props.open}
    title="Settings"
    subtitle={<div className="close-notification" onClick={() => props.onClose()} />}
    from="left"
    width="230px"
    className="side-settings-pane settings-list"
    onRequestClose={() => props.onClose()}
  >
    <ul className="profile-nav">
      {props.showProfile && (
        <li id="menu-view-profile-btn">
          <Link to={`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`}>
            View your profile
          </Link>
        </li>
      )}
      <li id="menu-edit-profile-btn">
        <Link to="/edit_profile">Edit your profile</Link>
      </li>
      <li id="menu-notification-settings-btn">
        <Link to="/notification_settings">Notification settings</Link>
      </li>
      {LOCALSTORAGE_GET('is_company_owner') && (
        <li id="menu-account-settings-btn">
          <Link to="/settings">Account settings</Link>
        </li>
      )}
      {LOCALSTORAGE_GET('is_company_owner') && (
        <li id="menu-company-settings-btn">
          <Link to={'/company-settings'}>Company settings</Link>
        </li>
      )}
      <li id="menu-sign-out-btn">
        <button
          onClick={e => {
            e.preventDefault();
            CLEAR_COOKIE_TOKEN();
            localStorage.clear();
            localStorage.setItem('is_authenticated', false);
            if(sessionStorage.getItem('microsoft') === 'true'){
              sessionStorage.clear();
              sessionStorage.setItem('microsoft_logout', true);
            }
            window.location.reload();
          }}
        >
          Sign Out
        </button>
      </li>
      <li role="separator" className="divider" />
      {LOCALSTORAGE_GET('is_authenticated') === 'true' && LOCALSTORAGE_BOOL('is_admin') && (
        <li id="menu-admin-panel-btn">
          <Link to="admin">
            <i className="fa fa-gear" />
            {' Admin'}
          </Link>
        </li>
      )}
    </ul>
    <div className="slack-button">
      <SlackButton />
    </div>
  </SlidingPane>
);

export default Settings;