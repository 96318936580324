/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { ReactComponent as Calendar } from 'components/shared/buttons/Calendar.svg';
import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';

const Date = styled.div`
  margin-left: 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  vertical-align: text-top;
`;

const DateWrapper = ({ note, user }) => (
  <div
    css={css`
      color: ${note.type === 'MeetingPreparation' ? '#11A9FF' : '#6A6A6A'};
      margin-bottom: 16px;
    `}
  >
    <Calendar
      css={css`
        ${note.type !== 'MeetingPreparation' && 'path {fill: #D5D5D5;}'}
      `}
    />
    <Date>{moment.utc(note.due_date).tz(user.timeZone).format('Do MMMM [at] h:mmA')}</Date>
  </div>
);

DateWrapper.propTypes = {
  note: PropTypes.shape({
    type: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    timeZone: PropTypes.string.isRequired,
  }).isRequired,
};

export default DateWrapper;
