/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import moment from 'moment';
import { ReactComponent as Calendar } from './Calendar.svg';

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 12px;
`;

const DateWrapper = styled.div`
  input.datepicker {
    border-radius: 5px;
    border: 1px solid #ededed;
    ::placeholder {
      color: #323232;
    }
  }

  div.react-datepicker__input-container {
    width: 100%;
  }
`;

const CustomDatePicker = ({
  date,
  setDate,
  title,
  future = false,
  placeholder = 'Select date',
}) => {
  useEffect(() => {
    registerLocale('en-GB', enGB);
  }, []);
  let calendar;
  return (
    <DateWrapper className="custom-calendar">
      {title && <Title>{title}</Title>}
      <DatePicker
        dropdownMode="select"
        className="form-control datepicker"
        dateFormat="dd-MM-yyyy"
        id="date"
        selected={date ? moment(date).toDate() : ''}
        name="date"
        placeholderText={placeholder}
        onChange={date => setDate(date)}
        filterDate={date => {
          if (!future) {
            return moment() > date;
          }
          return moment().subtract(1, 'day') < date;
        }}
        css={css`
          position: relative;
          cursor: pointer;
        `}
        ref={c => (calendar = c)}
        onFocus={e => e.target.blur()}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        locale={'en-GB'}
      />
      <Calendar
        onClick={() => calendar.setOpen(true)}
        css={css`
          position: relative;
          top: -30px;
          float: right;
          right: 14px;
        `}
      />
    </DateWrapper>
  );
};

export default CustomDatePicker;
