/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import ParticipantSelect from 'components/vibes/createForms/CreateMeeting/shared/ParticipantSelect';
import MeetingDateSelect from 'components/vibes/createForms/CreateMeeting/shared/MeetingDateSelect';
import SelectQuestion from 'components/onboarding/SelectQuestion';
import { css, jsx } from '@emotion/react';
import Can from 'rbac/Can';
import { integrations } from 'rbac/plans';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import {
  PromptWrapper,
  SwitchButtonText,
  SwitchButtonWrap,
} from 'components/vibes/createForms/CreateMeeting/shared/layout';
import PropTypes from 'prop-types';
import { MEETING_FREQUENCIES } from 'components/vibes/createForms/CreateMeeting/shared/shared';
import moment from 'moment';

const prompts = [
  {
    value: 'day',
    label: 'The day before',
  },
  {
    value: 'days',
    label: 'A few days before',
  },
  {
    value: 'week',
    label: 'A week before',
  },
];

const RepeatingMeeting = ({
  loadingOverlay,
  member,
  updateMember,
  members,
  nextMeeting,
  frequency,
  nextMeetingTime,
  setFrequencyField,
  setNextMeeting,
  setNextMeetingTime,
  ownerPrompt,
  updateOwnerReminder,
  memberPrompt,
  setMemberPrompt,
  syncWithGoogleCalendar,
  setSyncWithGoogleCalendar,
  showPrompt,
  shareAgendas,
  setShareAgendas,
}) => {
  useEffect(() => {
    if (moment(nextMeeting).isBefore(moment(), 'day')) {
      setNextMeeting(null);
    }
  }, [frequency]);
  return (
    <>
      <LoadingOverlay toggled={Boolean(loadingOverlay)} height="100%" message={loadingOverlay} />
      <ParticipantSelect
        memberValue={member?.value}
        updateMember={updateMember}
        members={members}
        question="Select a user or team you want to meet with"
      />
      <MeetingDateSelect
        id="next_meeting"
        date={nextMeeting}
        setDate={setNextMeeting}
        time={nextMeetingTime}
        setTime={setNextMeetingTime}
        value={frequency}
        setValue={setFrequencyField}
        options={MEETING_FREQUENCIES}
        dateTitle="When is your next meeting?"
        future
      />
      <PromptWrapper>
        <SelectQuestion
          question="Prompt me to prepare"
          id="owner_prompt"
          disabled={frequency === 'daily'}
          value={ownerPrompt}
          setValue={updateOwnerReminder}
          options={prompts}
          searchable={false}
          fullwidth={false}
          css={css`
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            margin-top: 55px;
            @media (min-width: 660px) {
              margin-top: 0;
            }

            .Select-control {
              height: 42px;

              .Select-placeholder,
              .Select-value-label {
                line-height: 42px;
              }
            }
          `}
        />
        <SelectQuestion
          question="Prompt attendees to prepare"
          id="member_prompt"
          disabled={frequency === 'daily'}
          value={memberPrompt}
          setValue={setMemberPrompt}
          options={prompts}
          searchable={false}
          fullwidth={false}
          css={css`
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            margin-top: 55px;
            @media (min-width: 660px) {
              margin-top: 0;
            }

            .Select-control {
              height: 42px;

              .Select-placeholder,
              .Select-value-label {
                line-height: 42px;
              }
            }
          `}
        />
      </PromptWrapper>

      <Can perform={integrations.addGoogleCalendar}>
        {canAdd => (
          <SwitchButtonWrap>
            <SwitchButton
              turnedOn={syncWithGoogleCalendar}
              onClick={() => {
                if (canAdd) {
                  setSyncWithGoogleCalendar(toggle => !toggle);
                } else {
                  showPrompt({
                    type: 'FeaturePrompt',
                    color: 'yellow',
                    data: {
                      title:
                        "You'll need to upgrade if you'd like to use our Google Calendar integration",
                    },
                  });
                }
              }}
            />
            <SwitchButtonText>Sync this meeting to your Google Calendar</SwitchButtonText>
          </SwitchButtonWrap>
        )}
      </Can>
      <SwitchButtonWrap
        css={css`
          margin-top: 18px;
        `}
      >
        <SwitchButton
          turnedOn={shareAgendas}
          onClick={() => {
            setShareAgendas(share => !share);
          }}
        />
        <SwitchButtonText>Allow guests to add their agendas</SwitchButtonText>
      </SwitchButtonWrap>
    </>
  );
};

RepeatingMeeting.propTypes = {
  loadingOverlay: PropTypes.string.isRequired,
  member: PropTypes.shape({}).isRequired,
  updateMember: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  nextMeeting: PropTypes.string.isRequired,
  setNextMeeting: PropTypes.func.isRequired,
  nextMeetingTime: PropTypes.string.isRequired,
  setNextMeetingTime: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  setFrequencyField: PropTypes.func.isRequired,
  ownerPrompt: PropTypes.string.isRequired,
  updateOwnerReminder: PropTypes.func.isRequired,
  memberPrompt: PropTypes.string.isRequired,
  setMemberPrompt: PropTypes.func.isRequired,
  syncWithGoogleCalendar: PropTypes.bool.isRequired,
  setSyncWithGoogleCalendar: PropTypes.func.isRequired,
  showPrompt: PropTypes.func.isRequired,
};

export default RepeatingMeeting;
