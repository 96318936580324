/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST_ACTIONS } from 'redux/actions/postActions';
import pin from 'images/feed/pin.svg';
import styled from '@emotion/styled';
import Can from 'rbac/Can';
import { general } from 'rbac/plans';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { htmlspecialchars_decode } from 'helpers';
import PostDropdown from './PostDropdown';
import CommentInput from '../post/shared/CommentInput';
import PostTileComments from './PostTileComments';
import FavPostTooltip from '../grow/tooltips/FavPostTooltip';
import questionnaire from './questionnaire.svg';
import QuestionnaireDetails from './QuestionnaireTile/QuestionnaireDetails';
import QuestionnaireModal from './QuestionnaireTile/QuestionnaireModal';

const Name = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #11a9ff;
  word-break: break-word;
  padding-left: 40px;
  padding-right: ${props => (props.pinned ? '45' : '40')}px;
`;

const Image = styled.img`
  margin-right: 14px;
  position: absolute;
  top: 0;
`;

const ResponsesWrapper = styled.div`
  background: #11a9ff;
  padding: 19px 24px 14px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const TotalResponses = styled.div`
  color: #ffffff;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
`;

const ViewMore = styled(Link)`
  text-decoration: underline !important;
  font-weight: normal;
  padding-left: 17px;
  cursor: pointer;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
`;

const PostTileWrapper = styled.div`
  div.post-dropdown {
    margin-left: auto !important;
    position: absolute !important;
    right: 0;
    top: 0;
  }
`;

const PinnedPost = styled.div`
  right: 20px;
  position: absolute;
  top: 0;
`;

class QuestionnaireTile extends Component {
  state = {
    errorAction: null,
    unfollowVisible: false,
    lightboxIsOpen: false,
    currentImage: 0,

    viewAllComments: false,
    vote: false,
    showModal: false,
    showResults: false,
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  handlePostClick = (answered, post, canView) => {
    if (!answered) {
      this.showModal();
    } else if (this.props.user.id === post.author_id) {
      if (canView) {
        this.props.history.push(`/questionnaires/${post.id}`);
      } else {
        this.props.showUpgradeModal();
      }
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleViewMore = (e, canView) => {
    if (!canView) {
      e.preventDefault();
      this.props.showUpgradeModal();
    }
  };

  render() {
    const { post, user } = this.props;
    const { showResults } = this.state;
    const answered = post.questionnaire_already_answered || showResults;
    return (
      <Can perform={general.accessQuestionnaires}>
        {canView => (
          <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
            {answered && (
              <ResponsesWrapper>
                <TotalResponses>
                  {post.questionnaire_answered_count}{' '}
                  {post.questionnaire_answered_count === 1 ? 'response' : 'responses'} in total
                  {user.id === post.author_id && (
                    <ViewMore
                      onClick={e => this.handleViewMore(e, canView)}
                      to={`/questionnaires/${post.id}`}
                    >
                      View all results
                    </ViewMore>
                  )}
                </TotalResponses>
              </ResponsesWrapper>
            )}
            <div className="post-tile poll-tile">
              <PostTileWrapper className="post-tile-header">
                <Image src={questionnaire} />
                <Name pinned={post.pinned}>{htmlspecialchars_decode(post.name)}</Name>
                {post.pinned ? (
                  <PinnedPost
                    className="pinned-post"
                    onClick={
                      user.isAccountAdmin && post.id > 0 ? () => this.props.pinPost(post.id) : null
                    }
                  >
                    <img src={pin} />
                  </PinnedPost>
                ) : null}
                <PostDropdown
                  type={this.props.type}
                  post={post}
                  markAsDone={() => this.setState({ markingAsDone: true })}
                  pinPost={this.props.pinPost}
                  disableEdit={post.questionnaire_answered_count}
                />
              </PostTileWrapper>
              <div
                className="post-tile-body"
                css={css`
                  cursor: pointer;
                `}
                onClick={() => this.handlePostClick(answered, post, canView)}
              >
                <QuestionnaireDetails
                  post={post}
                  showModal={() => this.showModal()}
                  tileRealignHandler={this.props.tileRealignHandler}
                  showResults={showResults}
                  setShowResults={() => this.setState({ showResults: true })}
                />
                <CommentInput
                  post={post}
                  tileRealignHandler={this.props.tileRealignHandler}
                  type={this.props.type}
                />
              </div>
              <div className="post-tile-comments-section">
                <div className="post-tile-comments">
                  <PostTileComments post={post} user={user} type="post" />
                </div>
              </div>
              <FavPostTooltip postId={post.id} isFavourite={post.favourite} />
            </div>
            <QuestionnaireModal
              show={this.state.showModal}
              close={() => this.closeModal()}
              post={post}
            />
          </div>
        )}
      </Can>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  pinPost: postId => dispatch(POST_ACTIONS.pinPost(postId)),
  showUpgradeModal: () => dispatch(PLAN_ACTIONS.showUpgradeModal()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionnaireTile));
