/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { TooltipIcon } from 'pages/Insights/Dashboard/TooltipIcon';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  INSIGHTS_COMPARISON_COLOR_1,
  INSIGHTS_COMPARISON_COLOR_2,
} from 'pages/Insights/OrganisationInsights/ScoreComparison/layout';
import { ReactComponent as Polygon } from './polygon.svg';

export const PerformanceContainer = styled.div`
  margin: 0 -52px;
  :after {
    content: '';
    display: block;
    clear: both;
  }
  @media (max-width: 1199px) {
    margin: 0;
  }
`;

const labelBase = css`
  font-size: 10px;
  color: #706e84;
  position: absolute;
  top: 24px;
`;

const activeBar = css`
  position: relative;
  height: 15px;
  background: linear-gradient(90deg, #fe4f41 0%, #ebd69e 29.46%, #ecd69f 47.27%, #52ba05 73.66%);
  border-radius: 12px;
  margin-bottom: 40px;
  opacity: 0.9;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const inactiveBar = css`
  position: relative;
  height: 15px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(210, 210, 210, 1) 100%);
  border-radius: 12px;
  margin-bottom: 40px;
  opacity: 0.9;
  cursor: pointer;
`;

const activeLabel = css`
  font-size: 16px;
  color: #323232;
`;

const inactiveLabel = css`
  font-size: 16px;
  color: #d2d2d2;
`;

const StyledPolygon = styled(Polygon)`
  display: block;
  position: absolute;
  left: -7px;
  top: -2px;
  path {
    fill: ${({ fill }) => fill};
  }
`;

export const PerformanceMetric = ({
  label,
  value,
  value2,
  previousValue,
  tooltip,
  onClick,
  comparisonMode,
}) => {
  let firstValue = value;
  let secondValue = value2;
  let isOrderSwitched = false;
  if (comparisonMode) {
    if (value !== null && value2 !== null) {
      isOrderSwitched = value > value2;
      firstValue = Math.min(value, value2);
      secondValue = Math.max(value, value2);
    }
    if (value2 !== null && value === null) {
      isOrderSwitched = true;
      firstValue = value2;
      secondValue = value;
    }
  }
  const firstWidth = 100 - (firstValue + 100) / 2;
  const secondWidth = secondValue ? 100 - (secondValue + 100) / 2 : 0;
  return (
    <div
      css={css`
        width: 50%;
        padding: 0 52px;
        float: left;
        @media (max-width: 1199px) {
          padding: 0;
          width: 100%;
        }
      `}
    >
      <h4
        css={
          Number.isInteger(firstValue) || Number.isInteger(previousValue)
            ? activeLabel
            : inactiveLabel
        }
      >
        {label}
        {tooltip && <TooltipIcon data-tooltip-content={tooltip} />}
      </h4>
      <div
        css={
          Number.isInteger(firstValue) || Number.isInteger(previousValue) ? activeBar : inactiveBar
        }
        onClick={onClick}
        data-tooltip-content={
          !Number.isInteger(firstValue) && !Number.isInteger(previousValue)
            ? 'No one chose this factor during the time period'
            : null
        }
        data-tooltip-id={
          !Number.isInteger(firstValue) && !Number.isInteger(previousValue)
            ? 'no-vibes-performance-tooltip'
            : null
        }
      >
        {Number.isInteger(previousValue) ? (
          <div
            css={css`
              position: absolute;
              height: 15px;
              width: ${100 - (previousValue + 100) / 2}%;
              border-left: solid 2px rgba(255, 255, 255, 0.8);
              right: 0;
            `}
          />
        ) : null}
        {Number.isInteger(firstValue) ? (
          <div
            css={css`
              position: absolute;
              height: 15px;
              width: ${firstWidth - secondWidth}%;
              right: ${secondWidth}%;
              background: rgba(255, 255, 255, 0.7);
            `}
          >
            {comparisonMode ? (
              <StyledPolygon
                data-tooltip-content={Math.round(firstValue)}
                data-tooltip-id="performance-tooltip"
                fill={isOrderSwitched ? INSIGHTS_COMPARISON_COLOR_2 : INSIGHTS_COMPARISON_COLOR_1}
              />
            ) : (
              <span
                css={css`
                  content: '';
                  display: block;
                  position: absolute;
                  width: 0;
                  height: 0;
                  left: -4px;
                  border: 20px solid #444444;
                  border-left: 4px solid transparent;
                  border-right: 4px solid transparent;
                  border-top-width: 0;
                `}
                data-tooltip-content={Math.round(firstValue)}
                data-tooltip-id="performance-tooltip"
              />
            )}
          </div>
        ) : null}
        {comparisonMode && Number.isInteger(secondValue) ? (
          <div
            css={css`
              position: absolute;
              height: 15px;
              width: ${secondWidth}%;
              right: 0;
            `}
          >
            <StyledPolygon
              data-tooltip-content={Math.round(secondValue)}
              data-tooltip-id="performance-tooltip"
              fill={isOrderSwitched ? INSIGHTS_COMPARISON_COLOR_1 : INSIGHTS_COMPARISON_COLOR_2}
            />
          </div>
        ) : null}
        <span
          css={css`
            ${labelBase}
            left: 0;
          `}
        >
          -100
        </span>
        <span
          css={css`
            ${labelBase}
            right: 0;
          `}
        >
          100
        </span>
      </div>
    </div>
  );
};
PerformanceMetric.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  value2: PropTypes.number,
  previousValue: PropTypes.number,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  comparisonMode: PropTypes.bool,
};
PerformanceMetric.defaultProps = {
  value: null,
  value2: null,
  previousValue: null,
  tooltip: null,
  onClick: () => {},
  comparisonMode: false,
};
