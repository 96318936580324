import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import AssignPopup from 'components/vibes/createForms/partials/AssignPopup';
import AddNextStepButton from './AddNextStepButton';
import { ReactComponent as Assign } from './assign.svg';

const StepsWrapper = styled.div`
  margin-top: 11px;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 14px;
  border: 1px solid ${props => (props.hasError ? '#CD3C40' : '#EDEDED')};
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  :not(:first-of-type) {
    margin-top: 15px;
  }
  :focus {
    outline: none;
    border: 1px solid ${props => (props.hasError ? '#CD3C40' : 'rgb(77, 144, 254, .5)')};
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:first-of-type) {
    margin-top: 15px;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 10px;
`;

const MeetingSteps = ({
  stepsCount,
  steps,
  id,
  fromNote,
  toggledMode,
  setMeetingState,
  recentNotes,
  label = 'step',
  stepsLimit = 10,
  disableAssignUser = false,
}) => {
  const setLabel = key => {
    if (label === 'point') {
      switch (key) {
        case 1:
          return 'For example, your highlights and learnings from last week';
        case 2:
          return 'For example, the focus for the week ahead';
      }
    }
    return `Next ${label} ${key}`;
  };

  useEffect(() => {
    if (steps.length < 1 && !recentNotes[id]) {
      setMeetingState('steps', setUpSteps, false);
    }
  }, [toggledMode, steps]);

  const setUpSteps =
    steps && steps.length
      ? steps
      : [
          {
            label: setLabel(1),
            value: '',
            id: 1,
            dbId: null,
          },
          {
            label: setLabel(2),
            value: '',
            id: 2,
            dbId: null,
          },
        ];

  const addStep = () => {
    if (stepsCount < stepsLimit) {
      const number = steps.length + 1;
      const name = `Next ${label} ${number}`;
      setMeetingState('stepsCount', number);
      setMeetingState('steps', [
        ...steps,
        {
          label: name,
          value: '',
          id: number,
          dbId: null,
        },
      ]);
    }
    setTimeout(() => updateScroll(), 100);
  };

  const inputs = [];

  const updateScroll = () => {
    const el = document.getElementById('steps-wrapper');
    el.scrollTop = el.scrollHeight;
  };

  const updateSteps = (e, key, steps) => {
    const stepsArray = steps.map(s => ({ ...s }));
    const { value } = e.target;
    const errorMess = getErrorMessage(value);
    stepsArray[key].value = value;
    stepsArray[key].error = errorMess;
    setMeetingState('steps', stepsArray);
    if (errorMess && key === stepsArray.length - 1) {
      setTimeout(() => updateScroll(), 100);
    }
  };
  const getErrorMessage = value => {
    if (value.length > 100) {
      return 'Maximum character limit of 100 reached';
    }
    return '';
  };
  const [shouldClosePopup, setClosePopup] = useState(false);
  const submitAssign = (step, recipient, date) => {
    if (Object.keys(recipient).length) {
      const updateStepIndex = steps.findIndex(el => el.id === step.id);
      const updatedSteps = [
        ...steps.slice(0, updateStepIndex),
        {
          ...steps[updateStepIndex],
          assign: {
            user: recipient,
            date: date,
          },
        },
        ...steps.slice(updateStepIndex + 1),
      ];

      setMeetingState('steps', updatedSteps);
      setClosePopup(true);
    }
  };

  const clearAssign = stepId => {
    const stepIndexToUpdate = steps.findIndex(x => x.id === stepId);

    const updatedSteps = [
      ...steps.slice(0, stepIndexToUpdate),
      {
        ...steps[stepIndexToUpdate],
        assign: null,
      },
      ...steps.slice(stepIndexToUpdate + 1),
    ];
    setMeetingState('steps', updatedSteps);
  };

  steps.forEach((step, key) => {
    const hasError = steps?.length > 0 && steps[key]?.error;
    inputs.push(
      <React.Fragment key={step.id}>
        <InputWrapper>
          <Input
            value={step.value}
            placeholder={step.label}
            onChange={e => updateSteps(e, key, steps)}
            hasError={hasError}
          />
          {!disableAssignUser && (
            <AssignPopup
              step={step}
              shouldClosePopup={shouldClosePopup}
              setClosePopup={setClosePopup}
              submitAssign={submitAssign}
              clearAssign={clearAssign}
            />
          )}
        </InputWrapper>
        {hasError && (
          <ErrorMessage className="error-message text-danger">{steps[key].error}</ErrorMessage>
        )}
      </React.Fragment>
    );
  });
  return (
    <>
      <StepsWrapper id="steps-wrapper">{inputs}</StepsWrapper>
      {stepsCount < stepsLimit && (
        <AddNextStepButton handleClick={() => addStep()} label={label} fromNote={fromNote} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  recentNotes: state.app.recentNotes,
});

export default connect(mapStateToProps)(MeetingSteps);
