import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import TextInput from 'react-autocomplete-input';
import loadImage from 'blueimp-load-image';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import { toggleUserModal } from 'redux/app/actions';
import { getTypeString } from 'components/feed/DiscussionTile';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import DropzoneWrap from 'components/shared/posts/DropzoneWrap';
import PinnedPost from 'components/post/shared/PinnedPost';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { AWS_S3_URL } from '../../../constants';
import {
  AUTOCOMPLETE_NATIVE_VALUE_RESET,
  AVATAR_ERROR,
  FORMAT_CONTENT_WITH_MARKUPS,
  FORMAT_FILTERS,
  GET_MENTIONS_FROM_CONTENT,
  ROTATE_IE_IMAGE,
  VALIDATE_FILE,
} from '../../../helpers';
import PostOwners from '../shared/PostOwners';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import PostComments from './PostComments';
import PostFile from '../../feed/PostFile';
import favActiveBtn from '../../../images/feed/star_active.png';
import favBtn from '../../../images/feed/star.png';
import FavPostTooltip from '../../grow/tooltips/FavPostTooltip';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import CommentInput from '../shared/CommentInput';
import EditMediaWrap from '../shared/EditMediaWrap';
import MarkedDone from '../shared/MarkedDone';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  discussionTags: state.tagsDomain.discussionTags,
  hashtags: state.tagsDomain.hashtags,

  post: state.postsDomain.singlePost,
  filters: state.postsDomain.filters,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
});

const mapDispatchToProps = dispatch => ({
  updatePost: (postId, data) => dispatch(POST_ACTIONS.updatePost(postId, data)),
  favPost: postId => dispatch(POST_ACTIONS.favPost(postId)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class Discussion extends Component {
  constructor(props) {
    super(props);
    this.allFilesSize = 0;
  }

  state = {
    errorAction: null,
    lightboxIsOpen: false,
    currentImage: 0,

    // ---For editing purposes
    commentEditing: false,
    commentEditingId: null,
    name: '',
    content: '',
    subjects: [],
    media: [],
    mediaUrl: [],
    commentMediaUrl: [],
    team: '',
    message: null,
    errors: null,
    processing: false,
    commentCharsLeft: 250,
    errorContent: null,
    possibleFixError: null,
    postErrors: [],
    loader: false,
  };

  componentDidMount() {
    const subjects = this.props.post.vibe_reasons.map(a => a.name);
    if (this.props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
        possible_fix: this.props.post.possible_fix,
        subjects,
        changeEditHandler: this.props.changeEditingStatechangeEditingState,
      });
    }
    const renderedHashtags = document.getElementsByClassName('inline-hashtag');
    Array.from(renderedHashtags).forEach(hashtag => {
      hashtag.addEventListener('click', this.hashtagClickedHandler, false);
    });
    const renderedMentions = document.getElementsByClassName('inline-mention');
    Array.from(renderedMentions).forEach(mention => {
      mention.addEventListener('click', this.mentionClickedHandler, false);
    });
  }

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    this.props.history.push(
      `/feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };

  //--------------------
  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  componentWillReceiveProps(props) {
    const subjects = this.props.post.vibe_reasons.map(a => a.name);
    if (props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
        possible_fix: this.props.post.possible_fix,
        subjects,
      });
    }
    if (this.state.loader === true && this.props.post !== props.post) {
      this.props.changeEditingState();
      this.setState({
        loader: false,
      });
    }
  }

  fileUploadHandler = files => {
    let mediaUrl = [];

    for (let i = 0; i < files.length; i++) {
      if (!VALIDATE_FILE(files[i], this.state.media, this.allFilesSize)) {
        break;
      }

      const file = files[i];
      const index = i;
      const type = file.type.split('/')[0];

      this.allFilesSize += file.size;

      if (
        (type === 'image' && !(this.state.mediaUrl[0] && this.state.mediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.mediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            mediaUrl: [],
            disabledButton: false,
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                mediaUrl[index] = {
                  url: img,
                  type: type === 'image' ? 'i' : 'v',
                  size: file.size,
                };
                if (mediaUrl.filter(Boolean).length === files.length) {
                  this.setState({
                    mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                    disabledButton: false,
                  });
                }
              },
              loadImageOptions
            );
          } else {
            mediaUrl[index] = {
              url: reader.result,
              type: type === 'image' ? 'i' : 'v',
              size: file.size,
            };
            if (mediaUrl.filter(Boolean).length === files.length) {
              this.setState({
                mediaUrl: [...this.state.mediaUrl, ...mediaUrl],
                disabledButton: false,
              });
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        mediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  removeMediaHandler = index => {
    const updatedMedia = [...this.state.media];

    const mediaIndex = updatedMedia.findIndex(m => m.id === index);

    updatedMedia.splice(mediaIndex, 1);
    this.setState({
      media: updatedMedia,
    });
  };

  removeNewMediaHandler = index => {
    this.allFilesSize -= this.state.mediaUrl[index].size;
    const updatedMediaUrl = [...this.state.mediaUrl];
    updatedMediaUrl.splice(index, 1);
    this.setState({
      mediaUrl: updatedMediaUrl,
    });
  };

  updatePostHandler = () => {
    if (!this.state.loader) {
      if (this.state.possibleFixError || this.state.contentError) {
        alert('Please fix errors first');
        return;
      }
      if (this.state.content.length > 0) {
        const data = new FormData();
        data.append('_method', 'PUT');
        this.state.name && data.append('name', this.state.name);
        data.append('content', this.state.content);
        this.state.possible_fix && data.append('possible_fix', this.state.possible_fix);
        data.append('type', 'Discussion');
        for (let i = 0; i < this.state.subjects.length; i++) {
          data.append('post_subjects[]', this.state.subjects[i]);
        }
        for (let i = 0; i < this.state.mediaUrl.length; i++)
          data.append('post_media[]', JSON.stringify(this.state.mediaUrl[i]));
        for (let i = 0; i < this.state.media.length; i++)
          data.append('post_old_media[]', this.state.media[i].id);
        this.props.updatePost(this.props.post.id, data);
        this.setState({
          name: '',
          content: '',
          media: [],
          mediaUrl: [],
          possible_fix: '',
          subjects: [],
          errorContent: null,
          possibleFixError: null,
          postErrors: [],
          loader: true,
        });
      }
    }
  };

  toggleOwnersModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showOwnersModal: !this.state.showOwnersModal });
  };

  editingNameChangedHandler = event => {
    const input = event.target.value;
    this.setState({
      name: input,
      nameError: input.length > 250 ? 'The title cannot be longer than 250 characters' : null,
    });
  };

  buildQueryString(tagName) {
    return `?page=1&${qs.stringify(
      { subjects: [tagName] },
      { encode: false, arrayFormat: 'brackets' }
    )}`;
  }

  subjectChoiceHandler(e) {
    if (e.length < 40) {
      this.setState({ subject: e });
      if (
        this.props.discussionTags.indexOf(e.trim()) >= 0 &&
        this.state.subjects.indexOf(e.trim()) < 0
      ) {
        this.setState({
          subjects: [...this.state.subjects, e.trim()],
          subject: '',
          showCharLimitInfo: false,
        });
        AUTOCOMPLETE_NATIVE_VALUE_RESET('subject-input');
      }
    } else if (e.length === 40) {
      this.setState({ showCharLimitInfo: true });
    }
  }

  removeSubjectHandler(subject) {
    const subjectIndex = this.state.subjects.indexOf(subject);
    const chosenHelperArray = this.state.subjects;
    chosenHelperArray.splice(subjectIndex, 1);
    this.setState({
      subjects: chosenHelperArray || [],
    });
  }

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      charsLeftContent: 1000 - unformattedContent.length,
      contentError:
        unformattedContent.length > 1000
          ? 'The content cannot be longer than 1000 characters'
          : null,
    });
  };

  possibleFixChangedHandler = e => {
    const input = e.target.value;
    this.setState({
      possible_fix: e.currentTarget.value,
      possibleFixError:
        input.length > 1000 ? 'The possible solution cannot be longer than 1000 characters' : null,
    });
  };

  render() {
    const hasSubtype = !!this.props.post.subtype;
    if (!this.props.post) {
      return null;
    }

    let nameError = null;
    let descriptionError = null;
    let possibleFixError = null;

    if (this.state.errors) {
      if (this.state.errors.name) {
        nameError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors.name[0]}
          </span>
        );
      }
      if (this.state.errors.content) {
        descriptionError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors.content[0]}
          </span>
        );
      }
      if (this.state.errors.possible_fix) {
        possibleFixError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors.possible_fix[0]}
          </span>
        );
      }
    }

    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }
    rolePosition.push(this.props.post.user.team.name);

    return (
      <div className="post-wrapper">
        <div className="post-header">
          <div className="author-avatar">
            <img
              alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
              src={this.props.post.user.image_url}
              onError={e => AVATAR_ERROR(e.target)}
            />
          </div>
          <AuthorInfo post={this.props.post} user={this.props.user} />
          <PinnedPost post={this.props.post} />
          <img
            src={this.props.post.favourite ? favActiveBtn : favBtn}
            data-tooltip-id={`post_fav_status${this.props.post.id}`}
            className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
            onClick={() => this.props.favPost(this.props.post.id)}
            alt=""
          />
        </div>
        <div className="post-body">
          {this.props.editing ? (
            <>
              <DropzoneWrap fileUploadHandler={this.fileUploadHandler} />
              <EditMediaWrap
                mediaUrl={this.state.mediaUrl}
                media={this.state.media}
                removeNewMediaHandler={this.removeNewMediaHandler}
                removeMediaHandler={this.removeMediaHandler}
              />
            </>
          ) : (
            <div className="post-show-media">
              <PostMedia
                media={this.props.post.media}
                embed={{
                  id: this.props.post.integration_video_id,
                  type: this.props.post.integration_video_type,
                }}
              />
            </div>
          )}
          {(this.props.post.name || hasSubtype || this.props.editing) && (
            <div className={`post-title ${this.props.post.status || ''}`}>
              {this.props.post.status === 'resolved' ? (
                <img
                  src={`${AWS_S3_URL}public/images/icons/marked-as-done.png`}
                  className={`vibe-tile-icon-done ${this.props.editing ? 'editing' : ''}`}
                  alt="done"
                />
              ) : (
                ''
              )}
              {this.props.editing && !hasSubtype ? (
                <>
                  <h2 className="editing-label">
                    In a few words, what could we be doing better?
                    <span className="required">*</span>
                  </h2>
                  <input
                    type="text"
                    id="discussion-editing-name"
                    maxLength="251"
                    className="form-control"
                    value={this.state.name}
                    onChange={this.editingNameChangedHandler}
                  />
                  {nameError}
                  {this.state.nameError && <p className="text-danger">{this.state.nameError}</p>}
                </>
              ) : (
                <span className={`type ${hasSubtype && 'black'}`}>
                  {getTypeString(this.props.post)}&nbsp;
                  <span className="title">{this.props.post.name}</span>
                </span>
              )}
            </div>
          )}
          <div className="post-content">
            {this.props.editing ? (
              <>
                {!hasSubtype && (
                  <div className="post-solution">
                    <div className="post-solution-content-text">
                      <h2 className="editing-label">Can you think of a possible solution?</h2>
                      <textarea
                        name="possible_fix"
                        id="possible_fix"
                        className="form-control"
                        cols="30"
                        rows="4"
                        maxLength="1001"
                        onChange={this.possibleFixChangedHandler}
                        value={this.state.possible_fix}
                      />
                    </div>
                    {possibleFixError}
                    {this.state.possibleFixError && (
                      <p className="text-danger">{this.state.possibleFixError}</p>
                    )}
                  </div>
                )}
                <h2 className="editing-label">
                  What topic does this relate to?
                  <span className="required">*</span>
                </h2>
                <TextInput
                  trigger=""
                  Component="input"
                  placeholder="Type it here ..."
                  options={this.props.discussionTags.filter(
                    tag => this.state.subjects.indexOf(tag) < 0
                  )}
                  onChange={e => this.subjectChoiceHandler(e)}
                  value={this.state.subject}
                  onKeyDown={e => {
                    const val = e.target.value.trim();
                    if (
                      e.key === 'Enter' &&
                      val !== '' &&
                      this.state.subjects.indexOf(val) === -1
                    ) {
                      e.preventDefault();
                      this.setState({
                        subjects: [...this.state.subjects, val],
                        subject: '',
                        showCharLimitInfo: false,
                      });
                    }
                  }}
                  onBlur={e => {
                    const element = e.target;
                    setTimeout(() => {
                      const val = element.value.trim();
                      AUTOCOMPLETE_NATIVE_VALUE_RESET(element);
                      if (val !== '' && this.state.subjects.indexOf(val) === -1) {
                        this.setState({
                          subjects: [...this.state.subjects, val],
                          subject: '',
                          showCharLimitInfo: false,
                        });
                      } else {
                        this.setState({
                          subject: '',
                        });
                      }
                    }, 200);
                  }}
                  className="discussion-input form-control"
                  id="subject-input"
                />
                <div className="subject-wrapper">
                  {this.state.subjects.map((subject, index) => (
                    <span className="subject-box" id={`subject-${index}`} key={index}>
                      {subject}
                      <div
                        onClick={() => this.removeSubjectHandler(subject)}
                        className="subject-cross"
                      />
                    </span>
                  ))}
                </div>
                <MentionsInputWrapper
                  value={this.state.content}
                  contentChangedHandler={this.contentChangedHandler}
                  placeholder="Type here..."
                  mentions={GET_MENTIONS_FROM_CONTENT(this.props.post.content)}
                  maxLength="1001"
                />
                {descriptionError}
                {this.state.contentError && (
                  <p className="text-danger">{this.state.contentError}</p>
                )}
                {this.props.editing && this.state.content.length === 0 ? (
                  <div className="help-block text-danger">Post content cannot be empty</div>
                ) : (
                  ''
                )}
                <span
                  id="save-edit-btn"
                  className={`edit-save btn-sm btn btn-fixed btn-primary ${
                    this.state.loader ? 'disabled' : ''
                  }`}
                  onClick={this.updatePostHandler}
                >
                  {this.state.loader ? 'Saving' : 'Save'}
                </span>
              </>
            ) : (
              <div
                className="post-content-text"
                dangerouslySetInnerHTML={{
                  __html: striptags(this.props.post.content, ['a', 'br']),
                }}
              />
            )}
            {!this.props.editing && this.props.post.possible_fix && (
              <div className="post-solution">
                <div className="post-solution-content-title">
                  <p>Possible solution:</p>
                </div>
                <div
                  className="post-solution-content-text"
                  dangerouslySetInnerHTML={{
                    __html: striptags(this.props.post.possible_fix, ['a', 'br']),
                  }}
                />
              </div>
            )}
            {!this.props.editing && this.props.post.vibe_reasons.length > 0 && (
              <div className={hasSubtype ? 'categories-wrapper' : 'tags-wrapper'}>
                {hasSubtype && <span>Category:&nbsp;</span>}
                {this.props.post.vibe_reasons.map(tag => (
                  <Link to={`/posts/list${this.buildQueryString(tag.name)}`}>{tag.name}&nbsp;</Link>
                ))}
              </div>
            )}
            {this.props.post.files.map((file, index) => (
              <PostFile file={file} key={index} />
            ))}
            <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} large />
          </div>
          {this.props.post.owners.length && this.props.post.status !== 'resolved' ? (
            <PostOwners
              owners={this.props.post.owners}
              showAllOwners={this.toggleOwnersModal}
              postId={this.props.post.id}
              user={this.props.user}
              show={this.state.showOwnersModal}
            />
          ) : (
            ''
          )}
          <CommentInput post={this.props.post} />
        </div>
        <div className="post-comments-section">
          <div className="post-comments">
            <PostComments />
            {this.props.post.status === 'resolved' ? (
              <div className="post-comment ">
                <div className="post-marked-done-content ">
                  <strong>
                    <Link
                      id="updated-user"
                      to={
                        this.props.post.updater.id === this.props.user.id
                          ? `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                          : `/user/${this.props.post.updater.id}`
                      }
                    >
                      {this.props.post.updater.first_name} {this.props.post.updater.last_name}&nbsp;
                    </Link>
                    <img
                      src={`${AWS_S3_URL}public/images/icons/marked-as-done.png`}
                      alt=""
                      className="post-icon-done"
                    />
                  </strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.props.post.reason,
                    }}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <MarkedDone post={this.props.post} user={this.props.user} />
        </div>
        <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Discussion));
