/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { ReactComponent as Icon } from './icon.svg';

const RecommendationsInfo = ({ recommendations }) => {
  const count = recommendations.filter(rec => !rec.completed).length;
  return (
    <div
      css={css`
        display: inline-block;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        max-width: 180px;
        margin: 28px 20px;
        @media (max-width: 1392px) {
          margin: 28px 10px;
        }
        @media (max-width: 415px) {
          margin: 20px 10px 10px;
        }
      `}
    >
      <Icon
        css={css`
          width: 50px;
          height: 50px;
          display: block;
          margin: 0 auto 16px;
        `}
      />
      {`You have ${Math.min(3, count)} unread `}
      <a href="#recommendations-section">{`recommendation${count !== 1 ? 's' : ''}`}</a>
    </div>
  );
};

export default RecommendationsInfo;
