/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { AWS_S3_URL } from 'constants.js';
import React, { useState } from 'react';
import { icons } from 'components/vibes/createForms/partials/BottomBar/PreloadIcons';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import { ReactComponent as MegaphoneShared } from './assets/megaphone_shared.svg';

const Wrap = styled.div`
  margin: 23px 60px 14px 25px;
`;

const Title = styled.div`
  font-size: 12px;
  color: #6a6a6a;
  display: inline-flex;
  align-items: center;
  max-width: 300px;
`;

const TextButton = styled.span`
  font-size: 12px;
  color: #11a9ff;
  cursor: pointer;
`;

const AssignedToWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;
  font-size: 12px;
  color: #11a9ff;

  > svg {
    margin-right: 12px;
  }
`;

const Icon = ({ isSharing }) => (
  <div css={css``}>
    <div
      css={css`
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
        background-color: ${isSharing ? '#11A9FF' : '#F3F3F3'};
      `}
    >
      <img
        src={`${AWS_S3_URL}public/images/icons/${isSharing ? icons.megaphone : icons.sunglasses}`}
        alt=""
      />
    </div>
  </div>
);

const getType = mode => {
  switch (mode) {
    case noteTypes.goal:
      return 'goal';
    case noteTypes.win:
      return 'win';
    default:
      return 'note';
  }
};

export const useShareMenu = mode => {
  const [shareState, setShareState] = useState({
    isSharedWithMultiple: false,
    isSharing: false,
    assignTo: '',
    shareWith: [],
    mode,
  });

  const setState = state => {
    setShareState(prevState => ({
      ...prevState,
      ...state,
    }));
  };

  return {
    shareState,
    setSharing: isSharing => () => setState({ isSharing }),
    setShareWith: shareWith => setState({ shareWith }),
  };
};

const ShareMenu = ({
  assignTo,
  setAssignTo,
  setShareWith,
  isSharing,
  setSharing,
  mode,
  isSharedWithMultiple,
}) => {
  const type = getType(mode);
  if (assignTo) {
    return (
      <AssignedToWrapper>
        <MegaphoneShared /> This action will be visible to one person.
      </AssignedToWrapper>
    );
  }
  return (
    <Wrap>
      <Title>
        <Icon isSharing={isSharing} />
        {isSharing ? (
          <div>
            <div>
              Your {type} will be visible to{' '}
              {isSharedWithMultiple ? 'multiple people' : 'one person'}.
            </div>
            <TextButton
              onClick={() => {
                setSharing(false)();
                setShareWith([]);
                setAssignTo('');
              }}
            >
              Cancel & keep private
            </TextButton>
          </div>
        ) : (
          <span>
            Your {type}s are always private, unless you choose to{' '}
            <TextButton onClick={setSharing(true)}>share them</TextButton>.
          </span>
        )}
      </Title>
    </Wrap>
  );
};

ShareMenu.propTypes = {
  setAssignTo: PropTypes.func.isRequired,
  setShareWith: PropTypes.func.isRequired,
  isSharing: PropTypes.bool.isRequired,
  setSharing: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  isSharedWithMultiple: PropTypes.bool.isRequired,
};

export default ShareMenu;
