/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import getScoreColor from 'pages/Insights/OrganisationInsights/getScoreColor';
import {
  calculateScoreWidth,
  calculateWidth,
  SCORE_MOBILE_BREAKPOINT,
} from 'pages/Insights/OrganisationInsights/Score/layout';
import React from 'react';
import { getScore } from 'pages/Insights/OrganisationInsights/Score/Score';
import { css, jsx } from '@emotion/react';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1px;
  position: relative;
  cursor: pointer;
  height: ${({ depth }) => (depth === 0 ? '30px' : '20px')};
  ${({ score, isParticipationView }) => getScoreColor(score, isParticipationView)};

  width: ${({ score, isParticipationView }) => calculateWidth(score, isParticipationView, true)};
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    width: ${({ score, isParticipationView }) => calculateWidth(score, isParticipationView)};
  }
`;

const PreviousScoreBar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  height: 20px;
  background: ${({ score, isParticipationView }) =>
    getScoreColor(score, isParticipationView)?.secondaryColor};
  width: 2px;
  margin-left: ${({ previousScore, isParticipationView }) =>
    calculateScoreWidth(previousScore, isParticipationView)};
`;

const Score = styled.div`
  font-weight: bold;
  font-size: 11px;
  margin-left: 7px;
  cursor: pointer;
  z-index: 2;

  display: block;
  @media (min-width: ${SCORE_MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

const ChartBar = ({ depth, score, previous_score, isParticipationView, onClick, showPrevious }) => {
  const showScoreOnBar =
    score === null || parseFloat(calculateWidth(score, isParticipationView, true)) > 27.5;
  return (
    <Container>
      <Bar depth={depth} score={score} isParticipationView={isParticipationView} onClick={onClick}>
        {showScoreOnBar && <Score>{getScore(score, isParticipationView)}</Score>}
      </Bar>
      {showPrevious && depth !== 0 && (
        <PreviousScoreBar
          score={score}
          previousScore={previous_score}
          isParticipationView={isParticipationView}
        />
      )}
      {!showScoreOnBar && (
        <Score
          onClick={onClick}
          css={css`
            color: ${getScoreColor(score, isParticipationView)?.backgroundColor};
            position: relative;
          `}
        >
          {getScore(score, isParticipationView)}
        </Score>
      )}
    </Container>
  );
};

ChartBar.propTypes = {
  depth: PropTypes.number.isRequired,
  score: PropTypes.number,
  previous_score: PropTypes.number,
  isParticipationView: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showPrevious: PropTypes.bool.isRequired,
};

ChartBar.defaultProps = {
  score: null,
  previous_score: null,
};

export default ChartBar;
