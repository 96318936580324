import React from 'react';
import {
  HIDE_MENTION_TOOLTIP,
  SHOW_MENTION_TOOLTIP,
} from 'components/shared/tooltips/MentionTooltip';
import Loader from 'components/shared/Loader';
import SamplePostsBanner from 'pages/Grow/SamplePostsBanner';
import DayAgenda from 'pages/Grow/GrowProfile/DayAgenda';
import qs from 'qs';
import { FORMAT_FILTERS } from 'helpers';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { toggleUserModal } from 'redux/app/actions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { jsx } from '@emotion/react';

const NewGrowPosts = ({
  hasMorePostsToLoad,
  user,
  dates,
  openPostModal,
  tileRealignHandler,
  hideSamplePosts,
  history,
  toggleUserModal,
  posts,
  filters,
  exportModeOn,
  hasDeleteError,
  selectedPosts,
  handleSelectPost,
}) => {
  const mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === user.id) {
      return history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    }
    return toggleUserModal(true, mentionedId);
  };

  // ---Hashtags listener and filter apply
  const hashtagClickedHandler = e => {
    e.preventDefault();
    history.push(
      `feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };

  const handleListeners = () => {
    const renderedHashtags = document.getElementsByClassName('inline-hashtag');
    Array.from(renderedHashtags).forEach(hashtag => {
      hashtag.removeEventListener('click', hashtagClickedHandler);
      hashtag.addEventListener('click', hashtagClickedHandler, false);
    });

    Array.from(document.getElementsByClassName('inline-mention')).forEach(mention => {
      mention.removeEventListener('mouseover', SHOW_MENTION_TOOLTIP);
      mention.addEventListener('mouseover', SHOW_MENTION_TOOLTIP, false);
      mention.removeEventListener('mouseout', HIDE_MENTION_TOOLTIP);
      mention.addEventListener('mouseout', HIDE_MENTION_TOOLTIP, false);
      mention.addEventListener('click', mentionClickedHandler, false);
    });
  };

  return (
    <>
      {!!posts.length &&
        dates.map(dayData => {
          return (
            <DayAgenda
              dayData={dayData}
              openPostModal={openPostModal}
              tileRealignHandler={tileRealignHandler}
              handleListeners={handleListeners}
              exportModeOn={exportModeOn}
              selectedPosts={selectedPosts}
              handleSelectPost={handleSelectPost}
              hasDeleteError={hasDeleteError}
            />
          );
        })}
      {hasMorePostsToLoad && <Loader />}
      {!user.hideSamplePosts && <SamplePostsBanner hideSamplePosts={() => hideSamplePosts()} />}
    </>
  );
};

NewGrowPosts.propTypes = {
  dates: PropTypes.instanceOf(Object).isRequired,
  hideSamplePosts: PropTypes.func.isRequired,
  tileRealignHandler: PropTypes.func.isRequired,
  openPostModal: PropTypes.func.isRequired,
  toggleUserModal: PropTypes.func.isRequired,
  hasMorePostsToLoad: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  filters: PropTypes.instanceOf(Array).isRequired,
  posts: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  posts: state.growDomain.posts,
  filters: state.growDomain.filters,
  selectedPosts: state.growDomain.selectedPosts,
});
const mapDispatch = dispatch => ({
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
});

export default connect(mapStateToProps, mapDispatch)(NewGrowPosts);
