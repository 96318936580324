/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import qs from 'qs';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { AVATAR_GENERIC, INITIAL_POST_FILTERS } from 'constants.js';
import { BODY_CLASSES, FORMAT_FILTERS } from 'helpers.js';
import { POST_ACTIONS } from 'redux/actions/postActions';
import debounce from 'lodash/debounce';
import { toggleUserModal } from 'redux/app/actions';
import { USER_ACTIONS } from 'redux/actions/userActions';
import SamplePostsBanner from 'pages/Grow/SamplePostsBanner';
import NoResultsPage from './NoResultsPage';
import Loader from '../shared/Loader';
import PostsMasonry from './PostsMasonry';
import FeedDateRange from '../shared/FeedDateRange';

class ShoutoutFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      postRenderAlignment: false,
    };
  }

  initialFilters = {
    ...INITIAL_POST_FILTERS,
    types: ['shoutout'],
  };

  componentDidUpdate(props) {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters));
    const qsFilters = qs.parse(window.location.href.split('shoutouts?')[1]);
    const newFilters = window.location.search;
    if (this.state.currentFilters !== newFilters) {
      this.setState({ currentFilters: newFilters });
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(this.props.filters)) {
        this.debouncedFetch(filtersCopy);
      }
    }
  }

  handleFetch = filtersCopy => {
    this.props.filtersFromObject({
      ...filtersCopy,
    });
    this.props.resetPosts();
    this.props.fetchPosts();
  };

  debouncedFetch = debounce(this.handleFetch, 1000, { leading: true });

  fetchTriggerThreshold = 1000;

  componentDidMount() {
    BODY_CLASSES(['vibes', 'index', 'jobvibe-future']);

    this.props.closeFlash();
    window.onscroll = () => {
      if (
        this.props.loadedPostsCount !== 0 &&
        window.innerHeight + window.scrollY + this.fetchTriggerThreshold >=
          document.body.offsetHeight &&
        !this.props.fetchingPosts &&
        this.props.hasMorePostsToLoad &&
        window.scrollY > 0
      ) {
        // --last condition prevent fetch on popstate
        this.props.fetchPosts();
      }
    };
    scroll.scrollTo(this.props.scrollPosition, { duration: 0 });
  }

  componentWillUnmount() {
    this.props.saveScroll(window.scrollY);
    window.onscroll = () => {};
  }

  tileRealignHandler() {
    // --Simply push new state to trigger rendering
    this.setState({ postRenderAlignment: true });
  }

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  shouldShowPost = spaceId => {
    const { spaces } = this.props;
    return spaces.map(space => space.id).includes(spaceId);
  };

  render() {
    return (
      <div className="vibe-index">
        <div className="heelix-feed-wrap">
          <div className="create-and-masonry-wrap">
            <div className="row post-feed-wrap">
              {this.props.user.should_see_sample_data &&
              !this.props.posts.length &&
              !this.props.hasMorePostsToLoad &&
              !this.props.user.should_see_sample_data ? (
                <NoResultsPage user={this.props.user} space={this.props.team} />
              ) : (
                <PostsMasonry
                  posts={this.props.posts}
                  tileRealignHandler={this.tileRealignHandler.bind(this)}
                  user={this.props.user}
                  hasMorePostsToLoad={this.props.hasMorePostsToLoad}
                  shouldShowPost={this.shouldShowPost}
                  mentionClickedHandler={this.mentionClickedHandler}
                  vibeTile=""
                />
              )}
              {this.props.hasMorePostsToLoad ? <Loader /> : ''}
            </div>
            {!this.props.user.hideSamplePosts && this.props.team.account_default_space && (
              <SamplePostsBanner hideSamplePosts={() => this.props.hideSamplePosts()} />
            )}
          </div>
          <div className={`mobile-panel-overlay ${this.state.mobileOpen ? 'open' : ''}`}>
            <div className="feed-right-panel-wrap">
              <div className="feed-right-panel-inner-wrap">
                <div
                  css={css`
                    margin-left: 6px;
                    margin-bottom: 250px;
                    width: 190px;
                  `}
                >
                  <FeedDateRange />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  saveScroll: position => dispatch(POST_ACTIONS.saveScroll(position)),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
  resetFilters: () => dispatch(POST_ACTIONS.resetFilters()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
});
const mapStateToProps = state => ({
  posts: state.postsDomain.posts,
  fetchingPosts: state.postsDomain.fetchingPosts,
  hasMorePostsToLoad: state.postsDomain.hasMorePostsToLoad,
  loadedPostsCount: state.postsDomain.loadedPostsCount,
  scrollPosition: state.postsDomain.scrollPosition,
  filters: state.postsDomain.filters,

  user: state.usersDomain.user,

  spaces: state.spacesDomain.spaces,
  team: state.teamsDomain.team,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoutoutFeed);
