/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Draggable } from 'react-beautiful-dnd';
import ProgressBoardPost from './ProgressBoardPost';

const Container = styled.div`
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35);
  cursor: pointer;
`;

const Post = ({
  post: { note, win, type, id, progress_status, add_to_progress_board_enabled, preparation_note },
  index,
  removeNoteClickHandle,
  openProgressBoardPost,
  confirmNote,
}) => {
  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <ProgressBoardPost
            note={note}
            win={win}
            type={type}
            postId={id}
            confirmNote={confirmNote}
            openProgressBoardPost={openProgressBoardPost}
            removeNoteClickHandle={removeNoteClickHandle}
            progressStatus={progress_status}
            addToProgressBoardEnabled={add_to_progress_board_enabled}
            preparationNote={preparation_note}
          />
        </Container>
      )}
    </Draggable>
  );
};

export default Post;
