/** @jsxImportSource @emotion/react */
import React from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Page from 'components/layouts/Page';
import ViewDetailsButton from '../shared/ViewDetailsButton';
import DetailedFeedback from '../../../components/vibes/DetailedFeedback';
import AnonymousComments from './AnonymousComments';

const Column = styled.div`
  float: left;
  width: 50%;
  padding: 0 10px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  min-height: 150px;
`;
const TriggersAnonymousPreview = ({
  search,
  detailedFeedbackData,
  anonymousComments,
  queryString,
}) => (
  <div className="row">
    <Column>
      <Page.Divider />
      <Page.H2 className="factor_analysis_target">
        Triggers
        <ViewDetailsButton url="/vibes/detailed_feedback" search={search} />
      </Page.H2>
      <DetailedFeedback teamName="" detailedFeedbackData={detailedFeedbackData} dashboard />
    </Column>
    <Column>
      <Page.Divider />
      <Page.H2 className="performance_over_time_target">
        Specific feedback
        <ViewDetailsButton
          url="/vibes/mood_vs_factor"
          search={search}
          state={{ section: 'anonymous' }}
        />
      </Page.H2>
      <AnonymousComments
        queryString={queryString}
        anonymousComments={anonymousComments}
        dashboard
      />
    </Column>
  </div>
);

export default TriggersAnonymousPreview;
