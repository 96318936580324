import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { APP_URL, AVATAR_GENERIC, AWS_S3_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import ModalValueError from './ModalValueError';

class AddNewDemoAccount extends Component {
  formValueTemplate = {
    email: '',
    first_name: '',
    last_name: '',
    one_week: true,
  };

  state = {
    rowsCount: 1,
    formValues: [this.formValueTemplate],
    validationMessages: [{}],
    generalFailureMessage: '',
    showOmittedMessage: false,
  };

  userId = -1;

  sendData = () => {
    const users = [];
    this.state.formValues.forEach(valuesSet => {
      users.push({
        email: valuesSet.email.trim().toLowerCase(),
        first_name: valuesSet.first_name.trim(),
        last_name: valuesSet.last_name.trim(),
        one_week: valuesSet.one_week,
        state: this.props.state,
      });
    });
    const headers = HEADERS();
    headers.append('Content-Type', 'application/json');
    fetch(`${APP_URL}/demo_accounts`, { method: 'post', headers, body: JSON.stringify(users) })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        const { ok } = response;
        response.json().then(jsonResponse => {
          if (ok) {
            this.props.close();
            this.setState({
              formValues: [this.formValueTemplate],
              validationMessages: [{}],
              rowsCount: 1,
            });
          } else if (jsonResponse.message)
            this.setState({ generalFailureMessage: jsonResponse.message });
          else {
            const validationMessages = [
              ...Array(this.state.formValues.length).fill('').map(Object),
            ];
            Object.keys(jsonResponse).forEach(key => {
              const keySplit = key.split('.');
              validationMessages[parseInt(keySplit[0], 10)][keySplit[1]] = jsonResponse[key][0];
            });
            this.setState({ validationMessages, showOmittedMessage: true });
          }
        });
      });
  };

  setRowValue = (name, index, value) => {
    const formValues = this.state.formValues.map((values, valuesIndex) =>
      valuesIndex === index
        ? {
            ...values,
            [name]: value,
          }
        : values
    );
    const validationMessages = this.state.validationMessages.map((messages, messagesIndex) =>
      messagesIndex === index
        ? {
            ...messages,
            [name]: '',
          }
        : messages
    );
    this.setState({
      formValues,
      validationMessages,
    });
    this.checkIfHasErrorMessages(validationMessages);
  };

  checkIfHasErrorMessages = messages => {
    const hasErrorMessage = messages.find(
      message => message.first_name || message.last_name || message.email
    );
    this.setState({ showOmittedMessage: hasErrorMessage });
  };

  render() {
    const enableButton = this.state.formValues.find(e => {
      for (const prop in e) {
        const has = e.hasOwnProperty(prop);
        if (has && e[prop]) {
          return true;
        }
      }
    });
    return (
      <div>
        { this.props.showNewDemoAccountModal && (
          <Modal
          show={this.props.showNewDemoAccountModal}
          onHide={this.props.close}
          dialogClassName="add-users-modal one-by-one"
          backdrop
          keyboard
        >
          <div className="close-cross" onClick={this.props.close} />
          <h1>Create new demo account</h1>
          <>
            <div className="adding-info">
              {this.state.showOmittedMessage &&
                'You’re missing a mandatory field, please review and complete to add a new user.'}
            </div>
            <div className="form-wrap">
              {[...Array(this.state.rowsCount)].map((object, index) => (
                <div key={index} className="form-row-wrap">
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].email ? 'error' : ''
                    }`}
                  >
                    <p>Email</p>
                    <input
                      value={this.state.formValues[index].email}
                      onChange={e => this.setRowValue('email', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].email}
                      isMounted={Boolean(this.state.validationMessages[index].email)}
                      delayTime={400}
                    />
                  </div>
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].first_name ? 'error' : ''
                    }`}
                  >
                    <p>First name</p>
                    <input
                      value={this.state.formValues[index].first_name}
                      onChange={e => this.setRowValue('first_name', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].first_name}
                      isMounted={Boolean(this.state.validationMessages[index].first_name)}
                      delayTime={400}
                    />
                  </div>
                  <div
                    className={`column-wrap ${
                      this.state.validationMessages[index].last_name ? 'error' : ''
                    }`}
                  >
                    <p>Last name</p>
                    <input
                      value={this.state.formValues[index].last_name}
                      onChange={e => this.setRowValue('last_name', index, e.target.value)}
                    />
                    <ModalValueError
                      message={this.state.validationMessages[index].last_name}
                      isMounted={Boolean(this.state.validationMessages[index].last_name)}
                      delayTime={400}
                    />
                  </div>
                  <div className="column-wrap">
                    <p>Remove After One Week</p>
                    <input
                      type="checkbox"
                      checked={this.state.formValues[index].one_week}
                      onChange={e => this.setRowValue('one_week', index, e.target.checked)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={`general-error ${this.state.generalFailureMessage ? 'visible' : ''}`}>
              {this.state.generalFailureMessage}
            </div>
            <div className="switch-method-wrap">
              <img src={AWS_S3_URL + AVATAR_GENERIC} alt="avatar generic" />{' '}
              <span
                onClick={() =>
                  this.setState({
                    rowsCount: this.state.rowsCount + 1,
                    formValues: [...this.state.formValues, this.formValueTemplate],
                    validationMessages: [...this.state.validationMessages, {}],
                  })
                }
              >
                Add another user
              </span>
            </div>
            <div className="buttons-wrap">
              <div
                className={`next-step-button ${!enableButton ? 'disabled' : ''}`}
                onClick={enableButton && this.sendData}
              >
                Create
              </div>
              <div className="cancel-button" onClick={this.props.close}>
                Cancel
              </div>
            </div>
          </>
        </Modal>
        )}
      </div>
    );
  }
}

export default AddNewDemoAccount;
