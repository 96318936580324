import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import qs from 'qs';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { APP_URL, AVATAR_GENERIC, INITIAL_POST_FILTERS } from 'constants.js';
import {
  BODY_CLASSES,
  CHECK_UNAUTHORIZED,
  FORMAT_FILTERS,
  HEADERS,
  LOCALSTORAGE_GET,
} from 'helpers.js';
import { POST_ACTIONS } from 'redux/actions/postActions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import debounce from 'lodash/debounce';
import { toggleUserModal } from 'redux/app/actions';
import SlackModal from 'components/shared/Modal/SlackModal';
import { USER_ACTIONS } from 'redux/actions/userActions';
import SamplePostsBanner from 'pages/Grow/SamplePostsBanner';
import Loader from '../shared/Loader';
import NoResultsPage from './NoResultsPage';
import VibeTile from './VibeTile';
import HomeFeedRightPanel from './HomeFeedRightPanel';
import PostsMasonry from './PostsMasonry';

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  addPost: post => dispatch(POST_ACTIONS.addPost(post)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  saveScroll: position => dispatch(POST_ACTIONS.saveScroll(position)),
  filtersFromObject: object => dispatch(POST_ACTIONS.filtersFromObject(object)),
  resetFilters: () => dispatch(POST_ACTIONS.resetFilters()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
  startFeedTour: () => dispatch(ONBOARDING_ACTIONS.startFeedTour()),
  hideSamplePosts: () => dispatch(USER_ACTIONS.hideSamplePosts()),
  updateVisited: page => dispatch(USER_ACTIONS.updateVisited(page)),
});
const mapStateToProps = state => ({
  displayOnboardingModal: state.onboardingDomain.displayOnboardingModal,
  posts: state.postsDomain.posts,
  fetchingPosts: state.postsDomain.fetchingPosts,
  hasMorePostsToLoad: state.postsDomain.hasMorePostsToLoad,
  loadedPostsCount: state.postsDomain.loadedPostsCount,
  scrollPosition: state.postsDomain.scrollPosition,
  filters: state.postsDomain.filters,

  companyValues: state.tagsDomain.companyValues,

  user: state.usersDomain.user,

  spaces: state.spacesDomain.spaces,
  teams: state.teamsDomain.teams,
  team: state.teamsDomain.team,

  spaceId: state.usersDomain.spaceId,
  plan: state.plansDomain.plan,

  editedSpaceId: state.spacesDomain.editedSpaceId,
});

class HomeFeed extends Component {
  constructor(props) {
    super(props);
    this.firstName = this.props.user ? this.props.user.firstName : '';
    this.image =
      this.props.user && this.props.user.image_uri ? this.props.user.image_url : AVATAR_GENERIC;
    this.state = {
      loaded: false,

      postRenderAlignment: false,

      showCroppie: false,
      imageCroppieUrl: '',
      croppieMounted: false,
      showFixModal: false,
      mediaUrl: [],
      subjects: [],
      subject: '',
      showShoutoutModal: false,
      showEditModal: false,
      vibeTileLoaded: false,
      currentFilters: '',
    };
    this.vibe_factors = [];
    this.links = [];
    this.scale = null;
    this.raw_text = [];
    this.images = [];
    this.enable_vibe_comments = [];
  }

  initialFilters = INITIAL_POST_FILTERS;

  componentDidUpdate(prevProps) {
    const filtersCopy = JSON.parse(JSON.stringify(this.props.filters));
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const newFilters = window.location.search;
    if (this.state.currentFilters !== newFilters) {
      this.setState({ currentFilters: newFilters });
      Object.keys(filtersCopy).map(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(this.props.filters)) {
        this.debouncedFetch(filtersCopy);
      }
    }
    if (
      !this.props.displayOnboardingModal &&
      !this.props.user.visited.feed &&
      this.props.plan !== 'free'
    ) {
      this.props.startFeedTour();
      this.props.updateVisited('feed');
    }
  }

  handleFetch = filtersCopy => {
    this.props.filtersFromObject(filtersCopy);
    this.props.resetPosts();
    this.props.fetchPosts();
  };

  debouncedFetch = debounce(this.handleFetch, 1000, { leading: true });

  fetchTriggerThreshold = 1000;

  getParameterByName(name, url) {
    if (!url) url = document.location;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getVibeData = () => {
    if (!this.props.userAlreadyVibed && this.props.plan !== 'free') {
      let accessToken = '';

      let mobileParam = '';
      if (this.getParameterByName('uta')) {
        mobileParam = '?mobile_app=1';
        accessToken = this.getParameterByName('uta');
      } else if (localStorage != null) {
        accessToken = LOCALSTORAGE_GET('access_token');
      }
      const headers = HEADERS(accessToken);
      fetch(`${APP_URL}/vibes/get_data${mobileParam}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          if (response.ok && response.status === 200) {
            response.json().then(jsonResponse => {
              this.vibe_factors = jsonResponse.vibe_factors;
              this.feel_factors = jsonResponse.feel_factors;
              this.links = jsonResponse.links;
              this.scale = jsonResponse.scale;
              this.raw_text = jsonResponse.raw_text;
              this.images = jsonResponse.images;
              this.enable_vibe_comments = jsonResponse.enable_vibe_comments;
              this.setState({ vibeTileLoaded: true });
            });
          }
        })
        .catch(function () {
          this.setState({ vibeTileLoaded: false });
        });
    }
  };

  componentDidMount() {
    BODY_CLASSES(['vibes', 'index', 'jobvibe-future']);
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const hasFilters = Object.keys(qsFilters).some(
      param => Object.keys(this.initialFilters).includes(param) && qsFilters[param]
    );
    if (this.props.filters.excludedSpaces?.length) {
      this.props.history.replace(
        `/?${qs.stringify({ excludedSpaces: this.props.filters.excludedSpaces })}`
      );
    }
    if (
      this.props.location.pathname === '/' &&
      (this.props.location.search === '' || !hasFilters)
    ) {
      this.props.filtersFromObject({
        ...this.initialFilters,
        excludedSpaces: this.props.filters.excludedSpaces,
      });
      this.props.resetPosts();
      this.props.fetchPosts();
    }
    window.onscroll = () => {
      if (
        this.props.loadedPostsCount !== 0 &&
        window.innerHeight + window.scrollY + this.fetchTriggerThreshold >=
          document.body.offsetHeight &&
        !this.props.fetchingPosts &&
        this.props.hasMorePostsToLoad &&
        window.scrollY > 0
      ) {
        // --last condition prevent fetch on popstate
        this.props.fetchPosts();
      }
    };
    if ('feed_tour' in qsFilters) {
      this.props.startFeedTour();
    }
    if (
      !this.props.user.visited.feed &&
      !this.props.displayOnboardingModal &&
      this.props.plan !== 'free'
    ) {
      this.props.startFeedTour();
      this.props.updateVisited('feed');
    }
    this.getVibeData();
    scroll.scrollTo(this.props.scrollPosition, { duration: 0 });
  }

  componentWillUnmount() {
    this.props.saveScroll(window.scrollY);
    window.onscroll = () => {};
  }

tileRealignHandler() {
  // check if the state is already true then don't re-render to prevent infinite loop
  if (!this.state.postRenderAlignment) {
    this.setState({ postRenderAlignment: true });
  }
}
  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    e.preventDefault();
    this.props.history.push(
      `?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: '',
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  shouldShowPost = spaceId => {
    const { spaces } = this.props;
    return spaces.map(space => space.id).includes(spaceId);
  };

  render() {
    let vibeTile = null;
    if (
      this.state.vibeTileLoaded &&
      !this.props.user.alreadyVibed &&
      this.props.user.isVibeTileVisible &&
      this.props.plan !== 'free'
    ) {
      vibeTile = (
        <VibeTile
          fetchPostsOnClose={false}
          vibe_factors={this.vibe_factors}
          feel_factors={this.feel_factors}
          links={this.links}
          scale={this.scale}
          raw_text={this.raw_text}
          images={this.images}
          enable_vibe_comments={this.enable_vibe_comments}
          vibeTileLoaded={this.state.vibeTileLoaded}
          tileRealignHandler={this.tileRealignHandler.bind(this)}
        />
      );
    }
    return (
      <div className="vibe-index">
        <div className="heelix-feed-wrap">
          <div className="create-and-masonry-wrap">
            <SlackModal />
            <div className="row post-feed-wrap">
              {!this.props.posts.length &&
              !this.props.hasMorePostsToLoad &&
              !this.props.user.should_see_sample_data ? (
                !this.props.user.alreadyVibed && this.props.user.isVibeTileVisible ? (
                  vibeTile
                ) : (
                  <NoResultsPage user={this.props.user} space={this.props.team} />
                )
              ) : (
                <PostsMasonry
                  posts={this.props.posts}
                  tileRealignHandler={this.tileRealignHandler.bind(this)}
                  user={this.props.user}
                  hasMorePostsToLoad={this.props.hasMorePostsToLoad}
                  shouldShowPost={this.shouldShowPost}
                  mentionClickedHandler={this.mentionClickedHandler}
                  vibeTile={vibeTile}
                />
              )}
              {this.props.hasMorePostsToLoad ? <Loader /> : ''}
            </div>
            {!this.props.user.hideSamplePosts && (
              <SamplePostsBanner hideSamplePosts={() => this.props.hideSamplePosts()} />
            )}
          </div>
          <HomeFeedRightPanel />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeFeed);
