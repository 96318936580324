/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import BlueCheckmark from './shared/BlueCheckmark.svg';
import BluePlus from './shared/BluePlus.svg';

const plans = [
  {
    first: true,
    key: 'free',
    name: 'Free',
    shortDescription:
      'All basic features including the ability to collect team feedback in real-time, manage performance and connect your team',
    priceString: true,
    price: 'Free',
    priceDescription: 'Free for up to 10 users',
    pointsIcon: BlueCheckmark,
    points: [
      'Real-time data & insights',
      'Plan meetings, track next steps, create goals, notes and wins',
      'Social recognition, team collaboration and knowledge sharing',
      'Automated recommendations for managers',
    ],
    features: [
      {
        name: 'Vibe & Insights',
        description: 'Just the basic information and dashboard',
        points: [
          'Insights Dashboard',
          'Automated recommendations for managers',
          'Unlimited history',
        ],
      },
      {
        name: 'Grow',
        description: 'Limited',
        points: [
          'Progress board to manage all your tasks in one place',
          'Up to 8 notes per month',
          'Up to 8 manager notes per month',
          'Up to 8 goals per month',
          'Up to 8 wins per month',
          'Up to 8 meetings per month',
        ],
      },
      {
        name: 'Feed',
        description: 'Everything you need to collaborate in one space',
        points: [
          '1 x Space to collaborate with your team',
          '4 shoutouts per person per month',
          'Posts / file sharing',
          'Start/stop/continue retrospective functionality',
          'Public Polls',
        ],
      },
      {
        name: 'User Management',
        description: 'Regular setup and onboarding',
        points: ['User, team and online self setup'],
      },
      {
        name: 'Data, access & support',
        description: 'All your data and basic online support',
        points: [
          'Google Drive',
          'Youtube',
          'Spotify',
          'Wistia',
          'Unlimited data and storage',
          '24/7 access to online support resources',
          'Google Sign On',
          'Apple Sign On',
        ],
      },
    ],
  },
  {
    key: 'pro',
    name: 'Connect & Perform',
    shortDescription:
      'Rich dashboards and insights to evaluate trends over time, run dynamic polls, greater access to performance tools, integrations, support and more',
    priceNumber: true,
    priceDescription: 'per month, billed annually',
    pointsIcon: BluePlus,
    points: [
      'Factor analysis, mood over time, mood vs factor and more',
      'Run single and mutli-question polls',
      'Unlock additional meetings, goals, notes and wins',
      'Integrations to connect the different tools you use',
    ],
    features: [
      {
        name: 'Vibe & Insights',
        description: 'Dashboard and advanced analytics',
        points: [
          'Insights Dashboard',
          'Analyse key performance metrics',
          'View how you track for key metrics over time',
          'Understand how the team mood changes over time',
          'Compare team mood vs key performance metrics',
          'Unlimited history',
          'Automated recommendations for managers',
        ],
      },
      {
        name: 'Grow',
        description: 'Unlimited notes, limited meetings',
        points: [
          'Progress board to manage all your tasks in one place',
          'Unlimited note creation',
          'Unlimited manager notes',
          'Up to 50 goals and tracking per month',
          'Up to 50 wins per month',
          'Up to 50 meetings per month',
        ],
      },
      {
        name: 'Feed',
        description: 'Gain more insight with questionnaires & collaborate in multiple spaces',
        points: [
          'Unlimited Spaces',
          '4 shoutouts per person per month',
          'Posts / file sharing',
          'Start/stop/continue retrospective functionality',
          'Public Polls',
          'Questionnaires',
          'Private Polls',
        ],
      },
      {
        name: 'User Management',
        description: 'Greater visibility on the setup of your team',
        points: ['User, team and online self setup', 'Dynamic organisational chart'],
      },
      {
        name: 'Data, access & support',
        description: 'Unlimited data, dedicated support + integrations',
        points: [
          'Slack',
          'Google Drive',
          'Youtube',
          'Spotify',
          'Wistia',
          'Google Calendar',
          'Unlimited data and storage',
          '24/7 access to online support resources',
          'Google Sign On',
          'Apple Sign On',
          'Support (24 hours SLA)',
        ],
      },
    ],
  },
  {
    key: 'plus',
    name: () => (
      <span>
        <span>Connect & Perform&nbsp;</span>
        <span
          css={css`
            color: #fe483c;
          `}
        >
          Pro
        </span>
      </span>
    ),
    shortDescription:
      'All the features of the Connect & Perform plan with no restrictions: unlimited performance features & integrations, dedicated setup & support',
    priceNumber: true,
    priceDescription: 'per month, billed annually',
    pointsIcon: BluePlus,
    points: [
      'Unlimited access to all performance features',
      'Unlimited integrations',
      'Advanced support and personalised setup to get your team up running',
      'Advanced insight analysis and heatmaps',
    ],
    features: [
      {
        name: 'Vibe & Insights',
        description: 'All advanced analytics plus additional team tools',
        points: [
          'Advanced insight analysis (filter by all teams, vibe, factor)',
          'Heatmap views to identify key strengths and areas for improvement',
          'Compare teams side-by-side',
          'Insights Dashboard',
          'Analyse key performance metrics',
          'View how you track for key metrics over time',
          'Understand how the team mood changes over time',
          'Compare team mood vs key performance metrics',
          'Unlimited history',
          'Automated recommendations for managers',
        ],
      },
      {
        name: 'Grow',
        description: 'Unlimited everything',
        points: [
          'Progress board to manage all your tasks in one place',
          'Unlimited note creation',
          'Unlimited manager notes',
          'Unlimited goal creation and tracking',
          'Unlimited win creation',
          'Unlimited meetings',
        ],
      },
      {
        name: 'Feed',
        description: 'All features + more manager benefits',
        points: [
          'Unlimited Spaces',
          '4 shoutouts per person per month',
          'Additional shoutouts for managers (1 per team member p/m)',
          'Posts / file sharing',
          'Start/stop/continue retrospective functionality',
          'Public Polls',
          'Questionnaires',
          'Private Polls',
        ],
      },
      {
        name: 'User Management',
        description: 'All features with more to come',
        points: ['User, team and online self setup', 'Dynamic organisational chart'],
      },
      {
        name: 'Data, access & support',
        description: 'All features plus personalised setup and early access',
        points: [
          'Slack',
          'Google Drive',
          'Youtube',
          'Spotify',
          'Wistia',
          'Google Calendar',
          'Unlimited data and storage',
          '24/7 access to online support resources',
          'Google Sign On',
          'Apple Sign On',
          'Support (12 hours SLA)',
          '2 x 1 hour online set up with one of our customer success team',
          'Early access to product releases & beta testing',
        ],
      },
    ],
  },
];

export default plans;
