import React, { Component } from 'react';

const userText = userData => {
  return userData.role ? `${userData.role}, ${userData.team.name}` : userData.team.name;
};

class MentionUserTooltip extends Component {
  render = () => (
    <div className="mention-tooltip">
      <img src={this.props.userData.image_url} alt="user_data" />
      <div className="user-info-wrap">
        <span>
          {this.props.userData.first_name} {this.props.userData.last_name}
        </span>
        <span>{userText(this.props.userData)}</span>
      </div>
    </div>
  );
}

export default MentionUserTooltip;
