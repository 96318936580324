/** @jsxImportSource @emotion/react */
import Page from 'components/layouts/Page';
import { css, jsx } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import LargeSpeedometer from 'components/vibes/team-vibe-show/TeamVibeDashboard/LargeSpeedometer';
import {
  METRICS,
  METRICS_TOOLTIPS,
  ScoreContainer,
  ScoreHistoryWrap,
  SpeedometerWrap,
} from 'pages/Insights/Dashboard';
import fetchApi from 'fetchApi';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import ScoreHistoryChart from 'components/vibes/ScoreHistoryChart';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import VibeInfo from 'pages/Insights/Dashboard/VibeInfo';
import SmallSpeedometer from 'components/vibes/team-vibe-show/TeamVibeDashboard/SmallSpeedometer';
import { PerformanceMetric } from 'pages/Insights/Dashboard/PerformanceMetric';
import PerformanceTooltips from 'pages/Insights/Dashboard/PerformanceTooltips';
import {
  BackArrowStyled,
  BackButton,
  ColoredSpan,
  DateRangeStyled,
  Header,
  INSIGHTS_COMPARISON_COLOR_1,
  INSIGHTS_COMPARISON_COLOR_2,
  PerformanceContainerStyled,
  PerformanceMetricsHeader,
  StatsContainer,
  TeamSelectStyled,
  Title,
  TopSection,
  TopVs,
} from 'pages/Insights/OrganisationInsights/ScoreComparison/layout';

const ScoreComparison = ({ location, history }) => {
  const [data, setData] = useState(null);
  const fetchData = async (query = {}) => {
    const data = await fetchApi(`/organisation_insights/comparison?${qs.stringify(query)}`);
    setData(data);
  };

  const params = useCallback(qs.parse(location.search, { ignoreQueryPrefix: true }), [
    location.search,
  ]);

  useEffect(() => {
    fetchData({
      ...params,
    });
  }, [params]);

  return !data ? null : (
    <>
      <Header>
        <BackButton onClick={() => history.goBack()}>
          <BackArrowStyled />
          Back
        </BackButton>
        <Title>Compare Heelix score</Title>
      </Header>
      <Page.Paper
        cssProps={css`
          position: relative;
        `}
      >
        <TopSection>
          <TeamSelectStyled
            queryString={params}
            tabLink={location.pathname}
            teamString="team1"
            coloredSquare={INSIGHTS_COMPARISON_COLOR_1}
          />
          <TopVs>vs</TopVs>
          <TeamSelectStyled
            queryString={params}
            tabLink={location.pathname}
            teamString="team2"
            coloredSquare={INSIGHTS_COMPARISON_COLOR_2}
          />
          <DateRangeStyled queryString={params} tabLink={location.pathname} hideThisWeek />
        </TopSection>
        <ScoreContainer>
          <SpeedometerWrap>
            <LargeSpeedometer
              value={data.team1.vibe.current}
              comparisonMode
              value2={data.team2.vibe.current}
              title="Vibe score"
            />
          </SpeedometerWrap>
          <ScoreHistoryWrap>
            <ScoreHistoryChart
              queryString={params}
              comparisonMode
              vibeStats={data.team1.vibeStats}
              vibeStats2={data.team2.vibeStats}
            />
          </ScoreHistoryWrap>
        </ScoreContainer>
        <StatsContainer>
          <div>
            <VibeInfo vibe={data.team1.vibe.overall}>
              Your overall team score for{' '}
              <ColoredSpan color={INSIGHTS_COMPARISON_COLOR_1}>{data.team1.name}</ColoredSpan> is{' '}
            </VibeInfo>
            <VibeInfo vibe={data.team2.vibe.overall}>
              Your overall team score for{' '}
              <ColoredSpan color={INSIGHTS_COMPARISON_COLOR_2}>{data.team2.name}</ColoredSpan> is{' '}
            </VibeInfo>
          </div>
          <div>
            <SmallSpeedometer
              className="engagement active"
              value={Math.floor(data.team1.engagement.current)}
              value2={Math.floor(data.team2.engagement.current)}
              comparisonMode
              previousValue={Math.floor(data.team1.engagement.previous)}
              description="How many people have vibed over the period"
              title="Participation"
            />
            <SmallSpeedometer
              className="active-users"
              value={Math.floor(data.team1.activeUsers.current)}
              value2={Math.floor(data.team2.activeUsers.current)}
              previousValue={Math.floor(data.team1.activeUsers.previous)}
              comparisonMode
              description="How many users have logged in at least once over the last four weeks"
              title="Monthly active users"
            />
          </div>
        </StatsContainer>
        <PerformanceMetricsHeader>Key performance metrics</PerformanceMetricsHeader>
        <PerformanceContainerStyled>
          {Object.keys(METRICS).map((key, index) => {
            return (
              <PerformanceMetric
                key={key}
                label={METRICS[key]}
                value={data.team1?.keyPerformanceMetrics?.[key]?.current}
                value2={data.team2?.keyPerformanceMetrics?.[key]?.current}
                tooltip={METRICS_TOOLTIPS[index]}
                comparisonMode
              />
            );
          })}
        </PerformanceContainerStyled>
      </Page.Paper>
      <PerformanceTooltips />
    </>
  );
};

export default withRouter(ScoreComparison);
