import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class AnalyticsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      times: [],
      stats: [],
      startDate: '',
      endDate: '',
      year: 0,
      week: 0,
      answers: [],
      notificationFilterColumn: 'sent_at',
      names: [],
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    const year = params.year ? params.year : this.state.year;
    const week = params.week ? params.week : this.state.week;
    let notificationFilterColumn = null;
    if (this.props.location.search) {
      notificationFilterColumn = this.props.location.search;
      this.setState({ notificationFilterColumn: notificationFilterColumn.split('=')[1] });
    }
    this.fetchData(year, week, notificationFilterColumn);
  }

  fetchData(year, week, search = null) {
    const notificationFilterColumn =
      search || `?notifications_filter_column=${this.state.notificationFilterColumn}`;
    const url = `/admin/analytics/${year}/${week}${notificationFilterColumn}`;
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            startDate: jsonResponse.startDate,
            endDate: jsonResponse.endDate,
            week: jsonResponse.week,
            year: jsonResponse.year,
            stats: jsonResponse.stats,
            answers: jsonResponse.answers,
            days: jsonResponse.days,
            times: jsonResponse.times,
            names: jsonResponse.names,
          });
          const url = `/admin/analytics/${jsonResponse.year}/${jsonResponse.week}${notificationFilterColumn}`;
          if (url !== this.props.location.pathname + this.props.location.search) {
            this.props.history.push(url);
          }
        });
      })
      .catch(() => {});
  }

  titleize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  prevWeek(e) {
    e.preventDefault();
    let week = parseInt(this.state.week);
    let year = parseInt(this.state.year);
    if (week > 1) {
      week -= 1;
      this.setState({ week });
    } else {
      year -= 1;
      week = 53;
      this.setState({ year });
      this.setState({ week });
    }
    this.fetchData(year, week);
  }

  nextWeek(e) {
    e.preventDefault();
    let week = parseInt(this.state.week);
    let year = parseInt(this.state.year);
    if (week < 53) {
      week += 1;
      this.setState({ week });
    } else {
      year += 1;
      week = 1;
      this.setState({ year });
      this.setState({ week });
    }
    this.fetchData(year, week);
  }

  responseColour(percent) {
    if (percent >= 0 && percent <= 30) {
      return '#CD3C40';
    }
    if (percent >= 31 && percent <= 70) {
      return '#FBAA00';
    }
    if (percent >= 71 && percent <= 100) {
      return '#26C381';
    }
  }

  setFilterColumn(e, column) {
    e.preventDefault();
    const search = `?notifications_filter_column=${column}`;
    this.setState({ notificationFilterColumn: column });
    this.fetchData(this.state.year, this.state.week, search);
  }

  render() {
    return (
      <div>
        <h1>Analytics</h1>
        <h2>
          {this.state.startDate} - {this.state.endDate}
        </h2>
        <a href="/#" onClick={e => this.prevWeek(e)}>
          Previous Week
        </a>
        &nbsp;-&nbsp;
        <a href="/#" onClick={e => this.nextWeek(e)}>
          Next Week
        </a>
        <div className="pull-right">
          <strong>Showing results for: </strong>
          <a
            href="/#"
            className={
              this.state.notificationFilterColumn === 'email_delivered_at'
                ? 'invisible-link no-underline'
                : ''
            }
            onClick={e => this.setFilterColumn(e, 'email_delivered_at')}
          >
            Delivered
          </a>
          &nbsp;-&nbsp;
          <a
            href="/#"
            className={
              this.state.notificationFilterColumn === 'sent_at' ? 'invisible-link no-underline' : ''
            }
            onClick={e => this.setFilterColumn(e, 'sent_at')}
          >
            Enqueued
          </a>
        </div>
        <h3 className="u-margin-top-md">Vibe Notifications</h3>
        <table className="table">
          <tbody>
            <tr>
              <th />
              {this.state.names.map(function (name, key) {
                {
                  return (
                    Object.keys(this.state.days).length > 0 && (
                      <th key={key}>
                        <strong>
                          <Link
                            to={`/admin/analytics/${this.state.days[name].date}/show?notifications_filter_column=${this.state.notificationFilterColumn}`}
                          >
                            {name}
                          </Link>
                          &nbsp;(
                          {this.state.days[name].count})
                        </strong>
                      </th>
                    )
                  );
                }
              }, this)}
            </tr>
            {this.state.times.map((time, key) => (
              <tr key={key}>
                <td>
                  <strong>{time}</strong>
                </td>
                {this.state.names.map(function (name, key) {
                  return (
                    <td key={key}>
                      {this.state.days[name].data.map(function (day, key) {
                        return (
                          time === day.time && (
                            <span key={key}>
                              {day.user_ids.length}
                              &nbsp;
                              {day.user_ids.length > 0 ? (
                                <span style={{ color: this.responseColour(day.percent) }}>
                                  ({day.percent}
                                  %)
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          )
                        );
                      }, this)}
                    </td>
                  );
                }, this)}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <h3>Stats</h3> */}
        {/* <table className="table"> */}
        {/* <tbody> */}
        {/* <tr> */}
        {/* {this.state.stats.map((stat, key) => */}
        {/* <td key={key}> */}
        {/* <strong>{this.titleize(stat.state)}: {stat.total}</strong> */}
        {/* </td> */}
        {/* )} */}
        {/* </tr> */}
        {/* </tbody> */}
        {/* </table> */}
      </div>
    );
  }
}

export default AnalyticsIndex;
