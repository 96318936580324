/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/react';
import { PromptModal } from 'components/payments/prompts/layout';
import SwitchToAnnualModal from 'components/payments/prompts/SwitchToAnnualModal';
import { connect } from 'react-redux';
import fetchApi from 'fetchApi';

const AnnualBillingPrompt = ({ displayAnnualUpgradeModal }) => {
  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    fetchApi('/alerts/annual_upgrade_modal', { method: 'delete' });
  };

  const openModal = () => {
    setShowModal(true);
    setShow(false);
    hideModal();
  };

  useEffect(() => {
    if (displayAnnualUpgradeModal) {
      setShow(true);
    }
  }, [displayAnnualUpgradeModal]);

  if (!show && !showModal) return null;

  return showModal ? (
    <SwitchToAnnualModal show={showModal} setShow={setShowModal} />
  ) : (
    <PromptModal>
      <PlanModal.TopBar color="#8ED04C" />
      <PlanModal.CloseButton
        onClick={() => {
          setShow(false);
          hideModal();
        }}
      />
      <PlanModal.Content>
        <PlanModal.Title>
          <PlanModal.Pig
            css={css`
              width: 24px;
              height: 24px;
              margin-right: 12px;
            `}
          />
          Switch to annual billing for more flexibility
        </PlanModal.Title>
        <PlanModal.Button
          css={css`
            background-color: #52ba05;
            margin-top: 20px;
          `}
          onClick={openModal}
        >
          TELL ME MORE
        </PlanModal.Button>
      </PlanModal.Content>
    </PromptModal>
  );
};
const mapState = state => ({
  displayAnnualUpgradeModal: state.plansDomain.displayAnnualUpgradeModal,
});

export default connect(mapState)(AnnualBillingPrompt);
