import React from 'react';
import { NotesWrap, Title, Wrap } from 'components/vibes/team-vibe-show/partials/RightPanel/layout';
import ListWrap from 'components/vibes/team-vibe-show/partials/ListWrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { handleSeeMore, noteShape } from 'pages/Grow/GrowPost/ManagerPrompts/common';
import { setShowActionModal } from 'redux/app/actions';
import { ReactComponent as ViewDetailedIcon } from './view-detailed.svg';

const NotesWrapStyled = styled(NotesWrap)`
  margin-bottom: 10px;
`;

const StyledWrap = styled(Wrap)`
  margin-top: 20px;
`;

const StyledTitle = styled(Title)`
  display: flex;
`;

const ViewDetailed = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #11a9ff;
  cursor: pointer;
`;

const ViewDetailedIconStyled = styled(ViewDetailedIcon)`
  margin-left: 10px;
  margin-top: 4px;
`;

const ManagerPromptPanel = ({
  preparationNote,
  setShowActionModal,
  history,
  resetActionModalTab,
}) => {
  const viewDetailed = () => {
    setShowActionModal(false);
    handleSeeMore(
      history.push,
      preparationNote.action_plan.team_id,
      preparationNote.action_plan.factor_position
    );
    resetActionModalTab();
  };

  return (
    <StyledWrap>
      <StyledTitle>
        Insights
        <ViewDetailed onClick={viewDetailed}>
          View detailed
          <ViewDetailedIconStyled />
        </ViewDetailed>
      </StyledTitle>
      <NotesWrapStyled>{preparationNote.additional_notes}</NotesWrapStyled>
      <ListWrap steps={preparationNote.insights} />
      <Title>Suggested actions</Title>
      <ListWrap steps={preparationNote.steps} />
    </StyledWrap>
  );
};

ManagerPromptPanel.propTypes = {
  setShowActionModal: PropTypes.func.isRequired,
  preparationNote: PropTypes.shape({
    steps: PropTypes.array.isRequired,
    insights: PropTypes.array.isRequired,
    additional_notes: PropTypes.string.isRequired,
    ...noteShape,
  }).isRequired,
};

const mapDispatch = {
  setShowActionModal,
};
const mapStateToProps = state => ({
  preparationNote: state.app.body.preparationNote,
});

export default connect(mapStateToProps, mapDispatch)(withRouter(ManagerPromptPanel));
