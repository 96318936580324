/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import Select from 'react-select';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS, findLabel } from '../../../helpers';

const Wrapper = styled.div`
  margin-top: 55px;
`;

const ButtonsWrap = styled.div`
  margin-top: 10px;
`;

const Button = styled.div`
  margin-right: 10px;
`;

const CrossWrapper = styled.div`
  margin-top: 70px;
`;

const MessageWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  transition: 0.2s 0.2s height, 0.2s opacity;
`;

const ErrorWrap = styled.div`
  color: #ff0000;
`;

const SuccessWrap = styled.div`
  color: #11a9ff;
`;

const ColumnWrapper = styled.div`
  width: 18%;
  margin-right: 24px;
  margin-top: 12px;

  p {
    margin-bottom: 10px;
  }

  input {
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    outline: none;
    font-size: 15px;
  }

  .Select-control {
    padding: 4px;
  }

  .Select-value {
    padding-top: 4px;
  }
`;

const Cross = styled.div`
  cursor: pointer;
  position: absolute;
  display: inline-block;
  transform: translateY(-50%);

  &:before,
  &:after {
    border-radius: 2px;
    width: 10px;
    height: 2px;
    background: #c4c4c4;
    content: '';
    position: absolute;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const UsersIndex = () => {
  const [rowsCount, setRowsCount] = useState(1);
  const [generalFailureMessage, setGeneralFailureMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [showOmittedMessage, setShowOmittedMessage] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [plans, setPlans] = useState([]);
  const formValueTemplate = {
    email: '',
    first_name: '',
    last_name: '',
    capacity: '',
    plan_id: '',
    name: '',
    allow_provisioning: false,
  };
  const [formValues, setFormValues] = useState([formValueTemplate]);
  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/admin/plans`, { method: 'get', headers });
    const json = await response.json();
    setPlans(json);
  };

  const setRowValue = (name, index, target) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const formValuesArr = formValues.map((values, valuesIndex) =>
      valuesIndex === index
        ? {
            ...values,
            [name]: value,
          }
        : values
    );
    setFormValues(formValuesArr);
  };

  const checkIfAccountNameContainsLetter = valuesSets => {
    const hasErrorMessage = valuesSets.find(
      valuesSet => !valuesSet.name.match(/([a-zA-Z])+([ -~])*/)
    );
    hasErrorMessage && setGeneralFailureMessage('Account name should contain at least one letter');
    return hasErrorMessage;
  };

  const checkIfCapacityIsPositive = valuesSets => {
    const hasErrorMessage = valuesSets.find(valuesSet => valuesSet.capacity < 1);
    hasErrorMessage && setGeneralFailureMessage('Plan capacity should be greater than 0');
    return hasErrorMessage;
  };

  const checkIfHasErrorMessages = valuesSets => {
    const hasErrorMessage = valuesSets.find(
      valuesSet =>
        !valuesSet.first_name ||
        !valuesSet.last_name ||
        !valuesSet.email ||
        !valuesSet.plan_id ||
        !valuesSet.name ||
        !valuesSet.capacity
    );
    setShowOmittedMessage(hasErrorMessage);
    return hasErrorMessage;
  };

  const sendData = () => {
    if (!disabledButton) {
      setShowOmittedMessage(false);
      setGeneralFailureMessage(false);
      setGeneralSuccessMessage(false);
      setDisabledButton(true);
      const users = [];
      if (
        !checkIfHasErrorMessages(formValues) &&
        !checkIfAccountNameContainsLetter(formValues) &&
        !checkIfCapacityIsPositive(formValues)
      ) {
        formValues.forEach(valuesSet => {
          users.push({
            email: valuesSet.email.trim().toLowerCase(),
            first_name: valuesSet.first_name.trim(),
            last_name: valuesSet.last_name.trim(),
            plan_id: valuesSet.plan_id,
            capacity: valuesSet.capacity,
            name: valuesSet.name.trim(),
            with_saml: valuesSet.with_saml,
            idp_name: valuesSet.idp_name,
            entity_id: valuesSet.entity_id,
            sso_url: valuesSet.sso_url,
            x509cert: valuesSet.x509cert,
            allow_provisioning: valuesSet.allow_provisioning,
          });
        });
        const headers = HEADERS();
        headers.append('Content-Type', 'application/json');
        fetch(`${APP_URL}/admin/users`, { method: 'post', headers, body: JSON.stringify(users) })
          .then(response => CHECK_UNAUTHORIZED(response))
          .then(response => {
            const { ok } = response;
            response.json().then(jsonResponse => {
              if (ok) {
                setRowsCount(1);
                setFormValues([formValueTemplate]);
                setShowOmittedMessage(false);
                setGeneralFailureMessage(null);
                setGeneralSuccessMessage(jsonResponse.message);
              } else if (jsonResponse.message) {
                setGeneralFailureMessage(jsonResponse.message);
              } else {
                setShowOmittedMessage(true);
              }
              setDisabledButton(false);
            });
          });
      } else {
        setDisabledButton(false);
      }
    }
  };

  const removeRow = rowId => {
    const newFormValues = formValues.filter((row, rowIndex) => rowIndex !== rowId);
    setRowsCount(rowsCount - 1);
    setFormValues(newFormValues);
  };

  return (
    <Wrapper>
      <h1>Invite account admin</h1>
      <div className="form-wrap">
        {[...Array(rowsCount)].map((object, index) => (
          <div key={index}>
            <div
              className="form-row-wrap"
              css={css`
                display: flex;
              `}
            >
              <ColumnWrapper>
                <p>Email</p>
                <input
                  value={formValues[index].email}
                  onChange={e => setRowValue('email', index, e.target)}
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <p>First name</p>
                <input
                  value={formValues[index].first_name}
                  onChange={e => setRowValue('first_name', index, e.target)}
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <p>Last name</p>
                <input
                  value={formValues[index].last_name}
                  onChange={e => setRowValue('last_name', index, e.target)}
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <p>Account name</p>
                <input
                  value={formValues[index].name}
                  onChange={e => setRowValue('name', index, e.target)}
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <p>Plan</p>
                <Select
                  name="invites[plan_id]"
                  onChange={e => setRowValue('plan_id', index, e)}
                  value={{ value: formValues[index].plan_id, label: findLabel( formValues[index].plan_id, plans) }}
                  options={plans}
                  isClearable={false}
                  isSearchable={false}
                  placeholder=""
                  classNamePrefix="r-select"
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <p>Plan capacity</p>
                <input
                  type="number"
                  min="0"
                  value={formValues[index].capacity}
                  onChange={e => setRowValue('capacity', index, e.target)}
                />
              </ColumnWrapper>
              <CrossWrapper>
                {rowsCount > 1 && <Cross onClick={() => removeRow(index)} />}
              </CrossWrapper>
            </div>
            <div
              className="form-row-wrap"
              css={css`
                display: flex;
              `}
            >
              {!formValues[index].with_saml ? (
                <button
                  css={css`
                    align-self: flex-end;
                    margin-top: 5px;
                  `}
                  className="btn btn-default"
                  onClick={() => {
                    setRowValue('with_saml', index, { value: true });
                  }}
                >
                  Add saml configuration
                </button>
              ) : (
                <>
                  <ColumnWrapper>
                    <p>IDP name</p>
                    <input
                      value={formValues[index].idp_name}
                      onChange={e => setRowValue('idp_name', index, e.target)}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper>
                    <p>Entity ID</p>
                    <input
                      value={formValues[index].entity_id}
                      onChange={e => setRowValue('entity_id', index, e.target)}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper>
                    <p>SSO url</p>
                    <input
                      value={formValues[index].sso_url}
                      onChange={e => setRowValue('sso_url', index, e.target)}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper>
                    <p>x509 Certificate</p>
                    <input
                      value={formValues[index].x509cert}
                      onChange={e => setRowValue('x509cert', index, e.target)}
                    />
                  </ColumnWrapper>
                  <ColumnWrapper>
                    <p>Allow JIT Provisioning</p>
                    <input
                      checked={Boolean(formValues[index].allow_provisioning)}
                      type="checkbox"
                      onChange={e => setRowValue('allow_provisioning', index, e.target)}
                    />
                  </ColumnWrapper>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <MessageWrap>
        <ErrorWrap>
          {showOmittedMessage &&
            'You’re missing a mandatory field, please review and complete to add a new user.'}
        </ErrorWrap>
        <ErrorWrap>{generalFailureMessage}</ErrorWrap>
        <SuccessWrap>{generalSuccessMessage}</SuccessWrap>
      </MessageWrap>
      <ButtonsWrap>
        <Button
          className="btn btn-default"
          onClick={() => {
            setRowsCount(rowsCount + 1);
            setFormValues([...formValues, formValueTemplate]);
          }}
        >
          Add another user
        </Button>
        <Button className="btn btn-primary" onClick={sendData} disabled={disabledButton}>
          Invite
        </Button>
      </ButtonsWrap>
    </Wrapper>
  );
};
