import Dropdown, { DropdownWrap } from 'components/shared/Dropdown';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { GROW_ACTIONS } from 'redux/actions/growActions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { getUrl, HEADERS } from '../../helpers';
import { clearActionBody, setAction, setShowActionModal } from '../../redux/app/actions';

const Option = styled.div`
  padding: 9px 28px 8px;
  font-size: 14px;
  color: #11a9ff;
  font-weight: normal;
`;

const ScheduleMeetingDropdown = ({
  meeting,
  resetPosts,
  fetchPosts,
  fetchMeetings,
  clearActionBody,
  setAction,
  setShowActionModal,
  setMeetingSettings,
}) => {
  const handleEdit = e => {
    e.preventDefault();
    setMeetingSettings(meeting);
    setShowActionModal(true);
    clearActionBody();
    setAction('create-note-meeting-schedule', { converting: 'edit' });
  };

  const handleDisable = async () => {
    const headers = HEADERS();
    const response = await fetch(getUrl(`meeting_settings/${meeting.id}`), {
      method: 'delete',
      headers,
    });
    const json = await response.json();
    if (json.disabled) {
      fetchMeetings && fetchMeetings();
      resetPosts();
      fetchPosts();
    }
  };

  return (
    <DropdownWrap>
      <Dropdown stopPropagation={false}>
        <Option onClick={e => handleEdit(e)}>Edit</Option>
        <Option onClick={() => handleDisable()}>Disable</Option>
      </Dropdown>
    </DropdownWrap>
  );
};

const mapDispatch = {
  resetPosts: () => GROW_ACTIONS.resetPosts(),
  fetchPosts: () => GROW_ACTIONS.fetchPosts(),
  setMeetingSettings: meetingSettings => ONBOARDING_ACTIONS.setMeetingSettings(meetingSettings),
  setAction,
  setShowActionModal,
  clearActionBody,
};
export default connect(null, mapDispatch)(ScheduleMeetingDropdown);
