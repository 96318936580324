/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import { HEADERS, LOCALSTORAGE_GET, getUrl } from 'helpers';
import OnboardingSamplePosts from '../../onboarding/OnboardingSamplePosts';
import OnboardingResources from '../../onboarding/OnboardingResources';
import OnboardingTour from '../../onboarding/OnboardingTour';

const MenuWrap = styled.div`
  height: 55px;
  margin-right: 50px;
  overflow: hidden;
  @media (max-width: 384px) {
    margin-right: 0;
  }
  @media (max-width: 331px) {
    overflow-x: scroll;
  }
`;

const Menu = styled.div`
  overflow-x: scroll;
  padding: 2px 0px 10px 12px;
  white-space: nowrap;
  display: flex;
`;

const MenuElement = ({ active, ...props }) => (
  <div
    css={css`
      padding: 18px 22px 15px 20px;
      display: inline-block;
      transition: color 0.3s, text-shadow 0.3s;
      text-shadow: none;
      font-size: 15px;
      line-height: 18px;
      font-weight: normal;
      white-space: nowrap;
      letter-spacing: 0.1px;
      position: relative;
      cursor: pointer;
      ${active &&
      css`
        color: #11a9ff;
        font-weight: bold;
        :after {
          width: calc(100% - 40px);
          height: 2px;
          position: absolute;
          content: '';
          transition: 0.3s width;
          bottom: -1px;
          left: 20px;
          background: #11a9ff;
        }
      `}
    `}
    {...props}
  />
);

const Shadow = styled.div`
  height: 9px;
  width: calc(100% - 1px);
  position: absolute;
  background: linear-gradient(180deg, #f3f3f3 0%, rgba(239, 239, 239, 0) 100%);
  mix-blend-mode: multiply;
`;

const Tab = ({ tab, onboardingData, finished, resetChecklist, setOpenOnboarding }) => {
  switch (tab) {
    case 'resources-tab':
      return <OnboardingResources />;
    case 'tour-tab':
      return (
        <OnboardingTour
          onboardingData={onboardingData}
          finished={finished}
          resetChecklist={resetChecklist}
          setOpenOnboarding={setOpenOnboarding}
        />
      );
    case 'demo-tab':
    default:
      return <OnboardingSamplePosts />;
  }
};

const Onboarding = ({ open, onClose, setOpenOnboarding }) => {
  const [tab, setTab] = useState('resources-tab');
  const [onboardingData, setOnboardingData] = useState([]);
  const [finished, setFinished] = useState([false]);
  useEffect(() => {
    const accessToken = LOCALSTORAGE_GET('access_token');
    const headers = HEADERS(accessToken);
    fetch(getUrl('onboarding_checklist_v2'), {
      method: 'get',
      headers,
    })
      .then(response => {
        return response.json();
      })
      .then(({ onboardingData, finished }) => {
        setOnboardingData(onboardingData);
        setFinished(finished);
      })
      .catch(console.error);
  }, [open]);

  const resetChecklist = (data, finished) => {
    setFinished(finished);
    setOnboardingData(data);
  };

  return (
    <RootCloseWrapper disabled={!open} onRootCloseWrapper={onClose}>
      <div
        css={css`
          max-height: 70vh;
          overflow-y: scroll;
          display: ${open ? 'block' : 'none'};
          background: #ffffff;
          border: 1px solid #e6e6e6;
          border-radius: 3px;
          width: ${tab === 'resources-tab' ? '600px' : '460px'};
          position: absolute;
          right: 86px;
          top: 53px;
          z-index: 100;
          color: #6a6a6a;
          margin-left: 20px;
          font-size: 19px;
          font-weight: bold;
          text-align: center;
          z-index: 1032;
          @media (max-width: 768px) {
            width: 100%;
            right: 0;
            height: auto;
          }
        `}
      >
        <MenuWrap>
          <Menu>
            <MenuElement
              tab="resources-tab"
              active={tab === 'resources-tab' ? 'true' : ''}
              onClick={() => setTab('resources-tab')}
              css={css`
                margin-right: -15px;
              `}
            >
              Knowledge base
            </MenuElement>
            <MenuElement
              tab="tour-tab"
              active={tab === 'tour-tab' ? 'true' : ''}
              onClick={() => setTab('tour-tab')}
            >
              Tour checklist
            </MenuElement>
            <MenuElement
              tab="demo-tab"
              active={tab === 'demo-tab' ? 'true' : ''}
              onClick={() => setTab('demo-tab')}
            >
              Demo content
            </MenuElement>
          </Menu>
        </MenuWrap>
        <Shadow />
        <Tab
          tab={tab}
          onboardingData={onboardingData}
          finished={finished}
          resetChecklist={resetChecklist}
          setOpenOnboarding={setOpenOnboarding}
        />
      </div>
    </RootCloseWrapper>
  );
};
export default Onboarding;
