import React, { Component } from 'react';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleUserModal } from 'redux/app/actions';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import PostDetails from 'components/shared/posts/PostDetails';
import pin from 'images/feed/pin.svg';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import PostDropdown from './PostDropdown';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import PollOptionsExpired from './PollTile/PollOptionsExpired';
import PollOptions from './PollTile/PollOptions';
import CommentInput from '../post/shared/CommentInput';
import PostTileComments from './PostTileComments';
import FavPostTooltip from '../grow/tooltips/FavPostTooltip';
import { AVATAR_ERROR } from '../../helpers';
import PostFile from './PostFile';
import favActiveBtn from '../../images/feed/star_active.png';
import favBtn from '../../images/feed/star.png';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  discussionTags: state.tagsDomain.discussionTags,
  hashtags: state.tagsDomain.hashtags,
});

const mapDispatchToProps = dispatch => ({
  pinPost: postId => dispatch(POST_ACTIONS.pinPost(postId)),
  favPost: (postId, type) => dispatch(POST_ACTIONS.favPost(postId, type)),
  votePoll: (id, data) => dispatch(POST_ACTIONS.votePoll(id, data)),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class PollTile extends Component {
  state = {
    errorAction: null,
    markingAsDone: false,
    unfollowVisible: false,
    lightboxIsOpen: false,
    currentImage: 0,

    viewAllComments: false,
    vote: false,
  };

  updatePoll = (e, id) => {
    e.preventDefault();
    const data = new FormData();
    data.append('poll[selectedOption]', id);
    if (this.props.post.id > 0) {
      this.props.votePoll(this.props.post.id, data);
    } else {
      return false;
    }
  };

  voteClick(vote) {
    this.setState({ vote });
    this.props.tileRealignHandler();
  }

  render() {
    let content = (
      <>
        <div className="post-tile-title">
          <h4 className="poll-content">
            <span>Poll: </span>
            <span
              dangerouslySetInnerHTML={{
                __html: striptags(this.props.post.content, ['a', 'br']),
              }}
            />
          </h4>
        </div>
        {this.props.post.expired ||
        this.props.post.is_poll_selected ||
        (this.props.post.is_author && !this.state.vote) ? (
          <PollOptionsExpired
            options={this.props.post.poll_options}
            poll={this.props.post}
            votes={this.props.post.votes}
          />
        ) : (
          <PollOptions
            options={this.props.post.poll_options}
            poll={this.props.post}
            updatePoll={this.updatePoll}
          />
        )}
      </>
    );

    if (this.props.post.id > 0) {
      content = <Link to={`/post/${this.props.post.id}`}>{content}</Link>;
    }

    if (this.props.post) {
      const rolePosition = [];
      if (this.props.post.user.role !== '') {
        rolePosition.push(this.props.post.user.role);
      }
      rolePosition.push(this.props.post.user.team.name);

      return (
        <div className="col-lg-6 col-xs-12 post-tile-wrap initial-hide">
          <div className={`post-tile poll-tile ${this.props.post.status}`}>
            <div className="post-tile-header">
              <div className="author-avatar">
                <img
                  alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
                  src={this.props.post.user.image_url}
                  onError={e => AVATAR_ERROR(e.target)}
                />
              </div>
              <AuthorInfo post={this.props.post} user={this.props.user} />
              {this.props.type !== 'growPost' && this.props.post.pinned ? (
                <div
                  className="pinned-post"
                  onClick={
                    this.props.user.leadTeamsIds.includes(this.props.post.audience_id) &&
                    this.props.post.id > 0
                      ? () => this.props.pinPost(this.props.post.id)
                      : null
                  }
                >
                  <img src={pin} />
                </div>
              ) : null}
              <img
                src={this.props.post.favourite ? favActiveBtn : favBtn}
                data-tooltip-id={`post_fav_status${this.props.post.id}`}
                className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
                onClick={() => this.props.favPost(this.props.post.id, this.props.type)}
                alt=""
              />
              <PostDropdown
                type={this.props.type}
                post={this.props.post}
                markAsDone={() => this.setState({ markingAsDone: true })}
                pinPost={this.props.pinPost}
              />
            </div>
            <div className="post-tile-body">
              <PostMedia
                media={this.props.post.media}
                embed={{
                  id: this.props.post.integration_video_id,
                  type: this.props.post.integration_video_type,
                }}
              />
              {content}
              {this.props.post.files.map((file, index) => (
                <PostFile file={file} key={index} />
              ))}
              {this.props.post.is_author && !this.props.post.is_poll_selected && (
                <div className="vote-btn">
                  {!this.state.vote ? (
                    <a onClick={() => this.voteClick(true)}>Vote</a>
                  ) : (
                    <a onClick={() => this.voteClick(false)}>Cancel</a>
                  )}
                </div>
              )}
              <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} />
              <CommentInput
                post={this.props.post}
                tileRealignHandler={this.props.tileRealignHandler}
                type={this.props.type}
              />
            </div>
            <div className="post-tile-comments-section">
              <div className="post-tile-comments">
                <PostTileComments
                  post={this.props.post}
                  user={this.props.user}
                  type={this.props.type}
                />
              </div>
            </div>
            <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollTile));
