/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PostFile from 'components/feed/PostFile';
import PostMedia from 'pages/Grow/GrowPost/PostMedia';
import Author from 'pages/Grow/Author';
import SharedTeam from 'pages/Grow/SharedTeam';
import CommentInput from 'components/grow/CommentInput';
import SharedWith from 'pages/Grow/SharedWith';
import NoteDropdown from 'pages/Grow/GrowPost/NoteDropdown/NoteDropdown';
import Comments from 'pages/Grow/GrowPost/Comments';
import * as Layout from 'pages/Grow/NoteLayout';
import { connect } from 'react-redux';
import ConfirmButton from 'pages/Grow/ConfirmButton';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import striptags from 'striptags';

import { getFollowUp } from 'helpers.js';
import { handleEdit } from 'pages/Grow/GrowPost/NoteDropdown/Convert';
import { setAction, setShowActionModal, toggleNoteModal } from 'redux/app/actions';
import MeetingSection from 'pages/Grow/Meeting/MeetingSection';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import { ReactComponent as CalendarIcon } from './Calendar.svg';

const ConfirmButtonWrap = styled.div`
  width: 106px;
  margin-left: auto;
`;
const GrowPostTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 0 24px;
  word-break: break-word;
  ${({ isResolved }) =>
    isResolved &&
    css`
      color: #52ba05;
    `};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
`;
const StepLink = styled.a`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #11a9ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #11a9ff;
    text-decoration: none;
  }
`;
const StepDateWrapper = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #adadad;
  margin-left: 10px;
  white-space: nowrap;
`;

const StepTitleWrapper = styled.span`
  margin-left: 12px;
`;

const StepNoteHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`;

const MyNote = ({
  postId,
  note,
  win,
  preparationNote,
  type,
  exportModeOn,
  hasDeleteError,
  handleSelectPost,
  openPostModal,
  isPostSelected,
  progressStatus,
  user,
  addToProgressBoardEnabled,
  setAction,
  setShowActionModal,
  commentAddedHandler,
  tileRealignHandler,
  commentDeletedHandler,
  toggleNoteModal,
}) => {
  note = note || win;

  const followUp = getFollowUp(note.due_date, note.created_at, note.type, user.timeZone);
  const isResolved = note.status === 'resolved';

  const showDropdown = () =>
    !type.includes('Shared') || (note.type === noteTypes.want && !note.status);

  const handleFollowUp = () => {
    if (note.due_date) return;
    handleEdit(
      note,
      'edit',
      setAction,
      setShowActionModal,
      toggleNoteModal,
      progressStatus,
      true,
      null,
      user
    );
  };
  const isMeeting = note.type === noteTypes.meetingPreparation || note.type === noteTypes.meeting;

  const notAuthorInfo = hasDeleteError && (type.includes('Shared') || type.includes('Shoutout'));
  return (
    <>
      {[
        'SharedNote',
        'SharedWin',
        'SharedMeeting',
        'SharedManagerPrompt',
        'SharedMeetingStep',
      ].includes(type) && (
        <Layout.Header variant={note.assignee_id ? 'yellow' : 'azure'}>
          <Author
            user={note.owner || note.author}
            shared
            assigned={Boolean(note.assignee_id)}
            type={note.type}
          />

          {exportModeOn ? (
            <Layout.NoteSelect>
              <Layout.CheckboxWrapper className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  checked={isPostSelected}
                  id={`select-${note.id}`}
                  name={`select-${note.id}`}
                  className={`select-${note.id}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSelectPost(postId)}
                />
                <Layout.CheckboxLabel
                  htmlFor={`select-${note.id}`}
                  className="boolean"
                  id={`select-${note.id}-label`}
                />
              </Layout.CheckboxWrapper>
              {notAuthorInfo && (
                <Layout.NotAuthorInfo>You are not the author of this post</Layout.NotAuthorInfo>
              )}
            </Layout.NoteSelect>
          ) : (
            (note.type === noteTypes.goal || ['SharedMeetingStep'].includes(type)) &&
            !isResolved && (
              <NoteDropdown
                postId={postId}
                preparationNote={preparationNote}
                object={note}
                type={type}
                progressStatus={progressStatus}
                addToProgressBoardEnabled={addToProgressBoardEnabled}
              />
            )
          )}
        </Layout.Header>
      )}
      {!['SharedMeetingStep', 'MeetingStepNote'].includes(type) && (
        <Layout.Header isMeeting={isMeeting}>
          <Layout.HeaderDate>
            {moment.utc(note.created_at).tz(user.timeZone).format('DD MMMM YYYY')}
          </Layout.HeaderDate>

          <Layout.HeaderType>
            {type !== 'SharedNote' &&
              type !== 'SharedWin' &&
              type !== 'SharedManagerPrompt' &&
              type !== 'SharedMeeting' &&
              (note.shared_with.length || note.shared_with_team.length || note.assignee ? (
                <>
                  {note.shared_with.length || note.shared_with_team.length ? (
                    <Layout.MegaphoneStyled
                      isCancelled={progressStatus === 'not_complete'}
                      isResolved={isResolved}
                    />
                  ) : (
                    <Layout.FlagStyled
                      isCancelled={progressStatus === 'not_complete'}
                      isResolved={isResolved}
                    />
                  )}
                  <Layout.Shared
                    isResolved={isResolved}
                    isCancelled={progressStatus === 'not_complete'}
                  >
                    {note.shared_with.length || note.shared_with_team.length
                      ? note.type === noteTypes.goal
                        ? 'Shared goal'
                        : note.type === noteTypes.meeting
                        ? 'Shared meeting'
                        : 'Shared note'
                      : 'Assigned'}
                  </Layout.Shared>
                </>
              ) : (
                <>
                  {/* glasses class is used by onboarding process */}
                  <Layout.GlassesStyled className="glasses" />
                  Only seen by you
                </>
              ))}
          </Layout.HeaderType>
          {(showDropdown() || type === 'SharedMeeting') &&
            (exportModeOn
              ? type !== 'SharedMeeting' && (
                  <Layout.NoteSelect>
                    <Layout.CheckboxWrapper className="checkbox checkbox-primary">
                      <input
                        type="checkbox"
                        checked={isPostSelected}
                        id={`select-${note.id}`}
                        name={`select-${note.id}`}
                        className={`select-${note.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleSelectPost(postId)}
                      />
                      <Layout.CheckboxLabel
                        htmlFor={`select-${note.id}`}
                        className="boolean"
                        id={`select-${note.id}-label`}
                      />
                    </Layout.CheckboxWrapper>
                    {notAuthorInfo && (
                      <Layout.NotAuthorInfo>
                        You are not the author of this post
                      </Layout.NotAuthorInfo>
                    )}
                  </Layout.NoteSelect>
                )
              : type !== 'SharedMeeting' && (
                  <NoteDropdown
                    postId={postId}
                    preparationNote={preparationNote}
                    object={note}
                    type={type}
                    progressStatus={progressStatus}
                    addToProgressBoardEnabled={addToProgressBoardEnabled}
                    removeNote={note.type === noteTypes.meetingStep}
                  />
                ))}
        </Layout.Header>
      )}
      {note.type === noteTypes.want && (
        <Layout.Want onClick={() => openPostModal(note, postId, type)} className="open-modal">
          I want to {note.category.toLowerCase()}
        </Layout.Want>
      )}
      {note.type === noteTypes.private && (
        <Layout.PrivateHeader
          onClick={() => openPostModal(note, postId, type)}
          className="open-modal"
        >
          <Layout.PrivateStyled />
          Private note: <strong>{Layout.getFeedbackRecipientName(note)}</strong>
        </Layout.PrivateHeader>
      )}
      {(note.type === noteTypes.win || (note.title && note.type === noteTypes.goal)) && (
        <Layout.WinHeader
          onClick={() => openPostModal(note, postId, type)}
          className="open-modal"
          isMeeting={isMeeting}
        >
          {note.type === noteTypes.win && <Layout.TrophyStyled />}
          <Layout.WinTitle isResolved={isResolved} isCancelled={progressStatus === 'not_complete'}>
            {note.title}
          </Layout.WinTitle>
        </Layout.WinHeader>
      )}
      {['MeetingStepNote', 'SharedMeetingStep'].includes(type) && (
        <div>
          <StepNoteHeaderWrapper>
            <StepLink href={note.step.meeting_note.grow_post_url}>
              <CalendarIcon />
              <StepDateWrapper>{moment(note.created_at).format('DD MMMM YYYY')}</StepDateWrapper>
              <StepTitleWrapper>{note.step.meeting_note.grow_post_url_title}</StepTitleWrapper>
            </StepLink>
            {!['SharedMeetingStep'].includes(type) && !isResolved && (
              <NoteDropdown
                postId={postId}
                preparationNote={preparationNote}
                object={note}
                type={type}
                progressStatus={progressStatus}
                addToProgressBoardEnabled={addToProgressBoardEnabled}
              />
            )}
          </StepNoteHeaderWrapper>
          <GrowPostTitle isResolved={isResolved} isCancelled={progressStatus === 'not_complete'}>
            {note.title}
          </GrowPostTitle>
        </div>
      )}

      {(type === 'MeetingNote' ||
        type === 'SharedMeeting' ||
        type === 'ManagerPromptNote' ||
        type === 'SharedManagerPrompt' ||
        type === 'MeetingPreparationNote') && <MeetingSection memberNote={note} />}
      {Boolean(note.content) &&
        note.type !== noteTypes.meeting &&
        note.type !== noteTypes.managerPrompt && (
          <Layout.Content
            onClick={() => openPostModal(note, postId, type)}
            className="open-modal"
            dangerouslySetInnerHTML={{
              __html: striptags(note.content, ['a', 'br']),
            }}
          />
        )}
      {Boolean(note.category) && <Layout.Marker label="Category" values={[note.category]} />}
      {Boolean(note.goals) && Boolean(note.goals.length) && (
        <Layout.Marker
          label="Goal/KPI"
          values={note.goals.map(goal => goal.name)}
          isResolved={isResolved}
        />
      )}
      {Boolean(note.company_values) && Boolean(note.company_values.length) && (
        <Layout.Marker
          label="Related company values"
          values={note.company_values.map(value => value.name)}
          isResolved={isResolved}
        />
      )}
      {Boolean(note.media.length) && type !== 'MeetingPreparationNote' && (
        <Layout.MediaWrap>
          <PostMedia media={note.media} />
        </Layout.MediaWrap>
      )}
      {Boolean(note.files.length) && type !== 'MeetingPreparationNote' && (
        <Layout.FileWrap>
          {note.files.map((file, index) => (
            <PostFile file={file} key={index} />
          ))}
        </Layout.FileWrap>
      )}
      {type !== 'SharedNote' &&
        type !== 'SharedWin' &&
        type !== 'SharedManagerPrompt' &&
        type !== 'SharedMeeting' &&
        type !== 'SharedMeetingStep' &&
        (note.shared_with.length || note.shared_with_team.length || note.assignee) && (
          <>
            {Boolean(note.assignee) && note.assignee.id !== note.owner.id && (
              <SharedWith
                isResolved={isResolved}
                label="Assigned to"
                isCancelled={progressStatus === 'not_complete'}
              >
                <Author user={note.assignee} />
              </SharedWith>
            )}
            {Boolean(note.shared_with.length || note.shared_with_team.length) && (
              <SharedWith
                isResolved={isResolved}
                label="Shared with"
                isCancelled={progressStatus === 'not_complete'}
              >
                <div>
                  {note.shared_with.map((user, index) => (
                    <Author
                      key={index}
                      user={user}
                      css={css`
                        &:not(:last-child) {
                          margin-bottom: 12px;
                        }
                      `}
                    />
                  ))}
                  {note.shared_with_team.map((team, index) => (
                    <SharedTeam
                      key={index}
                      team={team}
                      css={css`
                        &:not(:last-child) {
                          margin-bottom: 12px;
                        }
                      `}
                    />
                  ))}
                </div>
              </SharedWith>
            )}
          </>
        )}
      {isResolved &&
        Boolean(
          note.shared_with.length ||
            note.shared_with_team.length ||
            (note.assignee_id && note.assignee_id !== note.owner_id) ||
            type === 'SharedNote'
        ) && (
          <SharedWith isResolved={isResolved} label={note.resolved_by ? 'Completed by' : ''}>
            {note.resolved_by && <Author user={note.resolved_by} />}
            {Boolean(
              user.id === note.owner_id &&
                note.resolved_at &&
                !note.confirmed_at &&
                note.type === noteTypes.goal
            ) && (
              <ConfirmButtonWrap>
                <ConfirmButton noteId={note.id} />
              </ConfirmButtonWrap>
            )}
          </SharedWith>
        )}
      {note.resolved_at
        ? Boolean(note.confirmed_at) && (
            <Layout.Resolved>
              <Layout.TickStyled />
              Resolved
            </Layout.Resolved>
          )
        : Boolean(
            (note.type === noteTypes.goal || note.type === noteTypes.meetingStep) &&
              progressStatus !== 'not_complete' &&
              (note.owner_id === user.id || note.due_date)
          ) && (
            <Layout.FollowUp
              onClick={handleFollowUp}
              clickable={!note.due_date}
              color={followUp.color}
            >
              <Layout.ClockStyled color={followUp.color} />
              {followUp.date}
            </Layout.FollowUp>
          )}
      {note.type !== noteTypes.meetingPreparation && (
        <>
          <CommentInput
            commentAddedHandler={commentAddedHandler}
            tileRealignHandler={tileRealignHandler}
            postId={note.id}
            type={type.includes('Win') ? 'win' : 'note'}
          />
          <Comments
            post={note}
            type={type.includes('Win') ? 'win' : 'note'}
            commentAddedHandler={commentAddedHandler}
            commentDeletedHandler={commentDeletedHandler}
            tileRealignHandler={tileRealignHandler}
          />
        </>
      )}
    </>
  );
};
MyNote.propTypes = {
  exportModeOn: PropTypes.bool,
  isPostSelected: PropTypes.bool,
  handleSelectPost: PropTypes.func.isRequired,
  openPostModal: PropTypes.func,
  postId: PropTypes.number,
  note: PropTypes.shape(),
  win: PropTypes.object,
  type: PropTypes.string,
  progressStatus: PropTypes.string,
  elementId: PropTypes.string,
};

MyNote.defaultProps = {
  exportModeOn: false,
  isPostSelected: false,
  openPostModal: () => {},
  commentAddedHandler: () => {},
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatch = {
  setAction,
  setShowActionModal,
  toggleNoteModal,
};
export default connect(mapStateToProps, mapDispatch)(MyNote);
