import styled from '@emotion/styled';
import { css } from '@emotion/react';
import BlueCheckmark from './BlueCheckmark.svg';

export const ShortDescription = styled.div`
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin: 0 20px;
`;

export const PriceSection = styled.div`
  min-height: 40px;
  margin-top: 20px;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    margin-top: 35px;
    margin-bottom: 27px;
  }
`;

export const PriceString = styled.div`
  font-size: 32px;
  line-height: 37px;
  text-align: center;
`;

export const PriceNumber = styled.div`
  font-size: 34px;
  line-height: 40px;
  text-align: center;

  &:before {
    content: '$';
    display: inline;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    vertical-align: 33%;
    margin-right: 7px;
  }
`;

export const PriceDescription = styled.div`
  font-size: 14px;
  line-height: 130%;
  color: #7f7f8a;
  align-items: center;
  display: flex;
  max-width: 105px;
  margin-left: 11px;
`;

export const CheckmarkList = styled.ul`
  margin-top: 20px;
  margin-left: 34px;
  padding-bottom: 15px;
  margin-bottom: 0;

  > li {
    font-size: 14px;
    line-height: 19px;
    color: #11a9ff;
    list-style: none;
    background: url(${({ icon }) => icon ?? BlueCheckmark}) no-repeat left 2px;
    margin-left: -39px;
    margin-bottom: 15px;
    padding-left: 35px;
    padding-bottom: 5px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const DropdownTabs = styled.div`
  width: 100%;
`;

export const DropdownContent = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #323232;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const DropdownDescription = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #11a9ff;
`;

export const Assistance = styled.div`
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-top: 33px;
  font-weight: normal;
`;

export const AssistanceDescription = styled.div`
  color: #58575f;
`;

export const AssistanceLetsTalk = styled.div`
  color: #11a9ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const modalCss = css`
  .modal-dialog {
    @media (min-width: 768px) {
      width: 700px;
    }
  }

  .modal-content {
    overflow: hidden;
  }
`;
