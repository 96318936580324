import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { memo, useLayoutEffect, useRef } from 'react';

const rotationKeyframes = (radius, rotation, previousRotation = 90) => keyframes`
  from {
    transform: rotate(${previousRotation}deg) translateX(${-radius}px) rotate(${-previousRotation}deg);
  }
  to {
    transform: rotate(${rotation}deg) translateX(${-radius}px) rotate(${-rotation}deg);
  }
`;

const Container = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto auto;
  z-index: 4;
  color: ${({ color }) => color};
  height: 22px;
  line-height: 22px;
  animation: ${({ rotation, previousRotation, radius }) =>
      rotationKeyframes(radius, rotation, previousRotation)}
    1.5s cubic-bezier(0.44, 0.91, 0.63, 0.99) forwards;
`;

const ScoreDisplay = styled.div`
  display: inline-block;
  text-align: center;
  background: #ffffff;
`;

/**
 * Adjusts rotation to prevent score labels from overlapping
 *
 * @param {number} rotation
 * @param {number} otherRotation
 * @param {bool} isFirst
 * @param {number} minOffsetDegree
 * @returns {number}
 */
const getRotationWithOffset = (rotation, otherRotation, isFirst, minOffsetDegree) => {
  const diff = rotation - otherRotation;
  const isNegative = diff === 0 ? isFirst : diff < 0;
  const absDiff = Math.abs(diff);
  if (absDiff >= minOffsetDegree) return rotation;

  const offset = (minOffsetDegree - absDiff) / 2;
  return rotation + offset * (isNegative ? -1 : 1);
};

const ComparisonScore = React.forwardRef(
  ({ rotation, otherRotation, isFirst, color, radius, children, minOffsetDegree }, ref) => {
    const previousRotation = useRef(90);

    useLayoutEffect(() => {
      setTimeout(() => {
        previousRotation.current = rotation;
      }, 0);
    }, [rotation]);

    return (
      <Container
        rotation={getRotationWithOffset(rotation, otherRotation, isFirst, minOffsetDegree)}
        previousRotation={previousRotation.current}
        color={color}
        radius={radius}
      >
        <ScoreDisplay ref={ref}>{children}</ScoreDisplay>
      </Container>
    );
  }
);

ComparisonScore.propTypes = {
  rotation: PropTypes.number.isRequired,
  otherRotation: PropTypes.number.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  minOffsetDegree: PropTypes.number,
};

ComparisonScore.defaultProps = {
  isFirst: false,
  minOffsetDegree: 11,
};

export default memo(ComparisonScore);
