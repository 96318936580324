import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/react';
import { ReactComponent as Glasses } from './GrowPost/assets/glasses.svg';
import { ReactComponent as Megaphone } from './GrowPost/assets/megaphone.svg';
import { ReactComponent as Flag } from './GrowPost/assets/flag.svg';
import { ReactComponent as Private } from './GrowPost/assets/private.svg';
import { ReactComponent as Trophy } from './GrowPost/assets/trophy.svg';
import { ReactComponent as Tick } from './GrowPost/assets/tick.svg';
import { ReactComponent as Clock } from './GrowPost/assets/clock.svg';

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  margin-bottom: 24px;
  .post-tile-comment-input {
    position: relative;
    display: flex;
    padding: 0 24px 24px;
  }

  .post-tile-comment-input .media-control-panel {
    margin-left: 5px;
  }

  .post-tile-comment-input .media-control-panel img {
    cursor: pointer;
  }

  .post-tile-comment-input .post-tile-icon-wrapper {
    display: none;
  }
  .post-tile-comment-input .mentions-input-wrap {
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    flex-grow: 1;
    width: 80%;
    min-height: 40px;
    padding: 10px 34px 10px 14px;
    font-size: 14px;
    line-height: 16px;
    background: #ffffff;
  }

  .post-tile-comment-input .mentions-input-wrap textarea {
    word-wrap: break-word;
    border: none;
    min-height: unset;
  }

  .post-tile-comment-input .mentions-input-wrap textarea::placeholder {
    color: #b2b1c0;
    letter-spacing: 0.6px;
    font-size: 12px;
    line-height: 21px;
  }

  .post-tile-comment-input .mentions-input-wrap.error {
    border: 1px solid #f5351b;
    background: #fff7f5;
  }
  .comment-dropzone-wrap {
    display: none;
  }
`;

export const TimestampWrap = styled.span`
  margin-top: 2px;
  margin-left: 7px;
  font-size: 12px;
  color: #d5d5d5;
  white-space: nowrap;
`;

export const Header = styled.div`
  padding: ${({ progressBoard, isMeeting }) =>
    progressBoard ? '18px 24px' : isMeeting ? '21px 24px 12px 24px;' : '24px'};
  ${({ variant }) =>
    variant === 'azure'
      ? css`
          background-color: #d3efff;
        `
      : variant === 'yellow' &&
        css`
          background-color: #fff3c3;
        `}
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
`;
export const HeaderDate = styled.span`
  font-size: 12px;
  color: #adadad;
  margin-right: ${({ progressBoard }) => (progressBoard ? '12px' : '22px')};
  display: inline-block;
`;
export const HeaderType = styled.span`
  font-size: 11px;
  color: #6a6a6a;
  line-height: 34px;
`;
export const GlassesStyled = styled(Glasses)`
  margin-right: 8px;
  path {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
  }
`;
export const MegaphoneStyled = styled(Megaphone)`
  position: relative;
  top: ${({ progressBoard }) => (progressBoard ? '3px' : '7px')};
  margin-right: 8px;
  ${({ progressBoard }) =>
    progressBoard === true &&
    `
    width: 15px;
    height: 15px;
  `}
  g path {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
    ${({ isCancelled }) =>
      isCancelled &&
      css`
        fill: #979797;
      `};
  }
  path {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
    ${({ isCancelled }) =>
      isCancelled &&
      css`
        fill: #979797;
      `};
  }
`;

export const FlagStyled = styled(Flag)`
  position: relative;
  top: ${({ progressBoard }) => (progressBoard ? '3px' : '7px')};
  margin-right: 8px;
  ${({ progressBoard }) =>
    progressBoard === true &&
    `
    width: 15px;
    height: 15px;
  `}
  path {
    ${({ isResolved }) =>
      isResolved &&
      css`
        fill: #52ba05;
      `}
    ${({ isCancelled }) =>
      isCancelled &&
      css`
        fill: #979797;
      `};
  }
`;
export const Shared = styled.span`
  color: ${({ isResolved }) => (isResolved ? '#52BA05' : '#11A9FF')};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
`;
export const PrivateHeader = styled.div`
  font-weight: bold;
  font-size: ${({ progressBoard }) => (progressBoard ? '14px' : '16px')};
  color: #b7b7b7;
  padding: 0 24px 24px;
  strong {
    color: #323232;
  }
`;
export const PrivateStyled = styled(Private)`
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -3px;
`;
export const WinHeader = styled.div`
  display: flex;
  font-weight: bold;
  font-size: ${({ progressBoard }) => (progressBoard ? '14px' : '16px')};
  color: #323232;
  padding: 0 24px ${({ isMeeting }) => (isMeeting ? '21px' : '24px')};
  ${({ isMeeting }) => 'cursor: default !important;'}
`;
export const WinTitle = styled.div`
  display: inline-block;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  ${({ isResolved }) =>
    isResolved &&
    css`
      color: #52ba05;
    `};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
`;
export const TrophyStyled = styled(Trophy)`
  vertical-align: middle;
  margin-right: 15px;
  display: inline-flex;
  min-width: 30px;
  min-height: 30px;
`;
export const WantHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #b7b7b7;
  padding: 24px;
  strong {
    color: #323232;
  }
`;
export const Content = styled.div`
  padding: 25px;
  padding-top: 0;
  font-size: ${({ progressBoard }) => (progressBoard ? '13px' : '14px')};
  line-height: 21px;
  max-width: 100%;
  word-wrap: break-word;
  overflow-x: hidden;
`;
export const Want = styled.div`
  padding: 24px;
  padding-top: 0;
  font-size: 16px;
  font-weight: bold;
`;
export const ContentLink = styled(Link)`
  color: #323232;
`;
export const MarkerWrapper = styled.div`
  padding: 0 24px 14px;
`;
export const Label = styled.label`
  font-size: 12px;
  line-height: 14px;
  color: #bbbbbb;
  display: block;
  font-weight: normal;
  margin-bottom: 10px;
`;
export const Value = styled.span`
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  background: ${({ isResolved }) => (isResolved ? '#52BA05' : '#11A9FF')};
  border-radius: 18px;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  padding: 7px 18px;
  margin-right: 10px;
  margin-bottom: 4px;
`;
export const FileWrap = styled.div`
  padding: 0 24px;
`;
export const MediaWrap = styled.div`
  padding-bottom: 24px;
`;
export const Resolved = styled.div`
  padding: 0 24px 24px;
  color: #52ba05;
  font-weight: bold;
  font-size: 13px;
`;
export const TickStyled = styled(Tick)`
  vertical-align: middle;
  margin-right: 11px;
  margin-top: -3px;
`;
export const FollowUp = styled.div`
  padding: 0 24px 24px;
  font-weight: bold;
  font-size: 13px;
  color: ${props => props.color || '#11A9FF'};
  ${props =>
    props.clickable
      ? css`
          cursor: pointer;
        `
      : ''};
`;
export const ClockStyled = styled(Clock)`
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -3px;
  circle {
    fill: ${props => props.color || '#11A9FF'};
  }
`;

export const NoteSelect = styled.div`
  float: right;
  margin-left: auto;
  position: relative;
`;

export const NotAuthorInfo = styled.p`
  position: absolute;
  top: -20px;
  right: 0;
  width: 183px;
  line-height: normal;
  font-size: 12px;
  color: #fe483c;
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox']:checked + label:before {
    background-color: #11a9ff !important;
    border-color: #11a9ff !important;
  }
  input[type='checkbox']:focus + label::before {
    outline: none !important;
  }
  label:after {
    width: 20px !important;
    height: 20px !important;
    margin-left: -20px !important;
    padding-top: 3px !important;
    font-size: 15px !important;
    line-height: 25px;
    top: -5px !important;
  }
`;

export const CheckboxLabel = styled.label`
  font-size: 13px;
  text-decoration: none;
  color: #11a9ff;
  cursor: pointer;
  line-height: 25px;
  &:before {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #11a9ff !important;
    background: transparent !important;
    top: 0 !important;
  }
`;

export const Marker = ({ label, values, isResolved }) => (
  <MarkerWrapper>
    <Label>{label}</Label>
    {values.map(value => (
      <Value isResolved={isResolved}>{value}</Value>
    ))}
  </MarkerWrapper>
);

Marker.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  isResolved: PropTypes.bool,
};

export const getUserName = user => `${user.first_name} ${user.last_name}`;

export const getFeedbackRecipientName = note =>
  note.member ? `${note.member.first_name} ${note.member.last_name}` : note.member_team.name;
