/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Select from 'react-select';
import { jsx, css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { findLabel, getUrl, HEADERS } from 'helpers.js';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';
import AssigneeOption from './AssigneeOption';
import AssigneeValue from './AssigneeValue';

const Wrap = styled.div`
  margin-top: 25px;
  .Select {
    display: inline-block !important;
  }
`;

const SwitchWrapper = styled.div`
  height: 45px;
  @media (max-width: 600px) {
    height: 82px;
  }
  .Select-placeholder {
    color: #c4c4c4 !important;
  }
`;

const FilterName = styled.p`
  display: inline-block;
  margin-left: 6px;
  font-size: 13px;
  color: ${props => (props.isMeeting ? '#6A6A6A' : '#A6A6A6')};
`;

const Switch = styled.div`
  display: inline-block;
  position: absolute;
  padding-top: 8px;
  height: 36px;
  width: 50%;
  vertical-align: middle;
  padding-left: 24px;
  span.switch {
    vertical-align: middle;
  }
  @media (max-width: 600px) {
    display: block;
    padding-left: 0;
    width: 100%;
  }
`;

const customStylesD = {
  option: (provided, state) => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isSelected ? '#f4faff' : 'white',
    '&:hover': {
      backgroundColor: '#ebf5ff',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

const NoteRecipient = ({
  users,
  noteRecipient,
  setNoteRecipient,
  mode,
  isMeeting,
  toggleMeeting,
  fromNote,
  setMeetingMemberState,
  members,
  setMembers,
}) => {
  const [loaded, setLoaded] = useState(false);
  const fetchMembers = async () => {
    const headers = HEADERS();
    const response = await fetch(getUrl('meetings/members'), { method: 'get', headers });
    const json = await response.json();
    setLoaded(true);
    if (json.members) {
      setMembers(json.members);
    }
  };
  useEffect(() => {
    !loaded && fetchMembers();
    if (noteRecipient) {
      const selectedUser = members.find(user => user.value === noteRecipient.value);
      if (selectedUser) {
        setMeetingMemberState(selectedUser);
      }
    } else {
      setMeetingMemberState('');
    }
  }, [isMeeting]);

  return (mode === noteTypes.private || fromNote) && users ? (
    <Wrap>
      <SwitchWrapper className='d-flex'>
        <Select
          id='select-op1'
          options={members}
          inputProps={{ id: 'shoutout-for-input' }}
          value={noteRecipient.value ? { value: noteRecipient.value, label: findLabel(noteRecipient.value, members) }: ''}
          isSearchable
          onChange={user => (isMeeting ? setMeetingMemberState(user) : setNoteRecipient(user))}
          isClearable={mode === noteTypes.goal}
          styles={customStylesD}
          className="select-component select-op recipients orange"
          placeholder={
            mode === noteTypes.private
              ? 'Search for users or teams here'
              : 'Team member or team you making note about'
          }
          disabled={false}
          classNamePrefix="r-select"
          optionComponent={AssigneeOption}
          valueComponent={AssigneeValue}
        />
        {(mode === noteTypes.private || mode === noteTypes.meeting) && (
          <Switch>
            <SwitchButton
              turnedOn={isMeeting}
              onClick={toggleMeeting}
              filter-type="filter-spaces"
            />
            <FilterName isMeeting={isMeeting}>This was a meeting</FilterName>
          </Switch>
        )}
      </SwitchWrapper>
    </Wrap>
  ) : null;
};

NoteRecipient.propTypes = {
  noteRecipient: PropTypes.object.isRequired,
  setNoteRecipient: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
};

export default NoteRecipient;
