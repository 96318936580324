import styled from '@emotion/styled';
import { jsx, css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

const Page = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #323232;
  width: 32px;
  height: 32px;
  margin-left: -2px;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  line-height: 32px;
  ${props =>
    props.selected &&
    css`
      color: #11a9ff;
      background: #f3f3f3;
      border-radius: 55px;
      margin-right: 10px;
      margin-left: 8px;
    `}
`;

const Pagination = ({ index, total, handleChange }) => {
  const pages = [];
  pages.push(
    <Page onClick={() => handleChange(1)} key="«">
      «
    </Page>
  );
  pages.push(
    <Page onClick={() => handleChange(index > 1 ? index - 1 : 1)} key="‹">
      ‹
    </Page>
  );

  const firstIndex = index + 5 > total ? total - 9 : index - 5 > 0 ? index - 5 : 1;
  for (let i = firstIndex < 1 ? 1 : firstIndex; i <= firstIndex + 9; i++) {
    pages.push(
      <Page selected={i === index} onClick={() => handleChange(i)} key={i}>
        {i}
      </Page>
    );
  }

  pages.push(
    <Page onClick={() => handleChange(index < total ? index + 1 : total)} key="›">
      ›
    </Page>
  );
  pages.push(
    <Page onClick={() => handleChange(total)} key="»">
      »
    </Page>
  );

  return pages;
};

Pagination.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Pagination;
