/** @jsxImportSource @emotion/react */
import React from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Droppable } from 'react-beautiful-dnd';

import Post from './Post';

const Container = styled.div`
  margin: 8px;
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 320px !important;
`;
const Title = styled.h3`
  padding: 20px 20px 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`;
const PostList = styled.div`
  padding: 0 20px;
  transition: background-color 0.2s ease;
  background-color: #ebebeb;
  flex-grow: 1;
  min-height: 100px;
`;

const AddButtonWrapper = styled.div`
  height: 28px;
  padding: 30px 0 65px;
  cursor: pointer;
  background: #ebebeb;
  display: flex;
  justify-content: center;
  width: 100%;
`;

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.posts === this.props.posts) {
      return false;
    }
    return true;
  }

  render() {
    const { posts, removeNoteClickHandle, openProgressBoardPost, confirmNote } = this.props;
    return posts.map((post, index) => (
      <Post
        key={post.id}
        post={post}
        index={index}
        confirmNote={confirmNote}
        openProgressBoardPost={openProgressBoardPost}
        removeNoteClickHandle={removeNoteClickHandle}
      />
    ));
  }
}

const Column = ({
  column,
  posts,
  addNoteClickHandle,
  removeNoteClickHandle,
  openProgressBoardPost,
  confirmNote,
}) => {
  return (
    <Container>
      <Title className={`${column.id}_target`}>{column.title}</Title>
      <Droppable droppableId={column.id.toString()}>
        {(provided, snapshot) => (
          <>
            <PostList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              <InnerList
                posts={posts}
                removeNoteClickHandle={removeNoteClickHandle}
                openProgressBoardPost={openProgressBoardPost}
                confirmNote={confirmNote}
              />
              {provided.placeholder}
            </PostList>
            <AddButtonWrapper onClick={() => addNoteClickHandle(column.id)}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                  fill="#E1E1E1"
                />
                <rect
                  x="13.2222"
                  y="6.22217"
                  width="1.55556"
                  height="15.5556"
                  rx="0.777778"
                  fill="white"
                />
                <rect
                  x="21.7778"
                  y="13.2222"
                  width="1.55556"
                  height="15.5556"
                  rx="0.777778"
                  transform="rotate(90 21.7778 13.2222)"
                  fill="white"
                />
              </svg>
            </AddButtonWrapper>
          </>
        )}
      </Droppable>
    </Container>
  );
};

export default Column;
