import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { APP_URL, FILTER_TYPES_FEED } from 'constants.js';
import FeedDateRange from 'components/shared/FeedDateRange';
import { css, jsx } from '@emotion/react';
import handleStickyScroll from 'components/feed/handleStickyScroll';
import { FORMAT_FILTERS, CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import Auxiliary from '../layouts/Auxiliary';
import SpacesFilter from './SpacesFilter';
import { POST_ACTIONS } from '../../redux/actions/postActions';
/** @jsxImportSource @emotion/react */

class HomeFeedRightPanel extends Component {
  initialFilters = {
    spaces: [],
    excludedSpaces: [],
    types: [],
    hashtags: [],
    string: '',
    period: '',
    start_date: '',
    end_date: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      viewAllSpaces: false,
      viewAllHashtags: false,
      mobileOpen: false,
      filters: this.initialFilters,
      spaces: [],
    };
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('scroll', this.handleStickyScroll);

    this.panelParent = document.getElementsByClassName('mobile-panel-overlay')[0];
    this.panel = document.getElementsByClassName('feed-right-panel-wrap')[0];

    this.filtersUpdate();

    const headers = HEADERS();
    fetch(`${APP_URL}/member_spaces`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ spaces: jsonResponse.spaces });
        });
      });
  }

  componentDidUpdate() {
    this.filtersUpdate();
    const el = document.querySelector(".search-input-wrap input[filter-type='string']");
    if (el) el.value = this.state.filters.string;
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('scroll', this.handleStickyScroll);
  }

  filtersUpdate = () => {
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const filtersCopy = JSON.parse(JSON.stringify(this.state.filters));
    if (qsFilters) {
      Object.keys(filtersCopy).forEach(key => {
        filtersCopy[key] = qsFilters[key] ? qsFilters[key] : this.initialFilters[key];
      });
      if (JSON.stringify(filtersCopy) !== JSON.stringify(this.state.filters))
        this.setState({ filters: filtersCopy });
    }
  };
  // ---Swiping listeners

  xDown = null;

  yDown = null;

  handleTouchStart = e => {
    this.xDown = e.touches[0].clientX;
    this.yDown = e.touches[0].clientY;
  };

  handleTouchMove = e => {
    if (!this.xDown || !this.yDown) {
      return;
    }
    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        if ((window.innerWidth * 7) / 8 < this.xDown) {
          setTimeout(() => this.setState({ mobileOpen: true }), 10);
        }
      } else {
        setTimeout(() => this.setState({ mobileOpen: false }), 10);
      }
    }
    this.xDown = null;
    this.yDown = null;
  };

  //--------------------
  panelParent = null;

  panel = null;

  handleStickyScroll = () => {
    handleStickyScroll(this.panel, this.panelParent);
  };

  render() {
    const types = FILTER_TYPES_FEED;
    let tagsRenderedCount = -1;

    return (
      <div className={`mobile-panel-overlay ${this.state.mobileOpen ? 'open' : ''}`}>
        <div className="feed-right-panel-wrap">
          <div className="feed-right-panel-inner-wrap">
            <h3>Display feeds</h3>
            <SpacesFilter spaces={this.state.spaces} filters={this.props.filters} />
            <div
              css={css`
                margin-left: 6px;
                width: 190px;
              `}
            >
              <FeedDateRange />
            </div>
            <h3>By post type</h3>
            <div className="filters-group">
              {types.map((type, index) => (
                <p
                  onClick={e =>
                    this.props.history.push(
                      `/?${qs.stringify(FORMAT_FILTERS(e.currentTarget, this.state.filters))}`
                    )
                  }
                  custom-checked={
                    this.state.filters.types.indexOf(type.filter) > -1 ? 'true' : 'false'
                  }
                  filter-type="type"
                  type-filter={type.filter}
                  key={index}
                  id={`filter-${type.filter}`}
                >
                  {type.caption}
                </p>
              ))}
            </div>
            {this.props.hashtags && this.props.hashtags.length > 0 ? (
              <Auxiliary>
                <h3>
                  By tag
                  <span>{this.props.hashtags.length}</span>
                </h3>
                <div className="filters-group">
                  {this.props.hashtags
                    .filter(hashtag => this.state.filters.hashtags.indexOf(hashtag.key) > -1)
                    .map((hashtag, index) => {
                      tagsRenderedCount++;
                      if (
                        this.state.viewAllHashtags ||
                        (!this.state.viewAllHashtags && tagsRenderedCount < 5)
                      ) {
                        return (
                          <p
                            onClick={e =>
                              this.props.history.push(
                                `/?${qs.stringify(
                                  FORMAT_FILTERS(e.currentTarget, this.state.filters)
                                )}`
                              )
                            }
                            custom-checked={
                              this.state.filters.hashtags.indexOf(hashtag.key) > -1
                                ? 'true'
                                : 'false'
                            }
                            filter-type="hashtag"
                            hashtag-slug={hashtag.key}
                            key={index}
                          >
                            #{hashtag.key}
                          </p>
                        );
                      }
                      return '';
                    })}
                  {this.props.hashtags
                    .filter(hashtag => this.state.filters.hashtags.indexOf(hashtag.key) < 0)
                    .map((hashtag, index) => {
                      tagsRenderedCount++;
                      if (
                        this.state.viewAllHashtags ||
                        (!this.state.viewAllHashtags && tagsRenderedCount < 5)
                      ) {
                        return (
                          <p
                            onClick={e =>
                              this.props.history.push(
                                `/?${qs.stringify(
                                  FORMAT_FILTERS(e.currentTarget, this.state.filters)
                                )}`
                              )
                            }
                            custom-checked={
                              this.state.filters.hashtags.indexOf(hashtag.key) > -1
                                ? 'true'
                                : 'false'
                            }
                            filter-type="hashtag"
                            hashtag-slug={hashtag.key}
                            key={index}
                          >
                            #{hashtag.key}
                          </p>
                        );
                      }
                      return '';
                    })}
                  {this.props.hashtags.length > 5 ? (
                    <div
                      onClick={() =>
                        this.setState({ viewAllHashtags: !this.state.viewAllHashtags })
                      }
                      className={`unfold-button ${this.state.viewAllHashtags ? 'unfolded' : ''}`}
                    >
                      {this.state.viewAllHashtags ? 'Collapse' : 'View all'}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Auxiliary>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  hashtags: state.tagsDomain.feedTags,
  filters: state.postsDomain.filters,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeFeedRightPanel));
