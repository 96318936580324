/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { AVATAR_ERROR } from 'helpers';
import React, { useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';

const BlockSpan = styled.span`
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
`;

const SpaceMember = ({ index, member, handleClick }) => {
  const refParagraph = useRef();
  const refContainer = useRef();
  const refFirstName = useRef();
  const refLastName = useRef();
  const refLeadLabel = useRef();
  const refVisitedLabel = useRef();

  const labelMargin = 7;

  useLayoutEffect(() => {
    if (refContainer.current.clientHeight > refFirstName.current.clientHeight) {
      const labelNode = refLeadLabel.current ?? refVisitedLabel.current;
      if (labelNode) {
        const textWidth = Math.max(
          refFirstName.current.clientWidth,
          refLastName.current.clientWidth
        );
        const labelRect = labelNode.getBoundingClientRect();
        const labelWidth = labelRect.right - labelRect.left + labelMargin;
        const paragraphWidth = refParagraph.current.clientWidth;
        const offset = paragraphWidth - labelWidth - textWidth;

        refFirstName.current.style.maxWidth = `${paragraphWidth - labelWidth}px`;
        refLastName.current.style.maxWidth = `${paragraphWidth - labelWidth}px`;
        labelNode.style.right = offset < 0 ? 0 : `${offset}px`;
      }
    }
  }, []);

  const labelCss = css`
    height: 14px;
    line-height: 14px;
    padding: 0 5px !important;
    position: relative;
  `;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      key={index}
      onClick={handleClick}
    >
      <img src={member.image_url} alt="" onError={e => AVATAR_ERROR(e.target)} />
      <p
        className="space-members"
        css={css`
          display: flex !important;
          align-items: center;
          line-height: 1.5 !important;
        `}
        key={index}
        ref={refParagraph}
      >
        <div
          ref={refContainer}
          css={css`
            max-width: 100%;
          `}
        >
          <BlockSpan ref={refFirstName}>{member.first_name}</BlockSpan>
          <BlockSpan>&nbsp;</BlockSpan>
          <BlockSpan ref={refLastName}>{member.last_name}</BlockSpan>
        </div>
        {member.lead_spaces.length > 0 && (
          <div
            css={labelCss}
            className="lead-label"
            onClick={e => e.preventDefault()}
            ref={refLeadLabel}
          >
            lead
          </div>
        )}
        {member.visited_spaces_count > 0 && (
          <div
            css={labelCss}
            className="lead-label"
            onClick={e => e.preventDefault()}
            ref={refVisitedLabel}
          >
            visitor
          </div>
        )}
      </p>
    </div>
  );
};

export default SpaceMember;
