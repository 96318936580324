import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import Sidebar from '../partials/Sidebar';
import { HEADERS } from '../../../helpers';
import { APP_URL } from '../../../constants';
import SpaceModal from '../../spaces/InformalSpaceModal';
import EditLeadsModal from '../../spaces/EditLeadsModal';

const SpacesActions = ({
  history,
  spaces,
  selectedSpace,
  updateFlash,
  reloadData,
  user,
  fetchSpaces,
}) => {
  const [editSpaceModalToggled, setEditSpaceModalToggled] = useState(false);
  const [editSpaceLeadsModalToggled, setEditSpaceLeadsModalToggled] = useState(false);
  const [newSpaceModalToggled, setNewSpaceModalToggled] = useState(false);

  const deleteSpace = () => {
    const headers = HEADERS();
    fetch(`${APP_URL}/spaces/${selectedSpace?.id}`, { headers, method: 'delete' })
      .then(response => {
        if (response.status === 200 && response.ok === true) {
          reloadData();
          fetchSpaces();
        }
      })
      .catch(() => {});
  };

  const confirmAction = () => {
    if (window.confirm('Are you sure?')) {
      deleteSpace();
    }
  };

  const filterActions = default_space => {
    return [
      ...(!default_space
        ? [
            {
              label: 'Delete space',
              onClick: () => confirmAction(),
            },
          ]
        : []),
      {
        label: 'Edit space profile',
        onClick: () => setEditSpaceModalToggled(true),
      },
      {
        label: 'Edit space leads',
        onClick: () => setEditSpaceLeadsModalToggled(true),
      },
    ];
  };

  return (
    <>
      <Sidebar.Actions
        actions={[
          ...(selectedSpace && selectedSpace?.is_team_lead
            ? filterActions(selectedSpace?.account_default_space)
            : []),
          {
            label: 'Create new space',
            onClick: () => setNewSpaceModalToggled(true),
          },
        ]}
      />
      <SpaceModal
        updateFlash={updateFlash}
        reloadData={reloadData}
        showInformalModal={editSpaceModalToggled || newSpaceModalToggled}
        mode={editSpaceModalToggled ? 'edit' : 'new'}
        spaceId={editSpaceModalToggled ? selectedSpace?.id : ''}
        closeInformalModalHandler={() => {
          setEditSpaceModalToggled(false);
          setNewSpaceModalToggled(false);
        }}
      />
      {editSpaceLeadsModalToggled ? (
        <EditLeadsModal
          showEditLeadsModal={editSpaceLeadsModalToggled}
          closeEditLeadsModalHandler={() => setEditSpaceLeadsModalToggled(false)}
          assignTo={selectedSpace}
          updateFlash={updateFlash}
          reloadData={reloadData}
          resetList={() => fetchSpaces()}
        />
      ) : null}
    </>
  );
};

SpacesActions.propTypes = {
  spaces: PropTypes.array.isRequired,
  selectedSpace: PropTypes.object.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});
const mapDispatchToProps = dispatch => ({
  fetchSpaces: () => dispatch(SPACE_ACTIONS.fetchSpaces()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpacesActions));
