/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/react';
import Auxiliary from '../../layouts/Auxiliary';

class PollOptions extends Component {
  checkIfSelected(id) {
    return this.props.poll.selected_poll_option_id === id;
  }

  selectOption(e, id) {
    if (!this.checkIfSelected(id) && !this.props.poll.selected_poll_option_id) {
      const newSelected = id;
      this.props.updatePoll(e, newSelected);
      this.setState({ selected: newSelected });
    }
  }

  getString(count) {
    if (count === 0) {
      return 'votes';
    }

    return count > 1 ? 'votes' : 'vote';
  }

  classNames(optionId) {
    let classNames = 'pool-option-box';
    const votesForOption = this.props.poll.poll_options.filter(function (option) {
      return option.id === optionId;
    });
    if (votesForOption && votesForOption.vote_count === 0) {
      classNames += ' empty';
    }
    return classNames;
  }

  render() {
    return (
      <Auxiliary>
        <div className="poll-options">
          {this.props.poll.poll_options.map(option => (
            <div className={this.classNames(option.id)} key={option.id}>
              <div className="round">
                <input type="checkbox" id="checkbox" checked={this.checkIfSelected(option.id)} />
                <label htmlFor="checkbox" onClick={e => this.selectOption(e, option.id)} />
              </div>
              <div className="option-content" key={option.id}>
                <span
                  className="content-text"
                  onClick={e => this.selectOption(e, option.id)}
                  css={css`
                    word-wrap: break-word;
                  `}
                >
                  {option.content}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Auxiliary>
    );
  }
}

export default PollOptions;
