import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class Free extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      search: this.props.search,
      column: '',
      order: '',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.props.search) {
      this.setState({ search: nextProps.search }, () => {
        this.fetchData();
      });
    }
  }

  fetchData(order) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    let url = `/admin/accounts?tab=free&search=${this.state.search}`;
    if (order) {
      url += order;
    }
    fetch(APP_URL + url, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({
            accounts: jsonResponse.accounts,
            column: jsonResponse.column,
            order: jsonResponse.order,
          });
        });
      })
      .catch(() => {});
    if (url !== this.props.location.pathname + this.props.location.search) {
      this.props.history.push(url);
    }
  }

  accountStatus(account) {
    const nowDate = new Date().getTime();
    const trialDate = new Date(account.trial_ends_at).getTime();
    if (account.trial_ends_at && nowDate >= trialDate) {
      return <div className="label label-danger">Expired</div>;
    }
    if (account.trial_ends_at && nowDate + 7 * 24 * 60 * 60 * 1000 >= trialDate) {
      const days = Math.round((trialDate - nowDate) / (24 * 60 * 60 * 1000));
      return (
        <div className="label label-warning">
          Expire in
          {days} days
        </div>
      );
    }
    return <div className="label label-success">Free</div>;
  }

  orderBy(e, column, order = 'desc') {
    e.preventDefault();
    if (column === this.state.column) {
      order = this.state.order === 'desc' ? 'asc' : 'desc';
    }
    this.fetchData(`&order[${column}]=${order}`);
  }

  setIcon(name) {
    const caret = this.state.column !== name ? 'left' : this.state.order === 'asc' ? 'up' : 'down';
    return <i className={`fa fa-caret-${caret}`} />;
  }

  render() {
    return (
      <table className="table">
        <tbody>
          <tr>
            <th>Status</th>
            <th onClick={e => this.orderBy(e, 'name')}>
              <a href="/#">
                Name
                {this.setIcon('name')}
              </a>
            </th>
            <th>No. Active Users</th>
            <th onClick={e => this.orderBy(e, 'trial_ends_at')}>
              <a href="/#">
                Expire
                {this.setIcon('trial_ends_at')}
              </a>
            </th>
          </tr>
          {this.state.accounts.map(account => (
            <tr key={account.id}>
              <td>{this.accountStatus(account)}</td>
              <td>
                <Link to={`/admin/accounts/${account.id}`} className="primary-link">
                  {account.name}
                </Link>
              </td>
              <td>{account.users_count}</td>
              <td>{account.trial_ends_at && account.trial_ends_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
export default Free;
