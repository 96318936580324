import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';

class VibeAnonModal extends Component {
  render() {
    return (
      <Modal
        className="intro-modal js-intro-modal .modal-dialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        show={this.props.display}
        onHide={() => this.props.closeDescModal()}
      >
        <Modal.Body className="centered modal-body-no-padding">
          <div className="u-padding-vertical-xs">
            <div className="u-padding-horizontal-xs u-padding-vertical-xs text-center u-size0">
              <h3 className="bold u-margin-top-none u-margin-bottom-none"> What does this mean?</h3>
              <p>{this.props.vibeDescription}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default VibeAnonModal;
