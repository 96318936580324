/** @jsxImportSource @emotion/react */
import React from 'react';
import { jsx } from '@emotion/react';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import { CloseButton } from 'components/shared/Modal/UserModal';
import OnboardingButton from './partials/OnboardingButton';
import PostponeButton from './partials/PostponeButton';

export const ModalContent = styled.div`
  padding: 35px 35px 25px 35px;
  text-align: center;
  height: 100%;
  font-size: 16px;
  line-height: 150%;
  color: #323232;
  @media (max-width: 355px) {
    padding: 35px 15px;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 450px;
  @media (min-width: 620px) {
    img.close-button {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  padding-right: 26px;
  a.btn {
    font-size: 14px;
    line-height: 35px;
  }
  a:first-of-type {
    margin-right: 26px;
  }
  span:after {
    opacity: 0;
    line-height: 150%;
  }
  @media (max-width: 330px) {
    a.btn {
      margin-right: 5px;
    }
  }
`;

const ExistedMeetingModal = ({ showModal, member, members, closeModal, resetForm }) => {
  const user = members ? members.find(m => m.value === member) : null;
  const name = user && user.first_name ? `${user.first_name} ${user.last_name}` : user?.name;
  return (
    <Modal
      show={showModal}
      dialogClassName="existed-meeting-modal"
      backdrop
      keyboard
      onHide={() => closeModal()}
    >
      <ModalWrapper>
        <ModalContent>
          Looks like you already have {name} in one of your previous meetings. Do you want to
          continue?
          <ButtonWrapper>
            <OnboardingButton
              onClick={e => {
                e.preventDefault();
                closeModal();
              }}
              label="Yes"
              submit
              top={10}
              width={65}
              height={36}
            />
            <PostponeButton
              text="No, select a different user"
              onClick={e => {
                e.preventDefault();
                resetForm();
              }}
            />
          </ButtonWrapper>
        </ModalContent>
        <CloseButton className="close-button" onClick={() => closeModal()} />
      </ModalWrapper>
    </Modal>
  );
};

export default ExistedMeetingModal;
