import React from 'react';
import SelectQuestion from 'components/onboarding/SelectQuestion';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const ParticipantSelect = ({ memberValue, updateMember, members, question }) => {
  return (
    <SelectQuestion
      question={question}
      id="member"
      value={memberValue}
      setValue={updateMember}
      options={members}
      searchable
      fullwidth
      placeholder="Search for user or team here"
      css={css`
        .Select-control {
          height: 42px;

          .Select-placeholder,
          .Select-value-label {
            line-height: 42px;
          }
        }

        .Select-menu-outer {
          .Select-menu {
            .Select-option.has_settings:not(.is-focused) {
              background-color: rgba(237, 237, 237, 0.5);
            }
          }
        }
      `}
    />
  );
};

ParticipantSelect.propTypes = {
  memberValue: PropTypes.string,
  updateMember: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  question: PropTypes.string.isRequired,
};

ParticipantSelect.defaultProps = {
  memberValue: null,
};

export default ParticipantSelect;
