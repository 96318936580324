/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import 'chartjs-plugin-datalabels';
import Masonry from 'react-masonry-component';

const ShortAnswerWrapper = styled.div`
  width: 100%;
  padding-top: 5px;
  ${props =>
    props.isOdd &&
    css`
      .answer-wrap:nth-last-child(odd):last-child {
        left: 0 !important;
      }
    `}
`;

const AnswerContent = styled.div`
  &::before {
    content: '\\2022';
    color: #11a9ff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  padding-left: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-top: 10px;
  word-break: break-word;
  width: 100%;
  ${props =>
    props.details &&
    `
    @media(min-width:500px) {
      width: 50%;
      padding-right: 10px;    
    }
  `}
`;

const ViewAllResponses = styled.div`
  color: #11a9ff;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  padding: 14px 0;
  cursor: pointer;
`;

const ShortAnswerResults = ({ answers, question, details = false }) => {
  const [viewAll, setViewAll] = useState(false);
  const [allAnswers, setAllAnswers] = useState(
    answers.filter(answer => answer.question_id === question.id)
  );
  let shortAnswers = allAnswers;
  if (!viewAll) {
    shortAnswers = allAnswers.slice(0, 10);
  }
  const isOdd = allAnswers.lenght % 2;

  useEffect(() => {
    setAllAnswers(answers.filter(answer => answer.question_id === question.id));
  }, [answers]);
  return (
    <ShortAnswerWrapper isOdd={isOdd}>
      <Masonry options={{ transitionDuration: 0 }}>
        {shortAnswers.map((answer, key) => {
          if (answer.content) {
            return (
              <AnswerContent className="answer-wrap" key={key} details={details}>
                {answer.content}
              </AnswerContent>
            );
          }
        })}
      </Masonry>
      {!viewAll && allAnswers.length > 10 && details && (
        <ViewAllResponses onClick={() => setViewAll(true)}>
          View all responses ({allAnswers.length})
        </ViewAllResponses>
      )}
    </ShortAnswerWrapper>
  );
};

export default ShortAnswerResults;
