/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import DropdownCircle from 'components/payments/PaymentChoose/shared/DropdownCircle.svg';
import { css, jsx } from '@emotion/react';
import {
  CheckmarkList,
  DropdownContent,
  DropdownDescription,
} from 'components/payments/PaymentChoose/shared/layout';

import styled from '@emotion/styled';
import { Collapse } from 'react-bootstrap';

const DropdownTab = styled.div`
  padding: 0 6.66%;
  border-top: #dfebf0 1px solid;

  &:last-child {
    border-bottom: #dfebf0 1px solid;
  }

  &:nth-child(even) {
    background: #fafafa;
  }
`;

const DropdownContentStyled = styled(DropdownContent)`
  min-height: 60px;
`;

const FeatureDropdown = ({ feature }) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownTab>
      <DropdownContentStyled onClick={() => setOpen(open => !open)}>
        <img
          src={DropdownCircle}
          alt=""
          css={css`
            margin-right: 12px;
          `}
        />
        {feature.name}
      </DropdownContentStyled>
      <Collapse in={open}>
        <div>
          <DropdownDescription>{feature.description}</DropdownDescription>
          <CheckmarkList>
            {feature.points.map(point => (
              <li>{point}</li>
            ))}
          </CheckmarkList>
        </div>
      </Collapse>
    </DropdownTab>
  );
};

export default FeatureDropdown;
