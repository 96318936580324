/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, jsx, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import qs from 'qs';
import Page from 'components/layouts/Page';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import TeamSelect from 'components/vibes/team-vibe-show/partials/TeamSelect';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import LargeSpeedometer from 'components/vibes/team-vibe-show/TeamVibeDashboard/LargeSpeedometer';
import { CHECK_UNAUTHORIZED, getUrl, HEADERS } from 'helpers';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';

import SmallSpeedometer from 'components/vibes/team-vibe-show/TeamVibeDashboard/SmallSpeedometer';
import WeeklySummary from 'components/vibes/team-vibe-show/WeeklySummary';
import ReportDownload from 'pages/Insights/Dashboard/ReportDownload';
import RecommendationSection from 'components/vibes/team-vibe-show/partials/Recommendations/RecommendationSection';
import { USER_ACTIONS } from 'redux/actions/userActions';
import { INITIAL_ACTIONS } from 'redux/actions/initialActions';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import ScoreHistoryChart from 'components/vibes/ScoreHistoryChart';
import Can from 'rbac/Can';
import { insights } from 'rbac/plans';
import RecommendationsInfo from './RecommendationsInfo';
import SampleContentBanner from '../shared/SampleContentBanner';
import { INSIGHTS_STEPS } from '../../../onboardingSteps';
import DropdownBlue from '../../Grow/DropdownBlue';
import ChartsPreview from './ChartsPreview';
import TriggersAnonymousPreview from './TriggersAnonymousPreview';
import { ReactComponent as TeamDetails } from './details.svg';
import TeamModal from '../../../components/company_structure/view_mode/TeamModal';
import OtherMetrics from './OtherMetrics';
import {
  PerformanceContainer,
  PerformanceMetric,
} from 'pages/Insights/Dashboard/PerformanceMetric';
import PerformanceTooltips from 'pages/Insights/Dashboard/PerformanceTooltips';
import VibeInfo from './VibeInfo';

const initialState = {
  engagement: {
    current: 0,
    previous: 0,
  },
  activeUsers: {
    current: 0,
    previous: 0,
  },
  vibe: {
    overall: null,
    current: 0,
    previous: 0,
  },
  recommendations: [],
  canSeeRecommendations: false,
  canSeeAnonymousFeedback: false,
  vibeStats: {},
  keyPerformanceMetrics: {},
  weeklySummary: null,
  isInformal: false,
  showTeamDetailsModal: false,
  anonymousComments: [],
  vibeStatsPerformance: [],
  positions: null,
  factorsData: [],
  dates: undefined,
};

export const METRICS = {
  'Excitement & Energy': 'Excitement & Energy',
  'Being Connected': 'Being connected',
  'Learning & Growth': 'Learning & Growth',
  'Safety & Comfort': 'Safety & Comfort',
  'Autonomy & Independence': 'Autonomy & Independence',
  Purpose: 'Purpose',
  Valued: 'Valued',
  Productivity: 'Productivity',
  'Clarity & Certainty': 'Clarity & Certainty',
  External: 'External',
};
export const METRICS_TOOLTIPS = [
  'Is your team having fun at work or are they feeling bored and limited?',
  'Does your team feel like a team or just a bunch of individuals?',
  'Is your team developing new skills and receiving useful feedback or are they just treading water?',
  'Is your team able to share ideas and feedback freely or are they afraid to speak their mind?',
  'Does your team feel like they can self-manage at work or that they are being micromanaged too much?',
  'Does your team feel like they’re part of something bigger or are they just working for the paycheck?',
  'Is your team feeling appreciated for the work they do or are they feeling ignored and unrecognised?',
  'Is your team managing their workload or are they struggling to get things done?',
  'Does your team know what they’re doing and why they’re doing it or are they just working blind?',
  'Is your team happy or unhappy about what is happening outside of work?',
];

export const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
export const ScoreHistoryWrap = styled.div`
  flex: 1;
  @media (min-width: 1200px) {
    margin-left: 116px;
    margin-right: 35px;
  }
`;
export const SpeedometerWrap = styled.div`
  @media (min-width: 1200px) {
    margin-top: 50px;
    margin-left: 50px;
  }
`;
const ButtonBlue = styled.button`
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 26px;
  background: #11a9ff;
  border: none;
  border-radius: 4px;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.5);
  }
`;

const BeaconComponent = styled.span`
  animation: ${pulse} 2s ease-in-out infinite;
  border: 2px solid rgba(17, 169, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-top: -10px;
`;

class Dashboard extends Component {
  state = {
    fetching: true,
    enoughMembers: true,
    showPrevious: false,
    ...initialState,
  };

  componentDidMount() {
    this.props.weeklyInsightsShown();
    this.props.closeFlash();
    this.fetchData();
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    if ('insights_tour' in qsFilters) {
      this.props.startInsightsTour();
    }
  }

  componentDidUpdate(prevProps) {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const prevQuery = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });
    if (
      query.period !== prevQuery.period ||
      query.team !== prevQuery.team ||
      query.start_date !== prevQuery.start_date ||
      query.end_date !== prevQuery.end_date ||
      prevProps.teamsFromStore !== this.props.teamsFromStore
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    this.setState({ fetching: true });
    const headers = HEADERS();
    this.props.closeFlash();
    fetch(getUrl(`vibes/dashboard${window.location.search}`), { method: 'get', headers })
      .then(CHECK_UNAUTHORIZED)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.error) {
          this.props.updateFlash(responseData.flashName, responseData.message);
          this.setState(initialState);
          return true;
        }
        this.props.closeFlash();
        if (!responseData.enoughMembers) {
          this.setState(initialState);
        }
        responseData = {
          ...responseData,
          factorsData: responseData.factorsData ? Object.values(responseData.factorsData) : [],
        };
        this.setState({
          fetching: false,
          ...responseData,
        });
        this.props.refreshRecentTeams(responseData.recentSpaces);
        this.fetchTeamData();
      });
  };

  fetchTeamData = () => {
    const qsFilters = qs.parse(window.location.href.split('?')[1]);
    const teamId = parseInt(qsFilters.team);
    if (!this.state.teams) {
      const headers = HEADERS();
      fetch(getUrl('team_details'), { method: 'get', headers })
        .then(CHECK_UNAUTHORIZED)
        .then(response => response.json())
        .then(responseData => {
          responseData.filter(team => {
            team.parent_ids = team.parent_ids ? JSON.parse(team.parent_ids) : [];
          });
          this.setState({
            teams: responseData,
            team: responseData.find(team => team.id === teamId),
          });
        });
    } else {
      this.setState({
        team: this.state.teams.find(team => team.id === teamId),
      });
    }
  };

  render() {
    const {
      location,
      history,
      params,
      displayVibe,
      setAction,
      setShowActionModal,
      clearActionBody,
      setInsightsTourStep,
      insightsTourStep,
      isWeeklySummaryVisible,
      feedEnabled,
      growEnabled,
    } = this.props;
    const {
      vibe,
      recommendations,
      showPrevious,
      weeklySummary,
      canSeeRecommendations,
      factorsData,
      dates,
      vibeStatsPerformance,
      positions,
      detailedFeedbackData,
      anonymousComments,
      canSeeAnonymousFeedback,
      isTeamLive30Days,
    } = this.state;
    const togglePeriodStepIsOn = INSIGHTS_STEPS[insightsTourStep]?.key === 'togglePeriodStep';
    const postOption = feedEnabled
      ? {
          label: 'Share',
          onClick: () => {
            clearActionBody();
            setAction('create-post');
            setShowActionModal(true);
          },
        }
      : '';
    const shoutoutOption = feedEnabled
      ? {
          label: 'Give/Get feedback',
          onClick: () => {
            clearActionBody();
            setAction('create-shoutout');
            setShowActionModal(true);
          },
        }
      : '';

    const noteOption = growEnabled
      ? {
          label: 'My notes',
          onClick: () => {
            clearActionBody();
            setAction('create-note');
            setShowActionModal(true);
          },
        }
      : '';
    return (
      <>
        <SampleContentBanner queryString={params} />
        <Page.Right>
          <ReportDownload queryString={params} isInformal={this.state.isInformal} />
          {displayVibe && (
            <ButtonBlue
              className="vibe_btn_target"
              onClick={() => {
                clearActionBody();
                setAction('create-vibe');
                setShowActionModal(true);
              }}
            >
              Vibe
            </ButtonBlue>
          )}
          <DropdownBlue
            className="take_action_target"
            options={[postOption, shoutoutOption, noteOption]}
            onClick={() => {
              if (feedEnabled || growEnabled) {
                clearActionBody();
                if (feedEnabled) {
                  setAction('create-shoutout');
                } else {
                  setAction('create-note');
                }
                setShowActionModal(true);
              }
            }}
            css={css`
              min-width: 175px;
              padding-left: 33px;
            `}
            wrapperStyles="margin-left: 16px;"
            disabledStyles={
              !feedEnabled && !growEnabled ? 'opacity: 50%; cursor: not-allowed;' : ''
            }
            disabledOptions={!feedEnabled && !growEnabled}
          >
            Take action
          </DropdownBlue>
        </Page.Right>
        <Page.Header icon={Insights}>Insights dashboard</Page.Header>
        <Page.Paper
          cssProps={css`
            position: relative;
          `}
        >
          <div className="team-vibe-dashboard">
            <div className="top-controls-wrap">
              <TeamSelect queryString={params} tabLink={location.pathname} />
              <DateRange queryString={params} tabLink={location.pathname} hideThisWeek />
              <div className="period-switch-wrap">
                {togglePeriodStepIsOn && (
                  <BeaconComponent
                    css={css`
                    width: 38px;
                    height: 38px;
                    margin-left: -2px;
                    }
                `}
                  />
                )}
                <SwitchButton
                  turnedOn={showPrevious}
                  onClick={() => {
                    if (togglePeriodStepIsOn && showPrevious === false) {
                      setInsightsTourStep(insightsTourStep + 1);
                    }
                    this.setState({ showPrevious: !showPrevious });
                  }}
                />

                <span>Show previous period</span>
              </div>
            </div>
          </div>
          {weeklySummary && isWeeklySummaryVisible && (
            <WeeklySummary content={weeklySummary.content} date={weeklySummary.date} />
          )}
          <ScoreContainer>
            <SpeedometerWrap>
              <LargeSpeedometer
                value={vibe.current}
                previousValue={vibe.previous}
                showPrevious={showPrevious}
                title="Vibe score"
              />
            </SpeedometerWrap>
            <ScoreHistoryWrap>
              <ScoreHistoryChart
                queryString={params}
                vibeStats={this.state.vibeStats}
                showPrevious={showPrevious}
              />
            </ScoreHistoryWrap>
          </ScoreContainer>

          <div
            css={css`
              text-align: center;
            `}
          >
            <VibeInfo vibe={this.state.vibe.overall} />
            {canSeeRecommendations && <RecommendationsInfo recommendations={recommendations} />}

            <SmallSpeedometer
              className={`engagement ${!isTeamLive30Days ? 'disabled' : 'active'}`}
              value={Math.floor(this.state.engagement.current)}
              previousValue={Math.floor(this.state.engagement.previous)}
              showPrevious={showPrevious}
              description={
                isTeamLive30Days
                  ? 'How many people have vibed over the period'
                  : 'Unlock this view once your team has been live for 30 days'
              }
              title="Participation"
            />
            <SmallSpeedometer
              className="active-users"
              value={Math.floor(this.state.activeUsers.current)}
              previousValue={Math.floor(this.state.activeUsers.previous)}
              showPrevious={false}
              description="How many users have logged in at least once over the last four weeks"
              title="Monthly active users"
            />
            {this.state.team && (
              <div
                onClick={() => {
                  this.fetchTeamData();
                  this.setState({ showTeamDetailsModal: true });
                }}
                css={css`
                  color: #11a9ff;
                  font-size: 15px;
                  line-height: 20px;
                  display: inline-block;
                  margin: 28px 20px;
                  text-align: center;
                  vertical-align: top;
                  cursor: pointer;
                `}
              >
                <TeamDetails
                  css={css`
                    display: block;
                    width: 50px;
                    height: 50px;
                    display: block;
                    margin: 0 auto 16px;
                    overflow: hidden;
                  `}
                />
                <div
                  css={css`
                    display: block;
                  `}
                >
                  View team details
                </div>
              </div>
            )}
          </div>

          <Page.Divider />
          <Page.H2 className="key_performance_target">Key performance metrics</Page.H2>
          <PerformanceContainer>
            {Object.keys(METRICS).map((key, index) => {
              const { keyPerformanceMetrics } = this.state;
              const metric = keyPerformanceMetrics && keyPerformanceMetrics[key];
              return (
                <PerformanceMetric
                  key={key}
                  label={METRICS[key]}
                  value={metric && metric.current}
                  previousValue={showPrevious && metric && metric.previous}
                  tooltip={METRICS_TOOLTIPS[index]}
                  onClick={() => {
                    if (
                      (metric?.current ?? false) !== false ||
                      ((showPrevious && metric?.previous) ?? false) !== false
                    ) {
                      history.push({
                        pathname: '/vibes/performance_over_time',
                        search: location.search,
                        state: { factor: key },
                      });
                    } else {
                      return false;
                    }
                  }}
                />
              );
            })}
            <Can
              perform={insights.accessFactorAnalysis}
              yes={() => <OtherMetrics data={factorsData?.find(stat => stat.category === 11)} />}
            />
          </PerformanceContainer>
          {canSeeAnonymousFeedback && (
            <TriggersAnonymousPreview
              queryString={params}
              detailedFeedbackData={detailedFeedbackData}
              anonymousComments={anonymousComments}
              search={location.search}
            />
          )}
          <ChartsPreview
            showPrevious={showPrevious}
            data={factorsData}
            canSeeFeedback={false}
            dates={dates}
            vibeStats={vibeStatsPerformance}
            chartData={positions}
            search={location.search}
            queryString={params}
          />
          <Page.Divider />
          <Page.Clearfix />

          {canSeeRecommendations && (
            <RecommendationSection
              recommendations={recommendations}
              updatedCompleted={(id, completed) => {
                const index = recommendations.findIndex(r => r.id === id);
                recommendations[index].completed = completed;
                this.setState({ recommendations });
              }}
              updateRecommendations={recommendations => {
                this.setState({ recommendations });
              }}
              queryString={params}
            />
          )}
          <PerformanceTooltips />
        </Page.Paper>
        {this.state.teams && this.state.team && (
          <TeamModal
            issuesMode={false}
            showTeamModal={this.state.showTeamDetailsModal}
            close={() => this.setState({ showTeamDetailsModal: false })}
            team={this.state.team}
            teams={this.state.teams}
            dashboard
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  displayVibe: !state.usersDomain.user.alreadyVibed,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
  isWeeklySummaryVisible: state.usersDomain.user.isWeeklySummaryVisible,
  teamsFromStore: state.teamsDomain.teams,
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
});
const mapDispatch = {
  refreshRecentTeams: USER_ACTIONS.refreshRecentTeams,
  setAction,
  setShowActionModal,
  clearActionBody,
  initialFetchData: INITIAL_ACTIONS.fetchData,
  startInsightsTour: ONBOARDING_ACTIONS.startInsightsTour,
  setInsightsTourStep: ONBOARDING_ACTIONS.setInsightsTourStep,
  weeklyInsightsShown: USER_ACTIONS.weeklyInsightsShown,
};

export default connect(mapStateToProps, mapDispatch)(Dashboard);
