/** @jsxImportSource @emotion/react */
import {
  PriceDescription,
  PriceNumber,
  PriceSection as PriceSectionLayout,
  PriceString,
} from 'components/payments/PaymentChoose/shared/layout';
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 13px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-top: 23px;
    margin-bottom: 16px;
  }
`;

const PriceSection = ({ price, isPriceString, description, image, currency = 'usd' }) => {
  const Image = image;

  return image ? (
    <ImageContainer>
      <Image />
    </ImageContainer>
  ) : (
    <PriceSectionLayout>
      {isPriceString ? <PriceString>{price}</PriceString> : <PriceNumber>{price}</PriceNumber>}
      <PriceDescription
        css={
          isPriceString
            ? css`
                max-width: 95px;
              `
            : ''
        }
      >
        {!isPriceString && currency.toUpperCase()} {description}
      </PriceDescription>
    </PriceSectionLayout>
  );
};

export default PriceSection;
