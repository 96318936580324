/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import Page from 'components/layouts/Page';
import debounce from 'lodash/debounce';
import { insights } from 'rbac/plans';
import Can from 'rbac/Can';
import useCan from 'rbac/useCan';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import PerformanceOverTimeChart from '../Factors/PerformanceOverTimeChart';
import FactorAnalysisChart from '../Factors/FactorAnalysisChart';
import ViewDetailsButton from '../shared/ViewDetailsButton';

const ChartsWrapper = styled.div``;
const Column = styled.div`
  float: left;
  width: 50%;
  padding: 0 10px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const ChartsPreview = ({
  showPrevious,
  data,
  canSeeFeedback,
  dates,
  vibeStats,
  chartData,
  search,
  queryString,
}) => {
  const [chartType, setChartType] = useState('horizontal');

  const handleResize = () => {
    if (chartType === 'vertical' && window.innerWidth < 800) {
      setChartType('horizontal');
    }
    if (chartType === 'horizontal' && window.innerWidth > 800) {
      setChartType('vertical');
    }
  };
  useEffect(() => {
    handleResize();
    return () => {
      window.onresize = null;
    };
  }, []);
  window.onresize = debounce(handleResize, 200);

  const canSeeFactors = useCan(insights.accessFactorAnalysis);

  return [
    <Can perform={insights.accessFactorAnalysis} no={() => <UpgradeModal />} />,
    <ChartsWrapper className="row" css={!canSeeFactors && grayFilterCss}>
      <Column>
        <Page.Divider />
        <Page.H2 className="factor_analysis_target">
          Factor analysis
          <Can
            perform={insights.accessFactorAnalysis}
            yes={() => <ViewDetailsButton url="/vibes/factor_analysis" search={search} />}
          />
        </Page.H2>
        <div className="team-vibe-dashboard">
          <div
            className={`factors-section-horizontal factors-section-${chartType} dashboard`}
            css={css`
              border-top: unset !important;
            `}
          >
            <FactorAnalysisChart
              showPrevious={showPrevious}
              data={data}
              canSeeFeedback={canSeeFeedback}
              chartType={chartType}
              dashboard
              queryString={queryString}
            />
          </div>
        </div>
      </Column>
      <Column>
        <Page.Divider />
        <Page.H2 className="performance_over_time_target">
          Performance over time
          <Can
            perform={insights.accessPerformanceOverTime}
            yes={() => (
              <ViewDetailsButton
                url="/vibes/performance_over_time"
                search={search}
                state={{ factor: 'all' }}
              />
            )}
          />
        </Page.H2>
        <PerformanceOverTimeChart
          dates={dates}
          vibeStats={vibeStats}
          chartData={chartData}
          dashboard
        />
      </Column>
    </ChartsWrapper>,
  ];
};
export default ChartsPreview;
