/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import styled from '@emotion/styled';

const ButtonBlue = styled.button`
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 26px;
  background: #11a9ff;
  border: none;
  border-radius: 4px;
  width: 100%;
  text-align: left;
`;

const DropdownBlue = ({
  options,
  children,
  onClick,
  wrapperStyles,
  optionsStyles,
  disabledStyles,
  disabledOptions,
  id,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      id={id}
      css={css`
        display: inline-block;
        vertical-align: top;
        position: relative;
        ${wrapperStyles && wrapperStyles}
      `}
    >
      <RootCloseWrapper
        onRootCloseWrapper={() => {
          setOpen(false);
        }}
      >
        <ButtonBlue
          css={css`
            ${open &&
            'background: linear-gradient(360deg, rgba(17, 169, 255, 0) 0%, #1492DA 100%), #11A9FF;'}
            ${disabledStyles && disabledStyles}
          `}
          {...props}
          onClick={() => {
            setOpen(false);
            onClick();
          }}
        >
          <span
            css={css`
              color: #8bdbff;
              padding: 11px;
              border-left: solid 1px rgba(139, 219, 255, 0.5);
              float: right;
              margin: -11px -26px -11px 0;
            `}
            dangerouslySetInnerHTML={{
              __html: '&blacktriangledown;',
            }}
            onClick={e => {
              e.stopPropagation();
              setOpen(!open);
            }}
          />
          {children}
        </ButtonBlue>
      </RootCloseWrapper>
      {!disabledOptions && (
        <div
          css={css`
            display: ${open ? 'block' : 'none'};
            background: #ffffff;
            box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            padding: 8px 24px;
            position: absolute;
            top: calc(100% + 3px);
            width: 100%;
            z-index: 1;
            ${optionsStyles && optionsStyles}
          `}
        >
          {options.map(option => (
            <button
              key={option.label}
              css={css`
                display: block;
                border: none;
                background: none;
                padding: 0;
                line-height: 38px;
                font-size: 15px;
                width: 100%;
                text-align: left;
              `}
              type="button"
              onClick={option.onClick}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownBlue;
