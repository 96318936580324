/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import SelectTour from './SelectTour';
import AccountForm from './AccountForm';
import Survey from './Survey';
import UploadPhoto from './UploadPhoto';
import Intro from './Intro';
import OnboardingSummary from './OnboardingSummary';

const Step = ({ step, nextStep, setTitle, ...props }) => {
  switch (step) {
    case 'intro':
      return <Intro nextStep={nextStep} />;
    case 'upload-photo':
      return <UploadPhoto nextStep={nextStep} />;
    case 'account-form':
      return <AccountForm nextStep={nextStep} />;
    case 'survey':
      return <Survey nextStep={nextStep} />;
    case 'tours':
      return <SelectTour nextStep={nextStep} />;
    case 'summary':
      return <OnboardingSummary setTitle={setTitle} />;
  }
};

const ModalContent = styled.div`
  padding: 35px;
  text-align: center;
  height: 100%;
`;

const ModalTitle = styled.div`
  padding-top: 10px;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 18px;
  color: #323232;
`;

const getTitle = step => {
  switch (step) {
    case 'intro':
      return 'Before we start, a quick video on why we’re here...';
    case 'upload-photo':
      return 'Please upload your photo';
    case 'account-form':
      return 'Before we get started, tell us a bit more about you';
    case 'tours':
      return 'How long would you like your tour to be?';
    case 'summary':
      return 'Boost your performance';
    default:
      return '';
  }
};

const setFirstStep = isAccountAdmin => {
  return isAccountAdmin ? 'intro' : 'upload-photo';
};

const OnboardingModal = ({ displayOnboardingModal, isAccountAdmin }) => {
  const [step, setStep] = useState(setFirstStep(isAccountAdmin));
  const [title, setTitle] = useState(getTitle(step));

  const nextStep = (tour = null) => {
    let nextStep = '';
    switch (step) {
      case 'intro':
        nextStep = 'upload-photo';
        break;
      case 'upload-photo':
        nextStep = isAccountAdmin ? 'account-form' : 'tours';
        break;
      case 'account-form':
        nextStep = 'survey';
        break;
      case 'survey':
        nextStep = 'tours';
        break;
      case 'tours':
        nextStep = tour;
        break;
      default:
        nextStep = 'tours';
        break;
    }
    setTitle(getTitle(nextStep));
    setStep(nextStep);
  };

  const height = step => {
    switch (step) {
      case 'tours':
        return '488';
      case 'account-form':
        return '745';
      case 'survey':
        return '280';
      default:
        return '';
    }
  };

  return (
    <Modal
      show={displayOnboardingModal}
      dialogClassName={`onboarding-modal ${step}`}
      backdrop
      keyboard
    >
      <div
        css={css`
          height: ${height(step)}px;
          width: ${step === 'summary' ? 860 : 630}px;
        `}
      >
        <ModalContent>
          {title && <ModalTitle>{title}</ModalTitle>}
          <Step step={step} nextStep={nextStep} setTitle={setTitle} />
        </ModalContent>
      </div>
    </Modal>
  );
};
const mapStateToProps = state => ({
  displayOnboardingModal: state.onboardingDomain.displayOnboardingModal,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});

export default connect(mapStateToProps, null)(OnboardingModal);
