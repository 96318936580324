import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-autocomplete-input/dist/bundle.css';
import Select from 'react-select';
import { connect } from 'react-redux';
import fetchApi from 'fetchApi';
import styled from '@emotion/styled';
import Loader from 'components/shared/Loader';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import { POST_ACTIONS } from '../../redux/actions/postActions';
import { CHECK_UNAUTHORIZED, HEADERS, findLabel } from '../../helpers';
import { APP_URL } from '../../constants';

const LeadsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeadWrap = styled.div`
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Picture = styled.img`
  width: 30px;
  margin-left: 10px;
  margin-right: 12px;
  border-radius: 50%;
`;

const RemoveButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: block;
  height: 27px;
  width: 27px;
  transform: translateY(-50%);
  color: #6a6a6a;
  &:before,
  &:after {
    border-radius: 2px;
    width: 15px;
    height: 2px;
    background: #c4c4c4;
    content: '';
    position: absolute;
    top: 100%;
    left: 100%;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 30px 30px 60px;
  z-index: 1;
`;

class EditLeadsModal extends Component {
  state = {
    isLoading: false,
    users: [],
    selectedUser: '',
    spaceMembers: [],
    spaceLeads: [],
    selectedUsers: [],
  };

  fetchMembers = async () => {
    this.setState({ isLoading: true });
    const response = await fetchApi(`/assign_lead/${this.props.type}/${this.props.assignTo.id}`);
    this.setState({
      users: response.members,
      spaceMembers: response.members.filter(
        user =>
          user.lead_spaces.findIndex(space => space.id === parseInt(this.props.assignTo.id, 10)) ===
          -1
      ),
      spaceLeads: response.members.filter(
        user =>
          user.lead_spaces_count > 0 &&
          user.lead_spaces.findIndex(space => space.id === parseInt(this.props.assignTo.id, 10)) >=
            0
      ),
      isLoading: false,
    });
  };

  componentDidMount() {
    this.props.assignTo && this.props.assignTo.id && this.fetchMembers();
  }

  componentWillUnmount() {
    this.setState({
      users: [],
      selectedUser: '',
      spaceMembers: [],
      spaceLeads: [],
      selectedUsers: [],
    });
  }

  submitForm = () => {
    this.setState({ isLoading: true });
    const { type, dispatchAfterFetch, assignTo } = this.props;
    const { spaceLeads, selectedUsers } = this.state;
    const headers = HEADERS();
    const formData = new FormData();
    spaceLeads.map(lead => formData.append('leads[]', lead.id));
    selectedUsers.map(lead => formData.append('leads[]', lead));
    formData.append('_method', 'PUT');
    fetch(`${APP_URL}/space/save_leads/${assignTo.id}`, { method: 'post', headers, body: formData })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (type === 'teams') {
            return dispatchAfterFetch && dispatchAfterFetch();
          }
          if (this.props.reloadData) {
            this.props.reloadData();
          }
          if (this.props.resetList) {
            this.props.resetList();
          }
          if (this.props.updateFlash) {
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
          }
          this.props.closeEditLeadsModalHandler();
        });
      })
      .catch(error => {
        this.setState({ isLoading: true });
        this.props.closeEditLeadsModalHandler();
      });
    this.setState({
      users: [],
      selectedUser: '',
      spaceMembers: [],
      spaceLeads: [],
      selectedUsers: [],
    });
  };

  selectMemberHandler = members => {
    const formattedMembers = members.map(user => user.value);
    this.setState({
      selectedUsers: formattedMembers,
    });
  };

  removeFromLeadsHandler = leadId => {
    const memberIndex = this.state.users.findIndex(m => m.id === leadId);
    if (memberIndex >= 0) {
      this.setState({
        spaceLeads: this.state.spaceLeads.filter(lead => lead.id !== leadId),
        spaceMembers: [...this.state.spaceMembers, this.state.users[memberIndex]],
      });
    }
  };

  render() {
    const spaceLeads = this.state.spaceLeads.map(lead => (
      <LeadWrap key={lead.id}>
        <Picture src={lead.image_url} alt="" />
        {lead.name}
        {lead.id !== this.props.user.id && (
          <RemoveButton onClick={() => this.removeFromLeadsHandler(lead.id)} />
        )}
      </LeadWrap>
    ));
    const spaceMembers = this.state.spaceMembers.map(user => ({
      value: user.id,
      label: `${user.name} (${user.slug})`,
    }));

    const { selectedUsers } = this.state;
    return (
      <Modal
        show={this.props.showEditLeadsModal}
        onHide={this.props.closeEditLeadsModalHandler}
        dialogClassName="informal-space-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.closeEditLeadsModalHandler} />
        <div className="row table nopadding">
          <div className="col-md-8 create-space nopadding">
            {this.state.isLoading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            <form className="modal-inner-wrap">
              <h1>Edit leads</h1>
              <h2>Current Leads</h2>
              <LeadsWrapper>{spaceLeads}</LeadsWrapper>
              <h2>
                Assign lead(s) to {this.props.assignTo && this.props.assignTo.name}{' '}
                {this.props.type === 'spaces' ? 'space' : 'team'}{' '}
              </h2>
              <Select
                options={spaceMembers}
                isSearchable
                onChange={e=>{this.selectMemberHandler(e)}}
                isMulti
                className="select-component assign-lead orange"
                value={selectedUsers?.length> 0 ? selectedUsers.map(userId => ({
                  value: userId,
                  label: findLabel(userId, spaceMembers),
                })): ''}
                classNamePrefix="r-select"
              />
              <div>
                <input
                  id="create-space-btn"
                  type="button"
                  onClick={() => this.submitForm()}
                  value="Save changes"
                />
                <span
                  id="error-message"
                  className={`error-message ${this.state.errorState && 'shown'}`}
                >
                  {this.state.errorState}
                </span>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

EditLeadsModal.defaultProps = {
  type: 'spaces',
};

const mapStateToProps = state => ({
  user: state.usersDomain.user,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadsModal);
