/** @jsxImportSource @emotion/react */
import React from 'react';
import { PropTypes } from 'prop-types';
import { jsx } from '@emotion/react';

import Feed from '../Feed';

const MyWinsIndex = props => {
  const types = [
    { caption: 'Private', filter: 'private-wins' },
    { caption: 'Shared wins', filter: 'shared-wins' },
  ];
  return (
    <Feed
      pageTitle="Wins"
      pageUrl="wins"
      types={types}
      exportUrl="wins"
      postsType="win"
      history={props.history}
      setErrorPage={props.setErrorPage}
    />
  );
};

MyWinsIndex.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  setErrorPage: PropTypes.func.isRequired,
};

export default MyWinsIndex;
