/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Component } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import qs from 'qs';
import Page from 'components/layouts/Page';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers.js';
import SampleContentBanner from 'pages/Insights/shared/SampleContentBanner';
import PerformanceOverTimeChart from 'pages/Insights/Factors/PerformanceOverTimeChart';
import Can from 'rbac/Can';
import { insights } from 'rbac/plans';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import DateRange from './partials/DateRange';
import SwitchButton from '../../shared/buttons/SwitchButton';
import TeamSelect from './partials/TeamSelect';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { APP_URL } from '../../../constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const defaultState = {
  vibeStats: [],
  positions: [],
  dates: [],
  goodFactorsNames: [],
  betterFactorsNames: [],
  selectedPosition: 1,
  selectedCategory: null,
  loaded: false,
  chartData: {
    'Excitement & Energy': [],
    'Learning & Growth': [],
    'Autonomy & Independence': [],
    Valued: [],
    'Clarity & Certainty': [],
    'Being Connected': [],
    'Safety & Comfort': [],
    Purpose: [],
    Productivity: [],
    External: [],
  },
  showFactor: {
    'Excitement & Energy': true,
    'Learning & Growth': true,
    'Autonomy & Independence': true,
    Valued: true,
    'Clarity & Certainty': true,
    'Being Connected': true,
    'Safety & Comfort': true,
    Purpose: true,
    Productivity: true,
    External: true,
  },
  dottedHover: {
    toggled: false,
    x: null,
    y: null,
  },
};

class PerformanceOverTime extends Component {
  factorKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  categoryNames = [
    'Excitement & Energy',
    'Learning & Growth',
    'Autonomy & Independence',
    'Valued',
    'Clarity & Certainty',
    'Being Connected',
    'Safety & Comfort',
    'Purpose',
    'Productivity',
    'External',
  ];

  categories = [
    ['EXCITEMENT', '& ENERGY'],
    ['LEARNING', '& GROWTH'],
    ['AUTONOMY &', 'INDEPENDENCE'],
    ['VALUED'],
    ['CLARITY &', 'CERTAINTY'],
    ['BEING', 'CONNECTED'],
    ['SAFETY &', 'COMFORT'],
    ['PURPOSE'],
    ['PRODUCTIVITY'],
    ['EXTERNAL'],
  ];

  constructor(props) {
    super(props);

    this.chartRef = React.createRef();

    this.state = defaultState;
  }

  formatString = string => {
    const re = new RegExp(String.fromCharCode(160), 'g');
    return string.replace(re, ' ');
  };

  componentDidMount() {
    this.fetchData();
    const {
      location: { state },
    } = this.props;
    if (state && state.factor) {
      const { showFactor } = this.state;
      Object.keys(showFactor).forEach(key => {
        let show = key === this.formatString(state.factor);
        if (state.factor === 'all') {
          show = true;
        }
        showFactor[key] = show;
      });
      this.setState({
        showFactor,
      });
    }
    this.checkParamsFromUrl();
  }

  componentDidUpdate = prevProps => {
    if (JSON.stringify(prevProps.queryString) !== JSON.stringify(this.props.queryString)) {
      this.fetchData();
    }
  };

  checkParamsFromUrl = () => {
    if (this.props.location.search) {
      const selectedFactor =
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).factor || '';

      if (this.factorKeys.includes(selectedFactor)) {
        const factorFromUrl = this.categoryNames[selectedFactor - 1];
        const stateCopy = JSON.parse(JSON.stringify(this.state.showFactor));
        const keys = Object.keys(stateCopy);
        keys.forEach(key =>
          key !== factorFromUrl ? (stateCopy[key] = false) : (stateCopy[key] = true)
        );
        this.setState({
          showFactor: stateCopy,
        });
      }
    }
  };

  joinArray(array) {
    return array.join('<br/>');
  }

  loadChartHandler = category => {
    const { showFactor } = this.state;
    showFactor[category] = !showFactor[category];
    this.setState({
      showFactor,
    });
  };

  fetchData = () => {
    this.props.closeFlash();
    const headers = HEADERS();
    fetch(`${APP_URL}/performance_over_time_stats?${qs.stringify(this.props.queryString)}`, {
      method: 'get',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.error) {
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
            this.setState(defaultState);
            return true;
          }
          if (jsonResponse.enoughMembers) {
            this.setState({
              vibeStats: jsonResponse.vibe_stats,
              chartData: jsonResponse.positions,
              dates: jsonResponse.dates,
              loaded: true,
            });
          } else {
            this.setState(defaultState);
          }
          this.props.refreshRecentTeams(jsonResponse.recentSpaces);
        });
      })
      .catch(error => {
        this.setState({
          loaded: false,
        });
        console.log(error);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <SampleContentBanner queryString={this.props.queryString} />
        <Page.Header icon={Insights}>Performance over time</Page.Header>
        <Can perform={insights.accessFactorAnalysis} no={() => <UpgradeModal />} />
        <Can perform={insights.accessFactorAnalysis}>
          {canSeeFactors => (
            <Page.Paper cssProps={!canSeeFactors && grayFilterCss}>
              <div className="performance-over-time-tab" id="performance-over-time-tab">
                <div className="top-controls-wrap">
                  <TeamSelect
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                  />
                  <DateRange
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                    hideThisLastWeek={this.props.hideThisLastWeek}
                  />
                </div>
                <div className="chart-categories" id="chart-categories">
                  <ul id="categories-btns" className="hidden-sm hidden-xs">
                    {this.categories.map((category, index) => {
                      const cat = this.joinArray(category);
                      const i = index + 1;
                      return (
                        <li
                          id={`category-${i}`}
                          className={
                            this.state.showFactor[this.categoryNames[index]]
                              ? 'category-btn active'
                              : 'category-btn'
                          }
                          key={cat}
                          dangerouslySetInnerHTML={{ __html: cat }}
                          onClick={() => this.loadChartHandler(this.categoryNames[index])}
                        />
                      );
                    })}
                  </ul>
                </div>
                <PerformanceOverTimeChart
                  dates={this.state.dates}
                  vibeStats={this.state.vibeStats}
                  chartData={this.state.chartData}
                  dashboard={false}
                  showFactor={this.state.showFactor}
                />
                <div className="mobile-chart-categories visible-xs visible-sm">
                  {this.categoryNames.map((category, index) => {
                    const i = index + 1;
                    return (
                      <div className="category-wrap" id={`mobile-category-${i}`} key={i}>
                        <div
                          className={
                            this.state.showFactor[category]
                              ? 'category-name active'
                              : 'category-name'
                          }
                        >
                          {category}
                        </div>
                        <div className="switch-wrap">
                          <SwitchButton
                            turnedOn={this.state.showFactor[category]}
                            onClick={() => this.loadChartHandler(category)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Page.Paper>
          )}
        </Can>
      </div>
    );
  }
}

export default PerformanceOverTime;
