/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../partials/Sidebar';
import SpacesActions from './SpacesActions';

const SpacesSidebar = ({ spaces, selectedSpace, updateFlash, reloadData, plan }) => {
  if (plan === 'free') {
    return null;
  }

  return (
    <React.Fragment>
      <Sidebar.Header>Actions</Sidebar.Header>
      <SpacesActions
        selectedSpace={selectedSpace}
        spaces={spaces}
        updateFlash={updateFlash}
        reloadData={reloadData}
      />
    </React.Fragment>
  );
};

SpacesSidebar.propTypes = {
  spaces: PropTypes.object.isRequired,
  selectedSpace: PropTypes.object.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
};

export default SpacesSidebar;
