/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { ONBOARDING_ACTIONS } from 'redux/actions/onboardingActions';
import { connect } from 'react-redux';
import { AWS_S3_URL } from '../../constants';
import OnboardingButton from './partials/OnboardingButton';

const Wrapper = styled.div`
  padding: 5px 40px 10px 40px;
  @media (max-width: 520px) {
    padding: 10px;
    img {
      max-width: 77%;
    }
  }
  @media (max-width: 335px) {
    padding: 5px;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #323232;
  text-align: center;
  padding: 0 20px 20px 20px;
`;

const HintWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
`;

const ButtonsWrapper = styled.div`
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 25px;
  a.btn {
    font-size: 14px;
    line-height: 35px;
  }
`;

const HintInfo = styled.div`
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  font-weight: bold;
  padding-top: 24px;
`;

const Image = styled.img`
  box-shadow: 2px 2px 22px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  margin: auto;
`;

const Description = styled.div`
  padding: 12px 40px 40px 40px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #323232;
`;

const Arrow = styled.i`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  width: 20px;
  height: 20px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  margin: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  float: ${props => (props.left ? 'left' : 'right')};
  color: ${props => (props.disabled ? '#D5D5D5' : '#11A9FF')};
  box-sizing: border-box;
  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
  }
  transform: rotate(${props => (props.left ? '-135' : '45')}deg);
`;

const MeetingScheduleSuccess = ({ nextStep, member, reminder, closeMeetingModal, closePopup }) => {
  const [hint, setHint] = useState(1);

  const interval = () => {
    switch (reminder) {
      case 'day':
        return 'the day';
      case 'days':
        return 'a few days';
      case 'week':
        return 'a week';
    }
  };

  const nextHint = () => {
    if (hint < 3) {
      setHint(hint + 1);
    }
  };

  const prevHint = () => {
    if (hint > 1) {
      setHint(hint - 1);
    }
  };

  const description = () => {
    switch (hint) {
      case 1:
        return "You'll get a reminder on your Grow board (and via email) to get ready for your meeting.";
      case 2:
        return 'Simply fill out the short template on what you want to talk about.';
      case 3:
        return 'Start your meeting, jot down what was discussed and next steps. We’ll then share the meeting note with you and your team member.';
    }
  };

  const meetingMember = () => {
    return member.first_name ? member.first_name + ' ' + member.last_name : member.name;
  };

  return (
    <Wrapper>
      <Title>
        Your meeting with {meetingMember()} is set up.&nbsp; We’ll send you an email notification
        and put an item on your Grow board&nbsp;
        {interval()} before your meeting.
      </Title>
      <HintWrapper>
        <Arrow left disabled={hint === 1} onClick={prevHint} />
        <Image src={`${AWS_S3_URL}public/images/hint-${hint}.svg`} />
        <Arrow left={false} disabled={hint === 3} onClick={nextHint} />
      </HintWrapper>
      <HintInfo>Hint {hint} of 3</HintInfo>
      <Description>{description()}</Description>
      <ButtonsWrapper>
        <OnboardingButton
          onClick={e => {
            e.preventDefault();
            closeMeetingModal();
            closePopup(true);
          }}
          label="Close"
          submit={true}
          top={10}
          width={80}
          height={36}
        />
        &nbsp;or&nbsp;
        <OnboardingButton
          onClick={nextStep}
          label="Set up another meeting"
          submit
          top={10}
          width={190}
          height={36}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  closeMeetingModal: permanently => dispatch(ONBOARDING_ACTIONS.closeMeetingModal(permanently)),
});

export default connect(null, mapDispatchToProps)(MeetingScheduleSuccess);
