/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Player } from 'video-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ExifOrientationImg from 'react-exif-orientation-img';

import loadImage from 'blueimp-load-image';
import { css, jsx } from '@emotion/react';
import mimeTypes from 'components/shared/GooglePicker/mimeTypes';
import { ROTATE_IE_IMAGE, TOOLTIP_CONTENT } from '../../../helpers';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import { COMMENT_ACTIONS } from '../../../redux/actions/commentActions';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import PostLikesModal from './PostLikesModal';
import camera from '../../../images/feed/camera.svg';
import activeCamera from '../../../images/feed/active_camera.svg';
import likeItBtn from '../../../images/feed/like-it.png';
import likeItBlueBtn from '../../../images/feed/like-it-blue.png';

class CommentInput extends Component {
  constructor(props) {
    super(props);
    this.commentDropzone = React.createRef();
  }

  state = {
    comment: '',
    errorAction: null,
    errorComment: null,
    markingAsDone: false,
    commentCharsLeft: 1000,
    showLikesModal: false,
    unfollowVisible: false,
    lightboxIsOpen: false,
    currentImage: 0,
    showCommentMediaDropzone: false,
    commentMediaUrl: [],
    cameraImage: camera,
    showSendButton: false,
    likers: [],
  };

  shiftPressed = false;

  commentInput = null;

  submittingComment = false;

  cameraImageHandler = img => {
    this.setState({
      cameraImage: img,
    });
  };

  addCommentHandler = () => {
    const media = [];
    const data = {
      content: this.state.comment,
    };
    for (let i = 0; i < this.state.commentMediaUrl.length; i++)
      media.push(JSON.stringify(this.state.commentMediaUrl[i]));
    data.media = media;
    if (
      !this.submittingComment &&
      this.state.commentCharsLeft >= 0 &&
      this.state.commentMediaUrl.length < 6
    ) {
      this.props.addComment(this.props.post.id, data, this.props.type);
      this.setState(
        {
          comment: '',
          commentMediaUrl: [],
          showCommentMediaDropzone: false,
          showSendButton: false,
        },
        () => {
          this.commentInput.blur();
        }
      );
    }
  };

  commentFileUploadHandler = files => {
    let commentMediaUrl = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const index = i;
      const type = file.type.split('/')[0];

      if (type === 'video' && this.state.commentMediaUrl.length > 0) {
        alert('You cannot add a video once there are images in the post');
        break;
      }

      if (
        type === 'image' &&
        this.state.commentMediaUrl.length === 1 &&
        this.state.commentMediaUrl[0].type === 'v'
      ) {
        alert('You cannot add an image once there is a video in the post');
        break;
      }

      if (this.commentAllFilesSize + file.size > 18 * 1024 * 1024) {
        alert(
          'Your attachments are a bit big... Maximum total size allowed is 18MB. Please reduce your file size or remove some images to continue.'
        );
        break;
      }
      this.commentAllFilesSize += file.size;

      const maxSize = mimeTypes.VIDEO.includes(file.mimeType ?? file.type) ? 18 : 6;

      if (file.size > maxSize * 1024 * 1024) {
        alert(`One of files is too big! Maximum size allowed is ${maxSize}MB`);
        break;
      }

      if (
        (type === 'image' &&
          !(this.state.commentMediaUrl[0] && this.state.commentMediaUrl[0].type === 'v')) ||
        (type === 'video' && this.state.commentMediaUrl.length === 0)
      ) {
        if (type === 'video' && files.length > 1) {
          // Breaks loop if there are images and videos simultaneously
          this.setState({
            commentMediaUrl: [],
          });
          alert('You may attach either one video or few images.');
          break;
        }

        const reader = new FileReader();
        reader.onload = ((index, type) => e => {
          if (type === 'image') {
            const loadImageOptions = {
              orientation: true,
              noRevoke: true,
            };
            const img = ROTATE_IE_IMAGE(e.target.result);
            loadImage(
              img,
              canvas => {
                commentMediaUrl[index] = {
                  url: img,
                  type: type === 'image' ? 'i' : 'v',
                };
                if (commentMediaUrl.filter(Boolean).length === files.length) {
                  this.setState(
                    {
                      commentMediaUrl: [...this.state.commentMediaUrl, ...commentMediaUrl],
                      disabledButton: false,
                    },
                    () => {
                      this.props.tileRealignHandler();
                      this.commentInput.focus();
                    }
                  );
                }
              },
              loadImageOptions
            );
          } else {
            commentMediaUrl[index] = {
              url: reader.result,
              type: type === 'image' ? 'i' : 'v',
            };
            if (commentMediaUrl.filter(Boolean).length === files.length) {
              this.setState(
                {
                  commentMediaUrl: [...this.state.commentMediaUrl, ...commentMediaUrl],
                  disabledButton: false,
                },
                () => {
                  this.props.tileRealignHandler();
                  this.commentInput.focus();
                }
              );
            }
          }
        })(index, type);
        reader.readAsDataURL(file);
      } else {
        commentMediaUrl = [];
        alert('You may attach either one video or a few images.');
        return;
      }
    }
  };

  commentContentChangedHandler = (e, unformattedContent) => {
    const input = e.target.value;
    this.setState({
      comment: input,
      commentCharsLeft: 1000 - unformattedContent.length,
      errorComment:
        unformattedContent.length > 1000
          ? 'You’ve hit the maximum comment length which is 1000 characters.'
          : '',
      showSendButton: Boolean(input.length),
    });
  };

  removeNewCommentMediaHandler = index => {
    const updatedMediaUrl = [...this.state.commentMediaUrl];
    updatedMediaUrl.splice(index, 1);
    this.setState(
      {
        commentMediaUrl: updatedMediaUrl,
      },
      this.props.tileRealignHandler()
    );
  };

  toggleLikesModal = e => {
    if (e) e.preventDefault();
    this.setState({ showLikesModal: !this.state.showLikesModal });
  };

  render() {
    const commentDropzone = (
      <div
        css={
          this.state.showSendButton
            ? css`
                right: 78px !important;
              `
            : null
        }
        className="comment-dropzone-wrap"
      >
        <Dropzone onDrop={e => this.commentFileUploadHandler(e)}
          accept={{
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
            "video/*": [".mp4", ".mov", ".avi"],
          }}
          ref={this.commentDropzone}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              <div {...getRootProps({
                style: {
                  width: '100%',
                  height: '70px',
                  // border: 'dashed 1px #D5D5D5',
                  borderRadius: '3px',
                  position: 'relative',
                  zIndex: '1',
                }
              })}>
                <input {...getInputProps()} />

                <img
                  data-tooltip-id={`media-tooltip-${this.props.post.id}`}
                  className={this.props.reachedDataLimit ? 'cursor-not-allowed' : ''}
                  src={this.state.cameraImage}
                  onMouseEnter={() => this.cameraImageHandler(activeCamera)}
                  onMouseOut={() => this.cameraImageHandler(camera)}
                  alt=""
                />
              </div>

            </>
          )}
        </Dropzone>
        {this.props.reachedDataLimit && (
          <ReactTooltip
            id={`media-tooltip-${this.props.post.id}`}
            class="react-tooltip limit-reached"
            event="click"
            globalEventOff="click"
            place="left"
            type="dark"
            effect="solid"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: TOOLTIP_CONTENT(this.props.user.isAccountAdmin),
              }}
            />
          </ReactTooltip>
        )}
      </div>
    );

    let previewImages = null;

    if (this.state.commentMediaUrl.length > 0) {
      previewImages = (
        <div className="comment-images-wrap">
          {this.state.commentMediaUrl.map((media, index) =>
            media.type === 'i' ? (
              <div
                className="preview"
                onClick={() => this.removeNewCommentMediaHandler(index)}
                key={index}
              >
                <ExifOrientationImg src={media.url} alt="" className="preview-img" />
              </div>
            ) : (
              <div
                className="preview video-add-modal"
                key={index}
                onClick={() => this.removeNewCommentMediaHandler(index)}
              >
                <Player playsInline src={media.url} />
              </div>
            )
          )}
        </div>
      );
    }

    let commentPlaceholder = 'Be first to comment ...';
    if (this.props.post.comments.length > 0) {
      commentPlaceholder = 'Add your comment here';
    }
    const { post } = this.props;
    return (
      <>
        <div className="post-tile-comment-input" onClick={e => e.stopPropagation()}>
          <div
            className={`post-tile-icon-wrapper ${post.is_liker ? 'post-tile-liked' : 'post-tile-icon-like'
              }`}
          >
            <div
              id={`like-btn-${post.id}`}
              className="post-tile-icon"
              onClick={post.id > 0 ? () => this.props.likePost(post.id) : null}
            >
              <img src={post.is_liker ? likeItBlueBtn : likeItBtn} alt="" />
            </div>
            {this.props.user.id === post.user.id && (
              <span onClick={this.toggleLikesModal} id={`show-likers-${post.id}`}>
                {post.cache_total_likes > 0 ? post.cache_total_likes : null}
              </span>
            )}
            {this.props.user.id === post.user.id && this.state.showLikesModal && (
              <PostLikesModal
                likers={post.likers}
                toggleLikesModal={this.toggleLikesModal}
                likersNumber={post.cache_total_likes}
                postId={post.id}
                show={this.state.showLikesModal}
              />
            )}
          </div>
          <MentionsInputWrapper
            value={this.state.comment}
            contentChangedHandler={this.commentContentChangedHandler}
            placeholder={commentPlaceholder}
            className={this.state.errorComment ? 'error' : ''}
            setInputRef={ref => (this.commentInput = ref)}
            onKeyDown={e => {
              if (
                e.key === 'Enter' &&
                e.target.value.trim() === '' &&
                this.state.commentMediaUrl.length === 0
              ) {
                // ---Prevent empty comments
                e.preventDefault();
                this.setState({ errorComment: "This field can't be empty" });
              } else if (e.key === 'Enter' && !this.shiftPressed) {
                // --- Submit if shift not pressed
                e.preventDefault();
                this.addCommentHandler();
                this.props.tileRealignHandler();
              } else {
                // ---Realign tiles on natural line break
                const element = e.target;
                const previousHeight = window.getComputedStyle(element).height;
                setTimeout(() => {
                  if (previousHeight != window.getComputedStyle(element).height)
                    this.props.tileRealignHandler();
                }, 10);
                //---------------------
              }
              if (e.key === 'Shift') this.shiftPressed = true;
            }}
            onKeyUp={e => {
              if (e.key === 'Shift') this.shiftPressed = false;
            }}
            showSendButton={this.state.showSendButton}
          />
          {commentDropzone}
        </div>
        {this.state.errorComment ? (
          <div className="comment-error">{this.state.errorComment}</div>
        ) : (
          ''
        )}
        {previewImages}
        {this.state.commentMediaUrl.length > 5 ? (
          <div className="comment-error">You cannot add more than 5 images in the comment</div>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  submittingComment: state.postsDomain.submittingComment,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
});

const mapDispatchToProps = dispatch => ({
  likePost: postId => dispatch(POST_ACTIONS.likePost(postId)),
  addComment: (postId, content, type) =>
    dispatch(COMMENT_ACTIONS.addComment(postId, content, type)),
  resolvePost: (postId, content) => dispatch(POST_ACTIONS.resolvePost(postId, content)),
});

CommentInput.defaultProps = { tileRealignHandler: () => { } };

export default connect(mapStateToProps, mapDispatchToProps)(CommentInput);
