/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import Select from 'react-select';
import qs from 'qs';
import { connect } from 'react-redux';
import { getImage } from 'pages/Insights';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import Page from 'components/layouts/Page';
import SampleContentBanner from 'pages/Insights/shared/SampleContentBanner';
import AnonymousComments from 'pages/Insights/Dashboard/AnonymousComments';
import { css, jsx } from '@emotion/react';
import Can from 'rbac/Can';
import { insights } from 'rbac/plans';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import { APP_URL, AVATAR_GENERIC, AWS_S3_URL } from '../../../constants';
import DateRange from './partials/DateRange';
import TeamSelect from './partials/TeamSelect';
import { CHECK_UNAUTHORIZED, HEADERS, findLabel } from '../../../helpers';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DetailedFeedback from '../DetailedFeedback';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const defaultState = {
  selectedPosition: 1,
  selectedCategory: null,
  inactiveMoods: [],
  loaded: false,
  moods: [
    { id: -2, name: 'terrible', color: '#FE483C' },
    { id: -1, name: 'bad', color: '#FF7F63' },
    { id: 0, name: 'okay', color: '#BEB49E' },
    { id: 1, name: 'good', color: '#8ED04C' },
    { id: 2, name: 'awesome', color: '#52BA05' },
  ],
  factors: [],
  selectedFactor: 0,
  stats: [],
  dates: [],

  detailedFeedback: [],
  anonymousComments: [],
};

class TeamVibeMoodVsFactor extends Component {
  _isMounted = false;

  factorKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  constructor(props) {
    super(props);
    this.state = defaultState;
    this._customLabelX = this._customLabelX.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkParamsFromUrl();
    this.fetchFeedbackData();
    this.fetchData();
  }

  componentDidUpdate = prevProps => {
    if (JSON.stringify(prevProps.queryString) !== JSON.stringify(this.props.queryString)) {
      this.fetchFeedbackData();
      this.fetchData();
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkParamsFromUrl = () => {
    if (this.props.location.search) {
      const selectedFactor =
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).factor || '';

      if (this.factorKeys.includes(selectedFactor)) {
        this.setState({
          selectedFactor,
        });
      }
    }
  };

  fetchFeedbackData = () => {
    const headers = HEADERS();
    fetch(`${APP_URL}/vibes/mood-factor-feedback?${qs.stringify(this.props.queryString)}`, {
      method: 'get',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (this._isMounted) {
          response.json().then(jsonResponse => {
            if (jsonResponse.enoughMembers) {
              this.setState({
                detailedFeedback: jsonResponse.detailedFeedback,
                anonymousComments: jsonResponse.anonymousComments,
              });
            }
          });
        }
      });
  };

  fetchData = (factorId = null) => {
    this.props.closeFlash();
    const headers = HEADERS();
    const selectedFactor = factorId || this.state.selectedFactor;
    const url = `${APP_URL}/vibe_mood_vs_factor_stats?factor=${selectedFactor}&${qs.stringify(
      this.props.queryString
    )}`;
    fetch(url, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (this._isMounted) {
          response.json().then(jsonResponse => {
            if (jsonResponse.error) {
              this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
              this.setState({
                ...defaultState,
                selectedFactor,
                factors: {...jsonResponse.factors, 0: "All"},
                inactiveMoods: this.state.inactiveMoods,
              });
              return true;
            }
            if (jsonResponse.enoughMembers) {
              const maxValue = jsonResponse.stats
                ? Math.max.apply(
                    Math,
                    jsonResponse.stats.map(o =>
                      Math.max.apply(null, [o.awesome, o.good, o.okay, o.bad, o.terrible])
                    )
                  )
                : 10;
              this.setState(
                {
                  factors: {...jsonResponse.factors, 0: "All"},
                  stats: jsonResponse.stats,
                  dates: jsonResponse.dates,
                  maxValue,
                  loaded: true,
                  latestDate: jsonResponse.latestDate,
                },
                () => {
                  if (this.props.section) {
                    if (this.props.section === 'anonymous') {
                      this.anonymous.scrollIntoView();
                    } else if (this.props.section === 'feedback') {
                      this.feedback.scrollIntoView();
                    }
                  }
                  const section = this.props.location.state?.section;
                  if (section) {
                    if (section === 'anonymous') {
                      setTimeout(() => this.anonymous.scrollIntoView(), 1000);
                    } else if (section === 'feedback') {
                      setTimeout(() => this.feedback.scrollIntoView(), 1000);
                    }
                  }
                }
              );
            } else {
              this.setState({
                ...defaultState,
                selectedFactor,
                factors: {...jsonResponse.factors, 0: "All"},
                inactiveMoods: this.state.inactiveMoods,
              });
            }
            this.props.refreshRecentTeams(jsonResponse.recentSpaces);
          });
        }
      });
  };

  toggleLine(mood) {
    const arr = [...this.state.inactiveMoods];
    const index = arr.indexOf(mood);
    if (index !== -1) {
      arr.splice(index, 1);
      this.setState({ inactiveMoods: arr });
    } else {
      this.setState({ inactiveMoods: [...this.state.inactiveMoods, mood] });
    }
  }

  checkIfActive(index) {
    return !this.state.inactiveMoods.includes(index);
  }

  changeFactor(e) {
    this.setState({ selectedFactor: e.value });
    this.fetchData(e.value);
  }

  factorsOptions() {
    const opts = [];
    const { factors } = this.state;
    Object.keys(this.state.factors).forEach(factor => {
      opts.push({ value: factor, label: factors[factor] });
    });
    return opts;
  }

  _customLabelX(value, index) {
    const date = this.state.dates ? this.state.dates[index] : null;
    return moment(date).format('D MMM').toUpperCase();
  }

  _customTitleTooltip(tooltipItem, data) {
    return `${tooltipItem[0].formattedValue}`;
  }

  _customMaxValue(max) {
    return max && max > 0 ? Math.ceil(max / 10) * 10 : 10;
  }

  _options() {
    const max = this.state.maxValue;
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 45,
          bottom: 35,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          titleColor: '#575563',
          backgroundColor: 'white',
          titleFont: {
            family: 'Roboto, sans-serif',
            size: 14,
            weight: 'Bold',
          },
          borderColor: 'rgba(0, 0, 0, 0.15)',
          borderWidth: 1,
          titleMarginBottom: 0,
          cornerRadius: 3,
          padding: {
            x: 16,
            y: 7,
          },
          titleAlign: 'center',
          bodyAlign: 'center',
          displayColors: false,
          titleMarginBottom: 0,
          caretPadding: 10,
          yAlign: 'bottom',
          xAlign: 'center',
          callbacks: {
            title: this._customTitleTooltip,
            label: () => null,
          },
        },
      },
      scales: {
        y: {
          position: 'right',
          scaleFontColor: '#706E84',
          scaleFontSize: 12,
          scaleFontFamily: 'Roboto',
          scaleFontStyle: 'Regular',
          suggestedMax: this._customMaxValue(max),
          suggestedMin: 0,
          ticks: {
            color: '#C4C4C4',
            stepSize: max > 100 ? 25 : 10,
            padding: 9,
            callback(value, index, values) {
              const max = Math.max.apply(
                null,
                values.map(val => val.value)
              );
              const val = parseInt(value, 10);
              if (val === 0 || val === Math.ceil(max / 10) * 10) return val;
              return '';
            },
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
            zeroLineColor: '#F3F3F3',
            zeroLineWidth: 2,
            color: '#F3F3F3',
          },
          title: {
            display: true,
            color: '#C4C4C4',
            font: {
              family: 'Roboto',
              lineHeight: '17px',
            },
            text: 'Number of people',
            padding: 0,
            rotation: 180,
          },
        },
        x: {
          scaleFontColor: '#C4C4C4',
          scaleFontSize: 11,
          scaleFontFamily: 'Roboto',
          scaleFontStyle: 'Regular',
          ticks: {
            color: '#C4C4C4',
            font: {
              size: 11,
              family: 'Roboto',
            },
            padding: 10,
            callback: this._customLabelX,
          },
          grid: {
            display: false,
          },
        },
      },
      spanGaps: true,
      interaction: {
        mode: 'nearest',
        intersect: true,
      },
    };
  }

  _data() {
    const _ = require('underscore');
    const datasets = [];
    const { inactiveMoods } = this.state;
    const moods = this.state.moods.filter(o => !inactiveMoods.includes(o.id));
    for (let i = 0; i < moods.length; i++) {
      const mood = moods[i];
      const obj = {
        label: mood.name,
        fill: false,
        borderColor: mood.color,
        borderWidth: 2,
        pointHoverBackgroundColor: '#FFF',
        pointBackgroundColor: '#FFF',
        pointHoverBorderColor: mood.color,
        pointHoverRadius: 4,
        pointHitRadius: 4,
        lineTension: 0,
      };
      datasets.push({
        ...obj,
        data: this._buildGraphData(mood.name),
      });

      datasets.push({
        ...obj,
        label: `${mood.name} prediction`,
        data: this.buildDottedLine(mood.name),
        borderDash: [0, 7],
        borderCapStyle: 'square',
      });
    }
    return {
      labels: this.state.dates,
      datasets: this.state.loaded ? datasets : [],
    };
  }

  _buildGraphData(name) {
    const _ = require('underscore');
    const arr = [];
    const { stats } = this.state;

    this.state.dates.forEach(item => {
      if (item === this.state.latestDate) return;
      const stat = _.findWhere(stats, { date: item });
      if (stat) {
        arr.push(stat[name]);
      } else {
        arr.push(0);
      }
    });
    return arr;
  }

  buildDottedLine(name) {
    const _ = require('underscore');
    const arr = [];
    const { stats, latestDate } = this.state;
    const beforeLatestDate = moment(latestDate).subtract(7, 'days').format('YYYY-MM-DD');

    this.state.dates.forEach(item => {
      const stat = _.findWhere(stats, { date: item });
      const statName = stat && stat[name];
      if (item === latestDate) {
        arr.push(statName || null);
      } else if (item === beforeLatestDate) {
        arr.push(statName || 0);
      } else {
        arr.push(null);
      }
    });
    return arr;
  }

  render() {
    const team = this.props.teams.find(team => team.id == this.props.queryString.team);
    return (
      <div
        css={css`
          position: relative;
        `}
      >
        <SampleContentBanner queryString={this.props.queryString} />
        <Page.Header icon={Insights}>Mood vs Factor</Page.Header>
        <Can perform={insights.accessMoodVsFactor} no={() => <UpgradeModal />} />
        <Can perform={insights.accessMoodVsFactor}>
          {canSeeFactors => (
            <Page.Paper cssProps={!canSeeFactors && grayFilterCss}>
              <div className="mood-vs-factor-vibe" id="mood-vs-factor-vibe">
                <div className="top-controls-wrap">
                  <TeamSelect
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                  />
                  <DateRange
                    queryString={this.props.queryString}
                    tabLink={this.props.location.pathname}
                    hideThisLastWeek={this.props.hideThisLastWeek}
                  />
                </div>
                <div className="mood-vs-factor-wrapper">
                  <div className="mood-vs-factor-chart">
                    <div className="chart-categories" id="chart-categories">
                      <ul id="categories-btns">
                        <li className="category-btn factor-select">
                          <Select
                            ref="select"
                            name="factor-select"
                            id="factor-select"
                            classNamePrefix="r-select"
                            onChange={id => this.changeFactor(id)}
                            value={
                              this.state.selectedFactor != 0 ?
                              {
                                value: this.state.selectedFactor,
                                label: findLabel(this.state.selectedFactor, this.factorsOptions()),
                              } : {
                                value: 0,
                                label: "All"
                              }
                            }
                            defaultValue={{
                              value: 0,
                              label: "All"
                            }}
                            options={this.factorsOptions()}
                            isSearchable={false}
                          />
                        </li>
                        {this.state.moods.map(mood => (
                          <li
                            key={mood.id}
                            id={`category-${mood.name}`}
                            className={`category-btn ${mood.name}
                                    ${this.checkIfActive(mood.id) && 'active'}`}
                            onClick={() => this.toggleLine(mood.id)}
                          >
                            {this.checkIfActive(mood.id) ? (
                              <img
                                className="mood-icon"
                                src={getImage(true, mood.id)}
                                alt={mood.id}
                              />
                            ) : (
                              <img
                                className="mood-icon"
                                src={getImage(false, mood.id)}
                                alt={mood.id}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div id="stats-wrapper">
                      <div id="mood-over-time-chart">
                        <Line data={this._data()} options={this._options()} />
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.user.isAccountAdmin ||
                this.props.user.formalLeadTeamsWithChildrenIds.includes(
                  [...this.props.teams].find(team => team.id == this.props.queryString.team).id
                ) ? (
                  <>
                    <DetailedFeedback
                      teamName={team ? team.name : ''}
                      detailedFeedbackData={this.state.detailedFeedback}
                      factor={this.state.selectedFactor == 0 ? null : this.state.selectedFactor}
                    />
                    <div
                      className="anonymous-comments-section"
                      id="anonymous"
                      ref={el => {
                        this.anonymous = el;
                      }}
                    >
                      <h1>
                        Specific feedback
                        <svg width="30" height="10" viewBox="0 0 30 10" fill="none">
                          <path
                            d="M29.6978 0.394011C26.4881 -0.166582 20.3678 -0.277533 15.9571 1.06555C15.6582 1.06555 14.3523 1.06555 14.0534 1.06555C9.64274 -0.277533 3.52238 -0.160742 0.312733 0.394011C-0.0858511 0.504961 -0.0858511 2.4028 0.213087 2.51375C0.816207 2.8466 1.11514 3.51815 1.11514 3.51815C1.11514 3.51815 1.31444 4.18969 1.81791 6.64228C2.31614 9.10656 3.62203 9.5562 3.62203 9.5562C3.62203 9.5562 5.22685 10 7.03096 10C8.83508 10 9.94167 9.88911 11.1427 9.21757C12.3437 8.54602 12.8471 6.75907 12.8471 6.75907C12.8471 6.75907 13.4503 5.19409 13.8488 3.74589C13.9485 3.29625 15.9571 3.29625 16.0568 3.74589C16.4554 5.19992 17.0585 6.75907 17.0585 6.75907C17.0585 6.75907 17.562 8.54602 18.763 9.21757C19.9693 9.88911 21.0706 10 22.8747 10C24.6788 10 26.2836 9.5562 26.2836 9.5562C26.2836 9.5562 27.5895 9.22335 28.0877 6.65396C28.5912 4.08458 28.7905 3.52983 28.7905 3.52983C28.7905 3.52983 29.0894 2.85828 29.6925 2.52543C30.1016 2.40864 30.1016 0.394011 29.6978 0.394011Z"
                            fill="#C4C4C4"
                          />
                        </svg>
                      </h1>
                      <AnonymousComments
                        queryString={this.props.queryString}
                        anonymousComments={
                          this.state.selectedFactor == 0 ? 
                            this.state.anonymousComments : 
                            this.state.anonymousComments.filter(comment => 
                              comment.position == this.state.selectedFactor
                            )
                        }
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Page.Paper>
          )}
        </Can>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
});
export default connect(mapStateToProps)(TeamVibeMoodVsFactor);
