import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HEADERS } from 'helpers';
import { AWS_S3_URL, AVATAR_GENERIC, APP_URL } from '../../../constants';

class PostLikesModal extends Component {
  state = {
    likers: [],
  };

  componentDidMount() {
    if (this.props.likers) {
      this.setState({ likers: this.props.likers });
    } else {
      this.fetchLikers();
    }
  }

  fetchLikers = async () => {
    const headers = HEADERS();
    const response = await fetch(`${APP_URL}/post/${this.props.postId}/likers`, {
      method: 'get',
      headers,
    });
    const json = await response.json();
    this.setState({ likers: json.likers });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.toggleLikesModal}
        className="likers-modal"
        dialogClassName="modal-sm"
      >
        <Modal.Header>
          <Modal.Title>
            {this.props.likersNumber === 1
              ? `${this.props.likersNumber} like`
              : `${this.props.likersNumber} likes`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {this.state.likers.map((liker, index) => (
              <li className="u-margin-vertical-xs" key={index}>
                <div className="media">
                  <div className="media-left">
                    <img
                      src={liker.image_uid !== null ? liker.image_url : AWS_S3_URL + AVATAR_GENERIC}
                      alt=""
                      className="img-circle media-object"
                      style={{ width: '28px', height: '28px' }}
                    />
                  </div>
                  <div className="media-body media-middle">
                    <div className="media-heading bold">
                      {`${liker.first_name} ${liker.last_name}`}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PostLikesModal;
