import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import All from './All';
import Free from './Free';
import Stripe from './Stripe';
import Offline from './Offline';
import { APP_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class AccountsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsCount: 0,
      activeTab: 'all',
      search: '',
      searchInput: '',
    };
  }

  componentDidMount() {
    const search = this.props.location.search.replace('?', '');
    const array = search.split('&');
    array.forEach(function (arr) {
      if (arr.split('=')[0] === 'search') {
        this.setState({ [arr.split('=')[0]]: arr.split('=')[1] });
      } else if (arr.split('=')[0] === 'tab') {
        const tab = arr.split('=')[1] === 'offline' ? 'invoiced' : arr.split('=')[1];
        this.setState({ activeTab: tab });
        const url = `/admin/accounts?${search}`;
        this.props.history.push(url);
      }
    }, this);
  }

  updateAccountsCount(accountsCount) {
    this.setState({ accountsCount });
  }

  action() {
    switch (this.state.activeTab) {
      case 'all':
        return (
          <All
            updateAccountsCount={this.updateAccountsCount.bind(this)}
            search={this.state.search}
            history={this.props.history}
            location={this.props.location}
          />
        );
      case 'invoiced':
        return (
          <Offline
            search={this.state.search}
            history={this.props.history}
            location={this.props.location}
          />
        );
      case 'stripe':
        return (
          <Stripe
            search={this.state.search}
            history={this.props.history}
            location={this.props.location}
          />
        );
      case 'free':
        return (
          <Free
            search={this.state.search}
            history={this.props.history}
            location={this.props.location}
          />
        );
      default:
        return '';
    }
  }

  changeTab(e, tab) {
    e.preventDefault();
    this.setState({ activeTab: tab });
    const url = `/admin/accounts?tab=${tab}&search=${this.state.search}`;
    if (url !== this.props.location.pathname + this.props.location.search) {
      this.props.history.push(url);
    }
  }

  search(e) {
    e.preventDefault();
    const newSearch = this.state.searchInput;
    this.setState({ search: newSearch });
  }

  downloadAccountsReport(e) {
    e.preventDefault();
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    fetch(`${APP_URL}/admin/accounts/accounts_report`, { method: 'get', headers })
      .then(response => {
        response.json().then(jsonResponse => {
          this.downloadCsv(jsonResponse.data, jsonResponse.fileName);
        });
      })
      .catch(() => {});
  }

  downloadCsv(data, name) {
    let csv = '';
    data.forEach(row => {
      csv += row.join(',');
      csv += '\n';
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      navigator.msSaveBlob(blob, name);
    } else {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${name}.csv`;
      document.body.appendChild(hiddenElement);
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    }
  }

  render() {
    return (
      <div>
        <div className="row" style={{ marginTop: '50px' }}>
          <div className="col-md-9" style={{ padding: '0' }}>
            <form action="">
              <div className="col-md-5" style={{ padding: '0' }}>
                <div className="form-group email optional user_email col-md-8">
                  <input
                    className="string email optional form-control"
                    id="search"
                    type="text"
                    onChange={e => {
                      this.setState({ searchInput: e.target.value });
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary col-md-4"
                  onClick={e => this.search(e)}
                >
                  <i className="fa fa-search" />
                  Search
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-3">
            <p>
              <Link
                to="#"
                className="btn btn-primary pull-right margin-below"
                onClick={e => this.downloadAccountsReport(e)}
              >
                Download Accounts Report
              </Link>
            </p>
          </div>
        </div>
        <div className="row">
          <ul className="nav nav-tabs">
            <li className={this.state.activeTab === 'all' ? 'active' : ''}>
              <a href="/#" onClick={e => this.changeTab(e, 'all')}>
                All <small className="text-tiny text-muted">{this.state.accountsCount}</small>
              </a>
            </li>
            <li className={this.state.activeTab === 'invoiced' ? 'active' : ''}>
              <a href="/#" onClick={e => this.changeTab(e, 'invoiced')}>
                Invoiced
              </a>
            </li>
            <li className={this.state.activeTab === 'stripe' ? 'active' : ''}>
              <a href="/#" onClick={e => this.changeTab(e, 'stripe')}>
                Stripe
              </a>
            </li>
            <li className={this.state.activeTab === 'free' ? 'active' : ''}>
              <a href="/#" onClick={e => this.changeTab(e, 'free')}>
                Free
              </a>
            </li>
          </ul>
        </div>
        <div className="row">{this.action()}</div>
      </div>
    );
  }
}

export default AccountsIndex;
