import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/symbol';
import 'core-js/features/set';
import 'core-js/features/map';
import 'regenerator-runtime/runtime';
import React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { CookiesProvider } from 'react-cookie';

import store from './redux/store';

import { GOOGLE_ANALYTICS_ID } from './constants';

import CustomRouter from './CustomRouter';

ReactGA.initialize(GOOGLE_ANALYTICS_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

require('./stylesheets/all.css');
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <CookiesProvider>
      <CustomRouter />
    </CookiesProvider>
  </Provider>
);
