import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const VibeTooltip = ({ id }) => (
  <ReactTooltip id={id} class="react-tooltip" place="top" type="dark" effect="solid">
    <span>
      Automated weekly engagement tracking to keep you on top of how your team are feeling at work.
      You get actionable insight to resolve issues quickly and collaboratively.
    </span>
  </ReactTooltip>
);

VibeTooltip.propTypes = {
  id: PropTypes.string.isRequired,
};
export default VibeTooltip;
