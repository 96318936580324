import { isUndefined } from 'lodash';

export default function chartLineHover(chart) {
  const hoverData = chart?.config?.data?.datasets.filter(d => d.lineHover);
  const intervals = [];

  if (isUndefined(hoverData)) return intervals;

  hoverData.forEach((value, index) => {
    let a = null;
    let b = null;
    const visibleMetas = chart.getSortedVisibleDatasetMetas().filter(d => d._dataset.lineHover);
    // // const meta = Object.values(_meta).find(Boolean);
    for (let i = 0; i < value.data.length; i++) {
      if (value.data[i] !== null) {
        if (a === null) a = visibleMetas[index]?.data[i];
        else if (b === null) b = visibleMetas[index]?.data[i];
        else break;
      }
    }
    const ax = a?.x;
    const ay = a?.y;
    const bx = b?.x;
    const by = b?.y;

    const hoverWidth = 6;
    const dotOffset = 5;
    intervals.push(
      (x, y) =>
        x > ax + dotOffset && x < bx - dotOffset && y > ay - hoverWidth && y < ay + hoverWidth
    );
  });

  return intervals;
}
