/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import * as Layout from 'pages/Grow/NoteLayout';
import { connect } from 'react-redux';
import {
  QuickMeetingTitle,
  Title,
  Wrap,
} from 'components/vibes/team-vibe-show/partials/Headers/layout';

const MeetingHeader = ({ noteBody, isMeeting, isQuickMeeting, user }) => {
  let userName = '';
  let meetingType = 'meeting';
  if (!isQuickMeeting && noteBody) {
    const member = noteBody.member ?? noteBody.preparationNote?.member;
    const team = noteBody.team ?? noteBody.preparationNote?.team;
    const author = noteBody.lead ?? noteBody.preparationNote?.lead;
    userName = member != null ? `${member.first_name} ${member.last_name}` : team ? team.name : '';
    meetingType = team && author && user.id != author.id ? 'team meeting' : 'meeting';
  }
  if (!isQuickMeeting) {
    return (
      <Wrap className="menu-outer-wrap">
        {isMeeting && (
          <Title>
            Your {meetingType} with {userName}
          </Title>
        )}
        <Layout.HeaderDate>
          {moment.utc(noteBody.createdAt).tz(user.timeZone).format('DD MMMM YYYY')}
        </Layout.HeaderDate>
        <Layout.HeaderType>
          {!isMeeting && <Layout.GlassesStyled isResolved={false} />}
        </Layout.HeaderType>
      </Wrap>
    );
  }
  return (
    <Wrap className="menu-outer-wrap">
      <QuickMeetingTitle>Create meeting note</QuickMeetingTitle>
    </Wrap>
  );
};

const mapStateToProps = state => ({
  noteBody: state.app.body,
  user: state.usersDomain.user,
});

export default connect(mapStateToProps, null)(MeetingHeader);
