import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import 'react-autocomplete-input/dist/bundle.css';
import Select from 'react-select';
import Croppie from 'components/shared/croppie';
import { connect } from 'react-redux';
import SpaceLogo from './SpaceLogo';
import SwitchButton from '../shared/buttons/SwitchButton';
import { SPACE_ACTIONS } from '../../redux/actions/spaceActions';
import { USER_ACTIONS } from '../../redux/actions/userActions';
import { AWS_S3_URL, AVATAR_GENERIC, APP_URL } from '../../constants';
import { AVATAR_ERROR, CHECK_UNAUTHORIZED, HEADERS } from '../../helpers';
import Loader from '../shared/Loader';
import CroppieRotate from '../shared/CroppieRotate';
import verifyAvatarImage from '../shared/fileUpload/verifyAvatarImage';

class InformalSpaceModal extends Component {
  state = {
    name: '',
    description: '',
    isPrivate: false,
    isDefault: true,
    recordVibeStats: true,
    thumbnail_details: {
      colors: {
        background: '#F5CF3A',
        border: '#F5CF3A',
        letters: '#FFFFFF',
      },
      letters: 'M',
    },
    thumb_colors_index: 0,
    spaceMembers: [],
    image: '',
    imagePreviewUrl: null,
    imagePreviewCroppyUrl: false,
    errorState: false,
    sendImage: false,
    fetching: false,
  };

  componentDidUpdate(props) {
    if (this.state.imagePreviewCroppyUrl) {
      const el = this.refs.croppie;
      if (el) {
        this.croppie = new Croppie(el, {
          url: this.state.imagePreviewCroppyUrl,
          viewport: {
            width: 200,
            height: 200,
            type: 'circle',
          },
          boundary: {
            width: 300,
            height: 300,
          },
          enableExif: true,
          enableOrientation: true,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(this.props) === JSON.stringify(nextProps)) return;
    if (nextProps.mode === 'edit' && nextProps.spaceId !== undefined) {
      this.setState({ fetching: true });
      const headers = HEADERS();
      fetch(`${APP_URL}/get_edited_space/${nextProps.spaceId}`, { method: 'get', headers })
        .then(response => CHECK_UNAUTHORIZED(response))
        .then(response => {
          response.json().then(jsonResponse => {
            this.setState({
              name: jsonResponse.space.name,
              description: jsonResponse.space.description,
              isPrivate: jsonResponse.space.private,
              recordVibeStats: jsonResponse.space.record_vibe_stats,
              thumbnail_details: jsonResponse.space.thumbnail_details,
              thumb_colors_index: jsonResponse.space.thumb_colors_index,
              isDefault: jsonResponse.space.account_default_space,
              spaceMembers: jsonResponse.spaceMembers,
              imagePreviewUrl: jsonResponse.space.image_url,
              sendImage: false,
              fetching: false,
            });
          });
        });
    } else if (nextProps.mode === 'new') {
      const colorsIndex = Math.floor(Math.random() * this.props.thumbColors.length);
      this.setState({
        imagePreviewUrl: null,
        name: '',
        description: '',
        isPrivate: false,
        thumbnail_details: {
          colors: this.props.thumbColors[colorsIndex],
          letters: 'M',
        },
        thumb_colors_index: colorsIndex,
        spaceMembers: [],
        fetching: false,
      });
    }
  }

  uploadImage(e) {
    const reader = new FileReader();
    const image = e.target.files[0];
    if (verifyAvatarImage(image)) {
      this.showCroppieModal(null, true);
      this.setState({ image });
      reader.onloadend = () => {
        this.setState({
          file: image,
          imagePreviewCroppyUrl: reader.result,
        });
      };
      reader.readAsDataURL(image);
    } else {
      e.target.value = null;
    }
  }

  showCroppieModal(e, state) {
    if (!state) {
      this.setState({ imagePreviewCroppyUrl: false });
      document.getElementById('trigger-croppie').value = '';
    }
    if (e) {
      e.preventDefault();
    }
    this.setState({ croppieModal: state });
  }

  getCroppieResult() {
    this.croppie.result('blob').then(blob => {
      if (blob.size > 2000000) {
        alert('File is too big! Maximum size allowed is 2MB');
      } else {
        this.croppie.result('base64').then(base => {
          this.setState({
            imagePreviewCroppyUrl: false,
            imagePreviewUrl: base,
            croppieModal: false,
            sendImage: true,
          });
        });
      }
    });
  }

  inviteUsers = model => {
    this.setState({
      spaceMembers: [
        ...this.state.spaceMembers,
        this.props.users.find(user => user.id === model.value),
      ],
    });
  };

  removeFromList(userId) {
    this.setState({
      spaceMembers: this.state.spaceMembers.filter(user => user.id !== userId),
    });
  }

  fetchSpace(data, spaceId) {
    const headers = HEADERS();
    const url = spaceId ? `/spaces/${spaceId}` : '/spaces';
    fetch(APP_URL + url, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          if (jsonResponse.flashName === 'error') {
            this.setState({ errorState: jsonResponse.message });
          } else if (response.ok && response.status === 200) {
            this.props.reloadData();
            if (jsonResponse.space) {
              this.props.fetchSpace(jsonResponse.space.id);
              this.props.addLedTeamId(jsonResponse.space.id);
            }
            this.props.updateFlash(jsonResponse.flashName, jsonResponse.message);
            this.props.closeInformalModalHandler();
          }
        });
      });
  }

  submitForm() {
    if (!this.state.name)
      this.setState({ errorState: 'Form contains empty fields that are required' });
    else if (this.state.name.replace(/[^a-zA-Z]/g, '').length === 0)
      this.setState({ errorState: 'Space name should contain at least one letter' });
    else {
      this.setState({
        errorState: false,
      });
      const data = new FormData();
      data.append('space[name]', this.state.name);
      data.append('space[description]', this.state.description);
      for (let i = 0; i < this.state.spaceMembers.length; i++)
        data.append('space_users[]', this.state.spaceMembers[i].id);
      data.append('spaceImage', this.state.image);
      if (this.state.sendImage) {
        data.append('spaceImageBase64', JSON.stringify(this.state.imagePreviewUrl));
      }
      data.append('space[private]', this.state.isPrivate);
      data.append('space[record_vibe_stats]', this.state.recordVibeStats);
      data.append('space[thumb_colors_index]', this.state.thumb_colors_index);
      data.append('space[thumb_letters]', this.state.thumbnail_details.letters);
      this.fetchSpace(data, this.props.spaceId);
    }
  }

  changeSpaceName(e) {
    const name = e.currentTarget.value;
    const letters = this.getLettersFromSpaceName(name);

    this.setState({
      name,
      thumbnail_details: {
        ...this.state.thumbnail_details,
        letters: letters || 'M',
      },
    });
  }

  getLettersFromSpaceName(name) {
    const formattedName = name.trim().replace(/[^a-zA-Z ]/g, '');
    if (formattedName.length > 0) {
      let letters = formattedName.charAt(0).toUpperCase();
      if (letters && /\s/.test(formattedName)) {
        const wordsArray = formattedName.split(' ');
        for (let i = 1; i < wordsArray.length && letters.length < 2; i++) {
          const letter = wordsArray[i]
            .trim()
            .replace(/[^a-zA-Z ]/g, '')
            .charAt(0)
            .toUpperCase();
          if (letter) {
            letters += letter;
          }
        }
      }
      return letters;
    }
  }

  substrName(name) {
    if (name.length > 20) {
      return `${name.substr(0, 20)}...`;
    }
    return name;
  }

  rotateCroppie = deg => this.croppie.rotate(deg);

  render() {
    const { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl) {
      imagePreview = <img src={imagePreviewUrl} alt="" />;
    } else {
      imagePreview = (
        <SpaceLogo
          space={{
            thumbnail_details: this.state.thumbnail_details,
            thumb_colors_index: this.state.thumb_colors_index,
          }}
        />
      );
    }
    return (
      <Modal
        show={this.props.showInformalModal}
        onHide={this.props.closeInformalModalHandler}
        dialogClassName="informal-space-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.closeInformalModalHandler} />
        {this.props.mode === 'edit' && this.state.fetching === true ? (
          <Loader id="space-modal-loader" />
        ) : (
          <Row className="table nopadding">
            <Col md={7} className="create-space nopadding">
              <form className="modal-inner-wrap">
                {this.props.mode === 'new' ? <h1>Create new space</h1> : <h1>Edit your space</h1>}
                <h2>
                  Name your space
                  <span>*</span>
                </h2>
                <input
                  id="space-name"
                  onChange={e => this.changeSpaceName(e)}
                  value={this.state.name}
                />
                <span className={`switch-private ${this.state.isPrivate ? 'active' : ''}`}>
                  <SwitchButton
                    turnedOn={this.state.isPrivate}
                    onClick={() => this.setState({ isPrivate: !this.state.isPrivate })}
                  />
                  Make this space private
                </span>
                <h2>Profile picture</h2>
                <span className="profile-picture">
                  {imagePreview}
                  <label htmlFor="trigger-croppie" className="file optional control-label">
                    <i className="fa fa-camera" />
                    Upload new picture
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="trigger-croppie"
                    className="trigger-croppie"
                    id="trigger-croppie"
                    onChange={e => this.uploadImage(e)}
                    ref="uploadInput"
                  />
                  {this.state.croppieModal &&
                  <Modal
                    id="avatar_edit_modal"
                    show={this.state.croppieModal}
                    onHide={() => this.showCroppieModal(null, false)}
                  >
                    <Modal.Header className="no-border centered">
                      <Modal.Title>Crop your image</Modal.Title>
                      <p className="u-margin-bottom-none">Zoom in and out to crop your image</p>
                    </Modal.Header>
                    <Modal.Body className="clear">
                      <div id="croppie" ref="croppie" />
                      <CroppieRotate rotate={this.rotateCroppie.bind(this)} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-sm btn-wide btn-primary pull-right upload-result"
                        onClick={() => this.getCroppieResult()}
                      >
                        Save
                      </button>
                      <a
                        className="cancel btn btn-sm btn-fixed pull-right"
                        onClick={() => this.showCroppieModal(null, false)}
                      >
                        Cancel
                      </a>
                    </Modal.Footer>
                  </Modal>
                   }
                </span>
                <h2>Short description</h2>
                <textarea
                  id="description-input"
                  onChange={e => this.setState({ description: e.currentTarget.value })}
                  value={this.state.description}
                />
                <h2>Add users to your space by typing their name</h2>
                {this.props.users && (
                  <Select
                    options={this.props.users
                      .filter(
                        user => !this.state.spaceMembers.find(spaceUser => spaceUser.id === user.id)
                      )
                      .map(user => ({ value: user.id, label: `${user.name} (${user.slug})` }))}
                    isSearchable
                    onChange={this.inviteUsers}
                    isClearable={false}
                    inputProps={{ id: 'note-for-input' }}
                    className="select-component invite-users orange"
                    isMulti={false}
                    placeholder=""
                    scrollMenuIntoView={false}
                    classNamePrefix="r-select"
                  />
                )}
                <span className="info-box">
                  You can also do it later, from the Space settings menu
                </span>
                <div>
                  <input
                    id="create-space-btn"
                    type="button"
                    onClick={() => this.submitForm()}
                    value={this.props.mode === 'new' ? 'Create space' : 'Save changes'}
                  />
                  <span
                    id="error-message"
                    className={`error-message ${this.state.errorState && 'shown'}`}
                  >
                    {this.state.errorState}
                  </span>
                </div>
              </form>
            </Col>
            <Col md={5} className="space-members">
              <h2>Space members</h2>
              {this.props.users && this.props.mode === 'new' ? (
                <div key={this.props.user.id} className="member-element">
                  <img
                    id="member-img"
                    src={
                      this.props.user.imageUrl
                        ? this.props.user.imageUrl
                        : AWS_S3_URL + AVATAR_GENERIC
                    }
                    alt=""
                    onError={e => AVATAR_ERROR(e.target)}
                  />
                  <span title={`${this.props.user.firstName} ${this.props.user.lastName}`}>
                    {this.substrName(`${this.props.user.firstName} ${this.props.user.lastName}`)}
                  </span>
                  <span className="lead-label">lead</span>
                </div>
              ) : (
                ''
              )}
              {this.state.spaceMembers.map(user => (
                <div key={user.id} className="member-element">
                  <img
                    id="member-img"
                    src={user.image_url ? user.image_url : AWS_S3_URL + AVATAR_GENERIC}
                    alt={this.state.firstName}
                    onError={e => AVATAR_ERROR(e.target)}
                  />
                  <span title={user.name}>{this.substrName(user.name)}</span>
                  {user.lead_spaces && user.lead_spaces.length ? (
                    <span className="lead-label">lead</span>
                  ) : (
                    ''
                  )}
                  {!this.state.isDefault && (
                    <div
                      className={
                        user.lead_spaces && user.lead_spaces.length > 0
                          ? 'hidden'
                          : '' + ' pull-right cross'
                      }
                      onClick={() => this.removeFromList(user.id)}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

InformalSpaceModal.defaultProps = {
  reloadData: () => {},
};

const mapDispatchToProps = dispatch => ({
  fetchSpace: id => dispatch(SPACE_ACTIONS.fetchFormalSpace(id)),
  addLedTeamId: id => dispatch(USER_ACTIONS.addLedTeamId(id)),
});

const mapStateToProps = state => ({
  users: state.usersDomain.usersForShoutoutModal,
  user: state.usersDomain.user,
  thumbColors: state.spacesDomain.thumbColors,
});

export default connect(mapStateToProps, mapDispatchToProps)(InformalSpaceModal);
