/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { Player } from 'video-react';
import { Carousel as ReactCarousel } from 'react-bootstrap';
import { AWS_S3_URL } from 'constants.js';
import PropTypes from 'prop-types';
import VideoEmbed, { YoutubeEmbed } from './VideoEmbed';


const Wrap = styled.span`
  margin: 21px -26px 22px -26px;
  position: relative;
  height: 342px;
  &.no-height {
    height: auto !important;
  }
  .slide {
    height: 342px;
    bottom: 0;
  }
  .img-responsive {
    margin: 0 auto;
  }
  .border {
    border-radius: 0 !important;
    border: 1px solid #fff !important;
    .cover {
      border-radius: 0 !important;
    }
  }
  div.img-div {
    height: 342px;
    background-size: cover;
    background-position: center 10%;
    background-repeat: no-repeat;
  }
  .carousel-inner {
    .item {
      height: 342px;
      .img-responsive {
        margin: auto;
        max-width: none;
        width: auto;
        height: 100%;
      }
    }
  }
  .carousel-indicators {
    position: absolute;
    z-index: 1;
    bottom: -8px !important; //important required to overwrite webview settings
    button {
      position: relative;
      width: 5px;
      height: 5px;
      background-color: rgba(255, 255, 255, 0.6);
      margin: 0 2px;
      top: -2px;
      transition: 0.1s height, 0.1s width, 0.1s top, 0.1s margin;
      &.active {
        background-color: #ffffff;
        width: 9px;
        height: 9px;
        top: 0;
        margin: 0;
      }
    }
  }
  .carousel-control {
    color: #dadada;
    line-height: 30px;
    opacity: 1;
    width: 30px;
    &.right,
    &.left {
      background: none;
    }
    div {
      position: absolute;
      width: 13px;
      height: 13px;
      top: 50%;
      transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg);
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      &.control-right {
        right: 11px;
      }
      &.control-left {
        transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg);
        left: 11px;
      }
    }
  }
  .video-react {
    padding: 0 !important;
    min-height: 50px;
    height: 100%;
  }
`;

/**
 * Workaround for dynamically setting styles in a Lightbox element which cannot be easily referenced.
 *
 * @param {number} width - integer in pixels
 * @param {number} height - integer in pixels
 */
const setLightboxWidth = (width, height) => {
  const id = 'lightbox-width';
  let style = document.getElementById(id);
  if (!style) {
    style = document.createElement('style');
    style.id = id;
    const ref = document.querySelector('script');
    ref.parentNode.insertBefore(style, ref);
  }
  if (width > height) {
    style.innerHTML = `
      @media (min-width: ${width + 20}px) {
        #lightboxBackdrop img {
          max-width: ${width}px !important;
        }
      }
    `;
  } else {
    style.innerHTML = ` 
      @media (min-height: ${height + 90}px) {
        #lightboxBackdrop img {
          max-height: ${height}px !important;
        }
      }
    `;
  }
};

const PostMedia = ({ media, embed = null }) => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [src, setSrc] = useState(false);
  let lightbox;
  let output;

  useEffect(() => {
    setSrc(
      media &&
        media.map(img => ({
          src: img.url,
        }))
    );
  }, [media]);

  useEffect(() => {
    if (open) {
      loadLargeImages();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setLightboxWidth(media[currentImage].width, media[currentImage].height);
    }
  }, [currentImage, open]);

  const loadLargeImages = () => {
    let loadedCount = media.length;
    const images = [...Array(loadedCount)];
    media.forEach((media, i) => {
      const image = new Image();
      image.onload = () => {
        images[i] = image;
        loadedCount--;
        if (loadedCount === 0) {
          setSrc(
            images.map(img => ({
              src: img.src,
            }))
          );
        }
      };
      image.src = media.big_url;
    });
  };

  const galleryOpenHandler = (currentImage = 0) => {
    setCurrentImage(currentImage);
    setOpen(true);
  };

  const galleryCloseHandler = () => {
    setOpen(false);
  };
  if (media && media.length > 0) {
    const imgSrc = src.length>0 ? src.map((imageSrc) => ({ source: imageSrc.src })) : []
    lightbox = (
    <ModalGateway>
      {open && src?.length> 0 &&(
        <Modal onClose={galleryCloseHandler}>
          <Carousel
            currentIndex={currentImage}
            views={imgSrc}
          />
      </Modal>
    )}
</ModalGateway>
    );

    if (media.length === 1 && media[0].type === 'i') {
      output = (
        <div className="post-tile-media no-height text-center">
          <img
            src={media[0].url}
            css={css`
              margin: 0 auto;
            `}
            alt=""
            className="img-responsive text-center"
            onClick={() => {
              galleryOpenHandler();
            }}
          />
        </div>
      );
    } else if (media[0].type === 'v') {
      output = (
        <div className="post-tile-media">
          <div className="img-div">
            <Player playsInline src={AWS_S3_URL + media[0].path} />
          </div>
        </div>
      );
    } else {
      output = (
        <Wrap className="post-tile-media">
          <ReactCarousel
            wrap={false}
            interval={null}
            nextIcon={<div className="control-right" />}
            prevIcon={<div className="control-left" />}
          >
            {media.map((media, index) => (
              <ReactCarousel.Item key={media.id}>
                <div
                  className="img-div"
                  style={{ backgroundImage: `url(${media.url})` }}
                  onClick={() => {
                    galleryOpenHandler(index);
                  }}
                />
              </ReactCarousel.Item>
            ))}
          </ReactCarousel>
        </Wrap>
      );
    }
  }

  return output && lightbox ? (
    [output, lightbox]
  ) : embed ? (
    <VideoEmbed id={embed.id} type={embed.type} />
  ) : null;
};

PostMedia.propTypes = {
  media: PropTypes.array.isRequired,
  embed: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default PostMedia;
