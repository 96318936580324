/** @jsxImportSource @emotion/react */
import {
  CheckmarkList,
  DropdownContent,
  DropdownDescription,
} from 'components/payments/PaymentChoose/shared/layout';
import { ReactComponent as DropdownCircle } from 'components/payments/PaymentChoose/shared/DropdownCircle.svg';
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Collapse } from 'react-bootstrap';
import { Td } from 'components/payments/PaymentChoose/DesktopPlanSelector';
import mapPlans from 'components/payments/PaymentChoose/shared/mapPlans';
import { connect } from 'react-redux';

const FeatureRow = styled.tr`
  cursor: pointer;

  &:nth-child(4n - 1),
  &:nth-child(4n) {
    background: #fafafa;
  }
`;

const DropdownCircleStyled = styled(DropdownCircle)`
  margin-right: 12px;
  transition: 0.3s;
  flex-shrink: 0;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `}
`;

const TdTop = styled(props => <Td {...props} />)`
  padding-bottom: 0;
`;

const TdBottom = styled(props => <Td {...props} />)`
  padding-top: 0;
`;

const TableFeatureRow = ({ feature, index, planSize, currentPlanSize }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FeatureRow
        css={css`
          border-top: #dfebf0 1px solid;
        `}
        onClick={() => setOpen(open => !open)}
      >
        <TdTop>
          <DropdownContent>
            <DropdownCircleStyled open={open} />
            {feature.name}
          </DropdownContent>
        </TdTop>
        {mapPlans({ planSize, currentPlanSize }, ({ plan, disabled }) => (
          <TdTop
            css={
              disabled &&
              css`
                opacity: 0.2;
                pointer-events: none;
              `
            }
          >
            <DropdownDescription>{plan.features[index].description}</DropdownDescription>
          </TdTop>
        ))}
      </FeatureRow>
      <FeatureRow onClick={() => setOpen(open => !open)}>
        <TdBottom />
        {mapPlans({ planSize, currentPlanSize }, ({ plan, disabled }) => (
          <TdBottom
            css={
              disabled &&
              css`
                opacity: 0.2;
                pointer-events: none;
              `
            }
          >
            <Collapse in={open}>
              <div>
                <CheckmarkList>
                  {plan.features[index].points.map(point => (
                    <li>{point}</li>
                  ))}
                </CheckmarkList>
              </div>
            </Collapse>
          </TdBottom>
        ))}
      </FeatureRow>
    </>
  );
};

const mapState = state => ({
  currentPlanSize: state.plansDomain.planSize,
});

export default connect(mapState)(TableFeatureRow);
