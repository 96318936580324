/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { AVATAR_ERROR } from 'helpers';
import { css, jsx } from '@emotion/react';
import { ReactComponent as Calendar } from 'components/shared/buttons/Calendar.svg';
import { connect } from 'react-redux';
import ScheduleMeetingDropdown from './ScheduleMeetingDropdown';
import SpaceLogo from '../../components/spaces/SpaceLogo';

const MeetingWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 20px;
  margin-right: 8px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
`;

const ProfileWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  height: 38px;
  text-overflow: ellipsis;
  white-space: initial;
  overflow: hidden;
`;

const Description = styled.div`
  padding-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  vertical-align: middle;
  display: inline-flex;
  padding-top: 18px;
  padding-left: 3px;
`;

const description = meeting => {
  let desc = 'Every ';
  switch (meeting.frequency) {
    case 'daily':
      desc += 'day';
      break;
    case 'weekly':
      desc += 'week';
      break;
    case 'fortnightly':
      desc += 'two weeks';
      break;
    case 'monthly':
    default:
      desc += 'month';
      break;
  }
  desc += ` at ${meeting.time}`;
  return desc;
};

const reminder = prompt => {
  switch (prompt) {
    case 'day':
      return '1 day';
    case 'days':
      return '3 days';
    case 'week':
    default:
      return '1 week';
  }
};

const AdditionalInfoWrapper = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #6a6a6a;
  padding-top: 10px;
  padding-right: 50px;
`;

const SpaceLogoStyled = styled(SpaceLogo)`
  font-size: 17px;
  line-height: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  margin-right: 12px;
`;

const ScheduleMeetingSection = ({
  meeting,
  additionalInfo = false,
  fetchMeetings = null,
  user,
}) => {
  const isOwner = user.id === meeting.user_id;
  const isTeamMeeting = !!meeting.team;
  const member = isOwner ? meeting.member : meeting.user;
  return (
    <MeetingWrapper>
      {isOwner && <ScheduleMeetingDropdown meeting={meeting} fetchMeetings={fetchMeetings} />}
      <ProfileWrapper>
        {isTeamMeeting && isOwner ? (
          <>
            <SpaceLogoStyled space={meeting.team} />
            Meeting with {meeting.team.name}
          </>
        ) : (
          <>
            <Image src={member.image_url} alt="" onError={e => AVATAR_ERROR(e.target)} />
            {isTeamMeeting ? 'Team meeting' : 'Meeting'} with {member.first_name} {member.last_name}
          </>
        )}
      </ProfileWrapper>
      <Description>
        <Calendar
          css={css`
            margin-right: 12px;
          `}
        />
        {description(meeting)}
      </Description>
      {additionalInfo && isOwner && (
        <AdditionalInfoWrapper>
          We will remind you {reminder(meeting.owner_prompt)} before and{' '}
          {isTeamMeeting ? meeting.team.name : member.first_name} {reminder(meeting.member_prompt)}{' '}
          before the meeting
        </AdditionalInfoWrapper>
      )}
      {additionalInfo && !isOwner && (
        <AdditionalInfoWrapper>
          We will remind you {reminder(meeting.member_prompt)} before the meeting
        </AdditionalInfoWrapper>
      )}
    </MeetingWrapper>
  );
};
const mapStateToProps = state => ({
  user: state.usersDomain.user,
});
export default connect(mapStateToProps)(ScheduleMeetingSection);
