/** @jsxImportSource @emotion/react */
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { modalCss } from 'components/payments/PaymentChoose/shared/layout';
import { BlueButton } from 'components/payments/PaymentChoose/shared/PlanButton';
import { CHECK_UNAUTHORIZED, HEADERS } from 'helpers';
import { APP_URL } from 'constants.js';
import { withRouter } from 'react-router-dom';
import fetchApi from 'fetchApi';
import { ReactComponent as BlueLine } from './blue-line.svg';

const DowngradeModal = ({ show, handleClose, updateFlash, history }) => {
  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(null);

  const handleErrors = response => {
    if (!response.ok) {
      response.json().then(json => {
        handleClose();
        updateFlash('error', json.message);
      });
      throw Error('Could not cancel a subscription');
    }
    return response;
  };

  const handleDowngrade = () => {
    setDisabled(true);
    const data = new FormData();
    const headers = HEADERS();
    fetch(`${APP_URL}/payments/unsubscribe`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => handleErrors(response))
      .then(response => {
        response.json().then(jsonResponse => {
          handleClose();
          updateFlash('success', jsonResponse.message);
          history.push('/settings');
        });
      })
      .catch(() => {});
  };

  const fetchData = async () => {
    const response = await fetchApi('/payments/billing_period_end');
    setDate(response.period_end);
  };

  useEffect(() => {
    if (show) {
      fetchData();
    } else {
      setDate(null);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} css={modalCss}>
      <div
        css={css`
          text-align: center;
          padding: 55px 25px;
        `}
      >
        <div
          css={css`
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
          `}
        >
          Are you sure you want to switch to the Free plan?
        </div>
        <div
          css={css`
            font-size: 14px;
            line-height: 150%;
            margin-top: 24px;
          `}
        >
          After {date ?? '...'}, you won’t be able to...
        </div>
        <PlanModal.List
          css={css`
            margin: 23px auto 42px;
            max-width: 460px;
          `}
        >
          {[
            'Deep-dive into the data to explore your team’s emotional state',
            'Create more than 8 meeting notes, wins or have access to recurring meetings',
            'Access advanced polls and more',
          ].map(content => (
            <PlanModal.ListItem
              css={css`
                text-align: left;
              `}
            >
              <BlueLine
                css={css`
                  margin-right: 16px;
                  margin-top: 10px;
                  flex-shrink: 0;
                `}
              />
              {content}
            </PlanModal.ListItem>
          ))}
        </PlanModal.List>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <BlueButton
            onClick={handleDowngrade}
            disabled={disabled}
            css={css`
              margin: 0;
              width: auto;
              padding: 9px 24px;
            `}
          >
            Yes, downgrade
          </BlueButton>
          <div
            onClick={handleClose}
            css={css`
              font-size: 15px;
              line-height: 18px;
              color: #11a9ff;
              text-decoration-line: underline;
              cursor: pointer;
              margin-left: 32px;
            `}
          >
            Stay on Connect & Perform plan
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(DowngradeModal);
