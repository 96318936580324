import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP_URL } from '../../../constants';
import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import { SPACE_ACTIONS } from '../../../redux/actions/spaceActions';

const mapStateToProps = state => ({
  filters: state.postsDomain.filters,
});

const mapDispatchToProps = dispatch => ({
  fetchPosts: () => dispatch(POST_ACTIONS.fetchPosts()),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchSpaceDetails: spaceId => dispatch(SPACE_ACTIONS.fetchSpaceDetails(spaceId)),
  fetchFormalSpace: spaceId => dispatch(SPACE_ACTIONS.fetchFormalSpace(spaceId)),
});

class LeaveSpaceModal extends Component {
  leaveSpace = () => {
    this.props.close();
    const id = this.props.filters.space;
    const headers = HEADERS();
    fetch(`${APP_URL}/spaces/leave/${id}`, { method: 'put', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(() => {
            this.props.resetPosts();
            this.props.fetchPosts();
            this.props.fetchSpaceDetails(id);
            this.props.fetchFormalSpace(id);
          });
        }
      });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        dialogClassName="leave-modal"
        backdrop
        keyboard
      >
        <div className="close-cross" onClick={this.props.close} />
        <h1>You're about to leave this space.</h1>
        <h2>
          You can join a public space again at anytime but will need to be invited into a private
          space.
        </h2>
        <h3>Are you sure you want to leave?</h3>
        <div className="buttons-wrap">
          <div className="accept-button" onClick={this.leaveSpace}>
            Yes
          </div>
          <div className="cancel-button" onClick={this.props.close}>
            No
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaveSpaceModal));
