import React, { useEffect, useState } from 'react';
import Page from 'components/layouts/Page';
import { ReactComponent as Insights } from 'components/layouts/Navigation/insights.svg';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { HEADERS } from 'helpers';
import { APP_URL, AVATAR_GENERIC, AWS_S3_URL } from 'constants.js';
import TeamSelect from 'components/vibes/team-vibe-show/partials/TeamSelect';
import DateRange from 'components/vibes/team-vibe-show/partials/DateRange';
import SwitchButton from 'components/shared/buttons/SwitchButton';
import { css, jsx } from '@emotion/react';
import useCan from 'rbac/useCan';
import { insights } from 'rbac/plans';
import Can from 'rbac/Can';
import UpgradeModal, { grayFilterCss } from 'pages/Insights/UpgradeModal';
import DetailedFeedbackData from 'components/vibes/DetailedFeedback';
import AnonymousComments from '../Dashboard/AnonymousComments';
import FactorAnalysisChart from './FactorAnalysisChart';
import SampleContentBanner from '../shared/SampleContentBanner';
import { ReactComponent as LightBulb } from './light_bulb.svg';

const defaultData = [...Array(10)].fill('').map(() => ({
  current: {
    terrible: 0,
    bad: 0,
    okay: 0,
    good: 0,
    awesome: 0,
  },
  previous: {
    terrible: 0,
    bad: 0,
    okay: 0,
    good: 0,
    awesome: 0,
  },
}));

const defaultFeedback = [...Array(11)].fill('').map((item, index) => ({
  category: index + 1,
  data: [],
}));

const queryString = require('query-string');

const Factors = props => {
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const defaultCategory = params.factor ? +params.factor : 1;
  const [chartType, setChartType] = useState('horizontal');
  const [showPrevious, setShowPrevious] = useState(false);
  const [data, setData] = useState(undefined);
  const [feedbackCategory, setFeedbackCategory] = useState(defaultCategory);
  const [usersCount, setUsersCount] = useState(0);
  const [detailedFeedbackData, setDetailedFeedbackData] = useState(defaultFeedback);
  const [anonymousComments, setAnonymousComments] = useState([]);
  const [error, setError] = useState(null);

  const canSeeFactors = useCan(insights.accessFactorAnalysis);

  const fetchData = async () => {
    const headers = HEADERS();
    const response = await fetch(
      `${APP_URL}/vibes/factor_analysis?${qs.stringify(props.queryString)}`,
      { method: 'get', headers }
    );
    const json = await response.json();
    if (!json.enoughMembers) {
      setDetailedFeedbackData(defaultFeedback);
      setAnonymousComments([]);
      setData(undefined);
      if (json.error) {
        setError({ flashName: json.flashName, message: json.message });
      }
      return;
    }

    setData(Object.values(json.factorsData));
    setDetailedFeedbackData(Object.values(json.detailedFeedbackData));
    setAnonymousComments(json.anonymousComments);
    setUsersCount(json.usersCount);
    setError(null);
  };

  const handleResize = () => {
    if (chartType === 'vertical' && window.innerWidth < 1080) {
      setChartType('horizontal');
    }
    if (chartType === 'horizontal' && window.innerWidth > 1080) {
      setChartType('vertical');
    }
  };

  useEffect(() => {
    if (error) {
      props.updateFlash(error.flashName, error.message);
    } else {
      props.closeFlash();
    }
  }, [error]);

  window.onresize = debounce(handleResize, 200);

  useEffect(() => {
    handleResize();
    return () => {
      window.onresize = null;
    };
  }, []);

  useEffect(() => {
    if (props.location.state?.factor) {
      const state = { ...props.history.location.state };
      delete state.factor;
      setFeedbackCategory(props.location.state.factor);
      props.history.replace({ ...props.history.location, state });
    }
    props.closeFlash();
    fetchData();
  }, [props.queryString.team, props.queryString.period]);

  const getFeedCategories = (withLineBreaks = true) => {
    const helper = withLineBreaks ? <br /> : ' ';
    return [
      <>
        Excitement
        {helper}& Energy
      </>,
      <>
        Learning
        {helper}& Growth
      </>,
      <>
        Autonomy &{helper}
        Independence
      </>,
      <>Valued</>,
      <>
        Clarity &{helper}
        Certainty
      </>,
      <>Being Connected</>,
      <>
        Safety &{helper}
        Comfort
      </>,
      <>Purpose</>,
      <>Productivity</>,
      <>External</>,
      <>Other</>,
    ];
  };

  const filterDetailedFeedbackByCategory = (feedbackData, factor) => {
    return feedbackData.filter(data => data.position === feedbackCategory);
  };

  const canSeeFeedback =
    props.user.isAccountAdmin ||
    props.user.formalLeadTeamsWithChildrenIds.includes(
      props.teams.find(team => team.id == props.queryString.team).id
    );

  return (
    <div className="team-vibe-dashboard">
      <SampleContentBanner queryString={props.queryString} />
      <Page.Header icon={Insights}>Factor analysis</Page.Header>
      <Can perform={insights.accessFactorAnalysis} no={() => <UpgradeModal />} />
      <Page.Paper cssProps={!canSeeFactors && grayFilterCss}>
        <div className="team-vibe-dashboard">
          <div className="top-controls-wrap">
            <TeamSelect queryString={props.queryString} tabLink={props.location.pathname} />
            <DateRange
              queryString={props.queryString}
              tabLink={props.location.pathname}
              hideThisLastWeek={false}
            />
            <div className="period-switch-wrap">
              <SwitchButton
                turnedOn={showPrevious}
                onClick={() => setShowPrevious(!showPrevious)}
              />
              <span>Show previous period</span>
            </div>
          </div>
        </div>
        {chartType === 'vertical' ? (
          <div className="factors-section-vertical">
            <FactorAnalysisChart
              showPrevious={showPrevious}
              data={data}
              queryString={props.queryString}
              canSeeFeedback={canSeeFeedback}
              feedbackCategory={feedbackCategory}
              setFeedbackCategory={setFeedbackCategory}
              chartType={chartType}
            />
            {canSeeFeedback && (
              <div className="detailed-feedback-wrap">
                <h2>
                  Triggers <span>Summary</span>
                </h2>
                <DetailedFeedbackData
                  detailedFeedbackData={filterDetailedFeedbackByCategory(
                    detailedFeedbackData,
                    feedbackCategory
                  )}
                  limit={5}
                />
                <Link
                  className="view-more"
                  to={{
                    pathname: '/vibes/detailed_feedback',
                    search: qs.stringify(props.queryString),
                    state: { section: 'feedback' },
                  }}
                >
                  View all triggers
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="factors-section-horizontal">
            <h2>Factors</h2>
            <FactorAnalysisChart
              showPrevious={showPrevious}
              data={data}
              canSeeFeedback={canSeeFeedback}
              feedbackCategory={feedbackCategory}
              setFeedbackCategory={setFeedbackCategory}
              chartType={chartType}
            />
            {canSeeFeedback && (
              <div className="detailed-feedback-wrap">
                <h3>{getFeedCategories(false)[feedbackCategory - 1]}</h3>
                <h2>Triggers</h2>
                <DetailedFeedbackData
                  detailedFeedbackData={filterDetailedFeedbackByCategory(
                    detailedFeedbackData,
                    feedbackCategory
                  )}
                  limit={5}
                />
                <Link
                  className="view-more"
                  to={{
                    pathname: '/vibes/mood_vs_factor',
                    search: qs.stringify(props.queryString),
                    state: { section: 'feedback' },
                  }}
                >
                  View more
                </Link>
              </div>
            )}
          </div>
        )}
        {canSeeFeedback && (
          <div className="anonymous-feedback-section">
            <h2>
              Specific feedback
              <svg width="24" height="8" viewBox="0 0 24 8" fill="none">
                <path
                  d="M23.7582 0.315209C21.1905 -0.133266 16.2942 -0.222026 12.7657 0.852443C12.5266 0.852443 11.4819 0.852443 11.2427 0.852443C7.71419 -0.222026 2.8179 -0.128594 0.250186 0.315209C-0.0686809 0.403969 -0.0686808 1.92224 0.170469 2.011C0.652965 2.27728 0.892116 2.81452 0.892116 2.81452C0.892116 2.81452 1.05155 3.35175 1.45433 5.31383C1.85291 7.28524 2.89762 7.64496 2.89762 7.64496C2.89762 7.64496 4.18148 8 5.62477 8C7.06806 8 7.95334 7.91129 8.91414 7.37405C9.87493 6.83682 10.2777 5.40726 10.2777 5.40726C10.2777 5.40726 10.7602 4.15527 11.0791 2.99671C11.1588 2.637 12.7657 2.637 12.8454 2.99671C13.1643 4.15994 13.6468 5.40726 13.6468 5.40726C13.6468 5.40726 14.0496 6.83682 15.0104 7.37405C15.9754 7.91129 16.8565 8 18.2997 8C19.743 8 21.0269 7.64496 21.0269 7.64496C21.0269 7.64496 22.0716 7.37868 22.4702 5.32317C22.873 3.26766 23.0324 2.82386 23.0324 2.82386C23.0324 2.82386 23.2715 2.28663 23.754 2.02034C24.0813 1.92691 24.0813 0.315209 23.7582 0.315209Z"
                  fill="#C4C4C4"
                />
              </svg>
            </h2>
            <AnonymousComments
              queryString={props.queryString}
              anonymousComments={anonymousComments.filter(
                comment => comment.position === feedbackCategory
              )}
            />
          </div>
        )}
      </Page.Paper>
    </div>
  );
};

const mapStateToProps = state => ({
  plan: state.plansDomain.plan,
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
});
export default connect(mapStateToProps)(Factors);
