import React, { Component } from 'react';
import { connect } from 'react-redux';
import striptags from 'striptags';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import TextInput from 'react-autocomplete-input';
import { toggleUserModal } from 'redux/app/actions';
import PostDetails from 'components/shared/posts/PostDetails';
import AuthorInfo from 'components/shared/posts/AuthorInfo';
import PinnedPost from 'components/post/shared/PinnedPost';
import ShoutoutStarImage from 'components/shared/ShoutoutStarImage';
import { POST_ACTIONS } from '../../../redux/actions/postActions';
import {
  AUTOCOMPLETE_NATIVE_VALUE_RESET,
  AVATAR_ERROR,
  FORMAT_CONTENT_WITH_MARKUPS,
  FORMAT_FILTERS,
  GET_MENTIONS_FROM_CONTENT,
  findLabel,
} from '../../../helpers';
import PostComments from './PostComments';
import { TAG_ACTIONS } from '../../../redux/actions/tagActions';
import favActiveBtn from '../../../images/feed/star_active.png';
import favBtn from '../../../images/feed/star.png';
import FavPostTooltip from '../../grow/tooltips/FavPostTooltip';
import MentionsInputWrapper from '../../shared/MentionsInputWrapper';
import CommentInput from '../shared/CommentInput';
import TeamShoutoutLogo from '../../shared/posts/TeamShoutoutLogo';
import Select from 'react-select';
import NoCompanyValuesOption from '../../settings/partials/NoCompanyValuesOption';
import { jsx } from '@emotion/react';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import Auxiliary from '../../layouts/Auxiliary';

const mapStateToProps = state => ({
  user: state.usersDomain.user,

  hashtags: state.tagsDomain.hashtags,
  companyValues: state.tagsDomain.companyValues,

  post: state.postsDomain.singlePost,
  filters: state.postsDomain.filters,
  reachedDataLimit: state.plansDomain.reachedDataLimit,
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
});

const mapDispatchToProps = dispatch => ({
  updatePost: (postId, data) => dispatch(POST_ACTIONS.updatePost(postId, data)),
  favPost: postId => dispatch(POST_ACTIONS.favPost(postId)),
  resetPosts: () => dispatch(POST_ACTIONS.resetPosts()),
  fetchCompanyValues: () => dispatch(TAG_ACTIONS.fetchCompanyValues()),
  toggleUserModal: (toggled, id) => dispatch(toggleUserModal(toggled, id)),
});

class Shoutout extends Component {
  state = {
    errorAction: null,
    lightboxIsOpen: false,
    currentImage: 0,

    // ---For editing purposes
    commentEditing: false,
    commentEditingId: null,
    name: '',
    content: '',
    media: [],
    mediaUrl: [],
    commentMediaUrl: [],
    team: '',
    showCroppie: false,
    imageCroppieUrl: '',
    croppieMounted: false,
    message: null,
    errors: null,
    processing: false,
    commentCharsLeft: 250,
    companyValue: '',
    values: [],
    loader: false,
  };

  componentDidMount() {
    if (this.props.locationState !== undefined && this.props.locationState.editing) {
      this.setState({ editing: true }, () => this.editClickedHandler());
    }
    const values = this.props.post.shoutout.company_values.map(a => a.name);
    if (this.props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
        values,
      });
    }
    const renderedHashtags = document.getElementsByClassName('inline-hashtag');
    Array.from(renderedHashtags).forEach(hashtag => {
      hashtag.addEventListener('click', this.hashtagClickedHandler, false);
    });

    const renderedMentions = document.getElementsByClassName('inline-mention');
    Array.from(renderedMentions).forEach(mention => {
      mention.addEventListener('click', this.mentionClickedHandler, false);
    });
  }

  // ---Hashtags listener and filter apply
  hashtagClickedHandler = e => {
    this.props.history.push(
      `/feed?${qs.stringify(
        FORMAT_FILTERS(e.currentTarget, {
          space: this.props.filters.space,
          types: [],
          hashtags: [],
          string: '',
        })
      )}`
    );
  };
  //--------------------

  mentionClickedHandler = e => {
    e.preventDefault();
    const mentionedId = parseInt(e.currentTarget.getAttribute('user-id'));
    if (mentionedId === this.props.user.id) {
      this.props.history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      return this.props.toggleUserModal(true, mentionedId);
    }
  };

  componentWillReceiveProps(props) {
    const values = this.props.post.shoutout.company_values.map(a => a.name);
    if (props.editing) {
      this.setState({
        name: this.props.post.name,
        content: FORMAT_CONTENT_WITH_MARKUPS(this.props.post.content),
        media: this.props.post.media,
        values,
      });
    }
    if (this.state.loader === true && this.props.post !== props.post) {
      this.props.changeEditingState();
      this.setState({
        loader: false,
      });
    }
  }

  updatePostHandler = () => {
    if (!this.state.loader) {
      if (this.state.errorContent) {
        alert('Please reduce the number of characters');
        return;
      }

      if (this.state.content.length > 0) {
        const data = new FormData();
        data.append('_method', 'PUT');
        data.append('name', this.state.name);
        data.append('type', 'Shoutout');
        data.append('content', this.state.content);
        for (let i = 0; i < this.state.values.length; i++)
          data.append('shoutout_values[]', this.state.values[i]);
        this.props.updatePost(this.props.post.id, data);
        this.setState({
          loader: true,
        });
      }
    }
  };

  contentChangedHandler = (event, unformattedContent) => {
    const input = event.target.value;
    this.setState({
      content: input,
      errorContent:
        unformattedContent.length > 1000
          ? 'Your post exceeds the maximum length of 1000 characters'
          : '',
      charsLeft: 1000 - unformattedContent.length,
    });
  };

  subjectChoiceHandler(e) {
    if (e.length < 40) {
      this.setState({ companyValue: e });
    } else if (e.length === 40) {
      this.setState({ showCharLimitInfo: true });
    }
  }

  removeSubjectHandler(subject) {
    const subjectIndex = this.state.values.indexOf(subject);
    const chosenHelperArray = this.state.values;
    chosenHelperArray.splice(subjectIndex, 1);
    this.setState({
      values: chosenHelperArray || [],
    });
  }

  getSelectCompanyValuesOptions() {
    const options = this.props.companyValues
      ? this.props.companyValues
          .filter(value => this.state.values.indexOf(value) < 0)
          .map(companyValue => {
            return { value: companyValue, label: companyValue };
          })
      : [];
    return options;
  }

  companyValueChoiceHandler(e) {
    const companyValues = [...this.state.values];
    companyValues.push(e.value);
    this.setState({
      values: companyValues,
    });
  }

  render() {
    if (!this.props.post) {
      return null;
    }

    // todo: potentially block of dead code
    let nameError = null;
    let descriptionError = null;
    if (this.state.errors) {
      if (this.state.errors['post.name']) {
        nameError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors['post.name'][0]}
          </span>
        );
      }
      if (this.state.errors['post.content']) {
        descriptionError = (
          <span id="helpBlock" className="help-block text-danger">
            {this.state.errors['post.content'][0]}
          </span>
        );
      }
    }
    const rolePosition = [];
    if (this.props.post.user.role !== '') {
      rolePosition.push(this.props.post.user.role);
    }

    rolePosition.push(this.props.post.user.team.name);
    let shoutoutRecipientImage = null;
    let shoutoutRecipientName = '';
    if (this.props.post.shoutout.recipient) {
      shoutoutRecipientImage = this.props.post.shoutout.recipient.image_url;
      shoutoutRecipientName = `${this.props.post.shoutout.recipient.first_name} ${this.props.post.shoutout.recipient.last_name}`;
    } else {
      shoutoutRecipientImage = this.props.post.shoutout.recipient_team.image_url;
      shoutoutRecipientName = this.props.post.shoutout.recipient_team.name;
    }

    return (
      <div className="post-wrapper">
        <div className="post-header">
          <div className="author-avatar">
            <img
              alt={`${this.props.post.user.first_name} ${this.props.post.user.last_name}`}
              src={this.props.post.user.image_url}
              onError={e => AVATAR_ERROR(e.target)}
            />
          </div>
          <AuthorInfo post={this.props.post} user={this.props.user} />
          <PinnedPost post={this.props.post} />
          {this.props.post.type !== 'SampleShoutout' && (
            <img
              src={this.props.post.favourite ? favActiveBtn : favBtn}
              data-tooltip-id={`post_fav_status${this.props.post.id}`}
              className={`heart ${this.props.post.favourite ? 'marked' : ''}`}
              onClick={() => this.props.favPost(this.props.post.id)}
              alt=""
            />
          )}
        </div>
        <div className="post-body">
          <div className="shoutout-receiver">
            <Link to={`/post/${this.props.post.id}`}>
              {shoutoutRecipientImage ? (
                <ShoutoutStarImage image={shoutoutRecipientImage} />
              ) : (
                <TeamShoutoutLogo
                  thumbnailDetails={this.props.post.shoutout.recipient_team.thumbnail_details}
                />
              )}
              {this.props.post.subtype === 'SuperShoutout' ? (
                <h3 className="buzz-shoutout-title">
                  <Link
                    to=""
                    onClick={e => {
                      e.preventDefault();
                      if (this.props.post.shoutout.recipient.id === this.props.user.id) {
                        this.props.history.push(
                          `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                        );
                      } else {
                        return this.props.toggleUserModal(
                          true,
                          this.props.post.shoutout.recipient.id
                        );
                      }
                    }}
                  >
                    {shoutoutRecipientName}
                  </Link>
                  :&nbsp;{this.props.post.name}
                </h3>
              ) : (
                <h3 className="buzz-shoutout-title">
                  Shoutout to&nbsp;
                  <Link
                    to=""
                    onClick={e => {
                      e.preventDefault();
                      if (this.props.post.shoutout.recipient) {
                        if (this.props.post.shoutout.recipient.id === this.props.user.id) {
                          this.props.history.push(
                            `/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`
                          );
                        } else {
                          return this.props.toggleUserModal(
                            true,
                            this.props.post.shoutout.recipient.id
                          );
                        }
                      }
                    }}
                  >
                    {shoutoutRecipientName}
                  </Link>
                </h3>
              )}
            </Link>
          </div>
          <div className="post-content">
            {this.props.editing ? (
              <Auxiliary>
                <MentionsInputWrapper
                  value={this.state.content}
                  contentChangedHandler={this.contentChangedHandler}
                  placeholder="Type here..."
                  mentions={GET_MENTIONS_FROM_CONTENT(this.props.post.content)}
                  maxLength="1001"
                />
                {this.state.errorContent ? (
                  <div className="fix-tile-error">
                    <span className="text-danger">{this.state.errorContent}</span>
                  </div>
                ) : (
                  ''
                )}
                {this.props.editing && this.state.content.length === 0 ? (
                  <div className="help-block text-danger">Post content cannot be empty</div>
                ) : (
                  ''
                )}
                {(this.props.isAccountAdmin || Boolean(this.props.companyValues.length)) && (
                  <Select
                    options={this.getSelectCompanyValuesOptions()}
                    inputProps={{ id: 'company-value-input' }}
                    isSearchable
                    value={this.state.companyValue? {
                      value: this.state.companyValue,
                      label: findLabel(
                        this.state.companyValue,
                        this.getSelectCompanyValuesOptions()
                      ),
                    }: ''}
                    onChange={e => this.companyValueChoiceHandler(e)}
                    isClearable={false}
                    className="select-component company-values orange"
                    placeholder="Tag a related company value (optional)"
                    disabled={false}
                    noResultsText={<NoCompanyValuesOption />}
                    classNamePrefix="r-select"
                  />
                )}
                <div className="subject-wrapper">
                  {this.state.values.map((subject, index) => (
                    <span className="subject-box" id={`subject-${index}`} key={index}>
                      {subject}
                      <div
                        onClick={() => this.removeSubjectHandler(subject)}
                        className="subject-cross"
                      />
                    </span>
                  ))}
                </div>
                <span
                  id="save-edit-btn"
                  className={`edit-save btn-sm btn btn-fixed btn-primary ${
                    this.state.loader ? 'disabled' : ''
                  }`}
                  onClick={this.updatePostHandler}
                >
                  {this.state.loader ? 'Saving' : 'Save'}
                </span>
              </Auxiliary>
            ) : (
              <div
                className="post-content-text"
                dangerouslySetInnerHTML={{
                  __html: striptags(this.props.post.content, ['a', 'br']),
                }}
              />
            )}
            {!this.props.editing && this.props.post.shoutout.company_values.length > 0 && (
              <div className="tags-wrapper">
                <p className="tags-title">Related company values:</p>
                {this.props.post.shoutout.company_values.map((tag, index) => (
                  <span key={index}>
                    {tag.name}
                    &nbsp;
                  </span>
                ))}
              </div>
            )}
            <PostDetails post={this.props.post} showSpaceInfo={this.props.showSpaceInfo} large />
          </div>
          {this.props.post.type !== 'SampleShoutout' && <CommentInput post={this.props.post} />}
        </div>
        {this.props.post.type !== 'SampleShoutout' && (
          <div className="post-comments-section">
            <div className="post-comments">
              <PostComments />
            </div>
          </div>
        )}
        {this.props.post.type !== 'SampleShoutout' && (
          <FavPostTooltip postId={this.props.post.id} isFavourite={this.props.post.favourite} />
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shoutout));
