/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { AWS_S3_URL } from 'constants.js';

export const CloseButton = ({ onClick }) => (
  <img
    css={css`
      position: absolute;
      top: 15px;
      right: 13px;
      cursor: pointer;
    `}
    src={`${AWS_S3_URL}public/images/icons/close-modal.svg`}
    alt=""
    onClick={onClick}
  />
);
