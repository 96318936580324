import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  padding-right: 40px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected
      ? css`
          font-weight: bold;
          color: #323232;
        `
      : css`
          font-weight: normal;
          color: #6a6a6a;
        `}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 18px;
    width: 8px;
    height: 8px;
    transition: 0.3s;
    transform: translateY(-6px) rotateZ(45deg) skew(10deg, 10deg);
    background: linear-gradient(to bottom right, #ffffff, #ffffff 49.5%, #6a6a6a 50.5%);
  }
`;
const FilterButton = React.forwardRef(({ team, onClick }, ref) => (
  <Container ref={ref} onClick={onClick} isSelected={team?.name}>
    {team?.name ?? 'Filter by team'}
  </Container>
));

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  team: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default FilterButton;
