import React, { Component } from 'react';
import { AWS_S3_URL } from '../../../constants';
import { Dropdown } from 'react-bootstrap';

class ProfileToggle extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Dropdown.Toggle className="dropdown-toggle-new" variant="link" bsPrefix="p-0">
        <img
          src={`${AWS_S3_URL}public/images/icons/new_settings.svg`}
          alt="settings"
          id="settings-icon"
        />
        <span className="primary-sidebar-bottom-label">Settings</span>
      </Dropdown.Toggle>
    );
  }
}

export default ProfileToggle;
