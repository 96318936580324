/** @jsxImportSource @emotion/react */
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { css, jsx } from '@emotion/react';

const PerformanceTooltips = () => (
  <>
    <ReactTooltip
      id="no-vibes-performance-tooltip"
      css={css`
        && {
          background: #e1f2fa;
          margin-top: 0px !important;
          max-width: 224px;
          color: #000000;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

          :after {
            border-top: solid 8px #e1f2fa !important;
            bottom: -16px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
            color: #000000;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
    <ReactTooltip
      id="performance-tooltip"
      css={css`
        && {
          margin-top: -10px !important;
          :after {
            border-top: solid 8px #fff !important;
            bottom: -16px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
            color: #000000;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
    <ReactTooltip
      id="performance-icon-tooltip"
      css={css`
        && {
          background: #e1f2fa;
          margin-top: -10px !important;
          max-width: 224px;
          color: #000000;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          :after {
            border-top: solid 8px #e1f2fa !important;
            bottom: -8px;
            border-left-width: 8px !important;
            border-right-width: 8px !important;
            width: 0;
            color: #000000;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
        }
      `}
      place="top"
      type="light"
      effect="solid"
    />
  </>
);

export default PerformanceTooltips;
