/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

const Button = ({ submit, top, left, right, width, height, style, ...props }) => (
  <a
    css={css`
      background-color: #11a9ff;
      margin-top: ${top}px;
      margin-bottom: 10px;
      font-size: 15px;
      color: #fff;
      margin-left: ${left}px;
      margin-right: ${right}px;
      ${width && height
        ? css`
            width: ${width}px;
            height: ${height}px;
          `
        : css`
            padding: 0 29px;
          `}
      ${!submit &&
      css`
        color: #fff;
        background-color: #11a9ff !important;
        :hover,
        :focus,
        :active {
          color: #fff !important;
          border-color: #11a9ff !important;
        }
      `}
      ${style}
    `}
    {...props}
  />
);

const OnboardingDeleteButton = ({
  label,
  submit,
  top = 25,
  left = 12,
  right = 12,
  width,
  height,
  ...props
}) => (
  <Button
    submit={submit}
    className="btn btn-primary"
    top={top}
    left={left}
    right={right}
    width={width}
    height={height}
    {...props}
  >
    {label}
  </Button>
);

export default OnboardingDeleteButton;
