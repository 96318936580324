/** @jsxImportSource @emotion/react */
import { PromptModal } from 'components/payments/prompts/layout';
import PlanModal from 'rbac/components/PlanModal/PlanModal';
import { css, jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { PLAN_ACTIONS } from 'redux/actions/planActions';

export const promptColors = {
  yellow: '#FFC700',
  green: '#52BA05',
};

const getIcon = color => {
  switch (color) {
    case promptColors.yellow:
      return PlanModal.Crown;
    case promptColors.green:
      return PlanModal.GreenCheckmark;
  }
};

export const PromptButton = styled(PlanModal.Button)`
  margin-top: 20px;
  font-size: 12px;
  line-height: 32px;
  background: ${({ color }) => {
    switch (color) {
      case promptColors.yellow:
        return '#11A9FF';
      case promptColors.green:
      default:
        return color;
    }
  }};
`;

const Prompt = ({ color = promptColors.yellow, title, content, button, hidePrompt, ...props }) => {
  const hideModal = () => {
    hidePrompt();
  };

  const Icon = getIcon(color);

  return (
    <PromptModal {...props}>
      <PlanModal.TopBar color={color} />
      <PlanModal.CloseButton
        onClick={() => {
          hideModal();
        }}
      />
      <PlanModal.Content>
        <PlanModal.Title>
          <Icon
            css={css`
              width: 28px;
              height: 28px;
              margin-right: 12px;
              flex-shrink: 0;
            `}
          />
          {title}
        </PlanModal.Title>
        {Boolean(content) && (
          <div
            css={css`
              margin-top: 14px;
            `}
          >
            {content}
          </div>
        )}
        {Boolean(button) && button(hideModal)}
      </PlanModal.Content>
    </PromptModal>
  );
};

Prompt.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node,
  button: PropTypes.func,
};

const mapDispatch = {
  hidePrompt: () => PLAN_ACTIONS.hidePrompt(),
};

export default connect(null, mapDispatch)(Prompt);
