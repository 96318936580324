/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AutotrimString from './TeamDropdown/AutotrimString';
import DropdownList from './TeamDropdown/DropdownList';

const ColoredSquare = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: ${({ color }) => color};
`;

class TeamSelect extends Component {
  state = {
    unfolded: false,
  };

  componentDidUpdate() {
    const { queryString, teamString, insightsTourStep, user, history, tabLink, onboardingTeam } =
      this.props;

    if (
      !user.hideSampleTeam &&
      insightsTourStep >= 0 &&
      +queryString[teamString] !== +onboardingTeam.id
    ) {
      queryString[teamString] = onboardingTeam.id;
      const url = `${tabLink}?${qs.stringify(queryString)}`;
      history.replace(url);
    }
  }

  toggle = () => {
    const { unfolded } = this.state;
    this.setState({ unfolded: !unfolded });
  };

  render() {
    let { teams } = this.props;
    const {
      user,
      teamString,
      periodString,
      tabLink,
      queryString,
      onlyLeadTeams,
      className,
      onboardingTeam,
      coloredSquare,
    } = this.props;

    let teamList = [...teams];
    if (!user.hideSampleTeam && !teamList.find(team => +team.id === +onboardingTeam.id)) {
      teamList.push(onboardingTeam);
    }
    const string = teamList.find(team => +team.id === +queryString[teamString]);
    if (!string) return <Redirect to="/vibes/dashboard" />;
    teamList = onlyLeadTeams
      ? teamList.filter(team => user.formalLeadTeamsWithChildrenIds.indexOf(team.id) > -1)
      : teamList;

    const { unfolded } = this.state;
    return (
      <div
        className={`team-select ${className} ${unfolded ? 'unfolded' : ''}`}
        onClick={this.toggle}
      >
        {coloredSquare && <ColoredSquare color={coloredSquare} />}
        <AutotrimString
          css={css`
            margin-left: ${coloredSquare ? '29px' : 0};
          `}
          string={string.name}
          ending="..."
          offset={coloredSquare ? 77 : 40}
        />
        <svg className="dropdown-arrow" width="14" height="8" viewBox="0 0 14 8" fill="none">
          <path
            d="M7.00014 8C7.60137 8 8.09355 7.46894 8.09355 7.46894L13.6397 2.05153C14.1201 1.58228 14.1201 0.82147 13.6397 0.352218C13.1593 -0.117035 12.3804 -0.117035 11.9 0.352218L7.00014 5.13834L2.1 0.351939C1.6196 -0.117313 0.840707 -0.117313 0.360303 0.351939C-0.120101 0.821192 -0.120101 1.582 0.360303 2.05125L5.90566 7.46789C5.90566 7.46789 6.39892 8 7.00014 8Z"
            fill="#C4C4C4"
          />
        </svg>
        <DropdownList
          tabLink={tabLink}
          queryString={queryString}
          isMounted={unfolded}
          teams={teamList}
          toggle={() => {
            this.toggle();
          }}
          user={user}
          teamString={teamString}
          periodString={periodString}
        />
      </div>
    );
  }
}

TeamSelect.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    hideSampleTeam: PropTypes.bool,
    formalLeadTeamsWithChildrenIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onboardingTeam: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  insightsTourStep: PropTypes.number.isRequired,
  queryString: PropTypes.shape({}).isRequired,
  tabLink: PropTypes.string.isRequired,
  teamString: PropTypes.string,
  onlyLeadTeams: PropTypes.bool,
  className: PropTypes.string,
  coloredSquare: PropTypes.string,
};

TeamSelect.defaultProps = {
  teamString: 'team',
  periodString: 'period',
  onlyLeadTeams: false,
  className: '',
  coloredSquare: null,
};

const mapStateToProps = state => ({
  teams: state.teamsDomain.teams,
  user: state.usersDomain.user,
  onboardingTeam: state.onboardingDomain.onboardingTeam,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
});

export default withRouter(connect(mapStateToProps)(TeamSelect));
