/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../partials/Sidebar';
import TeamsActions from './TeamsActions';

const TeamsSidebar = ({ teams, selected, updateFlash, reloadData }) => {
  return !selected ? null : (
    <React.Fragment>
      <Sidebar.Header>Actions</Sidebar.Header>
      <TeamsActions
        selected={selected}
        teams={teams}
        updateFlash={updateFlash}
        reloadData={reloadData}
      />
    </React.Fragment>
  );
};

TeamsSidebar.propTypes = {
  teams: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  updateFlash: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

export default TeamsSidebar;
