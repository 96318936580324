import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Logo = styled.img`
  border-radius: 50%;
  border: none !important;
  ${props =>
    props.large
      ? css`
          width: 30px;
          height: 30px;
          margin-left: 5px;
        `
      : css`
          width: 18px;
          height: 18px;
        `}
`;

const getStyles = colors =>
  colors
    ? {
        backgroundColor: colors.background,
        color: colors.letters,
        borderColor: colors.border,
        borderWidth: '1px',
        borderStyle: 'solid',
      }
    : {};

const SpaceLogo = ({
  space: {
    name,
    image_url,
    thumbnail_details: { letters, colors },
  },
  className = '',
  large = false,
}) => {
  const [displayImage, setDisplayImage] = useState(!!image_url);

  useEffect(() => {
    setDisplayImage(!!image_url);
  }, [image_url]);

  return displayImage ? (
    <Logo
      alt={name}
      src={image_url}
      className={['space-img', className].join(' ')}
      onError={() => {
        setDisplayImage(false);
      }}
      large={large}
    />
  ) : (
    <span className={`space-logo ${className}`} style={getStyles(colors)}>
      {letters}
    </span>
  );
};

SpaceLogo.propTypes = {
  space: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    thumbnail_details: PropTypes.shape({
      letters: PropTypes.string.isRequired,
      colors: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SpaceLogo;
