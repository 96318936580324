/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Page from 'components/layouts/Page';
import CompanyValueDatePicker from 'pages/Grow/Champions/CompanyValueDatePicker';
import CompanyValueSelect from 'pages/Grow/Champions/CompanyValueSelect';
import Loader from 'components/shared/Loader';
import { fetchChampionsData } from 'pages/Grow/Champions/utils';
import {
  ChampionsWrapper,
  CompanyValueSelectWrapper,
  DateWrapper,
  RightSidebarWrapper,
} from 'pages/Grow/Champions/Champions.styles';
import CompanyValueChampions from 'pages/Grow/Champions/CompanyValueChampions';

const Champions = ({ setErrorPage }) => {
  const url = new URL(window.location);
  const month = url.searchParams.get('month');
  const year = url.searchParams.get('year');

  const [leaderBoardData, setData] = useState({});
  const [companyValueFilter, setCompanyValueFilter] = useState('');
  const [date, setDate] = useState(moment(`${year}-${month}-01`));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await fetchChampionsData(month, year);
        setCompanyValueFilter('');
        setData(data);
        setDate(moment(`${year}-${month}-01`));
        setIsLoading(false);
      } catch (e) {
        setErrorPage(404);
      }
    })();
  }, [month, year]);

  if (!Object.keys(leaderBoardData).length || isLoading) {
    return <Loader />;
  }

  return (
    <Page.Section style={{ padding: 0 }}>
      <Page.Wrapper>
        <Page.Content>
          <Page.Header style={{ textAlign: 'center' }}>Value champions</Page.Header>
          <DateWrapper>{leaderBoardData.date}</DateWrapper>
          <ChampionsWrapper>
            {leaderBoardData.companyValues
              .filter(companyValue =>
                companyValueFilter ? companyValue.id === companyValueFilter : true
              )
              .map(companyValue => (
                <CompanyValueChampions users={companyValue.users} name={companyValue.name} />
              ))}
          </ChampionsWrapper>
        </Page.Content>
        <Page.Sidebar>
          <RightSidebarWrapper>
            <CompanyValueDatePicker
              title="Date range"
              setCompanyValueDate={setDate}
              dateCompanyValue={date}
            />
            <CompanyValueSelectWrapper>
              <CompanyValueSelect
                setCompanyValueFilter={setCompanyValueFilter}
                companyValueFilter={companyValueFilter}
                leaderBoardData={leaderBoardData}
              />
            </CompanyValueSelectWrapper>
          </RightSidebarWrapper>
        </Page.Sidebar>
      </Page.Wrapper>
    </Page.Section>
  );
};

Champions.propTypes = {
  setErrorPage: PropTypes.func.isRequired,
};

export default Champions;
