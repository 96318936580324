/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, jsx, keyframes } from '@emotion/react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import SpaceLogo from 'components/spaces/SpaceLogo';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { SPACE_ACTIONS } from 'redux/actions/spaceActions';
import { clearActionBody, setAction, setShowActionModal } from 'redux/app/actions';
import { LOCALSTORAGE_BOOL } from 'helpers.js';
import { FEED_STEPS, INSIGHTS_STEPS, GROW_STEPS } from 'onboardingSteps';
import { general, insights, spaces as spacesPermissions } from 'rbac/plans';
import { ReactComponent as Crown } from 'rbac/components/PlanModal/crown.svg';
import { ReactComponent as CrownSmall } from 'images/insights/crown.svg';
import UserModal from 'components/shared/Modal/UserModal';
import { LINKS } from 'components/layouts/TopBar';
import InformalSpaceModal from 'components/spaces/InformalSpaceModal';
import moment from 'moment';
import Can from 'rbac/Can';
import useCan from 'rbac/useCan';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import Settings from './Settings';
import { ReactComponent as LogoImage } from './logo.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import { ReactComponent as CollapseIcon } from './collapse.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Add } from './add.svg';
import growBlue from './grow_blue.svg';
import growGrey from './grow_grey.svg';
import feedBlue from './feed_blue.svg';
import feedGrey from './feed_grey.svg';
import orgBlue from './org_blue.svg';
import orgGrey from './org_grey.svg';
import spacesBlue from './spaces_blue.svg';
import spacesGrey from './spaces_grey.svg';
import insightsGrey from './insights_grey.svg';
import insightsBlue from './insights_blue.svg';
import VibeLimitModal from './VibeLimitModal';
import PreloadIcons from '../../vibes/createForms/partials/BottomBar/PreloadIcons';
import TakeActionModal from '../../vibes/team-vibe-show/partials/TakeActionModal';

const CrownSmallStyled = styled(CrownSmall)`
  margin-left: 10px;
  width: 12px;
  height: 12px;
  margin-bottom: -2px;
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  width: 230px;
  height: 100vh;
  z-index: 1031;
  border: 1px solid #efefef;
  transition: all 0.4s;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-width: 768px) {
    box-shadow: none;
  }
  &.collapsed {
    width: 63px;
    overflow: unset;
  }
  :hover > svg {
    opacity: 1;
  }
`;
const Logo = () => (
  <div
    css={css`
      height: 58px;
      text-align: center;
      padding: 15px;
    `}
  >
    <LogoImage
      css={css`
        fill: #323232;
        transition: fill 0.4s;
        .collapsed & {
          fill: transparent;
          margin-left: -23px;
        }
      `}
    />
  </div>
);
const Divider = styled.div`
  height: 1px;
  background: #f3f3f3;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.5);
  }
`;

const BeaconComponent = styled.span`
  animation: ${pulse} 2s ease-in-out infinite;
  border: 2px solid rgba(17, 169, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  margin-top: -1px;
`;

const VibeButton = props => (
  <Link
    id="add-btn"
    to=""
    css={css`
      text-align: center;
      display: block;
      padding: 20px 0 25px 0;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      :hover {
        text-decoration: none;
      }
      .collapsed & {
        text-indent: 36px;
      }
    `}
    {...props}
  >
    <span
      id="add-btn-target"
      css={css`
        width: 36px;
        height: 36px;
        display: inline-block;
        margin-right: 16px;
        position: relative;
        top: 3px;
        text-indent: 0;
        margin-left: -24px;
      `}
    >
      <Add />
      {props.showBeacon && (
        <BeaconComponent
          css={css`
        width: 38px;
        height: 38px;
        margin-left: -37px;
        }
    `}
        />
      )}
      <span
        css={css`
          display: inline-block;
          position: relative;
          // bottom: 12px;
          left: 9px;
          top: 3px;
          .collapsed & {
            left: 16px;
          }
        `}
      >
        Add
      </span>
    </span>
  </Link>
);
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px;
`;
const ImpersonateButton = styled.button`
  vertical-align: center;
  margin-left: 40px;
  margin-top: 20px;
`;
const ListItem = ({
  children,
  icon: Icon,
  icons,
  to,
  href,
  open,
  count,
  arrow,
  notification,
  ...props
}) => {
  const Component = (to && NavLink) || (href && 'a') || 'button';
  return (
    <li>
      <Component
        css={css`
          font-size: 14px;
          font-weight: bold;
          padding: ${icons ? '6px' : '10px'};
          margin: 5px 0;
          color: #58575f;
          display: block;
          border-radius: 2px;
          cursor: pointer;
          border: none;
          width: 100%;
          text-align: left;
          outline: none;
          background: none;
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          ${icons && 'line-height: 26px;'}
          :hover {
            background: #f3f3f3;
            text-decoration: none;
          }
          &.active {
            color: #11a9ff;
            background: #f3f3f3;
          }
          span {
            font-size: 11px;
            margin-left: 10px;
          }
        `}
        to={to}
        {...props}
      >
        {Icon && (
          <Icon
            css={css`
              vertical-align: bottom;
              margin-right: 16px;
              fill: #adadad;
              .active & {
                fill: #11a9ff;
              }
            `}
          />
        )}
        {icons && icons.active && icons.inactive && (
          <div
            css={css`
              vertical-align: bottom;
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 16px;
              background-image: url(${icons.inactive});
              fill: #adadad;
              .active & {
                fill: #11a9ff;
                background-image: url(${icons.active});
              }
              ${notification &&
              css`
                position: relative;
                :after {
                  content: '';
                  display: block;
                  width: 7px;
                  height: 7px;
                  background: #fe483c;
                  border-radius: 50%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  box-sizing: content-box;
                }
              `}
            `}
          />
        )}
        {children}
        {count ? <span>{count}</span> : null}
        {arrow && (
          <Arrow
            css={css`
              position: absolute;
              top: 18px;
              right: 10px;
              transform: rotate(0deg);
              transition: transform 0.2s;
              ${open && 'transform: rotate(180deg);'}
              .collapsed & {
                display: none;
              }
            `}
          />
        )}
      </Component>
    </li>
  );
};
ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  // svg icons are of type object
  icon: PropTypes.shape(),
  icons: PropTypes.shape({
    active: PropTypes.shape().isRequired,
    inactive: PropTypes.shape().isRequired,
  }),
  to: PropTypes.string,
  href: PropTypes.string,
  count: PropTypes.number,
  arrow: PropTypes.bool,
  open: PropTypes.bool,
  notification: PropTypes.bool,
};
ListItem.defaultProps = {
  to: '',
  href: '',
  count: null,
  arrow: false,
  open: false,
  notification: false,
};
const Nested = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 30px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s;
  ${({ open, children }) => open && `max-height: ${React.Children.count(children) * 2}em;`}
`;
const NestedItem = ({ children, main, notification, ...props }) => (
  <li>
    <NavLink
      css={css`
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #58575f;
        cursor: pointer;
        margin-bottom: 9px;
        display: inline-block;
        &.active {
          color: #1297e3;
        }
        ${main &&
        css`
          font-weight: bold;
        `}
        .space-logo {
          display: inline-block;
          font-weight: 900;
          width: 18px;
          height: 18px;
          border: 1px solid white;
          border-radius: 100%;
          margin: 0;
          line-height: 18px;
          font-size: 10px;
          text-align: center;
          text-shadow: none;
          margin-right: 12px;
        }
        img {
          margin-right: 12px;
          margin-bottom: 3px;
        }
        ${notification &&
        css`
          position: relative;
          :after {
            content: '';
            display: block;
            width: 7px;
            height: 7px;
            background: #fe483c;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            right: -12px;
            box-sizing: content-box;
          }
        `}
      `}
      isActive={(match, location) => `${location.pathname}${location.search}`.includes(props.to)}
      {...props}
    >
      {children}
    </NavLink>
  </li>
);
NestedItem.propTypes = {
  children: PropTypes.node.isRequired,
  main: PropTypes.bool,
  to: PropTypes.string.isRequired,
};
NestedItem.defaultProps = {
  main: false,
};

const DisabledNestedItem = styled(NestedItem)`
  color: #d5d5d5;
  :hover,
  :focus {
    color: #d5d5d5;
    text-decoration: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(111, 111, 111, 0.4);
  z-index: 1031;
  transition: opacity 0.4s;
  @media (min-width: 768px) {
    display: none !important;
  }
`;
const Collapse = styled(CollapseIcon)`
  label: btn-collapse;
  position: absolute;
  top: 20px;
  right: 5px;
  transform: rotate(180deg);
  transition: transform 0.4s;
  fill: #f3f3f3;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0;
  :hover {
    color: #fff;
    fill: #11a9ff;
    .arrow {
      fill: #fff;
    }
  }
  .collapsed & {
    transform: rotate(0);
    right: -13px;
  }
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const AddSpace = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 11px;
  // vertical-align: middle;
  cursor: pointer;
`;

const Browse = styled(Link)`
  font-size: 10px;
  font-weight: bold;
  height: 20px;
  display: inline-block;
  border-radius: 20px;
  color: #ffffff;
  background-color: #dedede;
  padding: 3px 13px;
  vertical-align: middle;
  cursor: pointer;
  &:hover,
  :active,
  :focus {
    text-decoration: none;
    color: #ffffff;
  }
`;
const SpaceActionsWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 25px;
`;

const useSidePull = () => {
  const [start, setStart] = useState(0);
  const [diff, setDiff] = useState(0);
  const handleTouchStart = e => {
    setStart(e.touches[0].clientX);
  };
  const handleTouchMove = e => {
    const { clientX } = e.touches[0];
    setStart(startInner => {
      setDiff(clientX - startInner);
      return startInner;
    });
  };
  const handleTouchEnd = () => {
    setDiff(0);
  };
  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
  return [diff, start];
};

const useDrawer = (
  options = {
    open: false,
  }
) => {
  const [open, setOpen] = useState(options.open);
  const [diff, position] = useSidePull();
  if (open === false && position < 50 && diff > 50) {
    setOpen(true);
  }
  if (open === true && diff < -50) {
    setOpen(false);
  }
  const filteredPosition = position < 50 ? Math.min(diff, 300) : 0;
  return [filteredPosition, open, setOpen];
};

const Navigation = ({
  location,
  collapsed,
  displayVibe,
  plan,
  defaultSpace,
  lastSpaceId,
  spaces,
  displayLink,
  showUpgradeModal,
  onCollapseChange,
  setShowActionModal,
  clearActionBody,
  unreadHomePostsCount,
  setAction,
  userAlreadyVibed,
  recordedNoteInLastTwoWeeks,
  updateFlash,
  feedTourStep,
  growTourStep,
  insightsTourStep,
  fetchSpaces,
  isAccountAdmin,
  feedEnabled,
  growEnabled,
  weeklyInsightsNotification,
  isManaged,
  hasOpenQuestionnaires,
}) => {
  const [spacesOpen, setSpacesOpen] = useState(false);
  const [insightsOpen, setInsightsOpen] = useState(false);
  const [grow, setGrow] = useState(false);
  const [organisation, setOrganisation] = useState(false);
  const [settings, setSettings] = useState(false);
  const [vibeLimit, setVibeLimit] = useState(false);
  const mobile = window.innerWidth < 768;
  const [diff, open, setOpen] = useDrawer({
    open: !mobile,
  });
  const openStateMobile = mobile ? open : true;
  const [spaceModalOpen, setSpaceModalOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
    setSettings(false);
  }, [location.key]);
  useEffect(() => {
    if (FEED_STEPS[feedTourStep]?.key === 'createSpaceBtnStep') {
      setSpacesOpen(true);
    }
  }, [feedTourStep]);
  useEffect(() => {
    if (GROW_STEPS[growTourStep]?.key === 'setUpMeetingStep') {
      setGrow(true);
    }
  }, [growTourStep]);
  let translateX = openStateMobile ? 0 : -300;
  if (diff) {
    translateX = -300 + diff;
  }

  const displayUpgradeModal = e => {
    e.preventDefault();
    showUpgradeModal();
  };
  const canAddSpace = useCan(spacesPermissions.addNew);
  const canVibe = () => !userAlreadyVibed && plan !== 'free';
  return (
    <>
      <Overlay
        style={{
          display: openStateMobile || diff ? 'block' : 'none',
          opacity: openStateMobile ? 1 : Math.abs(diff / 300),
        }}
        onClick={() => {
          setOpen(false);
        }}
      />
      <Wrapper
        className={collapsed && 'collapsed'}
        style={{
          transform: `translateX(${translateX}px`,
          overflowY:
            spacesOpen && FEED_STEPS[feedTourStep]?.key === 'createSpaceBtnStep'
              ? 'scroll'
              : 'auto',
        }}
      >
        <Logo />
        <Divider />
        {displayVibe && (
          <>
            <VibeButton
              showBeacon={[
                FEED_STEPS[feedTourStep]?.key,
                GROW_STEPS[growTourStep]?.key,
                INSIGHTS_STEPS[insightsTourStep]?.key,
              ].includes('clickPlusBtnStep')}
              onClick={e => {
                e.preventDefault();
                setShowActionModal(true);
                clearActionBody();
                if (plan === 'free') return;
                const currentDay = moment().isoWeekday();
                if (canVibe() && (currentDay === 4 || currentDay === 5)) {
                  return setAction('create-vibe');
                }
                if (!recordedNoteInLastTwoWeeks) {
                  return setAction('create-note');
                }
              }}
            />
            <PreloadIcons />
            <Divider />
          </>
        )}
        <List>
          <React.Fragment>
            {growEnabled && (
              <ListItem
                icons={{
                  active: growBlue,
                  inactive: growGrey,
                }}
                exact
                to={`/${growProfileUrl}`}
              >
                Grow
              </ListItem>
            )}
            {feedEnabled && (
              <ListItem
                to="/"
                exact
                icons={{
                  active: feedBlue,
                  inactive: feedGrey,
                }}
                count={unreadHomePostsCount}
              >
                Home
              </ListItem>
            )}
          </React.Fragment>
          {feedEnabled && (
            <ListItem
              icons={{
                active: spacesBlue,
                inactive: spacesGrey,
              }}
              arrow
              to={`/feed?space=${lastSpaceId}`}
              open={spacesOpen}
              onClick={e => {
                if (!collapsed) {
                  e.preventDefault();
                  setSpacesOpen(!spacesOpen);
                }
              }}
              count={spaces.length + 1}
            >
              Spaces
            </ListItem>
          )}
          {feedEnabled && (
            <Nested
              open={spacesOpen}
              style={{
                marginBottom: `${spacesOpen ? '' : '-5px'}`,
              }}
            >
              {defaultSpace && (
                <NestedItem to={`/feed?space=${defaultSpace.id}`} main>
                  <SpaceLogo space={defaultSpace} />
                  {defaultSpace.name}
                </NestedItem>
              )}
              {spaces.map(space => (
                <Can
                  perform={general.accessQuestionnaires}
                  yes={() => (
                    <NestedItem key={space.id} to={`/feed?space=${space.id}`}>
                      {space.name}
                    </NestedItem>
                  )}
                  no={() => (
                    <DisabledNestedItem
                      key={space.id}
                      onClick={e => displayUpgradeModal(e)}
                      to={`/feed?space=${space.id}`}
                    >
                      {space.name}
                    </DisabledNestedItem>
                  )}
                />
              ))}
              <SpaceActionsWrapper className="add-space">
                <AddSpace
                  onClick={e => {
                    e.preventDefault();
                    if (canAddSpace) {
                      setSpaceModalOpen(true);
                    } else {
                      displayUpgradeModal(e);
                    }
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                      fill="#E1E1E1"
                    />
                    <rect
                      x="13.2222"
                      y="6.22217"
                      width="1.55556"
                      height="15.5556"
                      rx="0.777778"
                      fill="white"
                    />
                    <rect
                      x="21.7778"
                      y="13.2222"
                      width="1.55556"
                      height="15.5556"
                      rx="0.777778"
                      transform="rotate(90 21.7778 13.2222)"
                      fill="white"
                    />
                  </svg>
                </AddSpace>
                {spacesOpen && FEED_STEPS[feedTourStep]?.key === 'createSpaceBtnStep' && (
                  <BeaconComponent
                    css={css`
                      width: 26px;
                      height: 26px;
                      margin-left: -34px;
                    `}
                  />
                )}
                <Browse
                  to="/spaces"
                  onClick={e => {
                    if (!canAddSpace) {
                      displayUpgradeModal(e);
                    }
                  }}
                >
                  Browse
                </Browse>
              </SpaceActionsWrapper>
            </Nested>
          )}
          <ListItem
            icons={{
              active: insightsBlue,
              inactive: insightsGrey,
            }}
            isActive={(match, location) => Object.keys(LINKS[1]).includes(location.pathname)}
            arrow
            to="/vibes/dashboard"
            open={insightsOpen}
            onClick={e => {
              if (!collapsed) {
                e.preventDefault();
                setInsightsOpen(!insightsOpen);
              }
            }}
            notification={weeklyInsightsNotification}
          >
            Insights
          </ListItem>
          <Nested
            open={insightsOpen}
            style={{
              marginBottom: `${insightsOpen ? '' : '-5px'}`,
            }}
          >
            <NestedItem to="/vibes/dashboard">Team insights</NestedItem>
            <Can
              perform={insights.accessOrganisationInsights}
              yes={() => (
                <NestedItem to="/organisation_insights/score">Organisation insights</NestedItem>
              )}
              no={() => (
                <DisabledNestedItem
                  to="/organisation_insights/score"
                  onClick={e => displayUpgradeModal(e)}
                >
                  Organisation insights
                  <CrownSmallStyled />
                </DisabledNestedItem>
              )}
            />
            {feedEnabled && (
              <Can
                perform={general.accessQuestionnaires}
                yes={() => (
                  <NestedItem to="/questionnaires" notification={hasOpenQuestionnaires}>
                    Questionnaires
                  </NestedItem>
                )}
                no={() => (
                  <DisabledNestedItem to="/questionnaires" onClick={e => displayUpgradeModal(e)}>
                    Questionnaires
                    <CrownSmallStyled />
                  </DisabledNestedItem>
                )}
              />
            )}
          </Nested>
          <ListItem
            icons={{
              active: orgBlue,
              inactive: orgGrey,
            }}
            to="/users"
            arrow
            open={organisation}
            onClick={e => {
              if (!collapsed) {
                e.preventDefault();
                setOrganisation(!organisation);
              }
            }}
          >
            My organisation
          </ListItem>
          <Nested open={organisation}>
            <NestedItem to="/users">Users</NestedItem>
            <NestedItem to="/teams">Teams</NestedItem>
            {feedEnabled && (
              <Can
                perform={spacesPermissions.accessView}
                yes={() => <NestedItem to="/spaces">Spaces</NestedItem>}
                no={() => (
                  <DisabledNestedItem to="/spaces" onClick={e => displayUpgradeModal(e)}>
                    Spaces
                    <CrownSmallStyled />
                  </DisabledNestedItem>
                )}
              />
            )}
            <Can
              perform={general.seeOrgChart}
              yes={() => <NestedItem to="/company_structure">Org chart</NestedItem>}
              no={() => (
                <DisabledNestedItem to="/company_structure" onClick={e => displayUpgradeModal(e)}>
                  Org chart
                  <CrownSmallStyled />
                </DisabledNestedItem>
              )}
            />
          </Nested>
        </List>
        <Divider />
        <List>
          <ListItem
            icon={SettingsIcon}
            onClick={() => {
              setSettings(true);
            }}
          >
            Settings
          </ListItem>
          <Can
            perform={general.seePlanUpgradeOption}
            data={{ isAccountAdmin, isManaged }}
            yes={() => (
              <li>
                <NavLink
                  to="/payments/choose_plan"
                  css={css`
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    line-height: 15px;
                    color: #11a9ff;
                  `}
                >
                  <Crown
                    css={css`
                      width: 28px;
                      margin-right: 17px;
                      margin-left: 6px;
                    `}
                  />
                  Upgrade your plan
                </NavLink>
              </li>
            )}
          />
        </List>
        <Settings
          showProfile={growEnabled}
          open={settings}
          onClose={() => {
            setSettings(false);
          }}
        />
        {LOCALSTORAGE_BOOL('impersonate') && (
          <ImpersonateButton className="btn btn-danger">Impersonate</ImpersonateButton>
        )}
        <VibeLimitModal
          open={vibeLimit}
          onHide={() => {
            setVibeLimit(false);
          }}
        />
        <Collapse
          onClick={() => {
            setGrow(false);
            setSpacesOpen(false);
            setInsightsOpen(false);
            setOrganisation(false);
            onCollapseChange(!collapsed);
          }}
        />
      </Wrapper>
      <TakeActionModal />
      <UserModal />
      <InformalSpaceModal
        showInformalModal={spaceModalOpen}
        spaceId={null}
        mode="new"
        closeInformalModalHandler={() => setSpaceModalOpen(false)}
        updateFlash={updateFlash}
        reloadData={fetchSpaces}
      />
    </>
  );
};
Navigation.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  collapsed: PropTypes.bool.isRequired,
  displayVibe: PropTypes.bool.isRequired,
  plan: PropTypes.string.isRequired,
  defaultSpace: PropTypes.shape().isRequired,
  lastSpaceId: PropTypes.number.isRequired,
  spaces: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRegularUser: PropTypes.bool.isRequired,
  showUpgradeModal: PropTypes.func.isRequired,
  onCollapseChange: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
};

const mapState = state => ({
  defaultSpace: state.spacesDomain.spaces
    ? state.spacesDomain.spaces.find(space => space.account_default_space)
    : null,
  lastSpaceId: state.usersDomain.spaceId,
  spaces: state.spacesDomain.spaces
    ? state.spacesDomain.spaces.filter(
        space =>
          !space.account_default_space &&
          (space.is_member || space.is_team_lead || space.is_visitor)
      )
    : [],
  plan: state.plansDomain.plan,
  displayVibe: !!state.usersDomain.user,
  isRegularUser:
    !!state.usersDomain.user &&
    !state.usersDomain.user.isAccountAdmin &&
    state.usersDomain.user.leadTeamsIds.length === 0 &&
    state.usersDomain.user.formalLeadTeamsIds.length === 0,
  unreadHomePostsCount: state.postsDomain.unreadHomePostsCount,
  userAlreadyVibed: !!state.usersDomain.user && state.usersDomain.user.alreadyVibed,
  recordedNoteInLastTwoWeeks:
    state.usersDomain.user && state.usersDomain.user.recordedNoteInLastTwoWeeks,
  feedTourStep: state.onboardingDomain.feedTourStep,
  growTourStep: state.onboardingDomain.growTourStep,
  insightsTourStep: state.onboardingDomain.insightsTourStep,
  isAccountAdmin: state.usersDomain.user?.isAccountAdmin,
  weeklyInsightsNotification:
    !state.usersDomain.user?.weeklyInsightsSeen &&
    state.usersDomain.user?.formalLeadTeamsIds.length > 0,
  feedEnabled: state.usersDomain.feedEnabled,
  growEnabled: state.usersDomain.growEnabled,
  isManaged: state.plansDomain.isManaged,
  hasOpenQuestionnaires: state.usersDomain.user?.hasOpenQuestionnaires,
});

const mapDispatch = {
  fetchSpaces: SPACE_ACTIONS.fetchSpaces,
  showUpgradeModal: PLAN_ACTIONS.showUpgradeModal,
  setShowActionModal,
  setAction,
  clearActionBody,
};

export default withRouter(connect(mapState, mapDispatch)(Navigation));
