/** @jsxImportSource @emotion/react */
import { Input } from 'components/vibes/createForms/CreateNote/NoteTypes/ValuesAndGoals';
import { css, jsx } from '@emotion/react';
import React from 'react';
import { noteTypes } from 'components/vibes/createForms/CreateNote/NoteTypes/noteTypes';

const getPlaceholder = mode => {
  switch (mode) {
    case noteTypes.win:
      return 'Give your win a title...';
    case noteTypes.goal:
      return 'Give your action or goal a title...';
    default:
      return null;
  }
};

const NoteTitle = ({ value, onChange, mode }) => {
  const placeholder = getPlaceholder(mode);
  if (!placeholder) return null;
  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      css={css`
        margin-top: 25px;
        width: 100%;
        @media (min-width: 660px) {
          width: 50%;
        }
      `}
    />
  );
};

export default NoteTitle;
