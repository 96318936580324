import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { ReactComponent as Calendar } from '../../../shared/buttons/Calendar.svg';
import { htmlspecialchars_decode } from 'helpers';
import whiteCheckmark from 'pages/Insights/OrganisationInsights/media/white-checkmark.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 30px;
  max-height: 721px;
  overflow-y: auto;
  border-radius: 0 8px 8px 0;
`;

export const WhatWasDiscussedTitle = styled.h3`
  font-weight: bold;
  font-size: 14px;
  color: #323232;
  margin: 0;
`;

export const ContentWrapper = styled.p`
  margin-top: 24px;
  color: #323232;
  font-size: 14px;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const NextSteps = styled.div`
  margin-top: 24px;
`;

export const NextStepsTitle = styled.h3`
  color: #323232;
  font-weight: bold;
  font-size: 14px;
`;

export const NextStepsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 22px 0 0 0;
`;

export const NextStepItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 12px;
`;

export const NextStepContent = styled.span`
  color: #323232;
  font-size: 14px;
  margin: 0;
`;

const DateWrapper = styled.div`
  color: #11a9ff;
  display: inline-block;
`;

const Date = styled.div`
  margin-left: 10px;
  display: inline-block;
  font-size: 12px;
  line-height: 140%;
  vertical-align: text-top;
`;

export const CheckBox = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 15px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  flex-shrink: 0;
  opacity: 0.2;
  background: gray;
  ${({ isChecked }) =>
    isChecked &&
    css`
      opacity: 0.5;
      background: url(${whiteCheckmark}) center center no-repeat, #11a9ff;
      border: 1px solid #11a9ff;
    `};
`;

const WhatWasDiscussed = ({ previousMeeting, user }) => {
  return (
    <div>
      <WhatWasDiscussedTitle>What was discussed on:</WhatWasDiscussedTitle>
      <DateWrapper>
        <Calendar
          css={css`
            vertical-align: sub;
          `}
        />
        <Date>
          {moment.utc(previousMeeting.due_date).tz(user.timeZone).format('dddd[,] Do MMMM YYYY')}
        </Date>
      </DateWrapper>
      <ContentWrapper>{htmlspecialchars_decode(previousMeeting.content)}</ContentWrapper>
      <NextSteps>
        <NextStepsTitle>Next steps</NextStepsTitle>
        <NextStepsWrapper>
          {previousMeeting.steps.map(step => (
            <NextStepItem>
              <CheckBox isChecked={step.completed} />
              <NextStepContent>{step.content}</NextStepContent>
            </NextStepItem>
          ))}
        </NextStepsWrapper>
      </NextSteps>
    </div>
  );
};

export default WhatWasDiscussed;
