import React from 'react';
import { css, jsx } from '@emotion/react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CHECK_UNAUTHORIZED, HEADERS } from '../../../helpers';
import { APP_URL } from '../../../constants';
import { ButtonWrapper, ModalContent, ModalWrapper } from '../../onboarding/ExistedMeetingModal';
import LoadingOverlay from '../../signup/LoadingOverlay';
import OnboardingButton from '../../onboarding/partials/OnboardingButton';
import PostponeButton from '../../onboarding/partials/PostponeButton';
import { CloseButton } from '../../shared/Modal/UserModal';
import { TAG_ACTIONS } from '../../../redux/actions/tagActions';

class CompanyValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyValue: '',
      isCompanyValueEdited: false,
      companyValues: [],
      showModal: false,
      loading: false,
      formError: null,
    };
  }

  componentDidMount() {
    const headers = HEADERS();
    fetch(`${APP_URL}/account/company-values`, { method: 'get', headers })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        response.json().then(jsonResponse => {
          this.setState({ companyValues: jsonResponse.company_values });
        });
      })
      .catch(() => {
        this.setState({ companyValues: [] });
      });
  }

  companyValueChangedHandler = e => {
    this.setState({ companyValue: e.target.value });
  };

  editCompanyValueHandler = (e, id) => {
    const companyValueIndex = this.state.companyValues.findIndex(
      companyValue => companyValue.id === id
    );
    if (companyValueIndex < 0) {
      return false;
    }
    this.setState({
      isCompanyValueEdited: true,
      companyValueToProcess: this.state.companyValues[companyValueIndex],
      formError: null,
    });
  };

  deleteCompanyValueHandler = (e, id) => {
    const companyValueIndex = this.state.companyValues.findIndex(
      companyValue => companyValue.id === id
    );
    if (companyValueIndex < 0) {
      return false;
    }

    this.setState({
      companyValue: '',
      formError: null,
      isCompanyValueEdited: false,
      showModal: true,
      companyValueToProcess: this.state.companyValues[companyValueIndex],
    });
  };

  deleteCompanyValue = () => {
    this.setState({ loading: true });
    if (!this.state.companyValueToProcess) {
      this.setState({ loading: false, showModal: false });
      return false;
    }
    const headers = HEADERS();
    fetch(`${APP_URL}/account/company-value/${this.state.companyValueToProcess.id}`, {
      method: 'delete',
      headers,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(() => {
            const companyValues = [...this.state.companyValues];
            const companyValueIndex = this.state.companyValues.findIndex(
              companyValue => companyValue.id === this.state.companyValueToProcess.id
            );
            if (companyValueIndex < 0) {
              return false;
            }
            companyValues.splice(companyValueIndex, 1);
            this.setState({
              companyValues: companyValues,
              loading: false,
              showModal: false,
            });
          });
          this.props.fetchCompanyValues();
        } else {
          this.setState({
            loading: false,
            showModal: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          showModal: false,
        });
      });
    return false;
  };

  addCompanyValue = e => {
    e.preventDefault();
    if (this.state.companyValue === '') {
      this.setState({ formError: 'Please input a company value.' });
      return false;
    }
    this.setState({ formError: null });

    const headers = HEADERS();

    const data = new FormData();
    data.append('name', this.state.companyValue);

    fetch(`${APP_URL}/account/company-value`, { method: 'post', headers, body: data })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(jsonResponse => {
            const companyValues = [...this.state.companyValues];
            companyValues.push(jsonResponse.company_value);
            this.setState({
              companyValues: companyValues,
              companyValue: '',
            });
          });
          this.props.fetchCompanyValues();
        } else {
          response.json().then(jsonResponse => {
            if ('error' in jsonResponse) {
              this.setState({ formError: jsonResponse.error });
            } else {
              this.setState({ formError: 'Something went wrong. Please try again later.' });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    return false;
  };

  updateCompanyValue = e => {
    e.preventDefault();
    if (this.state.companyValueToProcess.name === '') {
      this.setState({ formError: 'Please input a company value.' });
      return false;
    }
    this.setState({ formError: null });

    const headers = HEADERS();

    const data = new FormData();
    data.append('name', this.state.companyValueToProcess.name);
    data.append('_method', 'PUT');

    fetch(`${APP_URL}/account/company-value/${this.state.companyValueToProcess.id}`, {
      method: 'post',
      headers,
      body: data,
    })
      .then(response => CHECK_UNAUTHORIZED(response))
      .then(response => {
        if (response.ok) {
          response.json().then(jsonResponse => {
            const companyValues = [...this.state.companyValues];
            const companyValueIndex = this.state.companyValues.findIndex(
              companyValue => companyValue.id === this.state.companyValueToProcess.id
            );
            if (companyValueIndex < 0) {
              return false;
            }
            companyValues[companyValueIndex] = jsonResponse.company_value;
            this.setState({
              companyValues: companyValues,
              companyValue: '',
              formError: null,
              companyValueToProcess: null,
              isCompanyValueEdited: false,
            });
          });
          this.props.fetchCompanyValues();
        } else {
          response.json().then(jsonResponse => {
            if ('error' in jsonResponse) {
              this.setState({ formError: jsonResponse.error });
            } else {
              this.setState({ formError: 'Something went wrong. Please try again later.' });
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    return false;
  };

  closeModal = () => {
    this.setState({ showModal: false, loading: false });
  };

  cancelClickedHandler = e => {
    e.preventDefault();
    this.setState({
      companyValue: '',
      formError: null,
      companyValueToProcess: null,
      isCompanyValueEdited: false,
    });
    return false;
  };

  companyValueUpdateChangedHandler = e => {
    const editedCompanyValue = { ...this.state.companyValueToProcess };
    editedCompanyValue.name = e.target.value;
    this.setState({ companyValueToProcess: editedCompanyValue });
  };

  render() {
    const {
      companyValues,
      companyValue,
      isCompanyValueEdited,
      formError,
      loading,
      showModal,
      companyValueToProcess,
    } = this.state;
    let companyValuesList = (
      <p className="section-description">There are no company values yet. Try adding some</p>
    );
    if (companyValues.length > 0) {
      companyValuesList = companyValues.map(companyValue => (
        <div className="current-value" key={companyValue.id}>
          {isCompanyValueEdited && companyValueToProcess.id === companyValue.id ? (
            <>
              <div className="current-value-name-wrapper">
                <input
                  type="text"
                  className="form-control edit-value-form"
                  id="edit-company-value"
                  value={companyValueToProcess.name}
                  onChange={this.companyValueUpdateChangedHandler}
                />
                {isCompanyValueEdited && formError && <p className="text-danger">{formError}</p>}
              </div>
              <p
                className="update-value-save"
                onClick={e => this.updateCompanyValue(e, companyValue.id)}
              >
                Save
              </p>
              <p className="update-value-cancel" onClick={this.cancelClickedHandler}>
                Cancel
              </p>
            </>
          ) : (
            <>
              <div className="current-value-name-wrapper">
                <p className="current-value-name">{companyValue.name}</p>
              </div>
              <div className="current-value-actions">
                <p
                  className="current-value-edit"
                  onClick={e => this.editCompanyValueHandler(e, companyValue.id)}
                >
                  Edit
                </p>
                <p
                  className="current-value-delete"
                  onClick={e => this.deleteCompanyValueHandler(e, companyValue.id)}
                >
                  Delete
                </p>
              </div>
            </>
          )}
        </div>
      ));
    }

    return (
      <>
        <h2 className="header-blue">Company values</h2>
        <p className="section-description">
          Add your company values so the team can select them when giving shoutouts, creating goals
          and more. It’s a great way to connect people around your company DNA.
        </p>
        <h3 className="settings-header">Add new company value</h3>
        <form className="form-inline" onSubmit={this.addCompanyValue}>
          <label className="sr-only" htmlFor="company-value">
            Company value
          </label>
          <input
            type="text"
            className="form-control input-value-form"
            id="company-value"
            value={companyValue}
            onChange={this.companyValueChangedHandler}
          />
          <button type="submit" className="btn btn-primary add-value-form" id="add-company-value">
            Add
          </button>
        </form>
        {!isCompanyValueEdited && formError && <p className="text-danger">{formError}</p>}
        <div className="current-values-list">
          <h3 className="settings-header">Current company values</h3>
          {companyValuesList}
        </div>
        <Modal
          show={showModal}
          dialogClassName="existed-meeting-modal"
          backdrop
          keyboard
          onHide={this.closeModal}
        >
          <ModalWrapper>
            <LoadingOverlay toggled={loading} message="Processing..." height="100%" />
            <ModalContent
              css={css`
                overflow: hidden;
              `}
            >
              Are you sure you want to remove this company value?
              <ButtonWrapper>
                <OnboardingButton
                  onClick={this.deleteCompanyValue}
                  label="Yes"
                  submit
                  top={10}
                  width={65}
                  height={36}
                />
                <PostponeButton
                  text="Cancel"
                  onClick={e => {
                    e.preventDefault();
                    this.closeModal();
                  }}
                />
              </ButtonWrapper>
            </ModalContent>
            <CloseButton className="close-button" onClick={this.closeModal} />
          </ModalWrapper>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCompanyValues: () => dispatch(TAG_ACTIONS.fetchCompanyValues()),
});

const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyValues));
