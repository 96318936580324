/** @jsxImportSource @emotion/react */
import Prompt, { promptColors } from 'components/payments/prompts/Prompt';
import { jsx } from '@emotion/react';
import React from 'react';
import { connect } from 'react-redux';
import UpgradeButton from 'components/payments/prompts/UpgradeButton';

const getContent = (isLimitReached, type, isAccountAdmin, adminName) => {
  let content = '';
  if (isLimitReached) {
    content = `You've reached your limit of ${type}s for this month. `;
    content += isAccountAdmin
      ? `Upgrade to access additional ${type}s now otherwise you'll need to wait until next month.`
      : `Chat with your Account Admin, ${adminName}, to ask about upgrading to get unlimited ${type}s every month.`;
  } else {
    content = `When you reach your limit your team won't be able to create any additional ${type}s until next month. `;
    content += isAccountAdmin
      ? `Upgrade to get unlimited ${type}s every month.`
      : `Chat with your Account Admin, ${adminName}, to ask about upgrading to get unlimited ${type}s every month.`;
  }

  return content;
};

const GrowLimitPrompt = ({ prompt, isAccountAdmin, accountAdmin }) => {
  const { isLimitReached, type } = prompt.data;

  return (
    <Prompt
      color={promptColors.yellow}
      title={
        isLimitReached
          ? `Your account is out of ${type}s`
          : `Your account is almost out of ${type}s for this month`
      }
      content={getContent(isLimitReached, type, isAccountAdmin, accountAdmin.first_name)}
      button={hide => <UpgradeButton hide={hide} />}
    />
  );
};

const mapState = state => ({
  isAccountAdmin: state.usersDomain.user.isAccountAdmin,
  accountAdmin: state.plansDomain.accountAdmin,
});

export default connect(mapState)(GrowLimitPrompt);
