import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const HeelixModal = props => (
  <Modal className="heelix-modal top" show={props.showModal} onHide={props.hideModal}>
    <div className="modal-content" style={props.center ? { textAlign: 'center' } : null}>
      <div className="modal-header no-border">
        <button onClick={props.hideModal}>
          <i className="fa fa-times" />
        </button>
        <h1 className="modal-title">{props.title}</h1>
      </div>
      <div
        className="modal-body"
        style={{ minHeight: props.height ? `${props.height}px` : '182px' }}
      >
        {props.children}
      </div>
    </div>
  </Modal>
);

HeelixModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  title: PropTypes.string,
};

export default HeelixModal;
