/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useLayoutEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { findLabel, getUrl, HEADERS } from 'helpers';
import moment from 'moment';
import TimePicker from 'components/vibes/createForms/partials/TimePicker';
import MeetingSteps from 'components/vibes/createForms/partials/MeetingSteps';
import 'moment/locale/en-gb';
import CustomDatePicker from 'components/shared/buttons/CustomDatePicker';
import {
  DataTimePickerWrapper,
  DataWrapper,
  Textarea,
  TimeWrapper,
  Title,
  Wrap,
} from 'components/vibes/createForms/CreateNote/NoteTypes/layout';

const MeetingForm = ({
  state,
  setMeetingState,
  showDatePicker,
  showSelectMember,
  setMeetingMemberState,
  members,
  setMembers,
  contentChangedHandler,
}) => {
  const [loading, setLoading] = useState(true);
  const fetchMembers = async () => {
    const headers = HEADERS();
    const response = await fetch(getUrl('meetings/members_teams'), { method: 'get', headers });
    const json = await response.json();
    setLoading(false);
    if (json.members) {
      setMembers(json.members);
    }
  };

  useLayoutEffect(() => {
    showSelectMember && loading && !state.fromNote && fetchMembers();
  }, [members]);

  const onDateSelection = date => {
    setMeetingState('meetingDate', moment(date).format('YYYY-MM-DD'));
  };

  return (
    <Wrap>
      {showSelectMember && !state.fromNote && (
        <>
          <Title>Select a user or team you met with</Title>
          <Select
            name="select-member"
            id="select-member"
            classNamePrefix="r-select"
            onChange={el => {
              el && setMeetingMemberState(el);
            }}
            value={state.meetingMember?.value ? { value: state.meetingMember?.value,
              label: findLabel(state.meetingMember?.value, members) }: ''}
            options={members}
            isSearchable
            isClearable={false}
            placeholder="Search for user here"
            css={css`
              margin-bottom: 25px;
            `}
          />
        </>
      )}
      {showDatePicker && (
        <DataTimePickerWrapper fromNote={state.fromNote}>
          <DataWrapper>
            <CustomDatePicker
              date={state.meetingDate}
              setDate={onDateSelection}
              title={!state.fromNote ? 'When was this meeting?' : ''}
              placeholder={!state.fromNote ? 'Choose a date' : 'Select date'}
            />
          </DataWrapper>
          <TimeWrapper>
            {!state.fromNote && <Title>What time?</Title>}
            <TimePicker
              setTime={date => setMeetingState('meetingTime', date)}
              time={state.meetingTime}
            />
          </TimeWrapper>
        </DataTimePickerWrapper>
      )}
      {!state.fromNote && <Title>What was discussed</Title>}
      <Textarea
        placeholder={!state.fromNote ? 'Enter here...' : 'What was discussed?'}
        value={state.meetingContent}
        onChange={e => {
          setMeetingState('meetingContent', e.target.value);
          contentChangedHandler(e, e.target.value);
        }}
        fromNote={state.fromNote}
      />
      {!state.fromNote && <Title>Next steps</Title>}
      <MeetingSteps
        stepsCount={state.stepsCount}
        steps={state.steps}
        id={state.id}
        fromNote={state.fromNote}
        toggledNote={state.toggledNote}
        setMeetingState={setMeetingState}
      />
    </Wrap>
  );
};

export default MeetingForm;
