/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import PropTypes from 'prop-types';
import dottedLogo from './dottedLogo.svg';
import categories from '../categories';
import VerticalCenterContainer from './VerticalCenterContainer';
import SwitchButton from '../../shared/buttons/SwitchButton';

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: #323232;
  margin-left: 19px;
`;

const CountWrap = styled.div`
  margin-left: 13px;
  background: #ffffff;
  border-radius: 3px;
  line-height: 13px;
  padding: 3px 6px 2px;
  margin-right: 35px;
`;

const Count = styled.div`
  font-weight: bold;
  font-size: 11px;
  color: #6a6a6a;
`;

const FilterContainer = styled.div`
  @media (min-width: 1000px) {
    margin-left: auto;
  }
  float: right;
  width: 279px;
  min-width: 160px;
  margin-bottom: 20px;
`;
const QuickFilter = styled.input`
  border-color: white;
  border-radius: 5px;
  border-width: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 34px;
  background: white;
  &::placeholder {
    color: #7b7b7b;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    flex-direction: row;
  }
  width: 100%;
`;

const HierarchicalView = styled.span`
  color: #6a6a6a;
  font-size: 13px;
  ${props =>
    props.toggled &&
    css`
      color: #11a9ff;
    `}
`;

const HierarchicalViewContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Header = ({ category, count, defaultFilter, handleFilter, switchButton }) => (
  <HeaderContainer>
    <div
      css={css`
        display: flex;
        margin-bottom: 20px;
      `}
    >
      <VerticalCenterContainer>
        <img
          css={css`
            width: 20px;
            height: 20px;
          `}
          src={dottedLogo}
          alt=""
        />
      </VerticalCenterContainer>
      <Title>{category} in Your Organisation</Title>
      <VerticalCenterContainer>
        <CountWrap>
          <Count>{count}</Count>
        </CountWrap>
      </VerticalCenterContainer>
    </div>
    {switchButton && switchButton.handleChange && (
      <HierarchicalViewContainer>
        <VerticalCenterContainer>
          <SwitchButton onClick={switchButton.handleChange} turnedOn={switchButton.toggled} />
        </VerticalCenterContainer>
        <VerticalCenterContainer>
          <HierarchicalView toggled={switchButton.toggled}>Hierarchical view</HierarchicalView>
        </VerticalCenterContainer>
      </HierarchicalViewContainer>
    )}
    {handleFilter && (
      <FilterContainer>
        <QuickFilter
          placeholder="Quick filter"
          defaultValue={defaultFilter}
          onChange={e => handleFilter(e.target.value)}
        />
      </FilterContainer>
    )}
  </HeaderContainer>
);

Header.propTypes = {
  category: PropTypes.oneOf(Object.values(categories)).isRequired,
  count: PropTypes.number.isRequired,
  // to set proper value from get params
  defaultFilter: PropTypes.string.isRequired,
  handleFilter: PropTypes.func.isRequired,
  switchButton: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    toggled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Header;
