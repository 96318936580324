/** @jsxImportSource @emotion/react */
import Prompt, { promptColors } from 'components/payments/prompts/Prompt';
import { jsx } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { PLAN_ACTIONS } from 'redux/actions/planActions';
import { connect } from 'react-redux';

const PlanDowngradedPrompt = ({ prompt, hidePrompt }) => {
  return (
    <Prompt
      color={promptColors.green}
      title="We got your instructions and have changed your plan"
      content={
        <div>
          You're now on <strong>{prompt.data.planType}</strong>. To check out our other plans at any
          time, just head over to{' '}
          <Link to="/settings" onClick={hidePrompt}>
            your account settings
          </Link>
          .
        </div>
      }
    />
  );
};

const mapDispatch = {
  hidePrompt: () => PLAN_ACTIONS.hidePrompt(),
};

export default connect(null, mapDispatch)(PlanDowngradedPrompt);
