import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const OuterWrap = styled.div`
  border-radius: 0 0 8px 8px;
  margin-left: -24px;
  margin-right: -24px;
  background: #ffffff;
`;
const Wrap = styled.div`
  ${props =>
    props.fixedHeight &&
    css`
      height: 88px;
    `};
  display: flex;
  border-radius: 0 0 8px 8px;
  > div {
    border-radius: 0 0 8px 8px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
    > div {
      order: 5;
      margin-left: 20px;
      margin-bottom: 15px;
      &#choose-space-dropdown {
        order: 2;
        > div {
          margin-left: 0;
        }
      }
      &.info-panel {
        order: 3;
        margin: 20px !important;
        margin-top: 0px !important;
        > input[type='button'] {
          margin-left: auto;
          margin-right: auto;
          height: 40px;
          width: 100%;
        }
      }
    }
    .controls-panel {
      order: 1;
      margin-left: 20px;
      padding-top: 20px;
      margin-bottom: 10px;
    }
  }
`;

const BottomBar = ({ children, secondBar, ...props }) => (
  <OuterWrap {...props}>
    <Wrap id="action-bottom-bar" fixedHeight={!secondBar}>
      <div id="choose-space-dropdown" />
      {children}
    </Wrap>
    {secondBar || null}
  </OuterWrap>
);

export default BottomBar;
