import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Wrapper = styled.div`
  padding: ${({ label }) => (label !== '' ? '0 25px 25px' : '0 25px')};
`;

const Content = styled.div`
  display: flex;
`;

const Label = styled.h5`
  font-size: 12px;
  margin: 0;
  font-weight: normal;
  color: ${({ isResolved }) => (isResolved ? '#52BA05' : '#11A9FF')};
  ${({ isCancelled }) =>
    isCancelled &&
    css`
      color: #979797;
    `};
  text-transform: none;
  margin-bottom: 12px;
`;
const SharedWith = ({ children, label, isResolved = false, isCancelled = false }) => (
  <Wrapper label={label}>
    {label !== '' && (
      <Label isResolved={isResolved} isCancelled={isCancelled}>
        {label}
      </Label>
    )}
    <Content>{children}</Content>
  </Wrapper>
);

SharedWith.propTypes = {
  label: PropTypes.string.isRequired,
  isResolved: PropTypes.bool,
};

export default SharedWith;
