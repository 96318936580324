/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import qs from 'qs';
import FeedDateRange from 'components/shared/FeedDateRange';
import { css, jsx } from '@emotion/react';
import handleStickyScroll from 'components/feed/handleStickyScroll';
import { toggleUserModal } from 'redux/app/actions';
import SpaceMember from 'components/feed/SpaceMember';
import { growProfileUrl } from 'pages/Grow/GrowProfile/constants';
import { FILTER_TYPES_FEED } from '../../constants';
import { FORMAT_FILTERS } from '../../helpers';
import SampleFeedRightPanel from './SampleFeedRightPanel';
import Auxiliary from '../layouts/Auxiliary';
import LeaveSpaceModal from '../shared/modals/LeaveSpaceModal';
import DeleteSpaceModal from '../shared/modals/DeleteSpaceModal';
import SpaceModal from '../spaces/InformalSpaceModal';
import EditLeadsModal from '../spaces/EditLeadsModal';

const mapStateToProps = state => ({
  user: state.usersDomain.user,
  spaceMembers: state.usersDomain.spaceMembers,

  teams: state.teamsDomain.teams,

  hashtags: state.tagsDomain.feedTags,

  filters: state.postsDomain.filters,
});

const mapDispatchToProps = {
  toggleUserModal,
};

class FeedRightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAllMembers: false,
      viewAllHashtags: false,
      mobileOpen: false,
      searchString: '',
      currentSpace: '',

      showLeaveSpaceModal: false,
      showDeleteSpaceModal: false,
      showEditSpaceModal: false,
      showEditSpaceLeadsModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const el = document.querySelector(".search-input-wrap input[filter-type='string']");
    if (el) el.value = this.props.filters.string;
    if (!this.state.currentSpace || this.props.filters.space !== prevProps.filters.space) {
      const space = this.props.teams.find(team => team.id === this.props.filters.space);
      if (space) {
        this.setState({
          currentSpace: space,
        });
      }
    }
  }

  componentDidMount() {
    this.setState({ searchString: this.props.filters.string });

    document.addEventListener('touchstart', this.handleTouchStart);
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('scroll', this.handleStickyScroll);
    window.addEventListener('resize', this.handleStickyScroll);

    this.panelParent = document.getElementsByClassName('mobile-panel-overlay')[0];
    this.panel = document.getElementsByClassName('feed-right-panel-wrap')[0];
    this.postCreationContainer = document.querySelector('div.page-title');
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('scroll', this.handleStickyScroll);
    window.removeEventListener('resize', this.handleStickyScroll);
  }

  // ---Swiping listeners

  xDown = null;

  yDown = null;

  handleTouchStart = e => {
    this.xDown = e.touches[0].clientX;
    this.yDown = e.touches[0].clientY;
  };

  handleTouchMove = e => {
    if (!this.xDown || !this.yDown) {
      return;
    }
    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;
    if (
      Math.abs(xDiff) > Math.abs(yDiff) &&
      !document.querySelector('#navbar-mobile-overlay.open')
    ) {
      if (xDiff > 0) {
        if ((window.innerWidth * 7) / 8 < this.xDown) {
          setTimeout(() => this.setState({ mobileOpen: true }), 10);
        }
      } else {
        setTimeout(() => this.setState({ mobileOpen: false }), 10);
      }
    }
    this.xDown = null;
    this.yDown = null;
  };

  //--------------------
  panelParent = null;

  panel = null;

  postCreationContainer = null;

  // ---Feed container can't be kept as reference - it is recreated after space change and variable
  // ---is still referencing to old unmounted view
  handleStickyScroll = () => {
    handleStickyScroll(this.panel, this.panelParent);
  };

  showModal(e) {
    const space = this.props.teams.find(team => team.id === this.props.filters.space);
    if (space && space.informal) {
      e.preventDefault();
      this.props.showModal();
    }
  }

  viewAllMembers(e) {
    e.preventDefault();
    this.setState({ viewAllMembers: !this.state.viewAllMembers });
  }

  handleMemberClick = (member, user) => {
    const { history, toggleUserModal } = this.props;
    if (user.id === member.id) {
      history.push(`/${growProfileUrl}?${qs.stringify({ tab: 'profile' })}`);
    } else {
      toggleUserModal(true, member.id);
    }
  };

  render() {
    const types = FILTER_TYPES_FEED;
    const { user, history } = this.props;
    let tagsRenderedCount = -1;
    const canDisplaySpace =
      this.props.user.isAccountAdmin &&
      this.state.currentSpace &&
      !this.state.currentSpace.informal;
    const isTeamLead = this.props.user.leadTeamsIds.includes(
      parseInt(this.props.filters.space, 10)
    );
    return (
      <div className={`mobile-panel-overlay ${this.state.mobileOpen ? 'open' : ''}`}>
        <div className="feed-right-panel-wrap">
          {!this.props.user.should_see_sample_data ? (
            <div className="feed-right-panel-inner-wrap">
              {!this.props.home && this.props.spaceMembers && (
                <>
                  <h3>
                    Space members
                    <span>{this.props.spaceMembers.length}</span>
                  </h3>

                  <div className="filters-group space-members">
                    {this.props.spaceMembers.map((member, index) => {
                      if (this.state.viewAllMembers || (!this.state.viewAllMembers && index < 5)) {
                        return (
                          <SpaceMember
                            index={index}
                            member={member}
                            handleClick={() => this.handleMemberClick(member, user)}
                          />
                        );
                      }
                      return '';
                    })}
                    {this.props.spaceMembers.length > 5 ? (
                      <Link to={`/team/${this.props.filters.space}`}>
                        <div
                          onClick={e => this.viewAllMembers(e)}
                          className={`unfold-button ${this.state.viewAllMembers ? 'unfolded' : ''}`}
                        >
                          {this.state.viewAllMembers ? 'Collapse' : 'View all'}
                        </div>
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
              <div
                css={css`
                  margin-left: 6px;
                  width: 190px;
                `}
              >
                <FeedDateRange />
              </div>
              <h3>By post type</h3>
              <div className="filters-group">
                {types.map((type, index) => (
                  <p
                    onClick={e =>
                      this.props.history.push(
                        `/feed?${qs.stringify(FORMAT_FILTERS(e.currentTarget, this.props.filters))}`
                      )
                    }
                    custom-checked={
                      this.props.filters.types.indexOf(type.filter) > -1 ? 'true' : 'false'
                    }
                    filter-type="type"
                    type-filter={type.filter}
                    key={index}
                    id={`filter-${type.filter}`}
                  >
                    {type.caption}
                  </p>
                ))}
              </div>
              {this.props.hashtags ? (
                <Auxiliary>
                  <h3>
                    By tag
                    <span>{this.props.hashtags.length}</span>
                  </h3>
                  <div className="filters-group">
                    {this.props.hashtags
                      .filter(hashtag => this.props.filters.hashtags.indexOf(hashtag.key) > -1)
                      .map((hashtag, index) => {
                        tagsRenderedCount++;
                        if (
                          this.state.viewAllHashtags ||
                          (!this.state.viewAllHashtags && tagsRenderedCount < 5)
                        ) {
                          return (
                            <p
                              onClick={e =>
                                this.props.history.push(
                                  `/feed?${qs.stringify(
                                    FORMAT_FILTERS(e.currentTarget, this.props.filters)
                                  )}`
                                )
                              }
                              custom-checked={
                                this.props.filters.hashtags.indexOf(hashtag.key) > -1
                                  ? 'true'
                                  : 'false'
                              }
                              filter-type="hashtag"
                              hashtag-slug={hashtag.key}
                              key={index}
                            >
                              #{hashtag.key}
                            </p>
                          );
                        }
                        return '';
                      })}
                    {this.props.hashtags
                      .filter(hashtag => this.props.filters.hashtags.indexOf(hashtag.key) < 0)
                      .map((hashtag, index) => {
                        tagsRenderedCount++;
                        if (
                          this.state.viewAllHashtags ||
                          (!this.state.viewAllHashtags && tagsRenderedCount < 5)
                        ) {
                          return (
                            <p
                              onClick={e =>
                                this.props.history.push(
                                  `/feed?${qs.stringify(
                                    FORMAT_FILTERS(e.currentTarget, this.props.filters)
                                  )}`
                                )
                              }
                              custom-checked={
                                this.props.filters.hashtags.indexOf(hashtag.key) > -1
                                  ? 'true'
                                  : 'false'
                              }
                              filter-type="hashtag"
                              hashtag-slug={hashtag.key}
                              key={index}
                            >
                              #{hashtag.key}
                            </p>
                          );
                        }
                        return '';
                      })}
                    {this.props.hashtags.length > 5 ? (
                      <div
                        onClick={() =>
                          this.setState({ viewAllHashtags: !this.state.viewAllHashtags })
                        }
                        className={`unfold-button ${this.state.viewAllHashtags ? 'unfolded' : ''}`}
                      >
                        {this.state.viewAllHashtags ? 'Collapse' : 'View all'}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Auxiliary>
              ) : (
                ''
              )}
              {isTeamLead && (
                <>
                  <div
                    className="edit-space"
                    onClick={() => this.setState({ showEditSpaceModal: true })}
                  >
                    <svg
                      width="5"
                      height="8"
                      viewBox="0 0 5 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.222578 0.222578C-0.0741927 0.519349 -0.0741928 1.00051 0.222578 1.29728L2.93021 4.00492L0.232228 6.7029C-0.0645429 6.99967 -0.0645427 7.48083 0.232228 7.77761C0.528999 8.07438 1.01016 8.07438 1.30693 7.77761L4.53932 4.54522C4.78261 4.30193 4.82645 3.93472 4.67084 3.64677C4.63574 3.57878 4.58969 3.51499 4.5327 3.45799L1.29728 0.222578C1.00051 -0.0741928 0.519349 -0.0741927 0.222578 0.222578Z"
                        fill="#11A9FF"
                      />
                    </svg>
                    <span>Edit space profile</span>
                  </div>
                  <div
                    className="edit-space-leads"
                    onClick={() => this.setState({ showEditSpaceLeadsModal: true })}
                  >
                    <svg
                      width="5"
                      height="8"
                      viewBox="0 0 5 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.222578 0.222578C-0.0741927 0.519349 -0.0741928 1.00051 0.222578 1.29728L2.93021 4.00492L0.232228 6.7029C-0.0645429 6.99967 -0.0645427 7.48083 0.232228 7.77761C0.528999 8.07438 1.01016 8.07438 1.30693 7.77761L4.53932 4.54522C4.78261 4.30193 4.82645 3.93472 4.67084 3.64677C4.63574 3.57878 4.58969 3.51499 4.5327 3.45799L1.29728 0.222578C1.00051 -0.0741928 0.519349 -0.0741927 0.222578 0.222578Z"
                        fill="#11A9FF"
                      />
                    </svg>
                    <span>Edit space leads</span>
                  </div>
                </>
              )}
              {!this.props.isDefaultSpace && (
                <div
                  className="leave-space"
                  onClick={() =>
                    isTeamLead
                      ? this.setState({ showDeleteSpaceModal: true })
                      : this.setState({ showLeaveSpaceModal: true })
                  }
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M12.7306 0.00264404L18 5.27582L17.9974 12.7306L12.7242 18L5.26943 17.9974L0 12.7242L0.0026442 5.26943L5.27582 0L12.7306 0.00264404Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M10 4.66667C10 4.29848 9.55228 4 9 4C8.44772 4 8 4.29848 8 4.66667V9.33333C8 9.70152 8.44772 10 9 10C9.55228 10 10 9.70152 10 9.33333V4.66667Z"
                      fill="white"
                    />
                    <path
                      d="M10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13Z"
                      fill="white"
                    />
                  </svg>
                  <span>{isTeamLead ? 'Delete space' : 'Leave space'}</span>
                </div>
              )}
              <LeaveSpaceModal
                show={this.state.showLeaveSpaceModal}
                close={() => this.setState({ showLeaveSpaceModal: false })}
              />
              <DeleteSpaceModal
                show={this.state.showDeleteSpaceModal}
                close={() => this.setState({ showDeleteSpaceModal: false })}
              />
              {this.props.filters.space && isTeamLead && (
                <SpaceModal
                  showInformalModal={this.state.showEditSpaceModal}
                  updateFlash={this.props.updateFlash}
                  mode="edit"
                  spaceId={this.props.filters.space}
                  closeInformalModalHandler={() => this.setState({ showEditSpaceModal: false })}
                />
              )}
              {this.props.filters.space && isTeamLead && this.state.showEditSpaceLeadsModal ? (
                <EditLeadsModal
                  showEditLeadsModal={this.state.showEditSpaceLeadsModal}
                  closeEditLeadsModalHandler={() =>
                    this.setState({ showEditSpaceLeadsModal: false })
                  }
                  assignTo={{ id: this.props.filters.space }}
                  updateFlash={this.props.updateFlash}
                  resetList={() => this.props.resetSpaceView(this.props.filters)}
                />
              ) : null}
            </div>
          ) : (
            <SampleFeedRightPanel />
          )}
        </div>
      </div>
    );
  }
}

FeedRightPanel.defaultProps = {
  showModal: () => {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedRightPanel)));
