/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { ReactComponent as CloseCross } from './close-cross.svg';
import { ReactComponent as Crown } from './crown.svg';
import { ReactComponent as GreenCheckmark } from './green-checkmark.svg';
import { ReactComponent as Pig } from './pig.svg';

const PlanModalContainer = styled.div`
  background: #ffffff;
  box-shadow: 12px 12px 32px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  color: #36363b;
`;

const TopBar = styled.div`
  height: 8px;
  background-color: ${({ color }) => (!color ? '#FFC700' : color)};
`;

const Title = styled.div`
  font-weight: bold;
  line-height: 150%;
  font-size: 16px;
  display: flex;
`;

const Content = styled.div`
  margin: 24px 26px 30px;
  font-size: 13px;
  line-height: 150%;
`;

const Button = styled.div`
  background: #11a9ff;
  border-radius: 3px;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  padding: 0 24px;
  display: inline-block;
  cursor: pointer;
`;

const List = styled.ul`
  padding-left: 0;
`;

const ListItem = styled.li`
  color: #11a9ff;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  margin-bottom: 15px;
`;

const CloseButton = ({ onClick, ...props }) => {
  return (
    <CloseCross
      css={css`
        position: absolute;
        top: 24px;
        right: 18px;
        cursor: pointer;
      `}
      onClick={onClick}
      {...props}
    />
  );
};

const PlanModal = ({ children, ...props }) => {
  return <PlanModalContainer {...props}>{children}</PlanModalContainer>;
};

PlanModal.TopBar = TopBar;
PlanModal.CloseButton = CloseButton;
PlanModal.Crown = Crown;
PlanModal.GreenCheckmark = GreenCheckmark;
PlanModal.Title = Title;
PlanModal.Content = Content;
PlanModal.Pig = Pig;
PlanModal.Button = Button;
PlanModal.List = List;
PlanModal.ListItem = ListItem;

export default PlanModal;
