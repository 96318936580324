/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import striptags from 'striptags';
import ListWrap from 'components/vibes/team-vibe-show/partials/ListWrap';
import { NotesWrap, Title, Wrap } from 'components/vibes/team-vibe-show/partials/RightPanel/layout';

const PreparationNoteWrap = ({ preparationNote }) => (
  <Wrap>
    <Title>Points to be discussed</Title>
    <ListWrap steps={preparationNote.steps} />
    <Title
      css={css`
        margin-top: 10px;
      `}
    >
      Other notes
    </Title>
    <NotesWrap
      dangerouslySetInnerHTML={{
        __html: striptags(preparationNote.additional_notes, ['a', 'br']),
      }}
    />
  </Wrap>
);

const mapStateToProps = state => ({
  preparationNote: state.app.body.preparationNote,
});

export default connect(mapStateToProps, null)(PreparationNoteWrap);
