/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import ScheduleMeetingSection from '../ScheduleMeetingSection';

const SchedulePost = ({ post }) => (
  <>
    {(post.member || post.team) && (
      <div
        className="col-lg-4 col-sm-6 col-xs-12"
        css={css`
          padding: 0 !important;
        `}
      >
        <ScheduleMeetingSection key={post.id} meeting={post} additionalInfo />
      </div>
    )}
  </>
);

export default SchedulePost;
