import React, { useEffect, useState } from 'react';
import ErrorPage from '../ErrorPage';
import LoadingOverlay from 'components/signup/LoadingOverlay';
import qs from 'qs';
import loginUser from 'components/signup/integration/loginUser';

const AppleLoginRedirect = ({ history, location }) => {
  const [error, setError] = useState('');

  useEffect(() => {
    handleLogin();
  }, []);

  const handleLogin = async () => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    await loginUser(
      query,
      e => setError(e.error),
      () => history.push('/')
    );
  };

  return error ? (
    <ErrorPage title="Something went wrong" body={error} goBack={() => history.push(`/login`)} />
  ) : (
    <div className="welcome">
      <LoadingOverlay toggled message="" height="100%" />
    </div>
  );
};

export default AppleLoginRedirect;
