import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { APP_URL, AWS_S3_URL } from '../../../constants';
import { LOCALSTORAGE_GET } from '../../../helpers';

class Offline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      offlineAccountsCount: 0,
      totalOutstanding: 0,
      totalAccountsOwing: 0,
      search: this.props.search,
      column: '',
      order: '',
      user: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.props.search) {
      this.setState({ search: nextProps.search }, () => {
        this.fetchData();
      });
    }
  }

  fetchData(order) {
    const headers = new Headers();
    let accessToken = '';
    if (localStorage != null) {
      accessToken = LOCALSTORAGE_GET('access_token');
    }
    headers.append('Accept', 'application/json');
    headers.append('authorization', `Bearer ${accessToken}`);
    let url = `/admin/accounts?tab=offline&search=${this.state.search}`;
    if (order) {
      url += order;
    }
    fetch(APP_URL + url, { method: 'get', headers }).then(response => {
      response
        .json()
        .then(jsonResponse => {
          this.setState({
            accounts: jsonResponse.accounts,
            offlineAccountsCount: jsonResponse.offline_accounts_count,
            totalOutstanding: jsonResponse.total_outstanding,
            totalAccountsOwing: jsonResponse.total_accounts_owing,
            column: jsonResponse.column,
            order: jsonResponse.order,
            user: jsonResponse.user,
          });
        })
        .catch(() => {});
    });
    if (url !== this.props.location.pathname + this.props.location.search) {
      this.props.history.push(url);
    }
  }

  imgSrc(account) {
    const defaultTeam = account.teams[0];

    if (defaultTeam) {
      return defaultTeam.image_uid
        ? defaultTeam.image_url
        : `${AWS_S3_URL}public/images/defaults/team.jpg`;
    }
  }

  nextInvoice(account) {
    const invoice = account.offline_invoices[0];

    if (invoice) {
      const dueDate = new Date(invoice.date_due).getTime();
      let nowDate = new Date();
      nowDate = nowDate.getTime();
      if (invoice.paid) {
        return 'All paid';
      }
      if (dueDate < nowDate) {
        return (
          <div>
            {invoice.amount_due} overdue by
            <TimeAgo date={invoice.date_due} />
          </div>
        );
      }
      return (
        <div>
          {invoice.amount_due} due in
          <TimeAgo date={invoice.date_due} />
        </div>
      );
    }
    return 'no invoices';
  }

  orderBy(e, column, order = 'desc') {
    e.preventDefault();
    if (column === this.state.column) {
      order = this.state.order === 'desc' ? 'asc' : 'desc';
    }
    this.fetchData(`&order[${column}]=${order}`);
  }

  setIcon(name) {
    const caret = this.state.column !== name ? 'left' : this.state.order === 'asc' ? 'up' : 'down';
    return <i className={`fa fa-caret-${caret}`} />;
  }

  getImpersonateLink(account) {
    return account.users.length > 0 ? `/admin/impersonate/${account.users[0].id}` : '#';
  }

  render() {
    return (
      <div>
        <div className="stats-bar">
          <div className="stats-content">
            <div className="stats-heading">Total Invoiced Accounts</div>
            <div className="stats-figure">{this.state.offlineAccountsCount}</div>
          </div>
          <div className="stats-content">
            <div className="stats-heading">Total Outstanding</div>
            <div className="stats-figure">${this.state.totalOutstanding}</div>
          </div>
          <div className="stats-content">
            <div className="stats-heading">Total Accounts Owing</div>
            <div className="stats-figure">{this.state.totalAccountsOwing}</div>
          </div>
        </div>
        <table className="table">
          <tbody>
            <tr>
              <th />
              <th onClick={e => this.orderBy(e, 'name')}>
                <a href="/#">
                  Name
                  {this.setIcon('name')}
                </a>
              </th>
              <th onClick={e => this.orderBy(e, 'created_at')}>
                <a href="/#">
                  Created
                  {this.setIcon('created_at')}
                </a>
              </th>
              <th className="hidden-sm hidden-xs">Invoices</th>
              <th className="hidden-sm hidden-xs">Next Invoice Due</th>
              <th onClick={e => this.orderBy(e, 'paid_until')}>
                <a href="/#">
                  Paid Until
                  {this.setIcon('paid_until')}
                </a>
              </th>
              <th />
            </tr>
            {this.state.accounts.map(account => (
              <tr key={account.id}>
                <td style={{ width: '40px' }}>
                  <img
                    src={this.imgSrc(account)}
                    alt={account.name}
                    className="shadow"
                    style={{ height: '20px' }}
                  />
                </td>
                <td>
                  <Link to={`/admin/accounts/${account.id}`} className="primary-link">
                    {account.name}
                  </Link>
                </td>
                <td className="hidden-sm hidden-xs">
                  <TimeAgo date={`${account.created_at} ${this.state.user.time_zone}`} />
                </td>
                <td className="hidden-sm hidden-xs">{account.offline_invoices_count}</td>
                <td className="hidden-sm hidden-xs">{this.nextInvoice(account)}</td>
                <td className="hidden-sm hidden-xs">
                  {account.paid_until ? account.paid_until : 'Not paid'}
                </td>
                <td className="text-right">
                  <Link to="" className="primary-link">
                    new invoice
                  </Link>
                  &nbsp;
                  <Link to={this.getImpersonateLink(account)} className="primary-link">
                    impersonate
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default Offline;
