/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import WistiaVideo from './WistiaVideo';

const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  width: 43%;
  float: left;
  text-align: left;
  color: #323232;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const Videos = styled.div`
  @media (min-width: 500px) {
    max-height: 420px;
  }
  padding-right: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
  }
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  margin-top: 8px;
  @media (max-width: 580px) {
    margin-top: 30px;
  }
  @media (max-width: 450px) {
    margin-top: 8px;
  }
`;

const Time = styled.span`
  color: #d5d5d5;
`;

const VideoWrapper = ({ video }) => (
  <div
    css={css`
      :not(:first-child) {
        margin-top: 20px;
      }
    `}
  >
    <WistiaVideo videoId={video.id} />
    <Description>
      {video.description}&nbsp;
      <Time>({video.time})</Time>
    </Description>
  </div>
);

const videosArray = [
  {
    id: 'vneyabgdlf',
    description: 'Grow',
    time: '3:00',
  },
  {
    id: '0laoi1xft8',
    description: 'Feed and Spaces',
    time: '1:37',
  },
  {
    id: '0uopi6416c',
    description: 'Insights',
    time: '2:26',
  },
  {
    id: 'dxoo3rrc2o',
    description: 'Give and Get Feedback',
    time: '1:30',
  },
  {
    id: 'c2yxz0e46k',
    description: 'Share',
    time: '0:57',
  },
  {
    id: 'mhnilnsaa7',
    description: 'Vibe',
    time: '1:10',
  },
];

const HowTo = ({}) => {
  return (
    <Wrapper>
      <Title>How-to-videos</Title>
      <Videos>
        {videosArray.map(video => (
          <VideoWrapper key={video.id} video={video} />
        ))}
      </Videos>
    </Wrapper>
  );
};

export default HowTo;
